import React, { MouseEventHandler } from 'react'
import classnames from 'classnames'
import Button from './Button'
import LoaderButton from '../../molecules/Loaders/LoaderButton'

export type Props = {
    type: 'button' | 'submit' | 'reset' | undefined
    ariaLabel: string
    className?: String
    disabled?: boolean
    children?: React.ReactNode
    onClick?: MouseEventHandler
    darkTheme?: boolean
    fullWidth?: boolean
    showLoader?: boolean
    style?: React.CSSProperties
}

const ButtonWithLoader: React.FC<Props> = ({
    type = 'button',
    ariaLabel,
    className,
    disabled = false,
    children,
    onClick = () => {
    },
    darkTheme = false,
    fullWidth = false,
    showLoader = false,
    style,
}: Props) => (
    <Button
        // @ts-ignore
        className={classnames(className, {
            'dark-mode': darkTheme,
            'full-width': fullWidth,
        })}
        type={type}
        ariaLabel={ariaLabel}
        disabled={disabled || showLoader}
        onClick={onClick}
        style={style}
    >
        {showLoader && <LoaderButton />}
        {children && !showLoader && children}
    </Button>
)

export default ButtonWithLoader

import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import SlideLeftMobile from '../Panels/SlideLeftMobile'

const PanelBlockResponsive = ({
    title = '', isOpen, onClose, children,
}) => (
    <>
        <SlideLeftMobile
            className={'md:hidden'}
            isOpen={isOpen}
            title={title}
            onClose={onClose}
        >
            {children}
        </SlideLeftMobile>

        <div className={'hidden md:grid account--panel-desktop-right'}>
            <div className={'grid w-full bg-white px-8 account--panel-desktop-wrap'}>
                {title && (
                    <h4 className={'account--panel-title font-bold mt-6'}>
                        <FormattedMessage id={title} />
                    </h4>
                )}
                {children}
            </div>
        </div>
    </>
)

PanelBlockResponsive.propTypes = {
    title: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
}

export default PanelBlockResponsive

import React, { useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import classnames from 'classnames'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import PropTypes from 'prop-types'
import { isArray } from 'lodash'
import StoreService, { OpeningTimeColor, OpeningTimeTypeNum } from '../../../../services/algolia/StoreService'
import { ClockIcon } from '../../../atoms/icon'
import { ReactComponent as ArrowDownIcon } from '../../../../../icons/sport2000-icons/arrow-down.svg'
import OpeningTime from '../StoreFinder/OpeningTime'

const OpeningHours = ({ hours, intl }) => {
    if (!hours) {
        return null
    }

    const isDesktop = useDeviceType() === 'desktop'

    const renderStatus = () => {
        if (isArray(hours)) {
            return null
        }

        const statusNumber = StoreService.getStatus(hours)
        const status = OpeningTimeTypeNum[statusNumber]

        return (
            <span
                className={'open-status lg:font-bold lg:mb-1'}
                style={{ color: OpeningTimeColor[statusNumber] }}
            >
                {intl.formatMessage({ id: `store.${status}` })}
            </span>
        )
    }

    const [isOpenTime, setIsOpenTime] = useState(false)

    const renderOpeningTimeToday = (workingTime) => StoreService.getOpeningTimeToday(workingTime)

    return (
        <div className={'store-detail-open-hour'}>
            {isDesktop && (
                <div className={'store-detail-open-hour-headline font-bold mb-4'}>
                    <FormattedMessage id={'storeDetail.openingHeadline'} />
                </div>
            )}
            <div className={classnames('store-slider-item--time store-finder-row', {
                active: isOpenTime && !isDesktop,
            })}
            >
                <button
                    type={'button'}
                    aria-label={''}
                    className={'w-full store-detail-open-hour-button'}
                    onClick={(e) => {
                        e.stopPropagation()
                        setIsOpenTime(!isOpenTime)
                    }}
                >
                    <ClockIcon width={20} height={20} className={'mr-2 lg:hidden'} />
                    {renderStatus()}
                    {!isDesktop && (
                        <>
                            <span className={'mx-1'}>-</span>
                            <FormattedMessage id={'storeFinder.today'} />
                            <span className={'mr-4'} />
                            <span className={'mr-2'}>
                                {renderOpeningTimeToday(hours).map((hour, index) => (
                                    <div key={hour + index}>{hour}</div>
                                ))}
                            </span>
                            <ArrowDownIcon width={12} height={12} className={'ml-2'} />
                        </>
                    )}
                </button>
                <div className={'opening-time-container'}>
                    <OpeningTime time={hours} />
                </div>
            </div>
        </div>
    )
}

OpeningHours.propTypes = {
    hours: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
}

export default injectIntl(OpeningHours)

import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Entity from '@frontastic/catwalk/src/js/app/entity'
import Stepper from '../Stepper'
import Panels from '../Panels'

import OverviewPanel from '../../../organisms/CheckoutPanels/OverviewPanel'
import ShippingPanel from '../../../organisms/CheckoutPanels/ShippingPanel'
import PaymentPanel from '../../../organisms/CheckoutPanels/PaymentPanel'

const CheckoutPanels = ({
    app,
    data,
    cart,
    countries,
    contextSession,
    policy,
    isLoading = false,
    paymentInfo,
    setPaymentInfo,
    paymentMethods,
}) => {
    const [current, setCurrent] = useState(0)
    const ts = useRef(null)
    const adyenComponentRef = useRef(null)
    const paymentIdRef = useRef(null)
    const adyenFormChallengeRef = useRef(null)

    const [activeStep1, setActiveStep1] = useState(true)
    const [activeStep2, setActiveStep2] = useState(false)
    const [activeStep3, setActiveStep3] = useState(false)
    const [divider1, setDivider1] = useState(false)
    const [divider2, setDivider2] = useState(false)
    const [validNextStep, setValidNextStep] = useState(false)
    const [validNextStep2, setValidNextStep2] = useState(false)
    const [buttonConfirmDisabled, setButtonConfirmDisabled] = useState(false)

    const steps = [
        {
            name: <FormattedMessage id={'checkout.shipping'} />,
            component: ShippingPanel,
        },
        {
            name: <FormattedMessage id={'checkout.payment'} />,
            component: PaymentPanel,
        },
        {
            name: <FormattedMessage id={'checkout.overview'} />,
            component: OverviewPanel,
        },
    ]

    return (
        <div className={'checkout-panels my-comp mb-0'}>
            <Stepper
                steps={steps}
                current={current}
                setCurrent={setCurrent}
                ref={ts}
                activeStep1={activeStep1}
                activeStep2={activeStep2}
                activeStep3={activeStep3}
                setActiveStep1={setActiveStep1}
                setActiveStep2={setActiveStep2}
                setActiveStep3={setActiveStep3}
                divider1={divider1}
                divider2={divider2}
                setDivider1={setDivider1}
                setDivider2={setDivider2}
            />
            <Panels
                app={app}
                cart={cart}
                activeStep1={activeStep1}
                activeStep2={activeStep2}
                activeStep3={activeStep3}
                setActiveStep1={setActiveStep1}
                setActiveStep2={setActiveStep2}
                setActiveStep3={setActiveStep3}
                validNextStep={validNextStep}
                validNextStep2={validNextStep2}
                setValidNextStep={setValidNextStep}
                setValidNextStep2={setValidNextStep2}
                setDivider1={setDivider1}
                setDivider2={setDivider2}
                data={data}
                countries={countries}
                policy={policy}
                steps={steps}
                current={current}
                setCurrent={setCurrent}
                ref={ts}
                isLoading={isLoading}
                setPaymentInfo={setPaymentInfo}
                paymentInfo={paymentInfo}
                contextSession={contextSession}
                paymentMethods={paymentMethods}
                adyenComponentRef={adyenComponentRef}
                paymentIdRef={paymentIdRef}
                buttonConfirmDisabled={buttonConfirmDisabled}
                setButtonConfirmDisabled={setButtonConfirmDisabled}
            />
        </div>
    )
}

CheckoutPanels.propTypes = {
    app: PropTypes.object.isRequired,
    contextSession: PropTypes.object.isRequired,
    setPaymentInfo: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    countries: PropTypes.array.isRequired,
    cart: PropTypes.instanceOf(Entity),
    isLoading: PropTypes.bool,
    policy: PropTypes.string,
    paymentMethods: PropTypes.object.isRequired,
}

export default CheckoutPanels

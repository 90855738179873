import React from 'react'
import PropTypes from 'prop-types'

import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import app from '@frontastic/catwalk/src/js/app/app'
import AccountWishlist from '../../patterns/organisms/Account/AccountWishlist'
import CartService from '../../services/cart'

const WishlistTastic = ({
    wishlist, context, data, node,
}) => (
    <AccountWishlist
        wishlist={wishlist}
        returnToHomePage={() => {
            app.getRouter().history.replace('/')
        }}
        handleAddToCart={CartService.addToCart}
        context={context}
        node={node}
    />
)

WishlistTastic.propTypes = {
    wishlist: PropTypes.object.isRequired,
}

export default tastify({ translate: true, connect: { wishlist: true, context: true, node: true } })(WishlistTastic)

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import { useHistory } from 'react-router-dom'
import Button from '../../../atoms/button/Button'

export const PdpCampaign = ({
    data, color, backgroundColor, position, alignText,
}) => {
    if (!data) {
        return null
    }

    const history = useHistory()
    const isMobile = useDeviceType() === 'mobile'

    // check if no mobileUrl will display desktopUrl
    const backgroundUrl = isMobile && data.campaignImageMobile?.url
        ? data.campaignImageMobile?.url : data.campaignImageDesktop?.url
    const logoPosition = isMobile ? data.logoMobilePosition : data.logoDesktopPosition

    const style = {
        backgroundImage: `url(${backgroundUrl})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '100%',
        minHeight: '400px',
    }

    const BRAND_LOGO_POSITION = {
        TOP_LEFT: 'top_left',
        TOP_CENTER: 'top_center',
        TOP_RIGHT: 'top_right',
        BOTTOM_LEFT: 'bottom_left',
        BOTTOM_CENTER: 'bottom_center',
        BOTTOM_RIGHT: 'bottom_right',
    }

    const handleChange = () => {
        history.push(`/${data.campaignUrl}`)
    }

    return (
        <div className={'pdp-campaign'}>
            <div className={classnames('text-image-component flex flex-wrap', {
                'md:flex-row-reverse': position === 'left',
            })}
            >
                <div
                    style={{ backgroundColor: `${backgroundColor}`, color: `${color}` }}
                    className={'content-wrapper flex w-full md:w-1/2 xl:w-1/3'}
                >
                    <div className={`group markdown--align__${alignText}`}>
                        {data.headline && (
                            <h3>{data.headline}</h3>
                        )}
                        {data.campaignSubheadline && (
                            <p>{data.campaignSubheadline}</p>
                        )}
                        <div className={'content-wrapper--button'}>
                            {data.ctaButton && (
                                <Button
                                    onClick={handleChange}
                                    className={'btn btn-default'}
                                >
                                    {data.ctaButton}
                                </Button>
                            )}
                        </div>

                    </div>
                </div>
                <div className={'w-full md:w-1/2 xl:w-2/3 image-wrapper'}>
                    <div style={style} className={'pdp-campaign-image-box relative'}>
                        <div className={classnames('pdp-campaign-brand-logo absolute', {
                            'brand-logo': !logoPosition,
                            'brand-logo-top left-0': logoPosition === BRAND_LOGO_POSITION.TOP_LEFT,
                            'brand-logo-top left-0 right-0 mx-auto': logoPosition === BRAND_LOGO_POSITION.TOP_CENTER,
                            'brand-logo-top right-0': logoPosition === BRAND_LOGO_POSITION.TOP_RIGHT,
                            'brand-logo-bottom left-0': logoPosition === BRAND_LOGO_POSITION.BOTTOM_LEFT,
                            'brand-logo-bottom left-0 right-0 mx-auto': logoPosition === BRAND_LOGO_POSITION.BOTTOM_CENTER,
                            'brand-logo-bottom right-0': logoPosition === BRAND_LOGO_POSITION.BOTTOM_RIGHT,
                        })}
                        >
                            {data.brandLogo?.url && (
                                <img
                                    src={data.brandLogo.url}
                                    alt={data.brandLogo.url}
                                />
                            )}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

PdpCampaign.propTypes = {
    data: PropTypes.object.isRequired,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    position: PropTypes.PropTypes.oneOf(['left', 'right']),
    alignText: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
}

export default PdpCampaign

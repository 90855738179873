import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import Button from '../../../atoms/button/Button'
import IconButton from '../../../atoms/button/IconButton'
import { EditIcon } from '../../../atoms/icon'

const AccountDetailsOverview = ({
    intl,
    firstName,
    lastName,
    email,
    openAccountDetailsForm,
    openChangePasswordForm,
}) => (
    <div className={'account--detail-overview mt-3 md:mt-0'}>
        <div className={'md:pb-0 md:mx-0 mx-4'}>
            <div className={'account--detail-overview-list'}>
                <div className={'mb-4 md:mt-4'}>
                    <strong>
                        <FormattedMessage id={'checkout.form.firstName'} />
                    </strong>
                    <div>
                        {firstName}
                        {' '}
                    </div>
                </div>
                <div className={'mb-4'}>
                    <strong>
                        <FormattedMessage id={'checkout.form.lastName'} />
                    </strong>
                    <div>
                        {lastName}
                        {' '}
                    </div>
                </div>
                <div className={'mb-6'}>
                    <strong>
                        <FormattedMessage id={'checkout.form.email'} />
                    </strong>
                    <div>
                        {email}
                        {' '}
                    </div>
                </div>
                <IconButton
                    type={'button'}
                    aria-label={intl.formatMessage({ id: 'account.edit' })}
                    className={'btn btn-text flex items-center account--edit-button icon--left '}
                    onClick={openAccountDetailsForm}
                    icon={<EditIcon />}
                >
                    <FormattedMessage id={'account.edit'} />
                </IconButton>
            </div>
        </div>
        <div className={'mt-6 text-center md:text-left'}>
            <Button
                type={'button'}
                aria-label={intl.formatMessage({ id: 'account.changePassword' })}
                className={'btn btn-secondary'}
                onClick={openChangePasswordForm}
            >
                <FormattedMessage id={'account.changePassword'} />
            </Button>
        </div>
    </div>
)

AccountDetailsOverview.propTypes = {
    intl: PropTypes.object.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    openAccountDetailsForm: PropTypes.func.isRequired,
    openChangePasswordForm: PropTypes.func.isRequired,
}

export default injectIntl(AccountDetailsOverview)

import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import _ from 'lodash'
import Price from '../../../atoms/price'
import IconButton from '../../../atoms/button/IconButton'
import { FileIcon } from '../../../atoms/icon'

const ListOrders = ({ intl, orders, selectOrder }) => {
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' }

    return (
        <div className={'account--order-overview mt-4'}>
            {orders.map((order, index) => {
                let lineItemSum = 0
                order.lineItems.map((lineItem) => (lineItemSum += lineItem.count))
                let lineItemPrice = 0
                order.lineItems.map((lineItem) => (lineItemPrice += lineItem.totalPrice))
                return (
                    <div key={order.orderId}>
                        <div className={'account--order-overview-item bg-white mb-4'}>
                            <strong>
                                <FormattedMessage id={'account.order'} />
                                {' '}
                                {order.orderId}
                            </strong>

                            <div className={'order-overview-text order-overview-date mb-4'}>
                                {new Date(order.createdAt).toLocaleString([], dateOptions)}
                            </div>
                            <div className={'text-sm mb-5'}>
                                {order.projectSpecificData.trackingCodes && order.projectSpecificData.trackingCodes.length > 0 && (
                                    <div>
                                        <span className={'text-black'}>
                                            <FormattedMessage id={'account.trackingNumber'} />
                                        </span>

                                        <span className={'font-bold'}>
                                            {' '}
                                            {order.projectSpecificData.trackingCodes.join(', ')}
                                        </span>
                                    </div>
                                )}
                                <div>
                                    <span className={'text-black'}>
                                        <FormattedMessage id={'account.quantity'} />
                                    </span>
                                    <span className={'font-bold'}>{lineItemSum}</span>
                                </div>
                                <div>
                                    <span className={'text-black'}>
                                        <FormattedMessage id={'account.totalAmountOrder'} />
                                        {' '}
                                    </span>
                                    <Price variant={'font-bold'} value={lineItemPrice} />
                                </div>
                            </div>

                            <IconButton
                                type={'button'}
                                ariaLabel={intl.formatMessage({ id: 'account.viewOrder' })}
                                className={'account--orderview-button flex items-center mt-4 p-0'}
                                icon={<FileIcon />}
                                disabled={_.isEmpty(order.orderId)}
                                onClick={() => {
                                    selectOrder(order)
                                }}
                            >
                                <FormattedMessage id={'account.viewOrder'} />
                                <div className={'tooltip'}>
                                    <FormattedMessage id={'account.noOrder'} />
                                </div>
                            </IconButton>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

ListOrders.propTypes = {
    intl: PropTypes.object.isRequired,
    orders: PropTypes.array.isRequired,
    selectOrder: PropTypes.func.isRequired,
}

export default injectIntl(ListOrders)

// The breakpoints have to match with tailwind.config.js
const BREAKPOINTS = {
    xsm: 420,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1624,
    xxxl: 1840,
    tablet: 768,
    laptop: 1024,
    desktop: 1280,
    desktopx: 1624,
    desktopxx: 1840,
}

export default BREAKPOINTS

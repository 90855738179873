import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import GenderFilter from './Gender'
import TermAndPriceFilter from './TermAndPrice'
import { CloseIcon, LoadingIcon } from '../../../atoms/icon'
import ChosenFacet from '../Facets/ChosenFacet'
import Button from '../../../atoms/button/Button'
import FacetService from '../../../../services/facet'

const VertialFilter = ({
    intl,
    facets,
    onChange,
    onClose,
    onApply,
    total,
    isOpen,
    isLoading,
    onReset,
}) => {
    if (!facets || !facets.length) {
        return null
    }
    const initFacets = [...facets]
    const isMobile = useDeviceType() === 'mobile'

    useEffect(() => {
        if (isMobile) {
            document.body.classList.toggle('overflow-hidden', isOpen)
        }
    }, [isOpen])

    const handleChange = (facets) => {
        if (onChange) {
            onChange(facets)
        }
    }

    const handleReset = () => {
        const clearedFacet = FacetService.clearFacets(initFacets)
        if (onReset) {
            onReset(clearedFacet)
        }
    }

    return (
        <CSSTransition
            in={isOpen}
            unmountOnExit
            timeout={500}
            classNames={'filter-all'}
        >
            <div className={'product-list-filter filter-all-wrapper'}>
                <div className={'overlay-filter hidden lg:block'} onClick={() => onClose()} />
                <div className={'product-list-filter-all'}>
                    <div className={'product-list-filter-all--header'}>
                        <h6><FormattedMessage id={'filters.filters'} /></h6>
                        <CloseIcon width={20} height={20} onClick={() => onClose()} />
                        <GenderFilter
                            facetList={facets}
                            onChange={(termIndex, facetIndex) => handleChange(termIndex, facetIndex)}
                        />
                    </div>
                    <ChosenFacet
                        facets={facets}
                        onRemove={(facets) => handleChange(facets)}
                        className={'lg:hidden'}
                    />
                    <div className={'product-list-filter-all--content'}>
                        <div className={'product-list-filter-all--content-wrapper'}>
                            <TermAndPriceFilter
                                facetList={facets}
                                onChange={(facets) => handleChange(facets)}
                            />
                        </div>
                    </div>
                    <div className={'product-list-filter-all--footer'}>
                        <p>
                            {isLoading ? <LoadingIcon width={20} height={20} /> : total}
                            <FormattedMessage id={'filters.total'} />
                        </p>
                        <div className={'filter-footer-action'}>
                            <Button
                                className={'btn btn-secondary'}
                                type={'button'}
                                ariaLabel={intl.formatMessage({ id: 'filters.filters' })}
                                onClick={handleReset}
                            >
                                <FormattedMessage id={'filters.resetAll'} />
                            </Button>
                            <Button
                                className={'btn btn-default'}
                                type={'button'}
                                ariaLabel={intl.formatMessage({ id: 'filters.filters' })}
                                onClick={onApply}
                            >
                                <FormattedMessage id={'filters.filters'} />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    )
}
VertialFilter.propTypes = {
    intl: PropTypes.object.isRequired,
    facets: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    total: PropTypes.number.isRequired,
}

export default injectIntl(VertialFilter)

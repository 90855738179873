import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import MediaImage from '@frontastic/catwalk/src/js/mediaImage'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import FullPageWidthWrapper from '../../molecules/Layout/FullPageWidthWrapper'
import Button from '../../atoms/button/Button'
import { useRouter } from "next/router";

const ThankYou = ({
    title,
    desc1,
    desc2,
    desc3,
    positionMode,
    label,
    media,
    reference,
    btnVariant,
    align,
    bgColor,
    textColor,
    data,
    context,
}) => {
    const {
        orderId, email, sum, currency, payments,
    } = data.stream || {}
    const style = {}
    const styleText = {}

    const { query } = useRouter()

    const formatDate = (date) => {
        const d = new Date(date)
        let month = `${d.getMonth() + 1}`
        let day = `${d.getDate()}`
        const year = d.getFullYear()

        if (month.length < 2) month = `0${month}`
        if (day.length < 2) day = `0${day}`

        return [year, month, day].join('-')
    }

    const caculateEstDeliveryDate = () => {
        const orderedDate = formatDate(data.stream.createdAt)

        if (!orderedDate) {
            return null
        }

        const estDate = new Date(orderedDate)
        estDate.setDate(estDate.getDate() + 4)

        return formatDate(estDate)
    }

    if (bgColor) {
        style.backgroundColor = bgColor
    }

    if (textColor) {
        styleText.color = textColor
    }

    const getCartId = () => {
        return query.order
    }

    const { algoliaIndexName } = context?.projectConfiguration || {}

    return (
        <FullPageWidthWrapper withWrapper customClasses={'thank-you-component'}>
            <div className={'relative'}>
                {algoliaIndexName && (
                    <div
                        data-insights-index={algoliaIndexName}
                        className={classnames('text-image-component is-thank-you flex flex-wrap', {
                            'md:flex-row-reverse': positionMode === 'left',
                        })}
                    >
                        <div style={style} className={'content-wrapper flex w-full md:w-1/2'}>
                            <div className={`group markdown--align__${align}`}>
                                {title && (
                                    <h2 className={'headline'} style={styleText}>{title}</h2>
                                )}
                                {desc1 && (
                                    <p style={styleText}>{desc1}</p>
                                )}

                                {desc2 && (
                                    <p style={styleText}>
                                        <span>
                                            {desc2}
                                        </span>
                                        <span>
                                            {getCartId()}
                                        </span>
                                    </p>
                                )}

                                {desc3 && (
                                    <p style={styleText}>{desc3}</p>
                                )}
                                {reference && (
                                    <Button
                                        type={'button'}
                                        ariaLabel={label}
                                        className={classnames('btn', {
                                            'btn-default': btnVariant === 'default',
                                            'btn-cta': btnVariant === 'cta',
                                            'btn-secondary': btnVariant === 'secondary',
                                        })}
                                        reference={reference}
                                    >
                                        {label}
                                    </Button>
                                )}
                            </div>
                        </div>
                        {media && (
                            <div className={'w-full md:w-1/2 image-wrapper'}>
                                <MediaImage media={media} />
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div id={'trustedShopsCheckout'} className={'hidden'}>
                {orderId && (
                    <span id={'tsCheckoutOrderNr'}>{orderId}</span>
                )}
                {email && (
                    <span id={'tsCheckoutBuyerEmail'}>{email}</span>
                )}
                {sum && (
                    <span id={'tsCheckoutOrderAmount'}>
                        {((sum / 100).toLocaleString('en', { minimumFractionDigits: 2 })).replace(',', '')}
                    </span>
                )}
                {currency && (
                    <span id={'tsCheckoutOrderCurrency'}>{currency}</span>
                )}
                {orderId && (
                    <span id={'tsCheckoutOrderNr'}>{orderId}</span>
                )}
                {payments && payments.length > 0 && (
                    <span id={'tsCheckoutOrderPaymentType'}>{payments[0].paymentMethod}</span>
                )}

                <span id={'tsCheckoutOrderEstDeliveryDate'}>{caculateEstDeliveryDate()}</span>

                {/* Product Reviews */}
                {data?.stream?.lineItems?.map((item) => {
                    const renderProductUrl = item.variant.projectSpecificData.productUrl && `https://www.sport2000.de/${item.variant.projectSpecificData.productUrl}`

                    return (
                        <span className={'tsCheckoutProductItem'}>
                            <span className={'tsCheckoutProductUrl'}>{renderProductUrl}</span>
                            {item.variant.images && item.variant.images.length && (
                                <span className={'tsCheckoutProductImageUrl'}>{item.variant.images[0]}</span>
                            )}
                            {item.name && (
                                <span className={'tsCheckoutProductName'}>{item.name}</span>
                            )}
                            {item.variant.sku && (
                                <span className={'tsCheckoutProductSKU'} data-insights-object-id={item.variant.sku}>{item.variant.sku}</span>
                            )}
                            {item.variant.attributes.att_prod_gtin && (
                                <span className={'tsCheckoutProductGTIN'}>{item.variant.attributes.att_prod_gtin}</span>
                            )}
                            {item.variant.attributes.att_prod_brand && (
                                <span className={'tsCheckoutProductBrand'}>{item.variant.attributes.att_prod_brand}</span>
                            )}
                        </span>
                    )
                })}
                {/* End Product Reviews */}
            </div>
        </FullPageWidthWrapper>
    )
}

ThankYou.propTypes = {
    title: PropTypes.string.isRequired,
    desc1: PropTypes.string.isRequired,
    desc2: PropTypes.string.isRequired,
    desc3: PropTypes.string.isRequired,
    align: PropTypes.string,

    label: PropTypes.string.isRequired,
    reference: PropTypes.object.isRequired,
    btnVariant: PropTypes.oneOf(['cta', 'secondary', 'default']),

    media: PropTypes.objectOf(PropTypes.any).isRequired,
    bgColor: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,

    positionMode: PropTypes.oneOf(['left', 'right']),
    data: PropTypes.object.isRequired,
}

export default tastify()(ThankYou)

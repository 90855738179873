import React from 'react'
import PropTypes from 'prop-types'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import ProductCategoryTiles from '../../patterns/organisms/ProductCategoryTiles'

export const ProductCategoryTilesTastic = ({ data }) => {
    const { title, categories } = data
    if (!categories) {
        return null
    }

    return (
        <ProductCategoryTiles categories={categories} title={title} />
    )
}
ProductCategoryTilesTastic.propTypes = {
    data: PropTypes.object.isRequired,
}

export default tastify({ translate: true })(ProductCategoryTilesTastic)

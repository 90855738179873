class FacetService {
    getColorStyle(name) {
        const colorStyle = {
            White: { backgroundColor: 'var(--color-white-100)' },
            Multicolored: {
                backgroundImage: 'linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)',
            },
            Beige: { backgroundColor: '#F5F5DC' },
            Grey: { backgroundColor: '#808080' },
            Blue: { backgroundColor: '#213EDA' },
            Black: { backgroundColor: 'var(--color-black-100)' },
            Pink: { backgroundColor: '#FFC0CB' },
            Green: { backgroundColor: '#87CB96' },
            Red: { backgroundColor: '#EA3636' },
            Yellow: { backgroundColor: '#FCEA18' },
            Gold: { backgroundColor: '#FFD700' },
            Oliv: { backgroundColor: '#808000' },
            Silver: { backgroundColor: '#C0C0C0' },
            Brown: { backgroundColor: '#A52A2A' },
        }

        return colorStyle[name]
    }

    isColorMulticolored(name) {
        return name === 'Multicolored'
    }

    isSortEqual(firstSort, secondSort) {
        return firstSort.attributeId === secondSort.attributeId && firstSort.order === secondSort.order
    }

    getFacetName(facet) {
        return facet.key.replace('variants.', '').replace('attributes.', '')
    }

    getFacetLabelValue(facet) {
        if (facet.selected) {
            if (facet.type === 'term' && this.numberOfSelectedFacet(facet) > 0) {
                return `${this.numberOfSelectedFacet(facet)}`
            }

            if (facet.type === 'range') {
                return '1'
                // return `${(facet.value.min / 100).toFixed(2)} - ${(facet.value.max / 100).toFixed(2)}`
            }
        }

        return ''
    }

    isColorFacet(facet) {
        return facet.key === 'variants.attributes.color'
    }

    isGenderFacet(facet) {
        return facet.key === 'variants.attributes.gender'
    }

    isPriceFacet(facet) {
        return facet.key === 'variants.attributes.price'
    }

    isSizeFacet(facet) {
        return facet.key === 'variants.attributes.att_prod_size_eu'
    }

    countTermSelected(facets) {
        let count = 0

        facets.forEach((facet) => {
            if (facet.type === 'range' && facet.selected) {
                count += 1
            }

            if (facet.type === 'term') {
                facet.terms.forEach((term) => {
                    if (term.selected) {
                        count += 1
                    }
                })
            }
        })

        return count
    }

    numberOfSelectedFacet(facet) {
        return facet.terms.filter((term) => term.selected === true).length
    }

    numberOfSelectedFacets(facets) {
        return this.countTermSelected(facets)
    }

    clearFacet(facet) {
        facet.selected = false

        if (facet.type === 'range') {
            facet.value.min = facet.min
            facet.value.max = facet.max
        }

        if (facet.type === 'term') {
            facet.terms.forEach((term) => {
                term.selected = false
            })
        }
    }

    clearFacets(facets) {
        facets.forEach((facet) => this.clearFacet(facet))
        return facets
    }
}

export default new FacetService()

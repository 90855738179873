import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import BREAKPOINTS from '../../../../config/breakpoints'

const useSlidePanel = () => {
    const [windowWidth, setWindowWidth] = useState(0)

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }

        setWindowWidth(window.innerWidth)

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const { usePanel } = useSelector((state) => {
        const width = state.renderContext.viewportDimension
            ? state.renderContext.viewportDimension.width
            : windowWidth

        return {
            usePanel: width < BREAKPOINTS.md,
        }
    })

    return { usePanel }
}

export default useSlidePanel

import React from 'react'
import PropTypes from 'prop-types'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import BlogShortDescription from '../../../patterns/organisms/GraphCMS/BlogShortDescription'

export const BrandContentShortTastic = ({ data }) => {
    const { content } = data
    if (!content) {
        return null
    }

    return (
        <div>
            <BlogShortDescription content={content} />
        </div>
    )
}

BrandContentShortTastic.propTypes = {
    data: PropTypes.object.isRequired,
}

export default tastify({ translate: true })(BrandContentShortTastic)

import React, { Component } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import classnames from 'classnames'
import { SwiperSlide } from 'swiper/react'
import { generateId } from '@frontastic/common'
import { Product } from '../../../../types/algolia/product'
import { Context } from '../../../../types/frontastic'
import tagManager from '../../../../domain/TagManager'
import GtmService from '../../../../services/gtm'
import OldTagManager from '../../../../domain/OldTagManager'
import OldGtmService from '../../../../services/oldGtm'
import ClientFactory from '../../../../services/algolia/ClientFactory'
import Slider from '../../../atoms/slider'
import ProductTile from '../Hits/ProductTile'

type Props = {
    context?: Context
    objectId: string | null
    gtmCategoryName: string
    gtmCategoryId: string
    renderAsSlider: boolean
    swiperColor: string
    alignment: string
    hits: Array<Product>
    isDisplayOnPDP?: boolean
} & WrappedComponentProps

type State = {
    colors: any
}

class ProductRecommendationTile extends Component<Props, State> {
    state = {
        colors: {},
    }

    protected sentinel: HTMLElement | null = null

    protected observer: IntersectionObserver | null = null

    public componentDidUpdate() {
        const { hits, context } = this.props
        const { colors } = this.state

        const modelKeys = []

        if (hits && hits.length > 0) {
            hits.forEach((hit) => {
                const productColors = colors[hit.root_model_key] ?? null
                if (productColors == null) {
                    colors[hit.root_model_key] = []
                    modelKeys.push(`root_model_key: ${hit.root_model_key}`)
                }
            })
        }

        if (context && modelKeys.length > 0) {
            this.setState({ colors })
            ClientFactory.getColors(context, modelKeys)
                .then((response) => {
                    // @ts-ignore
                    response.results[0]?.hits.forEach((hitColor: any) => {
                        const hitColors = colors[hitColor.root_model_key] ?? []
                        hitColors.push(hitColor)
                        colors[hitColor.root_model_key] = hitColors
                    })

                    this.setState({ colors })
                })
        }
    }

    public componentDidMount() {
        const categoryName = this.props.gtmCategoryName
        const categoryId = this.props.gtmCategoryId
        const gtmService = new GtmService(this.props.hits)
        const productListGTM = gtmService.createProductListGTM(categoryName, categoryId)
        tagManager.productImpressions(productListGTM, categoryName, categoryId)

        // for universal analytics ( will be removed )
        const oldGtmService = new OldGtmService(this.props.hits)
        const oldProductListGTM = oldGtmService.createProductListGTM(`Page: ${categoryName}`)
        OldTagManager.productImpressions(oldProductListGTM)
    }

    public componentWillUnmount() {
        this.observer?.disconnect()
    }

    bindColors = (hits, colors) => {
        if (!colors || hits.length == 0) {
            return hits
        }

        hits.forEach((hit) => {
            if (hit.root_model_key) {
                hit.colors = []
                const productColors = colors[hit.root_model_key] ?? []
                productColors.forEach((productColor) => {
                    productColor.isActive = productColor.sku === hit.sku
                    hit.colors.push(productColor)
                })
            }
        })
        return hits
    }

    renderProductTilesAsList = () => {
        const { gtmCategoryName } = this.props
        const hits = this.bindColors(this.props.hits, this.state.colors)

        return (
            hits.map((hit, index) => (
                <ProductTile
                    key={`ProductRecommendationTile_${generateId()}_${hit.product_id}_${index}`}
                    position={index + 1}
                    product={hit}
                    // @ts-ignore
                    nameCategory={gtmCategoryName}
                    colors={hit.colors}
                    // @ts-ignore
                    from={'Related Products'}
                />
            ))
        )
    }

    renderProductTilesAsSlider = () => {
        const { gtmCategoryName, gtmCategoryId, isDisplayOnPDP } = this.props
        const hits = this.bindColors(this.props.hits, this.state.colors)

        return (
            <Slider
                spaceBetween={0}
                slidesPerView={2}
                slidesPerGroup={2}
                loop={false}
                swiperColor={this.props.swiperColor}
                breakpoints={{
                    768: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                        spaceBetween: 12,
                    },
                    1024: {
                        slidesPerView: isDisplayOnPDP ? 3 : 5,
                        slidesPerGroup: isDisplayOnPDP ? 3 : 5,
                        spaceBetween: isDisplayOnPDP ? 16 : 20,
                    },
                    1280: {
                        slidesPerView: isDisplayOnPDP ? 4 : 5,
                        slidesPerGroup: isDisplayOnPDP ? 4 : 5,
                        spaceBetween: isDisplayOnPDP ? 16 : 20,
                    },
                }}
            >
                {hits.map((hit, index) => (
                    <SwiperSlide key={`ProductRecommendationTile_${hit.product_id}`}>
                        <ProductTile
                            key={`ProductRecommendationTile_${hit.product_id}_${index}`}
                            position={index + 1}
                            product={hit}
                            // @ts-ignore
                            gtmCategoryName={gtmCategoryName}
                            // @ts-ignore
                            gtmCategoryId={gtmCategoryId}
                            colors={hit.colors}
                            // @ts-ignore
                            from={'Related Products'}
                        />
                    </SwiperSlide>
                ))}
            </Slider>
        )
    }

    render() {
        if (!this.props.hits) {
            return null
        }

        return (
            <div className={'product-list--component'}>
                {this.props.renderAsSlider === false ? (
                    <div className={classnames('product-teaser-list', {
                        'justify-start': this.props.alignment === 'left',
                        'justify-center': this.props.alignment === 'center',
                        'justify-end': this.props.alignment === 'right',
                    })}
                    >
                        {this.renderProductTilesAsList()}
                    </div>
                ) : (
                    <>
                        {this.renderProductTilesAsSlider()}
                    </>
                )}
            </div>
        )
    }
}

export default injectIntl(ProductRecommendationTile)

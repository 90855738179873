import React from 'react'
import PropTypes from 'prop-types'
import Accordion from '../../patterns/organisms/Accordions'

function AccordionTastic({ data }) {
    return (
        <Accordion
            data={data.accordions}
            isBgMode={data.isBgMode}
            isDarkMode={data.isDarkMode}
            headline={data.text}
            tagHeadline={data.tagHeadline}
            btnColorDefault={data.btnColorDefault}
            btnColorHover={data.btnColorHover}
        />
    )
}

AccordionTastic.propTypes = {
    data: PropTypes.shape({
        accordions: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            content: PropTypes.string,
            ctaButton: PropTypes.string,
            ctaLink: PropTypes.string,
            btnColorDefault: PropTypes.string,
            btnColorHover: PropTypes.string,
        })).isRequired,
        isBgMode: PropTypes.bool,
        isDarkMode: PropTypes.bool,
        headline: PropTypes.string,
        tagHeadline: PropTypes.string,
        text: PropTypes.string,
    }).isRequired,
}

export default AccordionTastic

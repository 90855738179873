import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import Reference from '@frontastic/catwalk/src/js/component/reference'

const HelpLinks = ({
    links,
}) => (
    <div className={'cart-page--link-list mt-3 md:mt-0'}>
        <h5><FormattedMessage id={'cart.titleListLink'} /></h5>
        <div className={'cart-page--link-list-wrapper'}>
            {links.map((link, index) => (
                <Reference
                    key={`${link.reference.target}-${index}`}
                    reference={link.reference}
                >
                    {link.title}
                </Reference>
            ))}
        </div>
    </div>
)

HelpLinks.propTypes = {
    links: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default injectIntl(HelpLinks)

import React from 'react'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import FullPageWidthWrapper from '../../patterns/molecules/Layout/FullPageWidthWrapper'

export type Props = {
    data: {
        iframeUrl: string,
        iframeTitle: string,
        heightMobile,
        heightTablet,
        heightDesktop
    }
}

const storeFinder: React.FC<Props> = ({ data }: Props) => {
    if (!data) {
        return null
    }
    const {
        heightDesktop,
        heightTablet,
        heightMobile,
        iframeUrl,
        iframeTitle,
    } = data

    const style = {
        width: '100%',
        height: 'inherit',
    }

    if (useDeviceType() === 'desktop' && heightDesktop) {
        style.height = heightDesktop
    }

    if (useDeviceType() === 'tablet' && heightTablet) {
        style.height = heightTablet
    }

    if (useDeviceType() === 'mobile' && heightMobile) {
        style.height = heightMobile
    }

    return (
        <FullPageWidthWrapper>
            <iframe
                src={iframeUrl}
                title={iframeTitle}
                className={'storefinder--iframe'}
                style={style}
                frameBorder={0}
                loading={'lazy'}
            />
        </FullPageWidthWrapper>
    )
}

export default tastify()(storeFinder)

import React, {useEffect} from 'react'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import app from '@frontastic/catwalk/src/js/app/app'
import { Route } from '@frontastic/catwalk/src/js/types/frontend/index'
import AccountAccess from '../../../patterns/molecules/Account/Forms/AccountAccess'
import RegisterService from '../../../services/register'

export type Props = {
    context: any
    route: Route
}

const AccountAccessTastic: React.FC<Props> = ({ context, route }: Props) => {
    const originParameter = new URLSearchParams(typeof window !== 'undefined' ? window.location.search : '')
    const redirectRoute = originParameter.has('origin-cart') ? 'Frontastic.Frontend.Master.Checkout.checkout' : 'Frontastic.Frontend.Master.Account.profile'
    const restrictEmailDomains = context.projectConfiguration?.restrictEmailDomains
    const loggedIn = context.session.loggedIn;

    useEffect(() => {
        if (loggedIn && route.route !== 'Frontastic.Frontend.Master.Account.profile') {
            app.getRouter().push(redirectRoute)
        }
    }, [loggedIn, redirectRoute, route]);

    return (
        <AccountAccess
            handleRegister={RegisterService.register}
            handleLogin={app.getLoader('context').login}
            handleRequestPasswordReset={app.getLoader('context').requestPasswordReset}
            redirectRoute={redirectRoute}
            restrictEmailDomains={restrictEmailDomains}
        />
    )
}

export default tastify({
    translate: true,
    connect: {
        context: true,
        route: true,
    },
})(AccountAccessTastic)

import moment from 'moment'
import 'moment-timezone'
import _ from 'lodash'

export enum OpeningTimeTypeNum {
    closed,
    closeSoon,
    opening,
}

export enum OpeningTimeColor {
    '#BD0000',
    '#DB7C00',
    '#52B000',
}

export enum StatusStockOfStore {
    noStore = 'noStore',
    available = 'available',
    outOfStock = 'outOfStock',
    clickAndCollect = 'clickAndCollect',
}

export default class StoreService {
    private static daysByMoment = ['sonntags', 'montags', 'dienstags', 'mittwochs', 'donnerstags', 'freitags', 'samstags']

    public static getCurrentMoment() {
        const date = moment().tz('Europe/Berlin')
        const currentDay = this.daysByMoment[date.day()]
        const currentMinute = date.minutes() < 10 ? `0${date.minutes()}` : date.minutes()
        const currentFullTime = +`${date.hours()}${currentMinute}`

        return {
            currentDay,
            currentFullTime,
        }
    }

    public static getCurrentWorkingTimeByDay(openingTimeOfDay) {
        let start
        let end
        const time = []
        openingTimeOfDay.forEach((value) => {
            start = value.start < 1000 ? `0${value.start}` : `${value.start}`
            end = `${value.ende}`
            time.push(`${start.slice(0, 2)}:${start.slice(2)} - ${end.slice(0, 2)}:${end.slice(2)}`)
        })

        return time
    }

    public static formatWorkingTime(workingTime) {
        const result = []
        const days = ['montags', 'dienstags', 'mittwochs', 'donnerstags', 'freitags', 'samstags', 'sonntags']
        days.forEach((day) => {
            const currentWorkingTimeOfDay = workingTime[day] || []

            if (!currentWorkingTimeOfDay.length) {
                result.push({
                    label: day,
                    time: '',
                })
                return
            }

            const time = this.getCurrentWorkingTimeByDay(currentWorkingTimeOfDay)

            result.push({
                label: day,
                time,
            })
        })
        return result
    }

    public static getStatus(workingTime) {
        if (_.isArray(workingTime)) {
            return null
        }

        const {
            currentDay,
            currentFullTime,
        } = this.getCurrentMoment()

        const time = workingTime[currentDay] || []

        if (!time || !time.length) {
            return 0
        }

        const AMOUNT_CLOSE_SOON = 200 // 100 will be 1 hour
        let isOpening = false
        let isCloseSoon = false

        time.forEach((value) => {
            const isInRange = _.inRange(currentFullTime, value.start, value.ende)

            if (isInRange) {
                isOpening = true
                isCloseSoon = (+value.ende - currentFullTime) < AMOUNT_CLOSE_SOON
            }
        })

        if (!isOpening) {
            return OpeningTimeTypeNum.closed
        }

        if (isOpening && isCloseSoon) {
            return OpeningTimeTypeNum.closeSoon
        }

        return OpeningTimeTypeNum.opening
    }

    public static getOpeningTimeToday(workingTime) {
        const today = this.daysByMoment[moment().tz('Europe/Berlin').day()]
        const currentWorkingDay = workingTime[today] || []

        if (!currentWorkingDay && !currentWorkingDay.length) {
            return null
        }

        const time = this.getCurrentWorkingTimeByDay(currentWorkingDay)

        return time
    }

    public static calculateDistance(pos1, pos2) {
        if (!pos1 || !pos2) {
            return null
        }

        const R = 3958.8
        const rlat1 = pos1.lat * (Math.PI / 180)
        const rlat2 = pos2.lat * (Math.PI / 180)
        const difflat = rlat2 - rlat1
        const difflon = (pos2.lng - pos1.lng) * (Math.PI / 180)

        const result = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)))

        if (result < 1) {
            return parseFloat(`${result}`).toFixed(1)
        }

        return Math.floor(result)
    }

    public static loadGoogleScript(apiKey) {
        const BASE_GOOGLE_SCRIP = 'https://maps.googleapis.com/maps/api/js'
        const GOOGLE_SCRIPT = `${BASE_GOOGLE_SCRIP}?key=${apiKey}&libraries=geometry,places`
        const el = document.createElement('script')

        const scriptAttributes = {
            type: 'type="text/javascript"',
            class: 'cmplazyload',
            'data-cmp-src': GOOGLE_SCRIPT,
            'data-cmp-vendor': 's1104',
            'data-cmp-preview': '500x300',
            'data-cmp-purpose': 'c1',
            'data-cmp-preview-headline': 'Um Karten von Google Maps anzeigen zu können, stimmen Sie der Verwendung auch optionaler Cookies zu.',
            'data-cmp-preview-btn': 'Akzeptieren',
            'data-cmp-preview-text': 'Dieser Inhalt wird von Google Maps bereit gestellt. Wenn Sie den Inhalt aktivieren, werden ggf. personenbezogene Daten verarbeitet und Cookies gesetzt.',
            'data-cmp-preview-checkbox': 'Google Maps immer aktivieren.',
            'data-cmp-preview-image': 'https://res.cloudinary.com/dlwdq84ig/image/upload/v1669045270/tdwhusl09cfwsksnkeat.jpg',
            'data-cmp-preview-bgcolor': '#FFFFFF',
            'data-cmp-preview-mode': '0',
        }

        Object.keys(scriptAttributes).forEach((attribute) => {
            el.setAttribute(attribute, scriptAttributes[attribute])
        })

        document.body.appendChild(el)

        return new Promise((resolve) => {
            el.addEventListener('load', (r) => resolve(r))
        })
    }

    public static formatGLN(gln: string) {
        if (!gln) {
            return ''
        }
        const result = []
        const arrGLN = gln.split(',')
        arrGLN.forEach((val) => {
            result.push(`gln:${val.trim()}`)
        })

        return result
    }

    public static formatFacetFilterFromStudio(facets) {
        if (!facets || !facets.length) {
            return ''
        }

        const result = []
        facets.forEach((facet) => {
            const { attributes, condition } = facet
            const config = []
            attributes.forEach((attr) => {
                const { attribute, value } = attr
                config.push(`${attribute}:${value}`)
            })

            if (condition !== 'or') {
                result.push(...config)
            } else {
                result.push(config)
            }
        })

        return result
    }

    public static formatStoreForCookie(store) {
        return {
            id: store.objectID,
            name: store.name,
            url: store.url,
            street: store.street,
            streetNumber: store.street_number,
            city: store.city,
            postCode: store.post_code,
            channelId: store.channel_id,
            isClickCollect: store.is_click_collect || false,
            gln: store.gln,
        }
    }

    public static checkProductStockInStore(store, selectedVariant) {
        if (!selectedVariant) {
            return null
        }

        if (!store) {
            return {
                price: selectedVariant.price,
                stock: selectedVariant.projectSpecificData.stock,
                discountedPrice: selectedVariant?.discountedPrice || 0,
            }
        }

        const { channels } = selectedVariant.projectSpecificData
        let status = StatusStockOfStore.noStore
        const channelId = store.channel_id || store.channelId
        let { price } = selectedVariant
        let { stock } = selectedVariant
        let discountedPrice = selectedVariant?.discountedPrice || 0
        const channel = !_.isEmpty(channels) && channels[channelId]

        if (channel) {
            price = channel.price
            stock = channel.stock
            discountedPrice = channel.discountedPrice
            if (!channel.stock) {
                status = StatusStockOfStore.outOfStock
            } else {
                status = StatusStockOfStore.available
            }
        }

        return {
            price,
            stock,
            discountedPrice,
            status: `${status}`,
        }
    }
}

import React, {
    useCallback, useRef,
} from 'react'
import classNames from 'classnames'
import Masonry from 'react-masonry-css'
import LightGallery from 'lightgallery/react'
import Image from '../../atoms/image'

import Video from '../../atoms/video'

interface ImageType {
    title: string;
    desc: string;
    image: {
        media: any;
        title: string;
    };
}

interface PropsType {
    imageList: ImageType[];
    numOfCol: string;
    openOverlay: boolean;
    hasGap: boolean;
    url: string;
    previewPic: object;
    videoTitle?: string
    headline?: string
}

const GalleryMasonry = (props: PropsType) => {
    const {
        imageList, openOverlay, hasGap, numOfCol, videoTitle, url, previewPic, headline,
    } = props

    if (!imageList || !imageList.length) {
        return null
    }

    const lightGallery = useRef(null)

    const getItem = () => imageList.map((item, index) => {
        const title = item.title ? item.title : ''
        const desc = item.desc ? item.desc : ''

        return ({
            id: index,
            src: item.image.media ? item.image.media.file : null,
            thumb: item.image.media ? item.image.media.file : null,
            subHtml: `<div class="lightGallery-captions">
                    <h4>${title}</h4>
                    <p>${desc}</p>
            </div>`,
        })
    })

    const onInit = useCallback((detail) => {
        if (detail) {
            lightGallery.current = detail.instance
        }
    }, [])

    return (
        <>
            {headline && (
                <h3>{headline}</h3>
            )}

            {url && (
                <div className={'gallery-video'}>
                    <Video
                        url={url}
                        title={videoTitle}
                        previewPic={previewPic}
                    />
                </div>
            )}
            <Masonry
                breakpointCols={parseInt(numOfCol, 10)}
                className={classNames('masonry-grid', {
                    'masonry-grid--has-gap': hasGap,
                    'masonry-grid--center': parseInt(numOfCol, 10) === 4,
                })}
                columnClassName={classNames('masonry-grid-column', {
                    'masonry-grid-column--has-gap': hasGap,
                })}
            >
                {imageList.map((item, index) => {
                    if (!item || !item.image.media) {
                        return null
                    }

                    const title = item.title && item.title
                    return (
                        <div
                            tabIndex={item.image.media.mediaId}
                            role={'button'}
                            className={classNames('masonry-item', {
                                'disable-overlay': !openOverlay,
                                'masonry-item--has-gap': hasGap,
                            })}
                            onClick={() => lightGallery.current.openGallery(index)}
                            onKeyDown={() => lightGallery.current.openGallery(index)}
                        >
                            {/* @ts-ignore */}
                            <Image
                                title={title}
                                alt={title}
                                media={item.image.media.file}
                                width={item.image.media.width}
                                height={item.image.media.height}
                                triggerImageDimensionUpdate={false}
                            />
                        </div>
                    )
                })}
            </Masonry>

            <LightGallery
                mode={'lg-fade'}
                dynamic
                dynamicEl={getItem()}
                onInit={onInit}
            />
        </>
    )
}

export default GalleryMasonry

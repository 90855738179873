import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import {injectIntl} from 'react-intl'
import {Helmet} from 'react-helmet'
import ThankYou from '../../patterns/organisms/ThankYou'
import GtmService from '../../services/gtm'
import TagManager from '../../domain/TagManager'
import OldGtmService from '../../services/oldGtm'
import OldTagManager from '../../domain/OldTagManager'
import ConfirmationRegister from '../../patterns/molecules/Account/Confirmation'
import SessionService from '../../services/session'
import WebGainsService from '../../services/webgains'
import Gtag from '../../services/gtag'
import UserToken from '../../domain/UserToken'
import CriteoManager from '../../domain/CriteoManager'
import useConsentManagement, {
  CONSENT_VENDOR_GOOGLE_TAG_MANAGER_ID
} from '../../../../legacy/js/services/hook/useConsentManagement'

const ThankYouTastic = ({
  context,
  data,
  node,
}) => {
  if (!data || !data.stream) {
    return null
  }

  const [isConfirm, setIsConfirm] = useState(context.session.projectSpecificData.showRegisterMsg)
  const [hasScript, setHasScript] = useState(false)
  const [isSent, setIsSent] = useState(false)
  const [isSendToAlgoliaEvent, setIsSendToAlgoliaEvent] = useState(false)
  const vendorConsents = useConsentManagement()
  const isGtmAccepted = Boolean(vendorConsents?.[CONSENT_VENDOR_GOOGLE_TAG_MANAGER_ID])

  const {
    title,
    desc1,
    desc2,
    desc3,
    positionMode,
    buttonLabel,
    reference,
    btnVariant,
    align,
    bgColor,
    media,
    textColor,
  } = data

  const {
    orderId, sum, currency, lineItems,
  } = data.stream || {}

  const sentToId = Gtag.getId()
  const {algoliaIndexName} = context?.projectConfiguration || {}
  const valueOrder = ((sum / 100).toLocaleString('en', {minimumFractionDigits: 2})).replace(',', '')

  useEffect(() => {
    if (window.aa && !isSendToAlgoliaEvent) {
      const objectIds = data.stream.lineItems.map((lineItem) => lineItem.variant?.sku)
      TagManager.queryIdImpression(objectIds, algoliaIndexName)
      TagManager.purchasedObjectIDsAfterSearch(lineItems, sum, algoliaIndexName)
      setIsSendToAlgoliaEvent(true)
    }

    UserToken()
  })

  useEffect(() => {
    if (isGtmAccepted && !isSent) {
      const userToken = window.localStorage.getItem('userToken')
      const gtmService = new GtmService(data.stream.lineItems, null, null, data.stream)
      const productListGTM = gtmService.createProductListGTM('Thank You', node.nodeType, userToken)
      const orderInformation = gtmService.createPurchaseActionFieldGTM()
      TagManager.purchase(orderInformation, productListGTM)

      // for universal analytics ( will be removed )
      const oldGtmService = new OldGtmService(data.stream.lineItems, null, null, data.stream)
      const oldProductListGTM = oldGtmService.createProductListGTM('Page: Thank You', true, userToken)
      const oldActionFieldGTM = oldGtmService.createPurchaseActionFieldGTM()
      OldTagManager.purchase(oldProductListGTM, oldActionFieldGTM)

      TagManager.order({
        send_to: sentToId.toString(),
        value: parseFloat(valueOrder),
        currency: currency.toString(),
        transaction_id: orderId.toString(),
      })
      setIsSent(true)
    }
  }, [isGtmAccepted, isSent]);

  useEffect(() => {
    WebGainsService.sendDataToTrack(data)
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.criteo_q && !hasScript) {
        setHasScript(true)
      }
    }, 500)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (hasScript) {
      CriteoManager.sendEmail(data.stream?.email)
    }
  }, [hasScript])

  return (
    <>
      {isConfirm && (
        <ConfirmationRegister
          onClick={() => {
            SessionService.remove('showRegisterMsg')
            setIsConfirm(false)
          }}
        />
      )}
      <Helmet>
        <script>
          {`
                        (function (w, e, b, g, a, i, n, s) {
                        w.ITCVROBJ = a;
                        (w[a] = w[a] || function () {
                            (w[a].q = w[a].q || []).push(arguments)
                        }), w[a].l = 1 * new Date()
                        i = e.createElement(b),
                            n = e.getElementsByTagName(b)[0]
                        i.async = 1
                        i.src = g
                        n.parentNode.insertBefore(i, n)
                        }(window, document, 'script', 'https://analytics.webgains.io/cvr.min.js', 'ITCVRQ'))
                    `}
        </script>
      </Helmet>
      <ThankYou
        title={title}
        desc1={desc1}
        desc2={desc2}
        desc3={desc3}
        media={media}
        positionMode={positionMode}
        label={buttonLabel}
        reference={reference}
        btnVariant={btnVariant}
        align={align}
        bgColor={bgColor}
        textColor={textColor}
        data={data}
        context={context}
      />
    </>
  )
}

ThankYouTastic.propTypes = {
  data: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
}

export default tastify({translate: true, connect: {cart: true, context: true, node: true}})(injectIntl(ThankYouTastic))

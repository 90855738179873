import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { ConfigurationSchema } from 'frontastic-common'
import _ from 'lodash'
import Button from '../button/Button'
import SortSize from '../../../services/sortSize'
import FrontasticImage from '../frontasticImage'

const SelectVariant = ({
    variants,
    onSelect,
    formatLabel,
    isVariantSize = false,
    isVariantColor = false,
    isChooseColor,
    selectedSkuFromCart,
    refererStore,
    context,
}) => {
    if (!variants || variants.length === 0 || !context) {
        return null
    }

    const listed = []
    const [actives, setActives] = useState({})

    const projectSchema = new ConfigurationSchema(
        context.projectConfigurationSchema,
        context.projectConfiguration,
    )
    const sortedVariant = useMemo(() => isVariantSize ? SortSize.sortByProductType(variants, projectSchema) : variants, [variants, isVariantColor, isVariantSize])

    const handleCheckStock = (variant) => {
        // Please use projectSpecificData to check stock if there is a list of sizes
        let outOfStock = isVariantColor ? !variant.product.isOnStock : !variant.projectSpecificData?.stock

        if (refererStore) {
            const channels = variant.projectSpecificData?.channels
            outOfStock = true

            if (!_.isEmpty(channels) && channels[refererStore.channelId] && channels[refererStore.channelId].stock) {
                outOfStock = !channels[refererStore.channelId].stock > 0
            }
        }

        return outOfStock
    }

    return (
        <>
            {sortedVariant.map((variant, index) => {
                let label = {}
                let sku = null
                const key = isVariantSize ? variant?.sku : variant?.product?.productId

                if (isVariantColor) {
                    label = formatLabel ? formatLabel(variant.product) : variant.product
                    sku = variant.product.variants[0]?.sku
                } else {
                    label = formatLabel ? formatLabel(variant) : variant
                    sku = variant?.sku
                }

                if (isVariantSize && listed.includes(label)) {
                    return null
                }

                listed.push(label)

                let isSelectedElement = false

                if (isVariantSize) {
                    const conditionActive = (selectedSkuFromCart === variant.sku) || parseInt(actives.activeLink, 10) === index
                        || (parseInt(Object.keys(variants)[0], 10) === index && Object.keys(actives).length === 0 && isVariantColor)
                    // isVariantColor = false that means this select is for size variant
                    // isChooseColor = true that means on change color variant and the size variant will lost focus
                    isSelectedElement = !isVariantColor && isChooseColor ? false : conditionActive
                }

                return (
                    <div
                        key={key}
                        className={classnames('select-variant-item', {
                            'select-variant-active': isVariantColor ? variant.isActive : (isSelectedElement || (selectedSkuFromCart === variant.sku)),
                            'select-disabled cursor-not-allowed ': isVariantColor ? false : handleCheckStock(variant) && isVariantSize,
                        })}
                    >
                        <Button
                            type={'button'}
                            className={classnames('select-variant-button p-0 w-full h-full', {
                                'pointer-events-none': handleCheckStock(variant) && isVariantSize,
                            })}
                            onClick={() => {
                                setActives({ activeLink: index })

                                // get index
                                return onSelect(isVariantSize ? variant.currentIndex : index)
                            }}
                        >
                            {isVariantSize ? (
                                <>
                                    {variant.images[0] && (
                                        <FrontasticImage
                                            src={variant.images[0]}
                                            alt={label}
                                            title={label}
                                            width={50}
                                            height={50}
                                        />
                                    )}
                                </>
                            ) : (
                                <>
                                    {variant.product.variants[0].images[0] && (
                                        <FrontasticImage
                                            src={variant.product.variants[0].images[0]}
                                            alt={label}
                                            title={label}
                                            width={50}
                                            height={50}
                                        />
                                    )}
                                </>
                            )}

                            <div className={'variant-label'} data-insights-object-id={sku}>
                                <span>{label}</span>
                            </div>
                        </Button>
                    </div>
                )
            })}
        </>
    )
}

SelectVariant.propTypes = {
    variants: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    formatLabel: PropTypes.func,
    isVariantSize: PropTypes.bool,
    isVariantColor: PropTypes.bool,
    isChooseColor: PropTypes.bool.isRequired,
    refererStore: PropTypes.any,
    selectedSkuFromCart: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
}

export default SelectVariant

import fetch from '@frontastic/bridge/js/fetch'

class RegisterService {
    register(body) {
        return fetch('/api/account/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }).then((response) => {
            return response
            // if (response.ok) {
            //     return response.json
            // }
            //
            // if (response.json
            //     && response.json.message
            //     && (typeof response.json.message === 'string')) {
            //     // eslint-disable-next-line no-console
            //     console.error('Error:', response.json)
            //     throw response.json
            // } else {
            //     // eslint-disable-next-line no-console
            //     console.error('Unhandled Error:', response)
            //     throw { status: 500, message: 'Internal Server Error' }
            // }
        }).catch((error) => {
            throw error
        })
    }
}

export default new RegisterService()

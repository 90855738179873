import React from 'react'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import PropTypes from 'prop-types'
import MinimalFooter from '../../patterns/organisms/MinimalFooter'

const MinimalFooterTastic = ({
    data: {
        titleLegal,
        legalLinks,
        titleHelp,
        helpLinks,
        linkHelp,
        linkLegal,
        reference,
    },
}) => (
    <MinimalFooter
        titleHelp={titleHelp}
        titleLegal={titleLegal}
        legalLinks={legalLinks}
        helpLinks={helpLinks}
        linkHelp={linkHelp}
        linkLegal={linkLegal}
        reference={reference}
    />
)

MinimalFooterTastic.propTypes = {
    data: PropTypes.object.isRequired,
}

export default tastify()(MinimalFooterTastic)

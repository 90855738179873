import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { ReactComponent as AbsoluteTeamSportLogo } from '../../../../../icons/sport2000-icons/logos/absoluteteamsport.svg'
import { ReactComponent as OutdoorProfiLogo } from '../../../../../icons/sport2000-icons/logos/outdoor_profi_CMYK.svg'
import Sport2000Logo from '../../../atoms/sport2000Logo'

const SpecialLogo = ({ logos }) => {
    const logoArrays = logos.filter((logo) => !!logo)

    if (!logoArrays || !logoArrays.length) {
        return null
    }

    const handleLogo = (item) => {
        const LOGOS = {
            absolute_run: <Sport2000Logo name={'AbsoluteRun_1line'} alt={'logo Absolute Run'} height={'40px'} />,
            absolute_teamsport: <Sport2000Logo name={'AbsoluteTeamsport_1line'} alt={'logo Absolute Teamsport'} height={'40px'} />,
            running_expert: <Sport2000Logo name={'RunningExperts_1line'} alt={'logo Running Expert'} height={'40px'} />,
            teamsport_expert: <Sport2000Logo name={'TeamsportExperts_1line'} alt={'logo Teamsport Expert'} height={'40px'} />,
            absolute_sport: <AbsoluteTeamSportLogo width={250} />,
            outdoor_expert: <Sport2000Logo name={'OutdoorExperts_1line'} alt={'logo Outdoor Expert'} height={'40px'} />,
            ski_expert: <Sport2000Logo name={'SkiExperts_1line'} alt={'logo Ski Expert'} height={'40px'} />,
            outdoorprofi: <OutdoorProfiLogo width={125} height={30} />,
        }

        return (
            LOGOS[item] || null
        )
    }

    return (
        <div className={'store-detail-special-logo'}>
            <div className={'store-detail-special-logo-headline font-bold mb-4'}>
                <FormattedMessage id={'storeDetail.specialLogoHeadline'} />
            </div>

            {logoArrays.map((item) => (
                <div className={'mb-4'} key={item}>
                    {handleLogo(item)}
                </div>
            ))}
        </div>

    )
}

SpecialLogo.propTypes = {
    logos: PropTypes.array.isRequired,
}

export default injectIntl(SpecialLogo)

import React from 'react'
import PropTypes from 'prop-types'
import GalleryGrid from '../../patterns/organisms/ImageGallery/GalleryGrid'
import GalleryMasonry from '../../patterns/organisms/ImageGallery/GalleryMasonry'
import GallerySlider from '../../patterns/organisms/ImageGallery/GallerySlider'

import 'lightgallery/css/lightgallery.css'

const ImageGalleryTastic = ({ data }) => {
    const {
        autoSlide,
        imageList,
        galleryType,
        isLoop,
        numOfCol,
        openOverlay,
        hasGap,
        sliderNavType,
        url,
        previewPic,
        title,
        galleryTitle,
    } = data

    const showDots = sliderNavType === 'dots'

    const renderGalleryByType = () => {
        switch (galleryType) {
            case 'slider':
                return (
                    <>
                        {sliderNavType === 'thumbnails' ? (
                            <GallerySlider
                                imageList={imageList}
                                autoSlide={autoSlide}
                                thumb
                                isLoop={isLoop}
                                headline={galleryTitle}
                            />
                        ) : (
                            <GallerySlider
                                imageList={imageList}
                                autoSlide={autoSlide}
                                thumb={false}
                                isLoop={isLoop}
                                headline={galleryTitle}
                                showDots={showDots}
                            />
                        )}
                    </>
                )
            case 'grid':
                return (
                    <GalleryGrid
                        imageList={imageList}
                        numOfCol={numOfCol}
                        hasGap={hasGap}
                        openOverlay={openOverlay}
                        url={url}
                        previewPic={previewPic}
                        videoTitle={title}
                        headline={galleryTitle}
                    />
                )
            default:
                return (

                    <GalleryMasonry
                        hasGap={hasGap}
                        numOfCol={numOfCol}
                        openOverlay={openOverlay}
                        imageList={imageList}
                        url={url}
                        previewPic={previewPic}
                        videoTitle={title}
                        headline={galleryTitle}
                    />
                )
        }
    }

    return <div className={'my-5'}>{renderGalleryByType()}</div>
}

ImageGalleryTastic.propTypes = {
    data: PropTypes.object,
}

export default ImageGalleryTastic

import React from 'react'
import PropTypes from 'prop-types'

import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import FullPageWidthWrapper from '../../patterns/molecules/Layout/FullPageWidthWrapper'

const SpacerTastic = ({ data }) => {
    const { spaceInPx } = data

    return (
        <FullPageWidthWrapper
            className={'horisontal-spacer-tastic'}
            style={{
                height: `${spaceInPx}px`,
            }}
        />
    )
}

SpacerTastic.propTypes = {
    data: PropTypes.object.isRequired,
}

export default tastify()(SpacerTastic)

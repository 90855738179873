import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { ReactComponent as CloseIcon } from '../../../../icons/tailwind-icons/icon-close-black.svg'
import Button from '../../atoms/button/Button'

const PlainModal = ({
    onClose, children, intl, title = '', variant = '', subTitle = '',
}) => {
    const node = useRef()

    useEffect(() => {
        const handleClick = ({ target }) => {
            !node.current.contains(target) && onClose()
        }

        document.addEventListener('mousedown', handleClick)

        return () => {
            document.removeEventListener('mousedown', handleClick)
        }
    }, [onClose])

    return (
        <div className={variant}>
            <div
                className={'justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none'}
            >
                <div ref={node} className={'relative my-6 mx-auto plain-modal'}>
                    <div
                        className={'relative flex flex-col w-full bg-background-primary outline-none focus:outline-none'}
                    >
                        {title && (
                            <div
                                className={'flex items-center justify-between p-4 borders '}
                            >
                                <h3 className={'mb-0'}>
                                    <FormattedMessage id={title} />
                                    {subTitle && ` ${subTitle}`}
                                </h3>

                                <Button
                                    aria-label={intl.formatMessage({ id: 'header.closeView' })}
                                    className={'ml-auto text-black h-full float-right text-2xl outline-none focus:outline-none md:p-0'}
                                    onClick={onClose}
                                    type={'button'}
                                >
                                    <CloseIcon className={'fill-current text-neutral-900'} />
                                </Button>
                            </div>
                        )}

                        {children && (
                            <div
                                className={'text-black relative py-2 md:py-4 flex-auto w-full mx-auto plain-modal-content '}
                            >
                                {children}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div onClick={onClose} className={'opacity-25 fixed inset-0 z-40 bg-black'} />
        </div>
    )
}

PlainModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    intl: PropTypes.object.isRequired,
    title: PropTypes.string,
    variant: PropTypes.string,
    subTitle: PropTypes.string,
}

export default injectIntl(PlainModal)

import React, { useState } from 'react'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import classnames from 'classnames'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
    FormattedHTMLMessage, FormattedMessage, WrappedComponentProps, injectIntl, IntlShape,
} from 'react-intl'
import ErrorMessage from '../../patterns/atoms/errorMessage'
import { SweepstakesFormTypes } from './types'
import ButtonWithLoader from '../../patterns/atoms/button/WithLoader'
import FullPageWidthWrapper from '../../patterns/molecules/Layout/FullPageWidthWrapper'
import BorderOnTopAndBottom from '../../patterns/atoms/dotted/BorderOnTopAndBottom'

export type Props = {
    data: {
        headline: string
        question: string
        answers: {'answer': string}[]
        renderBlackBackground: boolean
        renderDottedBorders: boolean
    }
    intl: IntlShape
} & WrappedComponentProps

const SweepstakesForm: React.FC<Props> = ({ data, intl }: Props) => {
    const {
        register,
        handleSubmit,

        formState: {
            errors,
        },
    } = useForm()
    const requiredFieldMessage = intl.formatMessage({ id: 'validation.required' })
    const validEmailMessage = intl.formatMessage({ id: 'validation.email' })
    const validZipCodeMessage = intl.formatMessage({ id: 'validation.postalCode' })
    const [showLoader, setShowLoader] = useState(false)
    const [isChecked, setIsChecked] = useState(null)

    const onSubmitButton: SubmitHandler<SweepstakesFormTypes> = ({
        answer,
        salutation,
        firstName,
        lastName,
        email,
        street,
        houseNumber,
        zip,
        city,
        checkboxNewsletter,
        checkboxTOS,
    }) => {
        setShowLoader(true)

        // TODO: Replace console.table Method with Submit logic
        console.table([
            ['answer', answer],
            ['salutation', salutation],
            ['firstName', firstName],
            ['lastName', lastName],
            ['email', email],
            ['street', street],
            ['houseNumber', houseNumber],
            ['zip', zip],
            ['city', city],
            ['checkboxNewsletter', checkboxNewsletter],
            ['checkboxTOS', checkboxTOS],
        ])

        setShowLoader(false)
    }

    return (
        <FullPageWidthWrapper customClasses={classnames('sweepstakes-form', {
            'my-comp': !data.renderBlackBackground && !data.renderDottedBorders,
            'py-8 md:py-10 lg:py-12 xl:py-16 xxl:py-20': data.renderBlackBackground || data.renderDottedBorders,
            'dark-mode text-white bg-blacksport-100': data.renderBlackBackground,
        })}
        >
            {data.renderDottedBorders
                && <BorderOnTopAndBottom renderAtTop renderBlackBackground={data.renderBlackBackground} />}

            <section className={'o-wrapper'}>
                <form
                    onSubmit={handleSubmit(onSubmitButton)}
                    className={'flex flex flex-col lg:flex-row lg:gap-12 px-4 md:px-0'}
                >
                    <div
                        className={'sweepstakes-form--left w-full lg:w-1/2 flex flex-col px-4 py-6 md:px-10 md:py-10 mb-auto'}
                    >
                        <h2 className={'text-h2 mx-0 mb-6'}>{data.headline}</h2>
                        <h4 className={'text-h4 mx-0 mb-3 leading-normal'}>{data.question}</h4>

                        {data.answers.map((answer, index) => (
                            <label
                                key={answer.answer}
                                className={classnames('mt-3 p-4 checkbox-radio--wrapper items-center cursor-pointer', {
                                    'is--invalid': errors?.answer,
                                    'is--checked': isChecked === index,
                                })}
                                htmlFor={`sweepstakes-form--answer-option-${index}`}
                                onClick={() => setIsChecked(index)}
                            >
                                <input
                                    className={'mr-4'}
                                    id={`sweepstakes-form--answer-option-${index}`}
                                    type={'radio'}
                                    {...register('answer', {
                                        required: requiredFieldMessage,
                                    })}
                                    value={answer.answer}
                                    tabIndex={0} />
                                {answer.answer}
                            </label>
                        ))}
                    </div>

                    <div
                        className={'sweepstakes-form--right w-full lg:w-1/2 flex flex-row flex-wrap mt-10 lg:mt-0'}
                    >
                        <div className={'flex flex-row flex-wrap w-full mb-8'}>
                            <span className={'mr-10'}>
                                <FormattedMessage id={'sweepstakesForm.salutation.label'} />
                            </span>

                            <div className={classnames('mr-10 mb-2 items-center checkbox-radio--wrapper', {
                                'is--invalid': errors?.salutation,
                            })}
                            >
                                <input
                                    id={'sweepstakes-form--salutation-option1'}
                                    type={'radio'}
                                    {...register('salutation', {
                                        required: requiredFieldMessage,
                                    })}
                                    value={'Mr'}
                                    tabIndex={0} />
                                <label
                                    htmlFor={'sweepstakes-form--salutation-option1'}
                                    className={'text-sm whitespace-no-wrap'}
                                >
                                    <FormattedMessage id={'sweepstakesForm.salutation.option1'} />
                                </label>
                            </div>

                            <div className={classnames('mr-10 mb-2 items-center checkbox-radio--wrapper', {
                                'is--invalid': errors?.salutation,
                            })}
                            >
                                <input
                                    id={'sweepstakes-form--salutation-option2'}
                                    type={'radio'}
                                    {...register('salutation', {
                                        required: requiredFieldMessage,
                                    })}
                                    value={'Ms'}
                                    tabIndex={0} />
                                <label
                                    htmlFor={'sweepstakes-form--salutation-option2'}
                                    className={'text-sm whitespace-no-wrap'}
                                >
                                    <FormattedMessage id={'sweepstakesForm.salutation.option2'} />
                                </label>
                            </div>

                            <div className={classnames('mb-2 items-center checkbox-radio--wrapper', {
                                'is--invalid': errors?.salutation,
                            })}
                            >
                                <input
                                    id={'sweepstakes-form--salutation-option3'}
                                    type={'radio'}
                                    {...register('salutation', {
                                        required: requiredFieldMessage,
                                    })}
                                    value={'Mx'}
                                    tabIndex={0} />
                                <label
                                    htmlFor={'sweepstakes-form--salutation-option3'}
                                    className={'text-sm whitespace-no-wrap'}
                                >
                                    <FormattedMessage id={'sweepstakesForm.salutation.option3'} />
                                </label>
                            </div>
                        </div>

                        <div className={'flex w-full gap-6 mb-10'}>
                            <div className={classnames('w-1/2 input--wrapper', {
                                'is--invalid': errors?.firstName,
                            })}
                            >
                                <input
                                    id={'sweepstakes-form--firstname'}
                                    {...register('firstName', {
                                        required: requiredFieldMessage,
                                    })}
                                    type={'text'}
                                    placeholder={intl.formatMessage({ id: 'sweepstakesForm.address.firstNameLabel' })}
                                    tabIndex={0} />
                                <label htmlFor={'sweepstakes-form--firstname'}>
                                    <FormattedMessage id={'sweepstakesForm.address.firstNameLabel'} />
                                </label>
                                <span className={'input--border'} />
                                {errors?.firstName
                                    && (
                                        <span className={'input--hint'}>
                                            <ErrorMessage
                                                errors={errors}
                                                name={'firstName'}
                                            />
                                        </span>
                                    )}
                            </div>

                            <div className={classnames('w-1/2 input--wrapper', {
                                'is--invalid': errors?.lastName,
                            })}
                            >
                                <input
                                    id={'sweepstakes-form--lastname'}
                                    {...register('lastName', {
                                        required: requiredFieldMessage,
                                    })}
                                    type={'text'}
                                    placeholder={intl.formatMessage({ id: 'sweepstakesForm.address.lastNameLabel' })}
                                    tabIndex={0} />
                                <label htmlFor={'sweepstakes-form--lastname'}>
                                    <FormattedMessage id={'sweepstakesForm.address.lastNameLabel'} />
                                </label>
                                <span className={'input--border'} />
                                {errors?.lastName
                                    && (
                                        <span className={'input--hint'}>
                                            <ErrorMessage
                                                errors={errors}
                                                name={'lastName'}
                                            />
                                        </span>
                                    )}
                            </div>
                        </div>

                        <div className={classnames('mb-10 w-full input--wrapper', {
                            'is--invalid': errors?.email,
                        })}
                        >
                            <input
                                id={'sweepstakes-form--email'}
                                {...register('email', {
                                    required: requiredFieldMessage,
                                    pattern: {
                                        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: validEmailMessage,
                                    },
                                })}
                                type={'email'}
                                placeholder={intl.formatMessage({ id: 'sweepstakesForm.address.emailLabel' })}
                                tabIndex={0} />
                            <label htmlFor={'sweepstakes-form--email'}>
                                <FormattedMessage id={'sweepstakesForm.address.emailLabel'} />
                            </label>
                            <span className={'input--border'} />
                            {errors?.email
                                && (
                                    <span className={'input--hint'}>
                                        <ErrorMessage
                                            errors={errors}
                                            name={'email'}
                                        />
                                    </span>
                                )}
                        </div>

                        <div className={'flex w-full gap-6 mb-10'}>
                            <div className={classnames('w-2/3 input--wrapper', {
                                'is--invalid': errors?.street,
                            })}
                            >
                                <input
                                    id={'sweepstakes-form--street'}
                                    {...register('street', {
                                        required: requiredFieldMessage,
                                    })}
                                    type={'text'}
                                    placeholder={intl.formatMessage({ id: 'sweepstakesForm.address.streetLabel' })}
                                    tabIndex={0} />
                                <label htmlFor={'sweepstakes-form--street'}>
                                    <FormattedMessage id={'sweepstakesForm.address.streetLabel'} />
                                </label>
                                <span className={'input--border'} />
                                {errors?.street
                                    && (
                                        <span className={'input--hint'}>
                                            <ErrorMessage
                                                errors={errors}
                                                name={'street'}
                                            />
                                        </span>
                                    )}
                            </div>

                            <div className={classnames('w-1/3 input--wrapper', {
                                'is--invalid': errors?.houseNumber,
                            })}
                            >
                                <input
                                    id={'sweepstakes-form--housenumber'}
                                    {...register('houseNumber', {
                                        required: requiredFieldMessage,
                                    })}
                                    type={'text'}
                                    placeholder={intl.formatMessage({ id: 'sweepstakesForm.address.houseNumberLabel' })}
                                    tabIndex={0} />
                                <label htmlFor={'sweepstakes-form--housenumber'}>
                                    <FormattedMessage id={'sweepstakesForm.address.houseNumberLabel'} />
                                </label>
                                <span className={'input--border'} />
                                {errors?.houseNumber
                                    && (
                                        <span className={'input--hint'}>
                                            <ErrorMessage
                                                errors={errors}
                                                name={'houseNumber'}
                                            />
                                        </span>
                                    )}
                            </div>
                        </div>

                        <div className={'flex w-full gap-6 mb-10'}>
                            <div className={classnames('w-1/3 input--wrapper', {
                                'is--invalid': errors?.zip,
                            })}
                            >
                                <input
                                    id={'sweepstakes-form--zip'}
                                    {...register('zip', {
                                        required: requiredFieldMessage,
                                        pattern: {
                                            value: /^[0-9]{5}$/,
                                            message: validZipCodeMessage,
                                        },
                                        minLength: {
                                            value: 5,
                                            message: validZipCodeMessage,
                                        },
                                        maxLength: {
                                            value: 5,
                                            message: validZipCodeMessage,
                                        },
                                    })}
                                    type={'number'}
                                    max={'99999'}
                                    placeholder={intl.formatMessage({ id: 'sweepstakesForm.address.zipLabel' })}
                                    tabIndex={0} />
                                <label htmlFor={'sweepstakes-form--zip'}>
                                    <FormattedMessage id={'sweepstakesForm.address.zipLabel'} />
                                </label>
                                <span className={'input--border'} />
                                {errors?.zip
                                    && (
                                        <span className={'input--hint'}>
                                            <ErrorMessage
                                                errors={errors}
                                                name={'zip'}
                                            />
                                        </span>
                                    )}
                            </div>

                            <div className={classnames('w-2/3 input--wrapper', {
                                'is--invalid': errors?.city,
                            })}
                            >
                                <input
                                    id={'sweepstakes-form--city'}
                                    {...register('city', {
                                        required: requiredFieldMessage,
                                    })}
                                    type={'text'}
                                    placeholder={intl.formatMessage({ id: 'sweepstakesForm.address.cityLabel' })}
                                    tabIndex={0} />
                                <label htmlFor={'sweepstakes-form--city'}>
                                    <FormattedMessage id={'sweepstakesForm.address.cityLabel'} />
                                </label>
                                <span className={'input--border'} />
                                {errors?.city
                                    && (
                                        <span className={'input--hint'}>
                                            <ErrorMessage
                                                errors={errors}
                                                name={'city'}
                                            />
                                        </span>
                                    )}
                            </div>
                        </div>

                        <div className={'contents w-full checkbox-radio--wrapper'}>
                            <input
                                type={'checkbox'}
                                {...register('checkboxNewsletter', {
                                    required: false,
                                })}
                                id={'sweepstakes--checkbox-newsletter'}
                                tabIndex={0} />
                            <label
                                className={'text-sm mb-6'}
                                htmlFor={'sweepstakes--checkbox-newsletter'}
                            >
                                <FormattedMessage id={'sweepstakesForm.checkbox.newsletterLabel'} />
                            </label>
                        </div>

                        <div className={classnames('contents w-full checkbox-radio--wrapper', {
                            'is--invalid': errors?.checkboxTOS,
                        })}
                        >
                            <input
                                type={'checkbox'}
                                {...register('checkboxTOS', {
                                    required: requiredFieldMessage,
                                })}
                                id={'sweepstakes--checkbox-tos'}
                                tabIndex={0} />
                            <label
                                className={'text-sm mb-6'}
                                htmlFor={'sweepstakes--checkbox-tos'}
                            >
                                <FormattedHTMLMessage id={'sweepstakesForm.checkbox.tosLabel'} />
                            </label>
                        </div>

                        <ButtonWithLoader
                            className={'btn btn-cta w-1/3 flex'}
                            ariaLabel={intl.formatMessage({ id: 'sweepstakesForm.submitLabel' })}
                            showLoader={showLoader}
                            type={'submit'}
                            darkTheme={data.renderBlackBackground}
                        >
                            <FormattedMessage id={'sweepstakesForm.submitLabel'} />
                        </ButtonWithLoader>
                    </div>
                </form>
            </section>

            {data.renderDottedBorders
                && <BorderOnTopAndBottom renderAtTop={false} renderBlackBackground={data.renderBlackBackground} />}
        </FullPageWidthWrapper>
    )
}

export default tastify({ translate: true })(injectIntl(SweepstakesForm))

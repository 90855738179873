import React from 'react'
import PropTypes from 'prop-types'
import TermFacet from '../Facets/TermFacet'
import RangeFacet from '../Facets/RangeFacet'
import FacetService from '../../../../services/facet'

const TermAndPriceFilter = ({ facetList, onChange }) => {
    if (!facetList || !facetList.length) {
        return null
    }
    const initFacetList = [...facetList]
    const handleChange = (facet, facetIndex) => {
        initFacetList.splice(facetIndex, 1, facet)
        if (onChange) {
            onChange(initFacetList)
        }
    }

    return (
        facetList.map((facet, index) => {
            if (facet.type === 'term' && FacetService.isGenderFacet(facet)) {
                return null
            }

            if (facet.type === 'range') {
                return (
                    <RangeFacet
                        key={facet.key}
                        onChange={(facet) => {
                            handleChange(facet, index)
                        }}
                        facet={facet}
                    />
                )
            }

            if (!(facet.type === 'term' && facet.terms.length === 0)) {
                return (
                    <TermFacet
                        key={facet.key}
                        facet={facet}
                        onChange={(facet) => {
                            handleChange(facet, index)
                        }}
                    />
                )
            }

            return null
        })
    )
}
TermAndPriceFilter.propTypes = {
    facetList: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
}

export default TermAndPriceFilter

import React, { useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import classnames from 'classnames'
import { ReactComponent as InfoIcon } from '../../../../../icons/sport2000-icons/info.svg'
import Button from '../../../atoms/button/Button'
import useStoreCookie from '../../../../services/hook/useStoreCookie'
import { ReactComponent as LoadingIcon } from '../../../../../icons/sport2000-icons/loading.svg'

const Store = ({ store, tooltip, context }) => {
    const { cookieStore, updateCookieStore, idStoreToSave } = useStoreCookie(context)
    const [openPanel, setOpenPanel] = useState(false)
    const isMobile = useDeviceType() === 'mobile'

    const handleCheckSelectStore = () => {
        updateCookieStore(store)
    }

    const renderTooltip = () => (
        tooltip && (
            <Button type={'button'} tabIndex={'0'} className={'relative tooltip-content'}>
                <InfoIcon width={20} height={20} className={'info-icon'} />
                <div className={classnames('tooltip-message', {
                    'tooltip-message-mobile': isMobile && !openPanel,
                })}
                >
                    <div className={'tooltip-left'}>
                        {tooltip}
                    </div>
                </div>
            </Button>
        )
    )

    return (
        <div className={'store-detail-page-select-store'}>
            <div className={'checkbox-radio--wrapper'}>
                {store.objectID === idStoreToSave ? (
                    <div className={'w-8'}>
                        <LoadingIcon width={20} height={20} />
                    </div>
                ) : (
                    <input
                        className={'store-detail-page-select-store-button'}
                        id={'select-store-label'}
                        type={'radio'}
                        checked={cookieStore && store.objectID === cookieStore.id}
                        onChange={handleCheckSelectStore}
                    />
                )}
                <label htmlFor={'select-store-label'} className={'mr-2'}>
                    <FormattedMessage id={'storeDetail.selectStoreOption'} />
                </label>
                {renderTooltip()}
            </div>
        </div>
    )
}

Store.propTypes = {
    store: PropTypes.object.isRequired,
    tooltip: PropTypes.string,
    context: PropTypes.any,
}

export default injectIntl(Store)

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classnames from 'classnames'
import debounce from '@frontastic/common/src/js/helper/debounce'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import app from '@frontastic/catwalk/src/js/app/app'
import PriceHelper from 'anwr_sport2000/src/js/services/price'
import StoreService from 'anwr_sport2000/src/js/services/algolia/StoreService'
import { useCookies } from 'react-cookie'
import ProductData from './ProductData'
import ProductInfo from './ProductInfo/ProductInfo'
import ProductImages from './ProductImages'
import SizeTable from '../../molecules/SizeTable'
import ProductSchemaSeo from './ProductSchemaSeo'
import TagManager from '../../../domain/TagManager'
import GtmService from '../../../services/gtm'
import OldGtmService from '../../../services/oldGtm'
import ProductRecommendationList from '../productRecommendationList'
import OldTagManager from '../../../domain/OldTagManager'
import ProductTags from '../../atoms/tag/productTags'
import GraphImage from '../../atoms/graphImage'
import PdpCampaign from '../GraphCMS/ProductDetailCampaign'
import fetch from '@frontastic/bridge/js/fetch'
import { addOrUpdateQueryParam, getQueryParam } from '../../../utils/helpers/urlHelper'

const ProductDetails = ({
    productDefault,
    colorProducts,
    setColorProducts,
    handleAddToCart,
    productColor,
    selectedVariant,
    setSelectedVariantIndex,
    indexVariantFromCart,
    refererStore,
    productRecommendationData,
    productBrandRecommendationData,
    algoliaIndexName,
    graphCampaign,
    textColor,
    bgColor,
    positionMode,
    alignText,
    onOpenOffCanvasStoreFinder,
    gtmCategoryName,
    gtmCategoryId,
    context,
}) => {
    if (_.isEmpty(productColor) || _.isEmpty(selectedVariant)) {
        return null
    }

    const isMobile = useDeviceType() === 'mobile'
    const location = useLocation()
    const wishlistLineItems = useSelector((state) => state.wishlist?.wishlist?.data?.lineItems || [])

    const [openPanel, setOpenPanel] = useState(false)
    const [brandSizeTable, setBrandSizeTable] = useState({})
    const [brandLogo, setBrandLogo] = useState(null)
    const [isTrackedQueryImpression, setIsTrackedQueryImpression] = useState(false)

    const { runningCategoryId } = app.getApi().router.context.projectConfiguration
    const { absoluteCategoryId } = app.getApi().router.context.projectConfiguration

    const productCategoryIds = productColor?.projectSpecificData?.categoryIds || []
    const [cookie] = useCookies()
    const { store } = cookie
    const mainStore = refererStore || store || ''

    const defaultIndexRunningCategory = productCategoryIds.findIndex((category) => category === runningCategoryId) > -1
    const defaultIndexAbsoluteCategory = productCategoryIds.findIndex((category) => category === absoluteCategoryId) > -1

    const variantBrand = productDefault?.variants[0]?.attributes?.att_prod_subbrand || productDefault?.variants[0]?.attributes?.att_prod_brand
    const [variantBrandLogo, setVariantBrandLogo] = useState(variantBrand)
    const [productTags, setProductTags] = useState(selectedVariant?.attributes?.att_internal_category_sport2000 || [])
    const [queryId, setQueryId] = useState('')

    // Get product attributes series and filter to get GraphCMS
    const attributeProductSeries = productColor.variants[0].attributes.att_prod_product_series || ''
    const filterArrayCampaign = graphCampaign && graphCampaign.filter((data) => data?.adentifierattribut[0]?.toLowerCase() === attributeProductSeries.toLowerCase())
    const productGraphCampaign = filterArrayCampaign && filterArrayCampaign.length > 0 && filterArrayCampaign[0]
    const brandName = selectedVariant.attributes.att_prod_brand
    const brandUrl = selectedVariant.attributes.att_prod_brand_url
    const currentUrl = location.asPath

    useEffect(() => {
        if (defaultIndexAbsoluteCategory) {
            document.body.classList.add('absolute-teamsport--theme')
        }

        if (defaultIndexRunningCategory) {
            document.body.classList.add('absolute-run--theme')
        }
    })

    useEffect(() => {
        if (brandSizeTable.brandSizeTables) {
            return
        }
        const payload = {
            brand: productDefault.variants[0].attributes.att_prod_brand,
            categoryIds: productDefault.categories,
        }

        fetch('/api/graphcms/brand/table-size/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then((response) => response.json())
            .then((body) => {
                if (body.data) {
                    setBrandSizeTable(body.data)
                }
            })
    })

    useEffect(() => {
        if (brandLogo) {
            return
        }

        fetch(`/api/graphcms/brand-product/${variantBrandLogo}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((body) => {
                if (body.length === 0) {
                    setVariantBrandLogo(productDefault?.variants[0]?.attributes?.att_prod_brand)
                    return
                }
                if (body.logo) {
                    setBrandLogo(body.logo.url)
                }
            })
    }, [variantBrandLogo])

    useEffect(() => {
        const variantSelectedCheckedStockInStore = StoreService.checkProductStockInStore(mainStore, selectedVariant)
        const isSelectedVariantOnSale = PriceHelper.isDiscountOverThreshold(variantSelectedCheckedStockInStore.discountedPrice, variantSelectedCheckedStockInStore.price)

        if (isSelectedVariantOnSale) {
            setProductTags([...productTags, 'sale'])
        } else {
            setProductTags(selectedVariant?.attributes?.att_internal_category_sport2000 || [])
        }
    }, [selectedVariant, refererStore, cookie])

    let intervalId

    useEffect(() => {
        intervalId = setInterval(() => {
            if (window.aa && !isTrackedQueryImpression && algoliaIndexName && selectedVariant) {
                TagManager.queryIdImpression([selectedVariant.sku], algoliaIndexName)
                setIsTrackedQueryImpression(true)
            }
        }, 500)

        if (isTrackedQueryImpression) {
            clearInterval(intervalId)
        }

        return () => clearInterval(intervalId)
    }, [selectedVariant, algoliaIndexName, isTrackedQueryImpression])

    const stickyJProductInformation = () => {
        const targetDiv = document.getElementById('product--detail--data')

        if (targetDiv && document.documentElement.scrollTop >= targetDiv.offsetTop) {
            document.body.classList.add('is--sticky')
        }

        if (targetDiv && document.documentElement.scrollTop < targetDiv.offsetTop) {
            document.body.classList.remove('is--sticky')
        }
    }

    const handleAddToCartGTM = () => {
        const gtmService = new GtmService(null, selectedVariant)
        const productGTM = gtmService.createProductGTM('Product Detail', {
            quantity: 1,
            query_id: queryId,
            user_token: window.localStorage.getItem('userToken'),
        })
        TagManager.addProduct(productGTM)

        // for universal analytics ( will be removed )
        const oldGtmService = new OldGtmService(null, selectedVariant)
        const oldProductGtm = oldGtmService.createProductWithQuantityGTM({
            query_id: queryId,
            user_token: window.localStorage.getItem('userToken'),
        })
        OldTagManager.addProduct(oldProductGtm)
    }

    const handleUpdateSkuToUrl = (sku) => {
        const currentQueryParams = getQueryParam(currentUrl)
        const productColorUrl = productColor?._url
        const fullProductColorUrl = addOrUpdateQueryParam(productColorUrl, {
            ...currentQueryParams,
            sku,
            queryId,
        })
        window.history.replaceState(null, '', fullProductColorUrl)
    }

    useEffect(() => {
        setQueryId(getQueryParam(currentUrl, 'queryId'))
        window.addEventListener('scroll', debounce(() => stickyJProductInformation(), 25), { passive: true })

        return window.removeEventListener('scroll', debounce(() => stickyJProductInformation(), 25))
    }, [])

    const wishlistItem = wishlistLineItems.find((item) =>
        // For the time being we're using only first variant throughout the Boost theme
        item.variant.sku === selectedVariant?.sku)

    const isGoogleBot = typeof navigator !== 'undefined' && (navigator.userAgent.indexOf('Google') > -1 || navigator.userAgent.indexOf('AdsBot') > -1)

    const hasProductRecommendation = !isGoogleBot && !!productRecommendationData
    const hasProductBrandRecommendation = !isGoogleBot && !!productBrandRecommendationData

    const videoProductAttribute = selectedVariant.attributes.att_txt_long_product_video_links
    const productImagesSlider = videoProductAttribute ? [...selectedVariant.images, videoProductAttribute] : [...selectedVariant.images]

    const renderProductData = () => (
        <>
            {brandSizeTable.brandSizeTables && (
                <SizeTable
                    openPanel={openPanel}
                    setOpenPanel={setOpenPanel}
                    brandSizeTable={brandSizeTable}
                />
            )}
            <div className={'product-detail-data-wrapper'} id={'product--detail--data'}>
                <div className={'product-detail-data'}>
                    <ProductData
                        colorProducts={_.cloneDeep(colorProducts)}
                        setColorProducts={setColorProducts}
                        variants={productColor.variants}
                        selectedVariant={selectedVariant}
                        onChange={setSelectedVariantIndex}
                        handleAddToCart={handleAddToCart}
                        onUpdateSkuToUrl={handleUpdateSkuToUrl}
                        setOpenPanel={setOpenPanel}
                        brandSizeTable={brandSizeTable}
                        onTrackPurchaseGTM={handleAddToCartGTM}
                        indexVariantFromCart={indexVariantFromCart}
                        wishlisted={!!wishlistItem}
                        wishlistItem={wishlistItem}
                        refererStore={refererStore}
                        onOpenOffCanvasStoreFinder={onOpenOffCanvasStoreFinder}
                        queryId={queryId}
                        context={context}
                    />
                </div>
            </div>
        </>
    )

    return (
        <div className={'product-detail-wrapper'}>
            <ProductSchemaSeo
                productColor={productColor}
                selectedVariant={selectedVariant}
                refererStore={refererStore}
            />
            {selectedVariant.sku && algoliaIndexName && (
                <div
                    className={'product-detail-component lg:flex'}
                    data-insights-object-id={selectedVariant.sku}
                    data-insights-index={algoliaIndexName}
                >
                    <div className={'product-detail--wrapper'}>
                        <div className={'product-detail-image-wrapper'}>
                            <div className={'product-detail-image-logo'}>
                                <Link
                                    itemProp={'url'}
                                    to={brandUrl}
                                >

                                    <GraphImage
                                        src={brandLogo}
                                        alt={brandName}
                                        width={130}
                                        height={55}
                                    />
                                </Link>
                            </div>
                            {isMobile
                                && (
                                    <ProductTags
                                        tags={productTags}
                                        className={'product-detail-tags'}
                                    />
                                )}
                            {selectedVariant && selectedVariant.images
                                ? (
                                    <ProductImages images={productImagesSlider} title={productColor.name} />
                                )
                                : (
                                    <div className={'product-detail--placeholder'} />
                                )}
                        </div>

                        <div className={'product-data-mobile lg:hidden'}>
                            {renderProductData()}
                        </div>

                        <div className={classnames('product-detail-feature', {
                            'dark-mode': selectedVariant.attributes.att_internal_category_absolute_fitness || selectedVariant.attributes.att_internal_category_absolute_outdoor || selectedVariant.attributes.att_internal_category_absolute_run || selectedVariant.attributes.att_internal_category_absolute_teamsport,
                        })}
                        >
                            <ProductInfo
                                productColor={productColor}
                                selectedVariant={selectedVariant}
                            />
                        </div>
                        {productGraphCampaign && (
                            <PdpCampaign
                                data={productGraphCampaign}
                                color={textColor}
                                backgroundColor={bgColor}
                                position={positionMode}
                                alignText={alignText}
                            />
                        )}
                        {hasProductRecommendation
                            && (
                                <ProductRecommendationList
                                    {...productRecommendationData}
                                    selectedVariant={selectedVariant}
                                    brandName={brandName}
                                    brandUrl={brandUrl}
                                    isDisplayOnPDP
                                    gtmCategoryName={gtmCategoryName}
                                    gtmCategoryId={gtmCategoryId}
                                />
                            )}
                        {hasProductBrandRecommendation
                            && (
                                <ProductRecommendationList
                                    {...productBrandRecommendationData}
                                    selectedVariant={selectedVariant}
                                    brandName={brandName}
                                    brandUrl={brandUrl}
                                    isDisplayOnPDP
                                    gtmCategoryName={gtmCategoryName}
                                    gtmCategoryId={gtmCategoryId}
                                />
                            )}
                    </div>

                    <div className={'product-data-desktop hidden lg:block'}>
                        {renderProductData()}
                    </div>
                </div>
            )}
        </div>
    )
}

ProductDetails.propTypes = {
    colorProducts: PropTypes.array.isRequired,
    handleAddToCart: PropTypes.func.isRequired,
    setColorProducts: PropTypes.func.isRequired,
    productDefault: PropTypes.any,
    setIsOpenStoreSidebar: PropTypes.any,
    productColor: PropTypes.object,
    selectedVariant: PropTypes.any,
    setSelectedVariantIndex: PropTypes.any,
    indexVariantFromCart: PropTypes.any,
    productBrandRecommendationData: PropTypes.any,
    refererStore: PropTypes.any,
    context: PropTypes.any,
    onOpenOffCanvasStoreFinder: PropTypes.any,
    productRecommendationData: PropTypes.any,
    algoliaIndexName: PropTypes.string,
    bgColor: PropTypes.string,
    textColor: PropTypes.string,
    alignText: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
    positionMode: PropTypes.PropTypes.oneOf(['left', 'right']),
    graphCampaign: PropTypes.object,
}

export default ProductDetails

import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import IconButton from '../../../atoms/button/IconButton'
import { ReactComponent as EditIcon } from '../../../../../icons/sport2000-icons/edit.svg'
import { ReactComponent as DeleteIcon } from '../../../../../icons/sport2000-icons/delete.svg'
import { ReactComponent as CheckCircleIcon } from '../../../../../icons/sport2000-icons/check-circle.svg'

const AddressCard = ({
    address, countries, onEditClicked, onDeleteClicked, intl,
}) => {
    let country
    for (const c of countries) {
        if (c.code === address.country) {
            country = c
        }
    }

    return (
        <div className={'account-address-item'}>
            {(address.firstName || address.lastName) && (
                <strong>
                    {address.firstName}
                    {' '}
                    {address.lastName}
                </strong>
            )}

            {(address.streetName || address.streetNumber) && (
                <div>
                    {address.streetName}
                    {' '}
                    {address.streetNumber}
                </div>
            )}

            {(address.postalCode || address.city) && (
                <div>
                    {address.postalCode}
                    {' '}
                    {address.city}
                </div>
            )}

            {(address.additionalAddressInfo) && (
                <div>
                    {address.additionalAddressInfo}
                </div>
            )}

            {country.name && (
                <div className={'mb-0'}>{country.name}</div>
            )}

            <div className={'md:flex'}>
                <div className={'account-default-addresses md:flex-auto md:mr-4 lg:mr-0'}>
                    {address.isDefaultShippingAddress && (
                        <div className={'account-default-address mb-2 flex items-center'}>
                            <CheckCircleIcon className={'w-5 h-5 fill-current block mr-3'} />
                            <FormattedMessage id={'account.address.standardShipping'} />
                        </div>
                    )}

                    {address.isDefaultBillingAddress && (
                        <div className={'account-default-address flex items-center'}>
                            <CheckCircleIcon className={'w-5 h-5 fill-current block mr-3'} />
                            <FormattedMessage id={'account.address.standardBilling'} />
                        </div>
                    )}
                </div>
                <div className={'flex md:flex-auto justify-end mt-4 md:mt-0 md:items-end'}>
                    <IconButton
                        className={'flex account--edit-button'}
                        onClick={onEditClicked}
                        type={'button'}
                        ariaLabel={intl.formatMessage({ id: 'account.edit' })}
                        icon={<EditIcon />}
                    >
                        <FormattedMessage id={'account.edit'} />
                    </IconButton>

                    <IconButton
                        className={'flex account--delete-button'}
                        onClick={onDeleteClicked}
                        type={'button'}
                        ariaLabel={intl.formatMessage({ id: 'account.delete' })}
                        icon={<DeleteIcon />}
                    >
                        <FormattedMessage id={'account.delete'} />
                    </IconButton>
                </div>
            </div>
        </div>
    )
}

AddressCard.propTypes = {
    intl: PropTypes.object.isRequired,
    address: PropTypes.object.isRequired,
    countries: PropTypes.array.isRequired,
    onEditClicked: PropTypes.func.isRequired,
    onDeleteClicked: PropTypes.func.isRequired,
}

export default injectIntl(AddressCard)

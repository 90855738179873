import React, { useState } from 'react'
import {
    FormattedHTMLMessage, FormattedMessage, WrappedComponentProps, injectIntl, IntlShape,
} from 'react-intl'
import { SubmitHandler, useForm } from 'react-hook-form'
import app from '@frontastic/catwalk/src/js/app/app'
import Message from '@frontastic/catwalk/src/js/app/message'
import classNames from 'classnames'
import ErrorMessage from '../../../atoms/errorMessage/index'
import ButtonWithLoader from '../../../atoms/button/WithLoader'
import Button from '../../../atoms/button/Button'
import { RegisterFormTypes } from './types'
import { patternValueWithoutBlank } from '../../../../services/validate'
import { ReactComponent as EyeOpen } from '../../../../../icons/sport2000-icons/eye-open.svg'
import { ReactComponent as EyeClosed } from '../../../../../icons/sport2000-icons/eye-closed.svg'
import { validateEmail } from '../../../../utils/helpers/accountHelper'

export type Props = {
    intl: IntlShape
    showLoginForm: Function
    handleRegister: (data: RegisterFormTypes) => Promise<any>
    showLoader: boolean
    restrictEmailDomains?: Array<{ emailDomain: string }>
} & WrappedComponentProps

const Register: React.FC<Props> = ({
    intl, showLoginForm, handleRegister, showLoader, restrictEmailDomains,
}: Props) => {
    const {
        register,
        handleSubmit,
        setError,

        formState: {
            errors,
        },
    } = useForm()
    const requiredFieldMessage = intl.formatMessage({ id: 'validation.required' })
    const validEmailMessage = intl.formatMessage({ id: 'validation.email' })
    const validPasswordLengthMessage = intl.formatMessage({ id: 'validation.minLength' })
    const validPasswordPatternMessage = intl.formatMessage({ id: 'validation.passwordPattern' })
    const [inputPasswordType, setInputPasswordType] = useState('password')

    const onSubmit: SubmitHandler<RegisterFormTypes> = async ({
        firstName, lastName, email, password, data,
    }) => {
        if (data) {
            // try {
            // const responseEmailDuplicated = await EmailService.checkEmailDuplicated(email)
            // if (!responseEmailDuplicated.valid) {
            //     setError('email', 'notMatch', intl.formatMessage({ id: 'account.register.EmailExisted' }))
            //     return
            // }
            await handleRegister({
                firstName,
                lastName,
                email,
                password,
                // @ts-ignore
                salutation: '-',
                toc: true,
                data,
            })
            // } catch (err) {
            //     app.getLoader('context').notifyUser(<Message
            //         message={err}
            //     />, 'error')
            // }
        }
    }

    const toggleInputPasswordType = () => {
        if (inputPasswordType === 'password') {
            return setInputPasswordType('text')
        }

        return setInputPasswordType('password')
    }

    return (
        <section className={'account account--register grid grid-cols-12 gap-4 mt-6 md:mt-20'}>
            <div
                className={'col-span-12 lg:col-span-6 self-start order-1 lg:order-none px-4 py-6 lg:p-8 bg-white'}
            >
                <h3 className={'mx-0 mb-8'}>
                    <FormattedMessage id={'account.register.alreadyRegistered'} />
                </h3>

                <Button
                    className={'btn btn-secondary w-full flex'}
                    ariaLabel={intl.formatMessage({ id: 'account.login.login' })}
                    onClick={showLoginForm}
                >
                    <FormattedMessage id={'account.login.login'} />
                </Button>
            </div>

            <div className={'col-span-12 lg:col-span-6 px-4 py-6 lg:p-8 bg-white'}>
                <h3 className={'mx-0 mb-8'}>
                    <FormattedMessage id={'account.login.newToSport2000'} />
                </h3>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={classNames('mb-10 input--wrapper', {
                        'is--invalid': errors?.firstName,
                    })}
                    >
                        <input
                            id={'register-firstname'}
                            {...register('firstName', {
                                required: requiredFieldMessage,
                                pattern: {
                                value: patternValueWithoutBlank,
                                message: requiredFieldMessage,
                                },
                            })}
                            type={'text'}
                            className={'form-input'}
                            placeholder={intl.formatMessage({ id: 'account.register.firstName' })} />
                        <label htmlFor={'register-firstname'}>
                            <FormattedMessage id={'account.register.firstName'} />
                        </label>
                        <span className={'input--border'} />
                        {errors?.firstName
                            && (
                                <span className={'input--hint'}>
                                    <ErrorMessage
                                        errors={errors}
                                        name={'firstName'}
                                    />
                                </span>
                            )}
                    </div>

                    <div className={classNames('mb-10 input--wrapper', {
                        'is--invalid': errors?.lastName,
                    })}
                    >
                        <input
                            id={'register-lastname'}
                            {...register('lastName', {
                                required: requiredFieldMessage,
                                pattern: {
                                    value: patternValueWithoutBlank,
                                    message: requiredFieldMessage,
                                },
                            })}
                            type={'text'}
                            className={'form-input'}
                            placeholder={intl.formatMessage({ id: 'account.register.lastName' })} />
                        <label htmlFor={'register-lastname'}>
                            <FormattedMessage id={'account.register.lastName'} />
                        </label>
                        <span className={'input--border'} />
                        {errors?.lastName
                            && (
                                <span className={'input--hint'}>
                                    <ErrorMessage
                                        errors={errors}
                                        name={'lastName'}
                                    />
                                </span>
                            )}
                    </div>

                    <div className={classNames('mb-10 input--wrapper', {
                        'is--invalid': errors?.email,
                    })}
                    >
                        <input
                            id={'register-email'}
                            {...register('email', {
                                required: requiredFieldMessage,
                                validate: (email) => validateEmail(email, restrictEmailDomains, validEmailMessage),
                            })}
                            type={'email'}
                            className={'form-input'}
                            placeholder={intl.formatMessage({ id: 'account.form.emailLabel' })} />
                        <label htmlFor={'register-email'}>
                            <FormattedMessage id={'account.form.emailLabel'} />
                        </label>
                        <span className={'input--border'} />
                        {errors?.email
                            && (
                                <span className={'input--hint'}>
                                    <ErrorMessage
                                        errors={errors}
                                        name={'email'}
                                    />
                                </span>
                            )}
                    </div>

                    <div className={classNames('mb-10 input--wrapper', {
                        'is--invalid': errors?.password,
                    })}
                    >
                        <input
                            id={'register-password'}
                            {...register('password', {
                                required: requiredFieldMessage,
                                minLength: {
                                    value: 8,
                                    message: validPasswordLengthMessage,
                                },
                                pattern: {
                                    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,}$/,
                                    message: validPasswordPatternMessage,
                                },
                            })}
                            type={inputPasswordType}
                            placeholder={intl.formatMessage({ id: 'account.form.passwordLabel' })}
                            className={'form-input'} />
                        <label htmlFor={'register-password'}>
                            <FormattedMessage id={'account.form.passwordLabel'} />
                        </label>
                        <Button
                            type={'button'}
                            className={'account--password-reveal absolute p-4'}
                            ariaLabel={intl.formatMessage({ id: 'account.login.revealPassword' })}
                            onClick={() => toggleInputPasswordType()}
                        >
                            {inputPasswordType === 'password'
                                ? <EyeOpen width={'16'} height={'16'} />
                                : <EyeClosed width={'16'} height={'16'} />}
                        </Button>
                        <span className={'input--border'} />
                        {errors?.password
                            && (
                                <span className={'input--hint'}>
                                    <ErrorMessage
                                        errors={errors}
                                        name={'password'}
                                    />
                                </span>
                            )}
                    </div>

                    <div className={classNames('w-full checkbox-radio--wrapper mb-6', {
                        'is--invalid': errors?.data,
                    })}
                    >
                        <input
                            id={'register-data'}
                            {...register('data', {
                                required: requiredFieldMessage,
                                pattern: {
                                    value: patternValueWithoutBlank,
                                    message: requiredFieldMessage,
                                },
                            })}
                            type={'checkbox'} />
                        <label
                            className={'text-sm'}
                            htmlFor={'register-data'}
                        >
                            <FormattedHTMLMessage id={'account.form.dataProtection'} />
                        </label>
                    </div>

                    <div className={'mb-10 text-sm data-protection'}>
                        <label>
                            <FormattedHTMLMessage id={'account.form.dataEmail'} />
                        </label>
                    </div>

                    <ButtonWithLoader
                        className={'btn btn-default w-full flex'}
                        ariaLabel={intl.formatMessage({ id: 'account.register.joinSport2000' })}
                        showLoader={showLoader}
                        type={'submit'}
                    >
                        <FormattedMessage id={'account.register.joinSport2000'} />
                    </ButtonWithLoader>
                </form>
            </div>
        </section>
    )
}

export default injectIntl(Register)

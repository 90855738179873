import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Slider from '@ant-design/react-slick'
import FrontasticImage from '../../../atoms/frontasticImage'

const ProductImages = ({ images, title }) => {
    if (!images && !images.length) {
        return null
    }

    const settingsMain = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    const settingsThumbs = {
        slidesToScroll: 1,
        slidesToShow: 4,
        infinite: false,
        dots: false,
        swipeToSlide: true,
        focusOnSelect: true,
        variableWidth: true,
    }

    const [nav1, setNav1] = useState(null)
    const [nav2, setNav2] = useState(null)

    const youtubeUrl = 'youtube-nocookie'

    const renderProductImage = (width, height) => (
        images.map((image, index) => {
            const checkVideoOrImage = images.filter((item) => item.includes(youtubeUrl))

            return (
                <div className={'product-article-image'} key={`${image} + ${index}`}>
                    {(checkVideoOrImage.length > 0 && images.length - 1 === index) ? (
                        <div className={'product-video-item'}>
                            <iframe
                                data-cmp-vendor={'s30'}
                                src={'about:blank'}
                                className={'cmplazyload'}
                                width={'100%'}
                                height={'100%'}
                                data-cmp-src={checkVideoOrImage}
                                frameBorder={'0'}
                                allow={'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'}
                                allowFullScreen
                                title={'Embedded youtube'}
                            />
                        </div>
                    ) : (
                        <div className={'product-article-img'}>
                            <FrontasticImage
                                src={image}
                                width={width}
                                height={height}
                                alt={title}
                                priority={index === 0}
                            />
                        </div>
                    )}
                </div>
            )
        })
    )

    return (
        <div className={'product-detail-image'}>
            <Slider
                {...settingsMain}
                asNavFor={nav2}
                ref={(slider1) => setNav1(slider1)}
            >
                {renderProductImage(1000, 1000)}
            </Slider>
            <div className={'thumbnail-slider-wrap'}>
                <Slider
                    {...settingsThumbs}
                    asNavFor={nav1}
                    ref={(slider2) => setNav2(slider2)}
                >
                    {renderProductImage(120, 120)}
                </Slider>
            </div>
        </div>

    )
}

ProductImages.propTypes = {
    images: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
}

export default ProductImages

import React from 'react'
import PropTypes from 'prop-types'
import FullCart from './FullCart'
import EmptyCart from './FullCart/Empty'
import ErrorMessageProduct from '../ErrorMessageProduct'

const Cart = ({
    data, links, isLoading = false,
}) => {
    if (!data || !data.lineItems) {
        return null
    }

    const removedItems = data.projectSpecificData.removedLineItems ?? []
    const reducedLineItems = data.projectSpecificData.reducedLineItems ?? []
    if (data && data.lineItems && data.lineItems.length > 0) {
        const {
            lineItems, sum, currency,
        } = data

        return (
            <FullCart
                isLoading={isLoading}
                links={links}
                items={lineItems}
                sum={sum}
                currency={currency}
                removedItems={removedItems}
                reducedLineItems={reducedLineItems}
                data={data}
            />
        )
    }

    return (
        <div>
            <ErrorMessageProduct removedItems={removedItems} />
            <EmptyCart />
        </div>
    )
}

Cart.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
    links: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoading: PropTypes.bool,
}

Cart.defaultProps = {
    isLoading: false,
}

export default Cart

import React from 'react'

const ColorSchema = () => {
    return (
        <div>
            <p className='text-center text-3xl my-6'>Color schema</p>
            <div className='grid grid-cols-3 gap-4'>
                <div>
                    <div className='w-40 h-40 mb-4 text-center p-3 pt-20 bg-white shadow-2xl'>White / Primary</div>
                    <div className='w-40 h-40 mb-4 p-3 pt-20 text-white text-center bg-redsport-60'>red-60 / Primary
                    </div>
                    <div className='w-40 h-40 mb-4 p-3 pt-20 text-white text-center bg-blacksport-100'>black-100 /
                        Primary
                    </div>
                </div>
                <div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-redsport-100'>red-100</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-redsport-90'>red-90</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-redsport-80'>red-80</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-redsport-70'>red-70</div>
                    <div className='w-40 h-64 p-3 pt-8 text-center bg-redsport-60'>red-60</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-redsport-50'>red-50</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-redsport-40'>red-40</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-redsport-30'>red-30</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-redsport-20'>red-20</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-redsport-10'>red-10</div>
                </div>
                <div>
                    <div className='w-40 h-64 p-3 pt-8 text-center text-white bg-blacksport-100'>black-100</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center text-white bg-blacksport-90'>black-90</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center text-white bg-blacksport-80'>black-80</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center text-white bg-blacksport-70'>black-70</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center text-white bg-blacksport-60'>black-60</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center text-white bg-blacksport-50'>black-50</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center text-white bg-blacksport-40'>black-40</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-blacksport-30'>black-30</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-blacksport-20'>black-20</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-blacksport-10'>black-10</div>
                </div>
            </div>
            <p className='text-center text-3xl my-6'>Absolute Sports Colors</p>
            <div className='grid grid-cols-3 gap-4'>
                <div>
                    <div className='py-5 w-40 mb-4 text-center bg-teamsport-60'>
                        TEAMSPORT<br />
                        teamsport-60 / Primary
                    </div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-teamsport-100'>teamsport-100</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-teamsport-90'>teamsport-90</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-teamsport-80'>teamsport-80</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-teamsport-70'>teamsport-70</div>
                    <div className='w-40 h-64 p-3 pt-8 text-center bg-teamsport-60'>teamsport-60</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-teamsport-50'>teamsport-50</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-teamsport-40'>teamsport-40</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-teamsport-30'>teamsport-30</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-teamsport-20'>teamsport-20</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-teamsport-10'>teamsport-10</div>
                </div>
                <div>
                    <div className='py-5 w-40 mb-4 text-center bg-runsport-60'>
                        RUN<br />
                        run-60 / Primary
                    </div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-runsport-100'>runsport-100</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-runsport-90'>runsport-90</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-runsport-80'>runsport-80</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-runsport-70'>runsport-70</div>
                    <div className='w-40 h-64 p-3 pt-8 text-center bg-runsport-60'>runsport-60</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-runsport-50'>runsport-50</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-runsport-40'>runsport-40</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-runsport-30'>runsport-30</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-runsport-20'>runsport-20</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-runsport-10'>runsport-10</div>
                </div>
                <div>
                    <div className='py-5 w-40 mb-4 text-center bg-fitness-60'>
                        Fitness<br />
                        fitness-60 / Primary
                    </div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-fitness-100'>fitness-100</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-fitness-90'>fitness-90</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-fitness-80'>fitness-80</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-fitness-70'>fitness-70</div>
                    <div className='w-40 h-64 p-3 pt-8 text-center bg-fitness-60'>fitness-60</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-fitness-50'>fitness-50</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-fitness-40'>fitness-40</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-fitness-30'>fitness-30</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-fitness-20'>fitness-20</div>
                    <div className='w-40 h-16 p-3 pt-8 text-center bg-fitness-10'>fitness-10</div>
                </div>
            </div>
        </div>
    )
}
export default ColorSchema

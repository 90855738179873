import app from '@frontastic/catwalk/src/js/app/app'

export const setIsRequireTopicChecked = (isChecked) => {
    const store = app.getStore()
    store.dispatch({
        type: 'ACTION_CHECK_REQUIRE_TOPIC',
        payload: isChecked,
    })
}

export const setIsSubscribeChecked = (isChecked) => {
    const store = app.getStore()
    store.dispatch({
        type: 'ACTION_TOGGLE_SUBSCRIBE_CHECKBOX',
        payload: isChecked,
    })
}

export const setTopics = (topics) => {
    const store = app.getStore()
    store.dispatch({
        type: 'ACTION_UPDATE_TOPICS',
        payload: topics,
    })
}

export const setInvalidTopic = (invalidTopic) => {
    const store = app.getStore()
    store.dispatch({
        type: 'ACTION_UPDATE_INVALID_TOPIC',
        payload: invalidTopic,
    })
}

import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'

import { convertToCountryNameByCode, convertToStateName } from '../countries'
import Button from '../../../atoms/button/Button'

const Shipping = ({ intl, address, onClick }) => (
    <>
        <div className={'shipping-content'}>
            <div className={'mb-3 font-bold headline-checkout'}>
                <FormattedMessage id={'checkout.shippingInformation'} />
            </div>
            {(address.firstName || address.lastName) && (
                <div className={'mb-0'}>
                    {address.firstName}
                    {' '}
                    {address.lastName}
                </div>
            )}

            {(address.streetName || address.streetNumber) && (
                <div className={'mb-0'}>
                    {address.streetName}
                    {' '}
                    {address.streetNumber}
                </div>
            )}

            {(address.postalCode || address.city) && (
                <div className={'mb-0'}>
                    {address.postalCode}
                    {' '}
                    {address.city}
                </div>
            )}

            {address.additionalAddressInfo && (
                <div className={'mb-0'}>{address.additionalAddressInfo}</div>
            )}

            {address.country && (
                <div className={'mb-0'}>{convertToCountryNameByCode(intl, address.country)}</div>
            )}

            {address.stateOrProvince && (
                <div className={'mb-0'}>{convertToStateName(intl, address.country, address.stateOrProvince)}</div>
            )}
        </div>

        <div className={'mt-5'}>
            <Button
                type={'button'}
                ariaLabel={intl.formatMessage({ id: 'account.edit' })}
                className={'font-sans edit-address cursor-pointer capitalize lg:justify-end underline font-normal tracking-normal p-0 text-base'}
                onClick={onClick}
            >
                <FormattedMessage id={'account.edit'} />
            </Button>
        </div>
    </>
)

Shipping.propTypes = {
    intl: PropTypes.object.isRequired,
    address: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
}

export default injectIntl(Shipping)

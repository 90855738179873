import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormattedMessage, injectIntl } from 'react-intl'
import FacetService from '../../../../services/facet'
import { ArrowDownIcon, CheckIcon } from '../../../atoms/icon'
import useComponentVisible from '../../../../utils/hooks/UseComponentVisible'
import IconButton from '../../../atoms/button/IconButton'

const TermFacet = ({
    intl, facet, onChange, numberFacets,
}) => {
    const [, updateState] = React.useState()
    const forceUpdate = React.useCallback(() => updateState({}), [])
    const initFacet = { ...facet }
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
    const isColor = FacetService.isColorFacet(facet)
    const isSize = FacetService.isSizeFacet(facet)

    const handleClick = (e) => {
        e.stopPropagation()
        e.preventDefault()
        setIsComponentVisible(!isComponentVisible)
    }

    const onTermClicked = (term) => {
        term.selected = !term.selected

        initFacet.selected = initFacet.terms.some((term) => term.selected === true)
        if (onChange) {
            onChange(initFacet)
        }

        forceUpdate()
    }

    const formatFacetName = (facetName) =>
        // if (isSize) {
        //     return facetName.replace('EU', '').trim()
        // }
        facetName

    return (
        <div
            className={classnames('product-filter-item', {
                'product-filter-item--color': isColor,
                opened: isComponentVisible,
            })}
            ref={ref}
        >
            <IconButton
                className={'btn btn-text icon--right justify-between font-bold'}
                type={'button'}
                onClick={handleClick}
                icon={<ArrowDownIcon width={16} height={16} />}
                ariaLabel={intl.formatMessage({ id: `filters.${facet.handle}` })}
            >
                <FormattedMessage id={`filters.${facet.handle}`} />
            </IconButton>

            <div className={'product-filter-item--content'}>
                {facet && facet.terms.map((term, index) => {
                    if (numberFacets && (index + 1) > numberFacets) {
                        return null
                    }

                    return (
                        <div
                            className={classnames('filter-item', {
                                active: term.selected,
                            })}
                            key={term.name}
                            onClick={() => {
                                onTermClicked(term)
                            }}
                        >
                            <label>
                                {isColor ? (
                                    <div
                                        className={classnames('filter-item--color-box', {
                                            'color-white': term.value === 'White',
                                        })}
                                        style={FacetService.getColorStyle(term.value)}
                                    />
                                ) : <CheckIcon width={14} height={14} />}
                                {formatFacetName(term.name)}
                            </label>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

TermFacet.propTypes = {
    intl: PropTypes.object.isRequired,
    facet: PropTypes.objectOf(PropTypes.any).isRequired,
    onChange: PropTypes.func.isRequired,
    numberFacets: PropTypes.number,
}

TermFacet.defaultProps = {
    numberFacets: 0,
}

export default injectIntl(TermFacet)

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'

import Select from 'react-select'
import { patternValueWithoutBlank } from '../../../../services/validate'
import ErrorMessage from '../../../atoms/errorMessage'
import { convertToCountryNameByCode } from '../countries'

const Shipping = ({
    intl, countries, defaultValues = {}, onSubmit,
}) => {
    const requiredField = intl.formatMessage({ id: 'validation.required' })
    const validPhone = intl.formatMessage({ id: 'validation.phone' })
    const validPostalCode = intl.formatMessage({ id: 'validation.postalCode' })
    const [selectedCountry, setSelectedCountry] = useState('DE')

    const {
        register,
        getValues,

        formState: {
            errors,
        },
    } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues || {},
    })

    const options = countries.map((value) => ({
        value: value.code,
        label: convertToCountryNameByCode(intl, value.code),
    }))

    const getSelectedCountry = () => {
        if (!_.isEmpty(defaultValues)) {
            let selectedValue = {}
            options.map((item) => {
                if (item.value === defaultValues.country) {
                    selectedValue = item
                }
            })

            if (_.isEmpty(selectedValue)) {
                return options[0]
            }

            return selectedValue
        }

        return options[0]
    }

    const handleChange = (value) => {
        setSelectedCountry(value.value)
        onSubmit(getValues(), value.value)
    }

    const onChange = () => {
        onSubmit(getValues(), selectedCountry)
    }

    const [symbolsArr] = useState(['e', 'E', '+', '-', '.'])

    const validPhoneNumber = (e) => {
        if (_.isEmpty(e.target.value)) {
            return
        }

        const isValid = e.target.value.toString().match(/^[\d ()+-]+$/)

        if (_.isEmpty(isValid)) {
            e.target.value = e.target.value.replace(/^[\d ()+-]+$/, '')
        }
    }

    return (
        <form onChange={onChange}>
            <h4 className={'shipping-title'}>
                <FormattedMessage id={'checkout.shippingAddress'} />
            </h4>

            <div className={'lg:grid-cols-2 lg:gap-6 lg:grid'}>
                <div className={classnames('mb-8 input--wrapper', {
                    'is--invalid': errors?.firstName,
                })}
                >
                    <input
                        id={'shipping-address-id'}
                        {...register('addressId', {
                            pattern: {
                                value: patternValueWithoutBlank,
                                message: requiredField,
                            },
                        })}
                        type={'hidden'}
                    />
                    <input
                        id={'shipping-firstName'}
                        {...register('firstName', {
                            required: requiredField,
                            pattern: {
                                value: patternValueWithoutBlank,
                                message: requiredField,
                            },
                        })}
                        type={'text'}
                        placeholder={`${intl.formatMessage({ id: 'checkout.form.firstName' })}*`} />
                    <label htmlFor={'shipping-firstName'}>
                        <FormattedMessage id={'checkout.form.firstName'} />
                    </label>
                    <span className={'input--border'} />
                    {errors?.firstName
                        && (
                            <span className={'input--hint'}>
                                <ErrorMessage errors={errors} name={'firstName'} />
                            </span>
                        )}
                </div>

                <div className={classnames('mb-8 input--wrapper', {
                    'is--invalid': errors?.lastName,
                })}
                >
                    <input
                        id={'shipping-lastName'}
                        {...register('lastName', {
                            required: requiredField,
                            pattern: {
                                value: patternValueWithoutBlank,
                                message: requiredField,
                            },
                        })}
                        type={'text'}
                        placeholder={`${intl.formatMessage({ id: 'checkout.form.lastName' })}*`} />
                    <label htmlFor={'shipping-firstName'}>
                        <FormattedMessage id={'checkout.form.lastName'} />
                    </label>
                    <span className={'input--border'} />
                    {errors?.lastName
                        && (
                            <span className={'input--hint'}>
                                <ErrorMessage errors={errors} name={'lastName'} />
                            </span>
                        )}
                </div>
            </div>

            <div className={classnames('mb-8 input--wrapper', {
                'is--invalid': errors?.phone,
            })}
            >
                <input
                    id={'shipping-phone'}
                    {...register('phone', {
                        pattern: {
                            value: /^[\d ()+-]+$/,
                            message: validPhone,
                        },
                    })}
                    type={'tel'}
                    placeholder={`${intl.formatMessage({ id: 'checkout.form.phone' })}`}
                    onChange={validPhoneNumber} />
                <label htmlFor={'shipping-phone'}>
                    <FormattedMessage id={'checkout.form.phone'} />
                </label>
                <span className={'input--border'} />
                {errors?.phone
                    && (
                        <span className={'input--hint'}>
                            <ErrorMessage errors={errors} name={'phone'} />
                        </span>
                    )}
            </div>

            <div className={'lg:grid-cols-2 lg:gap-6 lg:grid'}>
                <div className={classnames('mb-8 input--wrapper', {
                    'is--invalid': errors?.streetName,
                })}
                >
                    <input
                        id={'shipping-streetName'}
                        {...register('streetName', {
                            required: requiredField,
                            pattern: {
                                value: patternValueWithoutBlank,
                                message: requiredField,
                            },
                        })}
                        type={'text'}
                        placeholder={`${intl.formatMessage({ id: 'checkout.form.address' })}*`} />
                    <label htmlFor={'shipping-streetName'}>
                        <FormattedMessage id={'checkout.form.address'} />
                    </label>
                    <span className={'input--border'} />
                    {errors?.streetName
                        && (
                            <span className={'input--hint'}>
                                <ErrorMessage errors={errors} name={'streetName'} />
                            </span>
                        )}
                </div>
                <div className={classnames('mb-8 input--wrapper', {
                    'is--invalid': errors?.streetNumber,
                })}
                >
                    <input
                        id={'shipping-streetNumber'}
                        {...register('streetNumber', {
                            required: requiredField,
                            pattern: {
                                value: patternValueWithoutBlank,
                                message: requiredField,
                            },
                        })}
                        type={'text'}
                        placeholder={`${intl.formatMessage({ id: 'checkout.form.streetNumber' })}*`} />
                    <label htmlFor={'shipping-streetNumber'}>
                        <FormattedMessage id={'checkout.form.streetNumber'} />
                    </label>
                    <span className={'input--border'} />
                    {errors?.streetNumber
                        && (
                            <span className={'input--hint'}>
                                <ErrorMessage errors={errors} name={'streetNumber'} />
                            </span>
                        )}
                </div>
            </div>

            <div className={'lg:grid-cols-2 lg:gap-6 lg:grid'}>
                <div className={classnames('mb-8 input--wrapper', {
                    'is--invalid': errors?.postalCode,
                })}
                >
                    <input
                        id={'shipping-postalCode'}
                        {...register('postalCode', {
                            required: requiredField,
                            pattern: {
                                value: /^[0-9]{5}$/,
                                message: validPostalCode,
                            },
                        })}
                        type={'number'}
                        placeholder={`${intl.formatMessage({ id: 'checkout.form.zipCode' })}*`}
                        onKeyDown={(event) => symbolsArr.includes(event.key) && event.preventDefault()} />
                    <label htmlFor={'shipping-postalCode'}>
                        <FormattedMessage id={'checkout.form.zipCode'} />
                    </label>
                    <span className={'input--border'} />
                    {errors?.postalCode
                        && (
                            <span className={'input--hint'}>
                                <ErrorMessage errors={errors} name={'postalCode'} />
                            </span>
                        )}
                </div>

                <div className={classnames('mb-8 input--wrapper', {
                    'is--invalid': errors?.city,
                })}
                >
                    <input
                        id={'shipping-city'}
                        {...register('city', {
                            required: requiredField,
                            pattern: {
                                value: patternValueWithoutBlank,
                                message: requiredField,
                            },
                        })}
                        type={'text'}
                        placeholder={`${intl.formatMessage({ id: 'checkout.form.city' })}*`} />
                    <label htmlFor={'shipping-firstName'}>
                        <FormattedMessage id={'checkout.form.city'} />
                    </label>
                    <span className={'input--border'} />
                    {errors?.city
                        && (
                            <span className={'input--hint'}>
                                <ErrorMessage errors={errors} name={'city'} />
                            </span>
                        )}
                </div>
            </div>

            <div className={classnames('mb-8 input--wrapper')}>
                <input
                    id={'billing-companyName'}
                    {...register('additionalAddressInfo', {
                        pattern: {
                            value: patternValueWithoutBlank,
                            message: requiredField,
                        },
                    })}
                    type={'text'}
                    placeholder={`${intl.formatMessage({ id: 'checkout.form.companyName' })}`} />
                <label htmlFor={'billing-companyName'}>
                    <FormattedMessage id={'checkout.form.companyName'} />
                </label>
                <span className={'input--border'} />
            </div>

            <div className={classnames('mb-8 select--wrapper', {
                'is--invalid': errors?.country,
            })}
            >
                <Select
                    defaultValue={getSelectedCountry}
                    placeholder={'Country'}
                    options={options}
                    className={'select-container'}
                    classNamePrefix={'select'}
                    onChange={(value) => handleChange(value)}
                />
                <span className={'input--border'} />
                {errors?.country
                    && (
                        <span className={'input--hint'}>

                            <ErrorMessage errors={errors} name={'country'} />
                        </span>
                    )}
            </div>
        </form>
    )
}

Shipping.propTypes = {
    intl: PropTypes.object.isRequired,
    countries: PropTypes.array.isRequired,
    defaultValues: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

export default Shipping

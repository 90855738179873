import _ from 'lodash'

class SortSize {
    sortByProductType(variants, projectSchema) {
        const clothSizeList = projectSchema.get('clothSizeList').split(',')
        const clothSizeProductType = projectSchema.get('clothSortSize').split(',')
        const slashSizeProductType = projectSchema.get('slashSortSize').split(',')
        const numericSizeProductType = projectSchema.get('numericSortSize').split(',')

        const sortedVariants = []
        variants.map((variant) => {
            const { attr_size: sizeAttribute, product_type: productType } = variant.attributes || {}
            variant.attributes.sort_size = sizeAttribute
            variant.attributes.sort_size_2 = null
            const clothSize = clothSizeList.indexOf(sizeAttribute)
            if (clothSizeProductType.indexOf(productType) >= 0 || clothSize >= 0) {
                variant.attributes.sort_size = clothSize
                return variant
            }

            // const isNumber = /^\d*([.,]\d+)?(\s[a-zA-Z]*)?$/.test(variant.attributes.sort_size)
            if (numericSizeProductType.indexOf(productType) >= 0) {
                variant.attributes.sort_size = parseFloat(variant.attributes.sort_size.replace(/,/g, '.'))
                return variant
            }

            const slashSize = sizeAttribute.indexOf('/')
            if (slashSizeProductType.indexOf(productType) >= 0 || slashSize >= 0) {
                const parseSize = sizeAttribute.split('/')
                if (parseSize.length > 1) {
                    variant.attributes.sort_size = parseInt(parseSize[0], 10)
                    variant.attributes.sort_size_2 = parseInt(parseSize[1], 10)
                    return variant
                }
            }
            return variant
        })

        // when using orderBy the index will replace to 0, 1, 2 and do not match with variants
        const tempOrderArray = _.orderBy(
            _.cloneDeep(variants),
            ['attributes.sort_size', 'attributes.sort_size_2'],
            ['asc', 'asc']
        )
        tempOrderArray.forEach((item) => {
            if (typeof (item) !== 'undefined') {
                // Get index of variant which matching id
                // Set correct index
                item.currentIndex = variants.map((variant) => variant.id).indexOf(item.id)
                sortedVariants.push(item)
            }
        })

        return sortedVariants
    }
}

export default new SortSize()

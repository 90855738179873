import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useInView } from 'react-intersection-observer'
import { LoadingIcon } from '../../../atoms/icon'
import Image from '../../../atoms/image'
import FullPageWidthWrapper from '../../../molecules/Layout/FullPageWidthWrapper'
import Button from '../../../atoms/button/Button'

const BlogCategoryITem = ({
    blogArticles, intl, handleLoadMore, isLoading, isFinish, loadMoreHref, amount,
}) => {
    if (!blogArticles || !blogArticles.length) {
        return null
    }

    const count = amount
    const { inView } = useInView({
        threshold: [0.25, 0.5, 0.75],
    })
    useEffect(() => {
        if (inView) {
            handleLoadMore()
        }
    }, [inView])

    return (
        <FullPageWidthWrapper>
            <div className={'o-wrapper'}>
                <div className={'blog-category-wrapper'}>
                    {blogArticles.map((article) => {
                        const myString = article.article_text.text.replace(/\\n|\\r\\n|\\r|\\n\\n/g, '<br />')

                        return (
                            <div className={'blog-category-wrapper-item'} key={article.id}>
                                <a href={article.url} title={article.headline}>
                                    <div className={'blog-category-item'}>
                                        <div className={'blog-category-item-image'}>
                                            {article.image ? (
                                                <>
                                                    <Image
                                                        media={article.image.url}
                                                        alt={article.slug}
                                                        title={article.slug}
                                                        className={'blog-category-item-image-img'}
                                                        height={500}
                                                        width={750}
                                                        disableImageCropping
                                                    />
                                                    <noscript>
                                                        <img
                                                            src={article.image.url}
                                                            alt={article.slug}
                                                            title={article.slug}
                                                            className={'blog-category-item-image-img'}
                                                            height={400}
                                                        />
                                                    </noscript>
                                                </>
                                            ) : (
                                                <div
                                                    className={'blog-category-item-image-placeholder blog-category-item-image-img'}
                                                />
                                            )}
                                        </div>

                                        {article.headline && (
                                            <h4 className={'blog-category-item-headline'}>
                                                {article.headline}
                                            </h4>
                                        )}

                                        {article.article_text.text && (
                                            <div className={'blog-category-item-description'}>
                                                {parse(myString)}
                                            </div>
                                        )}

                                        <div className={'blog-category-item-link'}>
                                            <FormattedMessage id={'blog.showDetail'} />
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )
                    })}
                </div>
                <div className={'blog-category-load-more'}>
                    {!isFinish && (
                        <Button
                            type={'button'}
                            onClick={() => {
                                if (isLoading) {
                                    return
                                }
                                handleLoadMore()
                            }}
                            className={'btn btn-default btn-load-more'}
                            ariaLabel={intl.formatMessage({ id: 'filters.loadMore.articles' })}
                        >
                            <span className={isLoading ? '' : 'hidden'}>
                                <LoadingIcon width={30} height={30} />
                            </span>
                            {!isLoading && <FormattedMessage id={'filters.loadMore.articles'} values={{ num: count }} />}
                        </Button>
                    )}
                </div>
            </div>
        </FullPageWidthWrapper>
    )
}

BlogCategoryITem.propTypes = {
    intl: PropTypes.object.isRequired,
    blogArticles: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleLoadMore: PropTypes.func.isRequired,
    amount: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isFinish: PropTypes.bool.isRequired,
    loadMoreHref: PropTypes.func.isRequired,
}

export default injectIntl(BlogCategoryITem)

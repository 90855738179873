import {ImageProps} from 'next/image'
import {MediaTypes} from '../../../../types/images'

type NextImageProps = ImageProps & {
    cloudName?: string
    mediaType?: MediaTypes
    resolution?: number
    rootDomain?: string
    quality?: string | number
}

const CLOUDINARY_TRANSFORMATIONS = [
    'f_auto',
    'c_pad',
    'q_auto',
    'q_auto:best',
    't_sde',
]

const normalizeSrc = (src: string): string => (src[0] === '/' ? src.slice(1) : src)

const removeExistedTransformationAndFolderName = (src: string): string => {
    const splitedSrc: string[] = src.split('/')

    const srcRemovedWidthHeight: string[] = splitedSrc.filter((item) => !/(h_\d+|w_\d+)/g.test(item)).map((item) => item.replace(/,{2,}/g, ',').replace(/(^,)|(,$)/g, ''));

    const transformations = srcRemovedWidthHeight.map((item) => item.split(',').filter((subItem) => !CLOUDINARY_TRANSFORMATIONS.includes(subItem)).join(','))
    return transformations.join('/')
}

const ProductSchuheLoader = ({
                                 src, width, height, mediaType, resolution = 1, rootDomain, quality,
                             }: NextImageProps): string => {
    const customWidthByScreen = Math.round(+width * resolution)
    const customHeightByScreen = Math.round(+height * resolution)
    const qualityParam = quality ? `q_${quality}` : 'q_auto:best'
    const isFrontasticImage = mediaType === 'FRONTASTIC'
    const isProductImage = mediaType === 'PRODUCT'
    const orginalSource = isProductImage ? removeExistedTransformationAndFolderName(src as string) : src

    const generateParamsForProduct = () => [
        ...(customWidthByScreen ? [`w_${customWidthByScreen}`] : []),
        ...(customHeightByScreen ? [`h_${customHeightByScreen}`] : []),
    ]

    const getParams = () => {
        if (isProductImage) {
            return generateParamsForProduct()
        }

        return []
    }

    const transformations = getParams().length ? `${getParams().join(',')}` : ''

    // image source with the query paremeters removed
    const source = isFrontasticImage ? `${(orginalSource as string).split('?')[0]}` : `${orginalSource as string}`

    /*
      * If the image source already has transformations, append the new transformations
      * to the existing transformations
      * Image url example: https://res.cloudinary.com/schuhede-images/image/upload/f_auto,c_pad,q_auto:best,w_520,h_520/w_520,h_520/v1693494121/schuhede/1433528/1433528-04.png
    */

    if (isProductImage) {
        return `${rootDomain}/f_auto/q_${quality || 'auto:best'}/${transformations}/${normalizeSrc(source)}`
    }

    return `${rootDomain}/${transformations}/${normalizeSrc(source)}`
}

export default ProductSchuheLoader

import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

import { ReactComponent as User } from '../../../../../../icons/sport2000-icons/group.svg'
import { ReactComponent as Star } from '../../../../../../icons/sport2000-icons/rating_star.svg'
import { ReactComponent as StarFilled } from '../../../../../../icons/sport2000-icons/rating_star_filled.svg'
import { ReactComponent as StarFilledHalf } from '../../../../../../icons/sport2000-icons/rating_star_half.svg'

const RatingTab = ({
    rating, viewer, storeName, storeCity,
}) => {
    if (!rating || !viewer) {
        return null
    }

    const TOTAL_STAR = 5

    const calculateStar = () => {
        const fullStars = Math.floor(rating)
        const starArray = []

        for (let i = 1; i <= fullStars; i++) {
            starArray.push(1)
        }

        if (rating < TOTAL_STAR) {
            // calculate half
            const halfStar = rating - fullStars
            starArray.push(halfStar)

            // calculate empty start
            const emptyStars = TOTAL_STAR - starArray.length
            for (let i = 1; i <= emptyStars; i++) {
                starArray.push(0)
            }
        }

        return starArray
    }

    return (
        <>
            <h2 className={'store-detail-brands-headline mb-7 lg:mb-8'}>
                <FormattedMessage id={'storeDetail.ratingHeadline'} />
                {' '}
                {storeName}
                {' '}
                <FormattedMessage id={'storeDetail.in'} />
                {storeCity}
            </h2>
            <div className={'flex bg-blacksport-10 py-10'}>
                <div className={'flex-1 text-center'}>
                    <div className={'m-auto flex w-full justify-center'}>
                        <>
                            {calculateStar().map((val, i) => (
                                val === 1 ? (
                                    <StarFilled width={30} height={30} className={'mr-2'} key={val + i} />
                                ) : val === 0 ? (
                                    <Star width={30} height={30} className={'mr-2'} key={val + i} />
                                ) : (
                                    <StarFilledHalf width={30} height={30} className={'mr-2'} key={val + i} />
                                )
                            ))}
                        </>
                    </div>
                    <div className={'font-bold mt-5'}>
                        <div>
                            {rating}
                            {' '}
                            <FormattedMessage id={'storeDetail.ratingValue'} />
                        </div>
                        <FormattedMessage id={'storeDetail.ratingDescription'} />
                    </div>
                </div>
                <div className={'flex-1 text-center'}>
                    <User width={40} height={40} className={'m-auto'} />
                    <div className={'font-bold mt-5'}>
                        {viewer}
                        {' '}
                        <FormattedMessage id={'storeDetail.viewerDescription'} />
                    </div>
                </div>
            </div>
            <div className={'font-bold mt-4'}>
                <FormattedMessage id={'storeDetail.summaryRating'} />

            </div>
        </>
    )
}

RatingTab.propTypes = {
    rating: PropTypes.number.isRequired,
    viewer: PropTypes.number.isRequired,
    storeName: PropTypes.string.isRequired,
    storeCity: PropTypes.string.isRequired,
}

export default RatingTab

import React from 'react'
import PropTypes from 'prop-types'
import Slider from '@ant-design/react-slick'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import MediaImage from '@frontastic/catwalk/src/js/mediaImage'
import classnames from 'classnames'
import Markdown from '@frontastic/catwalk/src/js/component/markdown'
import Button from '../../../atoms/button/Button'

const EventSlider = ({
    headline,
    buttonLabelViewAll,
    referenceButtonViewAll,
    btnVariantViewAll,
    eventList,
    swiperColor,
    isDarkMode,
}) => {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        rows: 1,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                    arrows: false,
                    swipeToSlide: true,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: true,
                    swipeToSlide: true,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: true,
                    swipeToSlide: true,
                },
            },
        ],
    }

    return (
        <section className={classnames('width--fullsize py-comp', {
            [`${swiperColor}`]: swiperColor,
            'bg-blacksport-100 text-white dark-mode': isDarkMode,
        })}
        >
            <div className={'o-wrapper event-teaser-slider'}>
                <div className={'flex justify-between items-center group-header'}>
                    {headline && (
                        <h2 className={'headline'}>{headline}</h2>
                    )}
                    {referenceButtonViewAll && (
                        <Button
                            type={'button'}
                            className={classnames('btn', {
                                'btn-default': btnVariantViewAll === 'default',
                                'btn-cta': btnVariantViewAll === 'cta',
                                'btn-secondary': btnVariantViewAll === 'secondary',
                                'dark-mode': isDarkMode,
                            })}
                            reference={referenceButtonViewAll}
                            ariaLabel={buttonLabelViewAll}
                        >
                            {buttonLabelViewAll}
                        </Button>
                    )}
                </div>
                <Slider {...settings}>
                    {eventList.map((item) => (
                        <div key={item.titleEvent} className={'event-item'}>
                            <Markdown
                                className={'event-item__date'}
                                text={item.dateEvent}
                            />
                            <MediaImage
                                className={'event-item__img'}
                                media={item.image}
                                draggable={false}
                            />

                            <div className={'event-item__info'}>
                                <h4 className={'event-item__title'}>{item.titleEvent}</h4>
                                <div className={'event-item__links'}>
                                    <div className={'text-sm grid grid-cols-2 gap-2'}>
                                        {item.referenceJoinEvent && (
                                            <Button
                                                type={'button'}
                                                className={'btn btn-default dark-mode'}
                                                reference={item.referenceJoinEvent}
                                                ariaLabel={item.textReadMore}
                                            >
                                                {item.textJoinEvent}
                                            </Button>
                                        )}

                                        {item.referenceReadMore && (
                                            <Button
                                                type={'button'}
                                                className={'btn btn-secondary btn-read-more dark-mode'}
                                                reference={item.referenceReadMore}
                                                ariaLabel={item.textReadMore}
                                            >
                                                {item.textReadMore}
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    )
}

EventSlider.propTypes = {
    headline: PropTypes.string.isRequired,
    buttonLabelViewAll: PropTypes.string.isRequired,
    referenceButtonViewAll: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    btnVariantViewAll: PropTypes.string.isRequired,
    swiperColor: PropTypes.string.isRequired,
    isDarkMode: PropTypes.bool,
    eventList: PropTypes.arrayOf(PropTypes.shape({
        dateEvent: PropTypes.string,
        image: PropTypes.object,
        titleEvent: PropTypes.string,
        textJoinEvent: PropTypes.string,
        referenceJoinEvent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        textReadMore: PropTypes.string,
        referenceReadMore: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    })).isRequired,
}

export default tastify()(EventSlider)

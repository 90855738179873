import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useForm } from 'react-hook-form'

import classnames from 'classnames'
import ButtonWithLoader from '../../../atoms/button/WithLoader'
import ErrorMessage from '../../../atoms/errorMessage'
import { patternValueWithoutBlank } from '../../../../services/validate'

const PersonalDetailsForm = ({
    intl, firstName, lastName, onSubmit, showLoader,
}) => {
    const requiredField = intl.formatMessage({ id: 'validation.required' })
    const [isDisabledSubmitButton, setIsDisabledSubmitButton] = useState(false)

    const {
        register,
        getValues,
        handleSubmit,
        errors,
        formState,
    } = useForm({
        mode: 'onChange',
        defaultValues: { firstName, lastName },
    })

    useEffect(() => {
        setIsDisabledSubmitButton(!formState?.isValid)
    }, [formState.isValid, setIsDisabledSubmitButton])

    const onNewDetailsSubmit = () => {
        const newValues = getValues()
        onSubmit(newValues)
    }

    return (
        <form
            onSubmit={handleSubmit(onNewDetailsSubmit)}
            className={'flex-col pt-6 m-4 md:p-0 md:mt-4'}
        >
            <div className={classnames('mb-8 input--wrapper', {
                'is--invalid': errors?.firstName,
            })}
            >
                <input
                    id={'firstName'}
                    {...register('firstName', {
                        required: requiredField,
                        pattern: {
                            value: patternValueWithoutBlank,
                            message: requiredField,
                        },
                    })}
                    type={'text'}
                    placeholder={`${intl.formatMessage({ id: 'checkout.form.firstName' })}*`} />
                <label htmlFor={'firstName'}>
                    <FormattedMessage id={'checkout.form.firstName'} />
                </label>
                <span className={'input--border'} />
                {errors?.firstName
                    && (
                        <span className={'input--hint'}>
                            <ErrorMessage errors={errors} name={'firstName'} />
                        </span>
                    )}
            </div>

            <div className={classnames('mb-8 input--wrapper', {
                'is--invalid': errors?.lastName,
            })}
            >
                <input
                    id={'lastName'}
                    {...register('lastName', {
                        required: requiredField,
                        pattern: {
                            value: patternValueWithoutBlank,
                            message: requiredField,
                        },
                    })}
                    type={'text'}
                    placeholder={`${intl.formatMessage({ id: 'checkout.form.lastName' })}*`} />
                <label htmlFor={'lastName'}>
                    <FormattedMessage id={'checkout.form.lastName'} />
                </label>
                <span className={'input--border'} />
                {errors?.lastName
                    && (
                        <span className={'input--hint'}>
                            <ErrorMessage errors={errors} name={'lastName'} />
                        </span>
                    )}
            </div>
            <div className={'text-center mt-8'}>
                <ButtonWithLoader
                    ariaLabel={intl.formatMessage({ id: 'account.save' })}
                    showLoader={showLoader}
                    type={'submit'}
                    disabled={isDisabledSubmitButton}
                    className={'btn btn-default account--save-button w-auto'}
                >
                    <FormattedMessage id={'account.save'} />
                </ButtonWithLoader>
            </div>
        </form>
    )
}

PersonalDetailsForm.propTypes = {
    intl: PropTypes.object.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    showLoader: PropTypes.bool.isRequired,
}

export default injectIntl(PersonalDetailsForm)

import React from 'react'
import ColorSchema from './ColorSchema'
import Typography from './Typography'
import Buttons from './Button'
import Screen from './Screen'

const StyleGuide = () => (
    <div className={'overflow-hidden'}>
        <Buttons />
        <ColorSchema />
        <Typography />
        <Screen />
    </div>
)

export default StyleGuide

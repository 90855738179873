import React, { useState } from 'react'
import PropTypes from 'prop-types'
import app from '@frontastic/catwalk/src/js/app/app'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import _ from 'lodash'
import { ConfigurationSchema } from 'frontastic-common'
import { Link } from 'react-router-dom'
import Message from '@frontastic/catwalk/src/js/app/message'
import MiniSummary from './MiniSummary'
import Summary from './Summary'
import HelpLinks from './HelpLinks'
import Payments from './Payments'
import SessionService from '../../../../services/session'
import ErrorMessageProduct from '../../ErrorMessageProduct'
import Product from './Product'
import { ReactComponent as LogoAbsoluteRun } from '../../../../../icons/sport2000-icons/logos/absoluterun.svg'
import { ReactComponent as LogoAbsoluteTeamsport } from '../../../../../icons/sport2000-icons/logos/absoluteteamsport.svg'
import { ReactComponent as LogoSports2000 } from '../../../../../icons/sport2000-icons/logos/Sport_2000_logo.svg'
import GtmService from '../../../../services/gtm'
import TagManager from '../../../../domain/TagManager'

const FullCart = ({
    intl,
    items,
    removedItems = [],
    reducedLineItems = [],
    sum = false,
    links,
    isLoading = false,
    data,
}) => {
    const loggedIn = useSelector((state) => state.app.context.session.loggedIn)
    const isDesktop = useDeviceType() === 'desktop'
    const orderFailed = useSelector((state) => state.app.context.session.projectSpecificData?.orderFailed)
    const [showNotification, setShowNotification] = useState(true)

    const handleGoogleTracking = () => {
        const gtmService = new GtmService(items, null, null, data)
        const productListGTM = gtmService.createProductListGTM('Cart')
        const beginCheckoutInfoGTM = gtmService.createBeginCheckoutInfoGTM()
        TagManager.beginCheckout(beginCheckoutInfoGTM, productListGTM)
        TagManager.hitViewdImpressions()
    }

    const checkLoggedIn = () => {
        handleGoogleTracking()
        if (loggedIn) {
            return app.getRouter().push('Frontastic.Frontend.Master.Checkout.checkout')
        }

        return app.getRouter().push('Frontastic.Frontend.Master.Account.index', { 'origin-cart': true })
    }

    const context = useSelector((state) => state.app.context)
    const { algoliaIndexName } = context?.projectConfiguration || {}

    const projectSchema = new ConfigurationSchema(
        context.projectConfigurationSchema,
        context.projectConfiguration,
    )

    const paymentLogos = projectSchema.get('paymentLogos')

    if (orderFailed && showNotification) {
        app.getLoader('context').notifyUser(<Message
            message={intl.formatMessage({ id: 'order.orderFailed' })}
        />, 'error', 20000)
        setShowNotification(false)
        SessionService.remove('orderFailed').then((data) => {

        })
    }

    // Group store in product by name
    const groupStoreId = _.groupBy(items, (item) => {
        const value = item.projectSpecificData.store.name || ''

        return value
    })

    const sorted = Object.keys(groupStoreId)
        .sort()
        .reduce((accumulator, key) => {
            accumulator[key] = groupStoreId[key]

            return accumulator
        }, {})

    const themeLogo = (value) => {
        const logo = value[0] || ''

        if (!logo) {
            return null
        }

        if (logo.variant.attributes.att_internal_category_absolute_run) {
            return (
                <>
                    <LogoAbsoluteRun width={isDesktop ? 140 : 120} height={25} />
                </>
            )
        }

        if (logo.variant.attributes.att_internal_category_absolute_teamsport) {
            return (
                <>
                    <LogoAbsoluteTeamsport width={isDesktop ? 200 : 170} height={24} />
                </>
            )
        }

        return (
            <>
                <LogoSports2000 width={isDesktop ? 140 : 112} height={30} />
            </>
        )
    }

    return (
        <div className={'cart-page'}>
            <MiniSummary
                sum={sum}
                onClick={checkLoggedIn}
                count={items.length}
            />
            <div className={'cart-page--wrapper'}>
                <div className={'cart-page--content-left'}>
                    <div className={'cart-page--content-error'}>
                        <ErrorMessageProduct
                            removedItems={removedItems}
                            reducedLineItems={reducedLineItems}
                        />
                    </div>

                    <div className={'cart-page-list-wrapper'}>
                        {Object.entries(sorted).map(([key, value]) => {
                            const url = value.filter((item) => item.projectSpecificData.store.name === key)[0]?.projectSpecificData.store.url
                            return (
                                <div className={'cart-page--list-item'}>
                                    <div
                                        className={'cart-page--list-item-header flex justify-between'}
                                    >
                                        <div className={'cart-page--list-item-headline'}>
                                            {key ? (
                                                <>
                                                    <FormattedMessage id={'cart.headlineStore'} />
                                                    <Link
                                                        to={url}
                                                        className={'cart-page--list-item-headline-link'}
                                                    >
                                                        {key}
                                                    </Link>
                                                </>
                                            )
                                                : (
                                                    <FormattedMessage id={'cart.headlineNoStore'} />
                                                )}
                                        </div>
                                        <div className={'cart-page--list-item-logo'}>
                                            {key && (
                                                themeLogo(value)
                                            )}
                                        </div>
                                    </div>

                                    {value.map((item) => (
                                        <>
                                            <Product
                                                key={item.variant.sku}
                                                product={item}
                                                groupStoreId={groupStoreId}
                                            />
                                        </>
                                    ))}
                                </div>
                            )
                        })}
                    </div>

                    {isDesktop && (
                        <div className={'cart-page--footer'}>
                            <Payments data={paymentLogos} />
                            <HelpLinks links={links} />
                        </div>
                    )}
                </div>

                <div className={'cart-page--content-right'}>
                    <Summary
                        onClick={checkLoggedIn}
                        isLoading={isLoading}
                        buttonLabel={intl.formatMessage({ id: 'cart.checkout' })}
                    />
                </div>

                {!isDesktop && (
                    <div className={'cart-page--footer mt-3 md:mt-6 flex-col md:flex-row'}>
                        <Payments data={paymentLogos} />
                        <HelpLinks links={links} />
                    </div>
                )}
            </div>
        </div>
    )
}

FullCart.propTypes = {
    intl: PropTypes.object.isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    removedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    reducedLineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    sum: PropTypes.number.isRequired,
    links: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoading: PropTypes.bool,
    data: PropTypes.any,
}

FullCart.defaultProps = {
    isLoading: false,
}

export default injectIntl(FullCart)

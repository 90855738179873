import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useForm } from 'react-hook-form'

import classnames from 'classnames'
import ButtonWithLoader from '../../../atoms/button/WithLoader'
import ErrorMessage from '../../../atoms/errorMessage'
import Button from '../../../atoms/button/Button'
import { ReactComponent as EyeOpen } from '../../../../../icons/sport2000-icons/eye-open.svg'
import { ReactComponent as EyeClosed } from '../../../../../icons/sport2000-icons/eye-closed.svg'

const ChangePasswordForm = ({ intl, onSubmit, showLoader }) => {
    const requiredField = intl.formatMessage({ id: 'validation.required' })
    const passwordMatch = intl.formatMessage({ id: 'validation.passwordMatch' })
    const validPasswordLength = intl.formatMessage({ id: 'validation.minLength' })
    const validPasswordPattern = intl.formatMessage({ id: 'validation.passwordPattern' })
    const [inputPasswordType, setInputPasswordType] = useState('password')
    const watchPassword = useRef({})
    const {
        register,
        getValues,
        watch,
        handleSubmit,

        formState: {
            errors,
        },
    } = useForm()

    watchPassword.current = watch('newPassword', '')

    const onNewPasswordSubmit = () => {
        const { oldPassword, newPassword } = getValues()
        onSubmit(oldPassword, newPassword)
    }

    const toggleInputPasswordType = () => {
        if (inputPasswordType === 'password') {
            return setInputPasswordType('text')
        }

        return setInputPasswordType('password')
    }

    return (
        <form onSubmit={handleSubmit(onNewPasswordSubmit)} className={'flex-col pt-4 md:p-0 md:mt-4 m-4'}>
            <div className={'text-sm text-black'}>
                <span>
                    <FormattedMessage id={'account.inOrder'} />
                </span>
                <span className={'font-bold'}>
                    {' '}
                    <FormattedMessage id={'account.protect'} />
                    {' '}
                </span>
                <span>
                    <FormattedMessage id={'account.passwordContain'} />
                </span>
                <ul className={'list-disc m-4'}>
                    <li>
                        <FormattedMessage id={'validation.minLength'} />
                    </li>
                    <li>
                        <FormattedMessage id={'validation.passwordPattern'} />
                    </li>
                </ul>
            </div>

            <div className={'mt-8 mb-4'}>
                <div className={classnames('mb-8 input--wrapper', {
                    'is--invalid': errors?.oldPassword,
                })}
                >
                    <input
                        id={'old-Password'}
                        {...register('oldPassword', { required: requiredField })}
                        type={inputPasswordType}
                        placeholder={`${intl.formatMessage({ id: 'account.login.password' })}*`} />
                    <label htmlFor={'oldPassword'}>
                        <FormattedMessage id={'account.login.password'} />
                    </label>
                    <Button
                        type={'button'}
                        className={'account--password-reveal absolute p-4'}
                        ariaLabel={intl.formatMessage({ id: 'account.login.revealPassword' })}
                        onClick={() => toggleInputPasswordType()}
                    >
                        {inputPasswordType === 'password'
                            ? <EyeOpen width={'16'} height={'16'} />
                            : <EyeClosed width={'16'} height={'16'} />}
                    </Button>
                    <span className={'input--border'} />
                    {errors?.oldPassword
                        && (
                            <span className={'input--hint'}>
                                <ErrorMessage errors={errors} name={'oldPassword'} />
                            </span>
                        )}
                </div>

                <div className={classnames('mb-8 input--wrapper', {
                    'is--invalid': errors?.newPassword,
                })}
                >
                    <input
                        id={'newPassword'}
                        {...register('newPassword', {
                            required: requiredField,
                            minLength: {
                                value: 8,
                                message: validPasswordLength,
                            },
                            pattern: {
                                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,}$/,
                                message: validPasswordPattern,
                            },
                        })}
                        type={inputPasswordType}
                        placeholder={`${intl.formatMessage({ id: 'account.login.password' })}*`} />
                    <label htmlFor={'newPassword'}>
                        <FormattedMessage id={'account.password.new'} />
                    </label>
                    <Button
                        type={'button'}
                        className={'account--password-reveal absolute p-4'}
                        ariaLabel={intl.formatMessage({ id: 'account.login.revealPassword' })}
                        onClick={() => toggleInputPasswordType()}
                    >
                        {inputPasswordType === 'password'
                            ? <EyeOpen width={'16'} height={'16'} />
                            : <EyeClosed width={'16'} height={'16'} />}
                    </Button>
                    <span className={'input--border'} />
                    {errors?.newPassword
                        && (
                            <span className={'input--hint'}>
                                <ErrorMessage errors={errors} name={'newPassword'} />
                            </span>
                        )}
                </div>

                <div className={classnames('mb-8 input--wrapper', {
                    'is--invalid': errors?.confirmNewPassword,
                })}
                >
                    <input
                        id={'confirmNewPassword'}
                        {...register('confirmNewPassword', {
                            validate: (value) => value === watchPassword.current || passwordMatch,
                        })}
                        type={inputPasswordType}
                        placeholder={`${intl.formatMessage({ id: 'account.password.confirmNew' })}*`} />
                    <label htmlFor={'confirmNewPassword'}>
                        <FormattedMessage id={'account.password.confirmNew'} />
                    </label>
                    <Button
                        type={'button'}
                        className={'account--password-reveal absolute p-4'}
                        ariaLabel={intl.formatMessage({ id: 'account.login.revealPassword' })}
                        onClick={() => toggleInputPasswordType()}
                    >
                        {inputPasswordType === 'password'
                            ? <EyeOpen width={'16'} height={'16'} />
                            : <EyeClosed width={'16'} height={'16'} />}
                    </Button>
                    <span className={'input--border'} />
                    {errors?.confirmNewPassword
                        && (
                            <span className={'input--hint'}>
                                <ErrorMessage errors={errors} name={'confirmNewPassword'} />
                            </span>
                        )}
                </div>

                <div className={'text-center mt-8'}>
                    <ButtonWithLoader
                        name={intl.formatMessage({ id: 'account.changePassword' })}
                        showLoader={showLoader}
                        type={'submit'}
                        className={'btn btn-default account--save-button w-auto'}
                    >
                        <FormattedMessage id={'account.changePassword'} />
                    </ButtonWithLoader>
                </div>
            </div>
        </form>
    )
}

ChangePasswordForm.propTypes = {
    intl: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    showLoader: PropTypes.bool.isRequired,
}

export default injectIntl(ChangePasswordForm)

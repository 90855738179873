import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { cloneDeep } from 'lodash'

import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import app from '@frontastic/catwalk/src/js/app/app'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'

import AccountMenu from '../../../patterns/molecules/Account/Menu'
import WelcomeOverview from '../../../patterns/organisms/Account/AccountOverview/welcomeOverview'
import MENU_ITEMS from '../../../patterns/molecules/Account/Menu/MENU_ITEMS'
import AccountOrders from '../../../patterns/organisms/Account/AccountOrder'

const DEFAULT_COUNTRY_CODE = 'DE'
const FLAG_DEFAULT_BILLING_ADDRESS = 'isDefaultBillingAddress'
const FLAG_DEFAULT_SHIPPING_ADDRESS = 'isDefaultShippingAddress'

const AccountOrdersTastic = ({ data: { stream }, context, route }) => {
    const {
        session: {
            loggedIn,
            account: { firstName, addresses },
        },
    } = context

    if (!loggedIn && route.route !== 'Frontastic.Frontend.Master.Account.index') {
        return app.getRouter().push('Frontastic.Frontend.Master.Account.index')
    }

    const isMobile = useDeviceType() === 'mobile'
    const [openPanel, setOpenPanel] = useState(!isMobile)

    const initAddress = (isDefaultAddress) => {
        if (!addresses || addresses.length <= 0) {
            return {}
        }

        const addressByDefault = addresses.find((address) => address[isDefaultAddress] && address.country === DEFAULT_COUNTRY_CODE)

        if (addressByDefault) {
            return cloneDeep(addressByDefault)
        }

        if (addresses[0].country === DEFAULT_COUNTRY_CODE) {
            return cloneDeep(addresses[0])
        }

        return {}
    }

    const defaultBillingAddress = initAddress(FLAG_DEFAULT_BILLING_ADDRESS)
    const defaultShippingAddress = initAddress(FLAG_DEFAULT_SHIPPING_ADDRESS)

    stream.forEach((item) => {
        // Set company name by default address
        item.billingAddress.additionalAddressInfo = defaultBillingAddress.additionalAddressInfo
        item.shippingAddress.additionalAddressInfo = defaultShippingAddress.additionalAddressInfo
    })

    return (
        <AccountMenu
            selectedMenuItem={MENU_ITEMS.ORDERS}
            welcome={<WelcomeOverview firstName={firstName} />}
            handleLogout={app.getLoader('context').logout}
            openPanel={() => {
                setOpenPanel(false)
            }}
            onClose={() => {
                setOpenPanel(true)
            }}
            isOpenPanel={openPanel}
        >
            <AccountOrders
                orders={stream}
                openPanel={openPanel}
                onClose={() => {
                    setOpenPanel(true)
                }}
            />
        </AccountMenu>
    )
}

AccountOrdersTastic.propTypes = {
    data: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
}

export default tastify({
    translate: true,
    connect: {
        context: true,
        route: true,
    },
})(AccountOrdersTastic)

import React from 'react'
import PropTypes from 'prop-types'
import MediaImage from '@frontastic/catwalk/src/js/mediaImage'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import classnames from 'classnames'

const Gallery = ({
    headlineColor, headline, galleryItem, isDarkMode,
}) => (
    <section className={classnames('width--fullsize py-comp', {
        'bg-blacksport-100 text-white': isDarkMode,
    })}
    >
        <div className={'o-wrapper'}>
            <div className={'o-grid o-region'}>
                <div className={'o-cell o-cell--12'}>
                    <div
                        className={'grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 lg:gap-8 team-gallery-component'}
                    >
                        <div className={'headline bg-blacksport-90 flex justify-center items-center'}>
                            <h2 className={'mb-0'}>
                                <span className={'text-redsport-60'}>{headlineColor}</span>
                                <br />
                                <span className={'text-white'}>{headline}</span>
                            </h2>
                        </div>

                        {galleryItem.map((item) => (
                            <div key={item.name} className={'gallery-item'}>
                                <MediaImage media={item.gallery} className={'gallery-img'} />
                                <div className={'font-bold mt-6'}>{item.name}</div>
                                <div className={'text-sm mt-2'}>
                                    <a title={`mailto:${item.email}`} href={`mailto:${item.email}`}>{item.email}</a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </section>
)

Gallery.propTypes = {
    headlineColor: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    isDarkMode: PropTypes.bool,
    galleryItem: PropTypes.arrayOf(PropTypes.shape({
        gallery: PropTypes.object,
        name: PropTypes.string,
        email: PropTypes.string,
    })).isRequired,
}

export default tastify()(Gallery)

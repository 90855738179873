import React from 'react'

const Typography = () => (
    <div>
        <hr/>
        <p className={'text-center text-3xl my-6 font-sport2000'}>Typo schema</p>
        <div className={'grid grid-cols-2 gap-4'}>
            <div className={'bg-white text-blacksport-100 p-10'}>
                <h1 className={'text-h1'}>Headline 1</h1>
                <div className={'text-subh1'}>Subtitle for h1</div>
                <br/>
                <h2 className={'text-h2'}>Headline 2</h2>
                <div className={'text-subh2'}>Subtitle for h2</div>
                <br/>
                <h3 className={'text-h3'}>Headline 3</h3>
                <div className={'text-subh3'}>Subtitle for h3</div>
                <br/>
                <h4 className={'text-h4'}>Headline 4</h4>
                <div className={'text-subh4'}>Subtitle for h4</div>
                <br/>
                <h5 className={'text-h5'}>Headline 5</h5>
                <div className={'text-subh5'}>Subtitle for h5</div>
                <br/>
            </div>
            <div className={'bg-black text-white p-10'}>
                <h1 className={'text-h1'}>Headline 1</h1>
                <div className={'text-subh1'}>Subtitle for h1</div>
                <br/>
                <h2 className={'text-h2'}>Headline 2</h2>
                <div className={'text-subh2'}>Subtitle for h2</div>
                <br/>
                <h3 className={'text-h3'}>Headline 3</h3>
                <div className={'text-subh3'}>Subtitle for h3</div>
                <br/>
                <h4 className={'text-h4'}>Headline 4</h4>
                <div className={'text-subh4'}>Subtitle for h4</div>
                <br/>
                <h5 className={'text-h5'}>Headline 5</h5>
                <div className={'text-subh5'}>Subtitle for h5</div>
                <br/>
            </div>
        </div>
        <hr/>
        <div className={'grid grid-cols-2 gap-8 p-10'}>
            <div className={'box'}>
                <h2 className={'text-headline text-h2'}>
                    EXAMPLE HEADLINE SPORT TWO THOUSAND
                </h2>
                <p className={'text-base'}>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                    ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
                    dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor
                    sit amet
                </p>
            </div>
            <div className={'box'}>
                <div className={'text-base text-redsport-60'}>Will be updated font face later</div>
                <h2 className={'text-headline text-h2'}>
                    EXAMPLE HEADLINE SPORT TWO THOUSAND
                </h2>
                <p className={'text-base'}>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                    ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
                    dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor
                    sit amet
                </p>
            </div>
        </div>
        <hr/>
        <div className={'grid grid-cols-3 gap-8 p-10'}>
            <div className={'box'}>
                <div className={'text-base'}>
                    Bodycopy 16px - 24 lineheight
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                    ut labore et dolore magna aliquyam era
                </div>
                <br/>
                <div className={'text-base font-bold'}>
                    Bodycopy 16px Bold - 24 lineheight
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                    ut labore et dolore magna aliquyam era
                </div>
            </div>
            <div className={'box'}>
                <div className={'text-sm'}>
                    Bodycopy 14px - 20 lineheight
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                    ut labore et dolore magna aliquyam era
                </div>
                <br/>
                <div className={'text-sm font-bold'}>
                    Bodycopy 14px Bold - 20 lineheight
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                    ut labore et dolore magna aliquyam era
                </div>
            </div>
            <div className={'box'}>
                <div className={'text-xs'}>
                    Bodycopy 12px - 18 lineheight
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                    ut labore et dolore magna aliquyam era
                </div>
                <br/>
                <div className={'text-xs font-bold'}>
                    Bodycopy 12px Bold - 18 lineheight
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                    ut labore et dolore magna aliquyam era
                </div>
            </div>
        </div>
        <hr/>
        <div className={'grid grid-cols-2 gap-4 p-10'}>
            <div className={'grid grid-cols-2 gap4'}>
                <div>
                    Bodycopy
                    {' '}
                    <a href={'/'} title={''} className={'btn-link'}>Textlink</a>
                    <br/>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.
                </div>
                <div>
                    Bodycopy
                    {' '}
                    <a href={'/'} title={''} className={'underline text-redsport-60'}>Textlink</a>
                    <br/>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.
                </div>
            </div>
            <div className={'grid grid-cols-3 gap4'}>
                <div>
                    <div>Default</div>
                    <br/>
                    <div>
                        <a
                            href={'/'}
                            title={''}
                            className={'btn-link'}
                        >
                            Stand alone Textlink
                        </a>
                    </div>
                    <br/>
                    <div>
                        <a
                            href={'/'}
                            title={''}
                            className={'btn-link uppercase'}
                        >
                            Stand alone Textlink
                        </a>
                    </div>
                </div>
                <div>
                    <div>Hover</div>
                    <br/>
                    <div>
                        <a href={'/'} title={''} className={'btn-link text-redsport-60'}>
                            Stand alone Textlink
                        </a>
                    </div>
                    <br/>
                    <div>
                        <a href={'/'} title={''} className={'btn-link uppercase text-redsport-60'}>
                            Stand alone Textlink
                        </a>
                    </div>
                </div>
                <div>
                    <div>Active</div>
                    <br/>
                    <div>
                        <a href={'/'} title={''} className={'btn-link text-redsport-80'}>
                            Stand alone Textlink
                        </a>
                    </div>
                    <br/>
                    <div>
                        <a href={'/'} title={''} className={'btn-link uppercase text-redsport-80'}>
                            Stand alone Textlink
                        </a>
                    </div>
                </div>
            </div>
            <div>
                <p>
                    <a
                        href={'/'}
                        title={''}
                        className={'o-link-hover--underline border-black'}
                    >
                        Textlink
                    </a>
                </p>
                <p>
                    <a
                        href={'/'}
                        title={''}
                        className={'o-link-hover--underline border-black uppercase'}
                    >
                        Textlink
                    </a>
                </p>
                <p>
                    <a href={'/'} title={''} className={'o-link--underline border-black'}>Textlink</a>
                </p>
                <p>
                    <a href={'/'} title={''} className={'o-link--underline border-black uppercase'}>Textlink</a>
                </p>
            </div>
        </div>
        <hr/>
    </div>
)
export default Typography

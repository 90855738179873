import React from 'react'
import IconButton from '../../atoms/button/IconButton'
import Button from '../../atoms/button/Button'
import {HeartIcon} from '../../atoms/icon'

const Buttons = () => (
    <>
        <div className={'md:px-20'}>
            <p className={'text-3xl my-6'}>Buttons</p>
            <div className={'grid grid-cols-3 gap-4'}>
                <div>
                    <p className={'text-sm mb-6'}>Default</p>
                    <div>
                        <div className={'float-left mr-8 mb-8 clear-both'}>
                            <Button className={'btn btn-default'}>
                                Button
                            </Button>
                        </div>
                        <div className={'float-left mr-8 mb-8'}>
                            <IconButton
                                ariaLabel={'Button'}
                                type={'button'}
                                className={'btn btn-default icon--left'}
                                icon={<HeartIcon/>}
                            >
                                Button
                            </IconButton>
                        </div>
                        <div className={'float-left mr-8 mb-8 clear-both'}>
                            <Button
                                className={'btn btn-default'}
                                disabled={1}
                            >
                                Button
                            </Button>
                        </div>
                        <div className={'float-left mr-8 mb-8'}>
                            <IconButton
                                ariaLabel={'Button'}
                                type={'button'}
                                className={'btn btn-default icon--left'}
                                icon={<HeartIcon/>}
                                disabled={1}
                            >
                                Button
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div>
                    <p className={'text-sm mb-6'}>CTA</p>
                    <div>
                        <div className={'float-left mr-8 mb-8 clear-both'}>
                            <Button className={'btn btn-cta'}>
                                Button
                            </Button>
                        </div>
                        <div className={'float-left mr-8 mb-8'}>
                            <IconButton
                                ariaLabel={'Button'}
                                type={'button'}
                                className={'btn btn-cta icon--left'}
                                icon={<HeartIcon/>}
                            >
                                Button
                            </IconButton>
                        </div>
                        <div className={'float-left mr-8 mb-8 clear-both'}>
                            <Button
                                className={'btn btn-cta'}
                                disabled={1}
                            >
                                Button
                            </Button>
                        </div>
                        <div className={'float-left mr-8 mb-8'}>
                            <IconButton
                                ariaLabel={'Button'}
                                type={'button'}
                                className={'btn btn-cta icon--left'}
                                icon={<HeartIcon/>}
                                disabled={1}
                            >
                                Button
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div>
                    <p className={'text-sm mb-6'}>Secondary</p>
                    <div>
                        <div className={'float-left mr-8 mb-8 clear-both'}>
                            <Button className={'btn btn-secondary'}>
                                Button
                            </Button>
                        </div>
                        <div className={'float-left mr-8 mb-8'}>
                            <IconButton
                                ariaLabel={'Button'}
                                type={'button'}
                                className={'btn btn-secondary icon--left'}
                                icon={<HeartIcon/>}
                            >
                                Button
                            </IconButton>
                        </div>
                        <div className={'float-left mr-8 mb-8 clear-both'}>
                            <Button
                                className={'btn btn-secondary'}
                                disabled={1}
                            >
                                Button
                            </Button>
                        </div>
                        <div className={'float-left mr-8 mb-8'}>
                            <IconButton
                                ariaLabel={'Button'}
                                type={'button'}
                                className={'btn btn-secondary icon--left'}
                                icon={<HeartIcon/>}
                                disabled={1}
                            >
                                Button
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className={'bg-blacksport-100 mt-10'}>
            <div className={'md:px-20 py-10'}>
                <h2 className={'text-white uppercase pb-10'}>ON DARK BACKGROUND</h2>
                <div className={'grid grid-cols-3 gap-4'}>
                    <div>
                        <p className={'text-sm mb-6 text-white'}>Default</p>
                        <div>
                            <div className={'float-left mr-8 mb-8 clear-both'}>
                                <Button
                                    className={'btn btn-default'}
                                    darkTheme
                                >
                                    Button
                                </Button>
                            </div>
                            <div className={'float-left mr-8 mb-8'}>
                                <IconButton
                                    ariaLabel={'Button'}
                                    type={'button'}
                                    className={'btn btn-default icon--left'}
                                    darkTheme
                                    icon={<HeartIcon/>}
                                >
                                    Button
                                </IconButton>
                            </div>
                            <div className={'float-left mr-8 mb-8 clear-both'}>
                                <Button
                                    className={'btn btn-default'}
                                    darkTheme
                                    disabled={1}
                                >
                                    Button
                                </Button>
                            </div>
                            <div className={'float-left mr-8 mb-8'}>
                                <IconButton
                                    ariaLabel={'Button'}
                                    type={'button'}
                                    className={'btn btn-default icon--left'}
                                    darkTheme
                                    icon={<HeartIcon/>}
                                    disabled={1}
                                >
                                    Button
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className={'text-sm mb-6 text-white'}>CTA</p>
                        <div>
                            <div className={'float-left mr-8 mb-8 clear-both'}>
                                <Button className={'btn btn-cta'}>
                                    Button
                                </Button>
                            </div>
                            <div className={'float-left mr-8 mb-8'}>
                                <IconButton
                                    ariaLabel={'Button'}
                                    type={'button'}
                                    className={'btn btn-cta icon--left'}
                                    icon={<HeartIcon/>}
                                >
                                    Button
                                </IconButton>
                            </div>
                            <div className={'float-left mr-8 mb-8 clear-both'}>
                                <Button
                                    className={'btn btn-cta'}
                                    disabled={1}
                                >
                                    Button
                                </Button>
                            </div>
                            <div className={'float-left mr-8 mb-8'}>
                                <IconButton
                                    ariaLabel={'Button'}
                                    type={'button'}
                                    className={'btn btn-cta icon--left'}
                                    icon={<HeartIcon/>}
                                    disabled={1}
                                >
                                    Button
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className={'text-sm mb-6 text-white'}>Secondary</p>
                        <div>
                            <div className={'float-left mr-8 mb-8 clear-both'}>
                                <Button
                                    darkTheme
                                    className={'btn btn-secondary'}
                                >
                                    Button
                                </Button>
                            </div>
                            <div className={'float-left mr-8 mb-8'}>
                                <IconButton
                                    ariaLabel={'Button'}
                                    type={'button'}
                                    darkTheme
                                    className={'btn btn-secondary icon--left'}
                                    icon={<HeartIcon/>}
                                >
                                    Button
                                </IconButton>
                            </div>
                            <div className={'float-left mr-8 mb-8 clear-both'}>
                                <Button
                                    darkTheme
                                    className={'btn btn-secondary'}
                                    disabled={1}
                                >
                                    Button
                                </Button>
                            </div>
                            <div className={'float-left mr-8 mb-8'}>
                                <IconButton
                                    ariaLabel={'Button'}
                                    type={'button'}
                                    darkTheme
                                    className={'btn btn-secondary icon--left'}
                                    icon={<HeartIcon/>}
                                    disabled={1}
                                >
                                    Button
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)
export default Buttons

import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import ButtonWithLoader from '../../atoms/button/WithLoader'

const RemoveAddressForm = ({
    intl, address, countries, onRemoveAddressClicked, showLoader,
}) => {
    let country
    for (const c of countries) {
        if (c.code === address.country) {
            country = c
        }
    }

    return (
        <div className={'my-6 mx-4'}>
            <div className={'text-black'}>
                <div className={'mb-6 font-bold'}>
                    <FormattedMessage id={'account.confirmDeleteAccount'} />
                </div>
                <strong>
                    {address.firstName}
                    {' '}
                    {address.lastName}
                </strong>
                <div>
                    {address.streetName}
                    {' '}
                    {address.streetNumber}
                </div>
                <div>{address.city}</div>
                <div>{country.name}</div>
            </div>

            <div className={'text-center mt-6'}>
                <ButtonWithLoader
                    type={'submit'}
                    showLoader={showLoader}
                    onClick={onRemoveAddressClicked}
                    className={'btn btn-default account--save-button w-auto'}
                    ariaLabel={intl.formatMessage({ id: 'account.delete' })}
                >
                    <FormattedMessage id={'account.delete'} />
                </ButtonWithLoader>
            </div>
        </div>
    )
}

RemoveAddressForm.propTypes = {
    intl: PropTypes.object.isRequired,
    address: PropTypes.object,
    countries: PropTypes.array.isRequired,
    onRemoveAddressClicked: PropTypes.func.isRequired,
    showLoader: PropTypes.bool.isRequired,
}

export default injectIntl(RemoveAddressForm)

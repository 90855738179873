import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import Cart from '../../patterns/organisms/Cart'
import GtmService from '../../services/gtm'
import TagManager from '../../domain/TagManager'

const CartTastic = ({ data, cart }) => {
    const { helpLink } = data

    useEffect(() => {
        if (cart.data) {
            const gtmService = new GtmService(cart.data.lineItems)
            const productListGTM = gtmService.createProductListGTM('Cart')
            TagManager.cartImpressions(cart.data.sum, productListGTM)
            TagManager.hitViewdImpressions()
        }
    }, [cart])

    if (cart.loading && cart.data) {
        return (
            <Cart
                isLoading
                data={cart.data}
                links={helpLink}
            />
        )
    }

    if (cart.isComplete()) {
        return (
            <Cart
                links={helpLink}
                data={cart.data}
            />
        )
    }

    return null
}

CartTastic.propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    cart: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default tastify({ translate: true, connect: { cart: true } })(CartTastic)

import React, { forwardRef, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import Entity from '@frontastic/catwalk/src/js/app/entity'
import { FormattedMessage } from 'react-intl'
import { ScrollContext } from '@frontastic/catwalk/src/js/app/scrollContext'
import { useSelector } from 'react-redux'
import { stepObject } from './stepObject'
import useObserver from './useObserver'

const Panels = forwardRef(
    ({
        steps,
        current,
        setCurrent,
        app,
        data,
        cart,
        countries,
        policy,
        isLoading = false,
        paymentInfo,
        setPaymentInfo,
        contextSession,
        activeStep1,
        activeStep2,
        activeStep3,
        setActiveStep1,
        setActiveStep2,
        setActiveStep3,
        validNextStep,
        validNextStep2,
        setValidNextStep,
        setValidNextStep2,
        divider1,
        divider2,
        setDivider1,
        setDivider2,
        paymentMethods,
        adyenComponentRef,
        paymentIdRef,
        buttonConfirmDisabled,
        setButtonConfirmDisabled,
    }, ts) => {
        const goToPanel = (panel) => {
            ts.current.slider.goTo(panel)
            setCurrent(panel)
        }

        const loggedIn = useSelector((state) => state.app.context.session.loggedIn)

        const [selectedShippingAddressId, setSelectedShippingAddressId] = useState(null)
        const [firstRun, setFirstRun] = useState(false)

        const getActive = (i) => {
            if (i === 1) {
                return activeStep1
            }
            if (i === 2) {
                return activeStep2
            }

            return activeStep3
        }

        const setActive = (i) => {
            if (i === 1) {
                return setActiveStep1
            }
            if (i === 2) {
                return setActiveStep2
            }

            return setActiveStep3
        }

        const setNextActive = (i) => {
            if (i === 1) {
                return setActiveStep2
            }
            if (i === 2) {
                return setActiveStep3
            }

            return null
        }

        return (
            <div className={'checkout-panels-slider relative min-h-full'}>
                <h2 className={'checkout-panels-title'}>
                    {loggedIn ? (
                        <FormattedMessage id={'checkout.checkoutTitleLoggedIn'} />
                    ) : (
                        <FormattedMessage id={'checkout.checkoutTitleNotLogIn'} />
                    )}
                </h2>

                {steps.map(({ component: PanelComponent, name }, i) => {
                    const panelRef = useRef()

                    useObserver({
                        callback: () => {
                            if (ts.current) {
                                ts.current.slider.refresh()
                            }
                        },
                        element: panelRef,
                    })

                    return (
                        <div ref={panelRef} key={name}>
                            <ScrollContext.Consumer>
                                {(context) => (
                                    <PanelComponent
                                        setCurrent={setCurrent}
                                        currentIndex={i}
                                        active={getActive(i + 1)}
                                        setActive={setActive(i + 1)}
                                        setNextActive={setNextActive(i + 1)}
                                        name={name}
                                        app={app}
                                        cart={cart}
                                        data={data}
                                        countries={countries}
                                        policy={policy}
                                        setPaymentInfo={setPaymentInfo}
                                        paymentInfo={paymentInfo}
                                        setValidNextStep={setValidNextStep}
                                        validNextStep={validNextStep}
                                        setValidNextStep2={setValidNextStep2}
                                        validNextStep2={validNextStep2}
                                        contextSession={contextSession}
                                        setSelectedShippingAddressId={setSelectedShippingAddressId}
                                        selectedShippingAddressId={selectedShippingAddressId}
                                        goToPanelIndex={(panel) => {
                                            goToPanel(panel)
                                            context.forceScrollToTop()
                                        }}
                                        goToNextPanel={() => {
                                            goToPanel(current + 1)
                                            context.forceScrollToTop()
                                        }}
                                        goToPreviousPanel={() => {
                                            goToPanel(current - 1)
                                            context.forceScrollToTop()
                                        }}
                                        isLoading={isLoading}
                                        firstRun={firstRun}
                                        setFirstRun={setFirstRun}
                                        divider1={divider1}
                                        divider2={divider2}
                                        setDivider1={setDivider1}
                                        setDivider2={setDivider2}
                                        paymentMethods={paymentMethods}
                                        setActiveStep1={setActiveStep1}
                                        setActiveStep2={setActiveStep2}
                                        setActiveStep3={setActiveStep3}
                                        adyenComponentRef={adyenComponentRef}
                                        paymentIdRef={paymentIdRef}
                                        buttonConfirmDisabled={buttonConfirmDisabled}
                                        setButtonConfirmDisabled={setButtonConfirmDisabled}
                                    />
                                )}
                            </ScrollContext.Consumer>
                        </div>
                    )
                })}
            </div>
        )
    },
)

Panels.propTypes = {
    app: PropTypes.object.isRequired,
    contextSession: PropTypes.object.isRequired,
    paymentInfo: PropTypes.object.isRequired,
    countries: PropTypes.array.isRequired,
    data: PropTypes.object.isRequired,
    steps: PropTypes.arrayOf(stepObject),
    current: PropTypes.oneOf([0, 1, 2]).isRequired,
    setPaymentInfo: PropTypes.func.isRequired,
    setCurrent: PropTypes.func.isRequired,
    cart: PropTypes.instanceOf(Entity),
    isLoading: PropTypes.bool,
    policy: PropTypes.string,
    setActiveStep1: PropTypes.func.isRequired,
    setActiveStep2: PropTypes.func.isRequired,
    setActiveStep3: PropTypes.func.isRequired,
    setValidNextStep: PropTypes.func.isRequired,
    setValidNextStep2: PropTypes.func.isRequired,
    setDivider1: PropTypes.func.isRequired,
    setDivider2: PropTypes.func.isRequired,
    activeStep1: PropTypes.bool.isRequired,
    activeStep2: PropTypes.bool.isRequired,
    activeStep3: PropTypes.bool.isRequired,
    validNextStep: PropTypes.bool.isRequired,
    validNextStep2: PropTypes.bool.isRequired,
    divider1: PropTypes.bool.isRequired,
    divider2: PropTypes.bool.isRequired,
    paymentMethods: PropTypes.object.isRequired,
    adyenComponentRef: PropTypes.any,
    paymentIdRef: PropTypes.any,
    buttonConfirmDisabled: PropTypes.bool,
    setButtonConfirmDisabled: PropTypes.func,
}

export default Panels

import React from 'react'
import PropTypes from 'prop-types'
import ImageContainer from '../../patterns/organisms/ImageContainer'

const ImageContainerTastic = ({
    data: {
        mode,
        title,
        desc,
        positionMode,
        positionMobile,
        buttonLabel,
        reference,
        btnVariant,
        btnColorDefault,
        btnColorHover,
        align,
        bgColor,
        media,
        textColor,
        heightMobile,
        heightTablet,
        heightDesktop,
        mediaMobile,
        mediaTablet,
        mediaDesktop,
    },
}) => {
    const mobile = {
        minHeight: heightMobile,
        media: mediaMobile,
    }
    const tablet = {
        minHeight: heightTablet,
        media: mediaTablet,
    }
    const desktop = {
        minHeight: heightDesktop,
        media: mediaDesktop,
    }

    return (
        <ImageContainer
            title={title}
            desc={desc}
            positionMode={positionMode}
            positionMobile={positionMobile}
            label={buttonLabel}
            reference={reference}
            btnVariant={btnVariant}
            btnColorDefault={btnColorDefault}
            btnColorHover={btnColorHover}
            align={align}
            bgColor={bgColor}
            media={media}
            textColor={textColor}
            mobile={mobile}
            tablet={tablet}
            desktop={desktop}
            mode={mode}
        />
    )
}

ImageContainerTastic.propTypes = {
    data: PropTypes.object.isRequired,
}

export default ImageContainerTastic

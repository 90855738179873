import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import classnames from 'classnames'
import { FormattedMessage, injectIntl } from 'react-intl'
import FullPageWidthWrapper from '../../../molecules/Layout/FullPageWidthWrapper'
import Button from '../../../atoms/button/Button'
import GraphImage from '../../../atoms/graphImage'

const BlogShortDescription = ({ intl, content }) => {
    if (!content) {
        return null
    }

    const isDesktop = useDeviceType() === 'desktop'

    const scrollPositionDelta = 200

    const handleScrollClick = () => {
        const scrollTarget = document.getElementById('blog-full')

        const getPositionDelta = () => {
            const position = scrollTarget.getBoundingClientRect()

            if (!isDesktop && position.top > 0) {
                return position.top
            }

            return position.top - scrollPositionDelta
        }

        if (scrollTarget) {
            setTimeout(() => {
                window.scrollTo({
                    behavior: scrollTarget ? 'smooth' : 'start',
                    top: getPositionDelta(scrollTarget),
                })
            }, 100)
        }
    }

    const imageBackground = () => {
        const tmpStyle = {}

        if (content.image && content.image.url) {
            tmpStyle.backgroundImage = `url(${content.image.url})`
        }

        return tmpStyle
    }

    const textBoxBlogDescription = () => (
        <div className={classnames('box-blog-short', {
            'box-blog-short-no-bg': !content.image && isDesktop,
        })}
        >
            {content.logo && content.logo.url && (
                <div className={'box-blog-short-logo'}>
                    <GraphImage
                        className={'box-blog-short-logo-img'}
                        src={content.logo.url}
                        alt={content.slug}
                        width={260}
                        height={110}
                    />
                </div>
            )}

            {content.headline && (
                <h3>
                    {content.headline}
                </h3>
            )}

            {content.content_brand_short && (
                <div className={'box-blog-short-desc'}>
                    {parse(content.content_brand_short.html)}
                </div>
            )}

            {content.content_brand_full && (
                <div className={'jump-mark--item'}>
                    <Button
                        type={'button'}
                        onClick={() => handleScrollClick()}
                        className={'btn btn-link normal-case font-normal tracking-normal p-0'}
                        ariaLabel={intl.formatMessage({ id: 'brandDetail.button' })}
                    >
                        <FormattedMessage id={'brandDetail.button'} />
                    </Button>
                </div>
            )}
        </div>
    )

    const BlogShortDesktop = () => (
        <FullPageWidthWrapper
            customClasses={'blog-short-desc-desktop'}
            style={imageBackground()}
            withWrapper
        >
            <>
                {textBoxBlogDescription()}
            </>
        </FullPageWidthWrapper>
    )

    return (
        <>
            {!isDesktop ? (
                <div className={'blog-short-desc-mobile'}>
                    {content.image && (
                        <FullPageWidthWrapper
                            customClasses={'blog-short-desc-mobile-bg'}
                            style={imageBackground()}
                        />
                    )}
                    <div className={classnames('blog-short-desc-mobile-bg-content', {
                        'blog-short-desc-mobile-bg-empty': !content.image,
                    })}
                    >
                        {textBoxBlogDescription()}
                    </div>
                </div>
            ) : (
                <>
                    {BlogShortDesktop()}
                </>
            )}
        </>
    )
}

BlogShortDescription.propTypes = {
    intl: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
}

export default injectIntl(BlogShortDescription)

import React from 'react'
import PropTypes from 'prop-types'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import classnames from 'classnames'
import Markdown from '@frontastic/catwalk/src/js/component/markdown'
import MediaImage from '@frontastic/catwalk/src/js/mediaImage'
import { ClockIcon, LocationIcon } from '../../atoms/icon'
import Button from '../../atoms/button/Button'

const StoreHeader = ({
    subHeadline,
    headline,
    map,
    titleStoreContact,
    address,
    phoneNumber,
    email,
    website,
    buttonLabelShowRoute,
    referenceButtonShowRoute,
    btnVariantShowRoute,
    titleStoreOpening,
    StoreOpeningHoursList,
    buttonLabelMakeAnAppointment,
    referenceButtonMakeAnAppointment,
    btnVariantMakeAnAppointment,
    isDarkMode,
}) => (
    <section className={classnames('width--fullsize py-comp', {
        'bg-blacksport-100 text-white': isDarkMode,
    })}
    >
        <div className={'o-wrapper'}>
            <div className={'o-grid o-region'}>
                <div className={'o-cell o-cell--12'}>
                    <div className={'store-detail-header-component'}>
                        {subHeadline && (
                            <div className={'text-subh2'}>{subHeadline}</div>
                        )}

                        {headline && (
                            <h2 className={'headline'}>{headline}</h2>
                        )}

                        <div className={'store-detail-header grid-cols-1 lg:grid-cols-2'}>
                            {map && (
                                <div className={'img-map'}>
                                    <MediaImage media={map} />
                                </div>
                            )}

                            <div
                                className={'store-info-wrapper grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-4 lg:my-auto'}
                            >
                                <div className={'store-contact-info'}>
                                    {titleStoreContact && (
                                                         <div className={'title-elm'}>
                                            <LocationIcon
                                                className={'icon'}
                                                width={32}
                                                height={32}
                                                fill={'var(--color-current-theme)'}
                                            />
                                            <h4 className={'store-title'}>{titleStoreContact}</h4>
                                        </div>
                                    )}

                                    {address && (
                                                         <div className={'store-address'}>
                                            <Markdown
                                                text={address}
                                            />
                                        </div>

                                    )}

                                    <div className={'store-contact-links'}>
                                                             {phoneNumber && (
                                            <div>
                                                <a
                                                    className={'btn-link'}
                                                    title={`tel:${phoneNumber}`}
                                                    href={`tel:${phoneNumber}`}
                                                >
                                                    {phoneNumber}
                                                </a>
                                            </div>
                                        )}

                                                             {email && (
                                            <div>
                                                <a
                                                    className={'btn-link'}
                                                    title={`mailto:${email}`}
                                                    href={`mailto:${email}`}
                                                >
                                                    {email}
                                                </a>
                                            </div>
                                        )}

                                                             {website && (
                                            <div>
                                                <a
                                                    className={'btn-link'}
                                                    title={website}
                                                    href={website}
                                                    target={'_blank'}
                                                    rel={'noreferrer noopener nofollow'}
                                                >
                                                    {website}
                                                </a>
                                            </div>
                                        )}
                                                         </div>

                                    {referenceButtonShowRoute && (
                                                         <Button
                                            type={'button'}
                                            className={classnames('btn dark-mode', {
                                                'btn-default': btnVariantShowRoute === 'default',
                                                'btn-cta': btnVariantShowRoute === 'cta',
                                                'btn-secondary': btnVariantShowRoute === 'secondary',
                                            })}
                                            reference={referenceButtonShowRoute}
                                            ariaLabel={buttonLabelShowRoute}
                                        >
                                            {buttonLabelShowRoute}
                                        </Button>
                                    )}
                                </div>
                                <div className={'store-opening-info'}>
                                    {titleStoreOpening && (
                                                         <div className={'title-elm'}>
                                            <ClockIcon
                                                className={'icon'}
                                                width={32}
                                                height={32}
                                                fill={'var(--color-current-theme)'}
                                            />
                                            <h4 className={'store-title'}>{titleStoreOpening}</h4>
                                        </div>
                                    )}

                                    {StoreOpeningHoursList && (

                                                         <div className={'store-opening-info-list'}>
                                            <Markdown
                                                text={StoreOpeningHoursList}
                                            />
                                        </div>
                                    )}

                                    {referenceButtonMakeAnAppointment && (
                                                         <Button
                                            type={'button'}
                                            className={classnames('btn dark-mode', {
                                                'btn-default': btnVariantMakeAnAppointment === 'default',
                                                'btn-cta': btnVariantMakeAnAppointment === 'cta',
                                                'btn-secondary': btnVariantMakeAnAppointment === 'secondary',
                                            })}
                                            ariaLabel={buttonLabelMakeAnAppointment}
                                            reference={referenceButtonMakeAnAppointment}
                                        >
                                            {buttonLabelMakeAnAppointment}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

StoreHeader.propTypes = {
    subHeadline: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    map: PropTypes.object,
    titleStoreContact: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    website: PropTypes.string.isRequired,
    buttonLabelShowRoute: PropTypes.string.isRequired,
    referenceButtonShowRoute: PropTypes.string,
    btnVariantShowRoute: PropTypes.string.isRequired,
    titleStoreOpening: PropTypes.string.isRequired,
    StoreOpeningHoursList: PropTypes.string.isRequired,
    buttonLabelMakeAnAppointment: PropTypes.string.isRequired,
    referenceButtonMakeAnAppointment: PropTypes.string,
    btnVariantMakeAnAppointment: PropTypes.string.isRequired,
    isDarkMode: PropTypes.bool,
}

export default tastify()(StoreHeader)

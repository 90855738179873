import fetch from '@frontastic/bridge/js/fetch'

class PaymentService {
    async createAdyenPayment(paymentMethod, browserInfo) {
        const res = await fetch(`/api/payment/adyen/payment?origin=${window.location.origin}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ paymentMethod, browserInfo }),
        })
        if (!res.ok) {
            throw res
        }
        return res.json()
    }

    async updateAdyenPayment(paymentId, details, paymentData) {
        const res = await fetch(`/api/payment/adyen/payment/${paymentId}/additionalDetails?origin=${window.location.origin}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                details,
                paymentData,
            }),
        })

        if (!res.ok) {
            throw res
        }
        return res.json()
    }

    async fingerprintAdyenPayment(paymentId, paymentData) {
        const fingerprintKey = { threeDSCompInd: 'Y' }
        const res = await fetch(`/api/payment/adyen/payment/${paymentId}/additionalDetails?origin=${window.location.origin}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                details: { 'threeds2.fingerprint': window.btoa(JSON.stringify(fingerprintKey)) },
                paymentData,
            }),
        })

        if (!res.ok) {
            throw res
        }
        return res.json()
    }

    async cancelAdyenPayment(paymentId) {
        const res = await fetch(`/api/payment/adyen/payment/${paymentId}/cancel`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
        if (!res.ok) {
            throw res
        }
        return res.json()
    }

    async getAdyenPaymentMethods() {
        const res = await fetch(`/api/payment/adyen/paymentMethods?origin=${window.location.origin}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
        if (!res.ok) {
            throw res
        }
        return res.json()
    }

    async getPayeverPaymentMethods() {
        const res = await fetch(`/api/payment/payever/paymentMethods`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
        if (!res.ok) {
            throw res
        }
        return res.json()
    }

    async createPayeverPayment(paymentMethod) {
        const res = await fetch(`/api/payment/payever/payment`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ paymentMethod }),
        })
        if (!res.ok) {
            throw res
        }
        return res.json()
    }

    async cancelPayeverPayment(paymentId) {
        const res = await fetch(`/api/payment/payever/payment/${paymentId}/cancel`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
        if (!res.ok) {
            throw res
        }
        return res.json()
    }

    isPayever(paymentPlatform) {
        return paymentPlatform === 'payever'
    }

    isAdyen(paymentPlatform) {
        return paymentPlatform === 'adyen'
    }
}

export default new PaymentService()

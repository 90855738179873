import React, {
    useEffect, useMemo, useRef, useState,
} from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import debounce from 'lodash/debounce'
import { ReactComponent as CloseIcon } from '../../../../icons/sport2000-icons/close.svg'
import { ReactComponent as LocationIcon } from '../../../../icons/sport2000-icons/location.svg'
import { ReactComponent as LocationFilledIcon } from '../../../../icons/sport2000-icons/location-filled.svg'
import { ReactComponent as SearchIcon } from '../../../../icons/sport2000-icons/search.svg'
import IconButton from '../../atoms/button/IconButton'
import ClientFactory from '../../../services/algolia/ClientFactory'
import useGooglePlaces from '../../../services/hook/useGooglePlaces'
import useComponentVisible from '../../../utils/hooks/UseComponentVisible'
import useDebounce from '../../../services/hook/useDebounce'
import Hits from './Hits'

const StoreSidebar = ({
    isOpen, onClose, context, selectedVariant, themeName,
}) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
    const [isFocus, setIsFocus] = useState(false)
    const [value, setValue] = useState('')
    const [location, setLocation] = useState(null)
    const [hits, setHits] = useState([])
    const [suggestionStore, setSuggestionStore] = useState([])
    const [isAllow, setIsAllow] = useState(false)
    const debounceSearch = useDebounce(value, 300)

    const [currentLocation, setCurrentLocation] = useState(null)
    const inputRef = useRef(null)
    const isCurrentLocation = location && currentLocation && currentLocation.lat === location.lat && currentLocation.lng === location.lng
    const isAbsoluteTeamsport = themeName === 'theme-absolute-teamsport'
    const aroundRadius = isAbsoluteTeamsport ? 50000 : 20000

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
    } = useGooglePlaces({})

    const focusInput = debounce(() => {
        if (isOpen && inputRef && inputRef.current && !value) {
            inputRef.current.focus()
        }
    }, 300)

    useMemo(() => {
        focusInput()
        return () => focusInput.cancel()
    }, [isOpen])

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsAllow(false)
        }, 3000)

        return () => clearTimeout(timer)
    }, [isAllow])

    useEffect(() => {
        if (debounceSearch) {
            ClientFactory.queryStore(context, value, { hitsPerPage: 5, aroundRadius }).then((res) => {
                const stores = res.results[0]?.hits
                const sortedStores = getSortedStores(stores)

                setSuggestionStore(sortedStores)
            })
        }
    }, [debounceSearch])

    const getSortedStores = (stores) => {
        if (!stores || !stores.length) {
            return []
        }

        const sortedStores = isAbsoluteTeamsport ? [
            ...stores.filter((hit) => hit.specialization_logos?.some((concept) => concept === 'absolute_teamsport')),
            ...stores.filter((hit) => !hit.specialization_logos?.some((concept) => concept === 'absolute_teamsport')),
        ] : stores

        return sortedStores
    }

    const handleQueryStoreBylatLng = (lat, lng) => {
        ClientFactory.queryStore(context, '', {
            hitsPerPage: 30,
            aroundLatLng: `${lat}, ${lng}`,
            aroundRadius,
        }).then((res) => {
            setHits(getSortedStores(res.results[0]?.hits))
        })
    }

    const handleSelectPrediction = (place, isFirstPlace) => {
        if ((!placePredictions || !placePredictions.length) && !suggestionStore.length) {
            return
        }

        let lat
        let lng
        const placePrediction = isFirstPlace ? placePredictions[0] : place
        setValue(placePrediction.name || placePrediction.description)
        setIsFocus(false)

        if (placePrediction._geoloc) {
            lat = placePrediction._geoloc.lat
            lng = placePrediction._geoloc.lng
            setLocation({
                lat,
                lng,
            })
            handleQueryStoreBylatLng(lat, lng)
            return
        }

        placesService?.getDetails(
            {
                placeId: placePrediction.place_id,
            },
            (placeDetails) => {
                lat = placeDetails.geometry.location.lat()
                lng = placeDetails.geometry.location.lng()
                setLocation({
                    lat,
                    lng,
                })
                handleQueryStoreBylatLng(lat, lng)
            },
        )
    }

    const renderPlacePredictions = () => {
        if (!value || (!placePredictions.length && !suggestionStore.length) || !isFocus || !isComponentVisible) {
            return null
        }
        const suggestion = [...suggestionStore, ...placePredictions]

        return suggestion.map((place) => (
            <div
                className={'place-prediction-item'}
                key={place.place_id || place.account_id}
                onClick={() => {
                    handleSelectPrediction(place)
                }}
            >
                {place.name || place.description}
            </div>
        ))
    }

    const handleGetCurrentLocation = async () => {
        if (isCurrentLocation) {
            return
        }

        await navigator.geolocation.getCurrentPosition(
            (position) => {
                const lat = position.coords.latitude
                const lng = position.coords.longitude

                setCurrentLocation({
                    lat,
                    lng,
                })

                setLocation({
                    lat,
                    lng,
                })

                handleQueryStoreBylatLng(lat, lng)
                setValue(`${lat}, ${lng}`)
                getPlacePredictions({
                    input: '',
                })
            },
            () => {
                setIsAllow(true)
                setIsFocus(false)
            },
        )
    }

    return (
        <div>
            <div
                className={'off-canvas-store__overlay'}
                onClick={onClose}
            />
            <div className={'off-canvas-store__container'}>
                <div className={'off-canvas-store__wrapper'}>
                    <div className={'off-canvas-store__header'}>
                        <h4 className={'mb-0 flex-grow'}>
                            <FormattedMessage id={'productDetail.storeSidebarTitle'} />
                        </h4>
                        <IconButton
                            className={'cursor-pointer'}
                            type={'button'}
                            icon={<CloseIcon width={20} height={20} onClick={onClose} />}
                            onClick={onClose}
                        />
                    </div>
                    <div className={'off-canvas-store__form'} ref={ref}>
                        <div className={'relative'}>
                            <input
                                ref={inputRef}
                                type={'text'}
                                value={value}
                                onFocus={() => {
                                    setIsFocus(true)
                                    setIsComponentVisible(true)
                                }}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        handleSelectPrediction(null, true)
                                    }
                                }}
                                onChange={(e) => {
                                    getPlacePredictions({
                                        input: e.target.value,
                                    })
                                    setValue(e.target.value)
                                }}
                            />
                            {isAllow && (
                                <div className={'mt-1 text-red-600 text-xs'}>
                                    <FormattedMessage id={'storeFinder.permissionLocation'} />
                                </div>
                            )}
                            <div className={'btn-store-sidebar btn-location-store-sidebar'}>
                                <IconButton
                                    className={'cursor-pointer'}
                                    type={'button'}
                                    icon={isCurrentLocation
                                        ? <LocationFilledIcon width={16} height={16} />
                                        : <LocationIcon width={16} height={16} />}
                                    onClick={handleGetCurrentLocation}
                                />
                            </div>
                            <div className={'btn-store-sidebar btn-search-store-sidebar'}>
                                <IconButton
                                    className={'cursor-pointer'}
                                    type={'button'}
                                    icon={<SearchIcon width={16} height={16} />}
                                    onClick={() => handleSelectPrediction(null, true)}
                                />
                            </div>
                            <div className={'off-canvas-store__place-predictions'}>
                                {renderPlacePredictions()}
                            </div>
                        </div>
                    </div>
                    <Hits
                        hits={hits}
                        location={location}
                        selectedVariant={selectedVariant}
                        context={context}
                    />
                </div>
            </div>
        </div>
    )
}

StoreSidebar.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    context: PropTypes.any,
    intl: PropTypes.any,
    selectedVariant: PropTypes.any,
    themeName: PropTypes.any,
}

export default injectIntl(StoreSidebar)

import React, { useState } from 'react'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import objectSportCategories from './IconSport'
import IconButton from '../../../../atoms/button/IconButton'
import { ReactComponent as ArrowDownIcon } from '../../../../../../icons/sport2000-icons/arrow-down.svg'
import { ReactComponent as ArrowUpIcon } from '../../../../../../icons/sport2000-icons/arrow-up.svg'

const SportSection = ({ categories, intl }) => {
    if (!categories) {
        return null
    }

    const isDesktop = useDeviceType() === 'desktop'

    const ITEMS_TO_SHOW = 6
    const [itemList, setItemList] = useState(ITEMS_TO_SHOW)
    const [expanded, setExpanded] = useState(false)

    const sportIconCategories = {}

    const mergeArrayCategories = () => {
        let arrayCategories = JSON.parse(JSON.stringify(categories))

        categories.forEach((item) => {
            arrayCategories = arrayCategories.concat(item.warenunterkategorien)
        })

        return arrayCategories
    }

    const showMore = () => {
        if (itemList === ITEMS_TO_SHOW && !isDesktop) {
            setExpanded(true)
            setItemList(mergeArrayCategories().length)
            return
        }

        setExpanded(false)
        setItemList(ITEMS_TO_SHOW)
    }

    mergeArrayCategories().forEach((item) => {
        const iconSport = objectSportCategories[item.name]
        if (iconSport) {
            sportIconCategories[item.id] = {
                name: item.name,
                iconSport,
            }
        }
    })

    const sportCategories = Object.keys(sportIconCategories)

    const renderTemplate = () => {
        if (!isDesktop) {
            return (
                sportCategories.slice(0, itemList).map((item) => (
                    <div key={item}>{sportIconCategories[item].iconSport.iconName}</div>
                )))
        }

        return (
            sportCategories.map((item) => (
                <div key={item}>{sportIconCategories[item].iconSport.iconName}</div>
            ))
        )
    }

    return (
        sportCategories.length > 0 && (
            <div className={'store-detail-sports'}>
                <h2 className={'store-detail-sports-headline mb-7 lg:mb-8'}>
                    <FormattedMessage id={'storeDetail.sportHeadline'} />
                </h2>
                <div className={'store-detail-sports-wrap'}>
                    <>
                        {renderTemplate()}
                    </>
                </div>
                <div className={'w-full'}>
                    {(categories.length > ITEMS_TO_SHOW && !isDesktop) && (
                        <IconButton
                            className={'btn-link store-detail-btn-icon flex'}
                            icon={(
                                <>
                                    {!expanded ? (
                                        <ArrowDownIcon
                                            className={'mt-1 mr-2'}
                                            width={16}
                                            height={16}
                                        />
                                    ) : (
                                        <ArrowUpIcon
                                            className={'mt-1 mr-2'}
                                            width={16}
                                            height={16}
                                        />
                                    )}
                                </>
                            )}
                            type={'button'}
                            ariaLabel={intl.formatMessage({ id: 'storeDetail.showMore' })}
                            onClick={() => showMore()}
                        >
                            <>
                                {!expanded ? (
                                    <FormattedMessage id={'storeDetail.showMore'} />
                                ) : (
                                    <FormattedMessage id={'storeDetail.showLess'} />
                                )}
                            </>
                        </IconButton>
                    )}
                </div>
            </div>
        )
    )
}

SportSection.propTypes = {
    intl: PropTypes.object.isRequired,
    categories: PropTypes.array,
}

export default injectIntl(SportSection)

import React from 'react'
import PropTypes from 'prop-types'

const Map = ({ location, context }) => {
    const googleMapKey = context.project.configuration.googleMap.mapKey

    return (
        <div className={'custom-cmp'} style={{ height: '250px', width: '100%' }}>
            <iframe
                className={'cmplazyload'}
                width={'100%'}
                height={'250'}
                frameBorder={'0'}
                referrerPolicy={'no-referrer-when-downgrade'}
                data-cmp-src={`https://www.google.com/maps/embed/v1/place?key=${googleMapKey}&q=${parseFloat(location.lat)},${parseFloat(location.lng)}&center=${parseFloat(location.lat)},${parseFloat(location.lng)}&zoom=12`}
                title={'Embedded youtube'}
                loading={'lazy'}
                data-cmp-vendor={'s1104'}
                data-cmp-preview={'500x300'}
                data-cmp-purpose={'c1'}
                data-cmp-preview-headline={'Um Karten von Google Maps anzeigen zu können, stimmen Sie der Verwendung auch optionaler Cookies zu.'}
                data-cmp-preview-btn={'Akzeptieren'}
                data-cmp-preview-text={'Dieser Inhalt wird von Google Maps bereit gestellt. Wenn Sie den Inhalt aktivieren, werden ggf. personenbezogene Daten verarbeitet und Cookies gesetzt.'}
                data-cmp-preview-checkbox={'Google Maps immer aktivieren.'}
                data-cmp-preview-image={'https://res.cloudinary.com/dlwdq84ig/image/upload/v1669045270/tdwhusl09cfwsksnkeat.jpg'}
                data-cmp-preview-bgcolor={'#FFFFFF'}
                data-cmp-preview-mode={'0'}
                allowFullScreen
            />
        </div>
    )
}

Map.propTypes = {
    location: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
}

export default Map

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Slider from '@ant-design/react-slick'
import classnames from 'classnames'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import Price from '../../atoms/price'
import Button from '../../atoms/button/Button'
import { Product, Variant } from '../../../types/frontastic'
import tagManager from '../../../domain/TagManager'
import GtmService from '../../../services/gtm'
import OldTagManager from '../../../domain/OldTagManager'
import OldGtmService from '../../../services/oldGtm'
import ProductImage from '../../atoms/image/ProductImage'

import { ReactComponent as LogoAbsoluteRun } from '../../../../icons/sport2000-icons/logos/absoluterun.svg'
import { ReactComponent as LogoAbsoluteTeamsport } from '../../../../icons/sport2000-icons/logos/absoluteteamsport.svg'

export type Props = {
    intl: IntlShape
    product: Product
    position: number
    nameCategory: string
}

const ProductTile: React.FC<Props> = ({
    intl,
    product,
    position,
    nameCategory,
}: Props) => {
    const getActiveColorIndex = product.colors?.findIndex((color) => color.isActive)
    const getVariantsPrice = (inputProduct: Product) => new Set(inputProduct.variants.map((item: Variant) => item.price))
    const variant = product.variants[0]
    const productVariantsPrices = [...getVariantsPrice(product)]
    const colorVariant = product.colors || []
    const isDesktop = useDeviceType() === 'desktop'

    const [lowestPrice, setLowestPrice] = useState(Math.min(...productVariantsPrices))
    const [highestPrice, setHighestPrice] = useState(Math.max(...productVariantsPrices))
    const [imgVariant, setImgVariant] = useState('')
    const [isMouseOver, setIsMouseOver] = useState(false)
    const [activeColor, setActiveColor] = useState(0)
    const [productUrl, setProductUrl] = useState(product._url)

    const productAbsoluteRun = variant.attributes.att_internal_category_absolute_run
    const productAbsoluteTeamSport = variant.attributes.att_internal_category_absolute_teamsport

    useEffect(() => {
        setActiveColor(getActiveColorIndex)
    }, [getActiveColorIndex])

    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: colorVariant.length > 4,
                },
            },
            {
                breakpoint: 1624,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: colorVariant.length > 5,
                },
            },
        ],
    }

    const handleMouseOverVariant = (hoveredProduct: Product, index: number) => {
        setImgVariant(hoveredProduct.variants?.length && hoveredProduct.variants[0].images[0])
        setLowestPrice(Math.min(...getVariantsPrice(hoveredProduct)))
        setHighestPrice(Math.max(...getVariantsPrice(hoveredProduct)))
        setProductUrl(hoveredProduct._url)
        setActiveColor(index)
    }

    const handleMouseMove = () => {
        setIsMouseOver(!isMouseOver)
    }

    const renderColorSlider = () => (
        colorVariant.map((item: any, index: number) => {
            const imgUrl = item.product.variants?.length && item.product.variants[0].images[0]

            return (
                <div
                    key={item.product.productId}
                    className={classnames('product-teaser-item', {
                        active: activeColor === index,
                    })}
                >
                    <Button
                        type={'button'}
                        className={'btn p-1 w-full'}
                        ariaLabel={item.product.name}
                        onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            handleMouseOverVariant(item.product, index)
                        }}
                    >
                        {/* @ts-ignore */}
                        <ProductImage
                            title={item.product.name}
                            alt={item.product.name}
                            width={48}
                            height={48}
                            url={imgUrl || ''}
                        />
                    </Button>
                </div>
            )
        })
    )

    const renderPrice = () => {
        if (variant.discountedPrice) {
            return (
                <>
                    <Price variant={'text-red-600 text-xl font-bold'} value={variant.discountedPrice} />
                    {' '}
                    <s>
                        <Price variant={'mr-1 text-xs font-bold'} value={variant.price} />
                    </s>
                </>
            )
        }

        if (lowestPrice === highestPrice) {
            return <Price variant={'text-xl font-bold'} value={lowestPrice} />
        }

        return (
            <>
                <FormattedMessage id={'productTeaser.from'} />
                {' '}
                <Price variant={'text-xl font-bold'} value={lowestPrice} />
            </>
        )
    }

    const themeLogo = () => {
        if (productAbsoluteRun) {
            return (
                <div className={'product-teaser-logo-run'}>
                    <LogoAbsoluteRun width={isDesktop ? 120 : 90} height={24} />
                </div>
            )
        }

        if (productAbsoluteTeamSport) {
            return (
                <div className={'product-teaser-logo-teamsport'}>
                    <LogoAbsoluteTeamsport width={isDesktop ? 170 : 120} height={24} />
                </div>
            )
        }
    }

    const handleProductClickGTM = () => {
        const gtmService = new GtmService(null, product, position)
        const productGTM = gtmService.createProductGTM()
        // @ts-ignore
        tagManager.productClick(productGTM, nameCategory)

        // for universal analytics ( will be removed )
        const oldGtmService = new OldGtmService(null, product, position)
        const oldProductGTM = oldGtmService.createProductGTM()
        OldTagManager.productClick(oldProductGTM, nameCategory)
    }

    const renderColors = () => {
        if (isMouseOver && isDesktop) {
            return (
                <div className={'product-teaser--slider-wrapper'}>
                    <div className={'product-teaser--slider-variant'}>
                        <Slider {...sliderSettings}>
                            {renderColorSlider()}
                        </Slider>
                    </div>
                </div>
            )
        }
        return (
            <p className={'product-teaser--count'}>
                {product.colors?.length}
                {' '}
                <FormattedMessage
                    id={product.colors?.length === 1 ? 'productTeaser.color' : 'productTeaser.colors'}
                />
            </p>
        )
    }

    return (
        <article
            className={'product-teaser'}
            onMouseEnter={handleMouseMove}
            onMouseLeave={handleMouseMove}
        >
            <Link to={productUrl} onClick={handleProductClickGTM}>
                <div className={'product-teaser--visual-content'}>
                    <div className={'product-teaser-logo'}>
                        {themeLogo()}
                    </div>
                    {(imgVariant || variant.images[0])
                        ? (
                            <figure className={'product-teaser--photo'}>
                                {/* @ts-ignore */}
                                <ProductImage
                                    title={product.name}
                                    alt={product.name}
                                    width={350}
                                    height={350}
                                    url={imgVariant || variant.images[0] || ''}
                                />
                            </figure>
                        )
                        : (
                            <div className={'product-teaser--placeholder'} />
                        )}

                    <div className={'btn btn-default btn-add-to-cart'}>
                        <FormattedMessage id={'productTeaser.buttonIntoCart'} />
                    </div>
                </div>

                <h3 className={'product-teaser--name'}>{product.name}</h3>

                <div>
                    <p className={'product-teaser--price'}>
                        {renderPrice()}
                    </p>
                </div>

                {product.colors?.length > 0
                && (
                    <div className={'product-teaser--foot'}>
                        {renderColors()}
                    </div>
                )}
            </Link>
        </article>
    )
}

export default injectIntl(ProductTile)

import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import TermAndPrice from './TermAndPrice'
import Gender from './Gender'
import { FilterIcon, LoadingIcon } from '../../../atoms/icon'

const HorizontalFilter = ({
    facets,
    onChange,
    onOpen,
    isLoading,
    categoryGenders,
}) => {
    if (!facets || !facets.length) {
        return null
    }
    const maxHorizontalFacet = 6

    const handleChange = (facets) => {
        if (onChange) {
            onChange(facets)
        }
    }

    const handleOpen = () => {
        if (onOpen) {
            onOpen()
        }
    }

    return (
        <div className={'product-list-filter'}>
            <Gender categoryGenders={categoryGenders} />
            {facets.length > 0 && (
                <button
                    className={'product-list-filter--btn-open-all'}
                    aria-label={<FormattedMessage id={'filters.all'} />}
                    type={'button'}
                    onClick={handleOpen}
                >
                    <FormattedMessage
                        id={'filters.all'}
                    />
                    {isLoading ? <LoadingIcon width={20} height={20} fill={'var(--color-black-100)'} />
                        : <FilterIcon width={20} height={20} />}
                </button>
            )}
            <div className={'product-list-filter-term'}>
                <TermAndPrice
                    facetList={facets}
                    onChange={(facets) => handleChange(facets)}
                    numberFacets={5}
                />
                {facets.length > maxHorizontalFacet && (
                    <button
                        type={'button'}
                        aria-label={<FormattedMessage id={'filters.all'} />}
                        className={'btn-all-filter btn btn-secondary icon--right'}
                        onClick={handleOpen}
                    >
                        <FormattedMessage id={'filters.all'} />
                        {isLoading ? <LoadingIcon width={20} height={20} fill={'var(--color-black-100)'} />
                            : <FilterIcon width={20} height={20} />}
                    </button>
                )}
            </div>
        </div>
    )
}
HorizontalFilter.propTypes = {
    facets: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    categoryGenders: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default injectIntl(HorizontalFilter)

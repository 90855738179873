import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { SwiperSlide } from 'swiper/react'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import Slider from '../../../atoms/slider'
import Image from '../../../atoms/image'

const Brands = ({ brands }) => {
    const brandsArray = brands.filter((brand) => !!brand.logoUrl)
    const isMobile = useDeviceType() === 'mobile'

    if (!brandsArray || !brandsArray.length) {
        return null
    }

    return (
        <div className={'store-detail-brands'}>
            <h2 className={'store-detail-brands-headline mb-7 lg:mb-8'}>
                <FormattedMessage id={'storeDetail.brandHeadline'} />
            </h2>
            <div className={'store-detail-brands-wrap'}>
                <Slider
                    spaceBetween={16}
                    slidesPerView={2.5}
                    slidesPerGroup={2.5}
                    centerMode
                    breakpoints={{
                        768: {
                            slidesPerView: 5,
                            slidesPerGroup: 5,
                        },
                        1024: {
                            slidesPerView: 8,
                            slidesPerGroup: 8,
                        },
                    }}
                >
                    {brandsArray.map((brand, index) => (
                        <SwiperSlide key={brand.id + index}>
                            <div className={'store-detail-brand'}>
                                <Image
                                    alt={brand.name}
                                    className={'store-detail-brand-img'}
                                    title={brand.name}
                                    media={brand.logoUrl}
                                    height={isMobile ? 55 : 70}
                                    width={isMobile ? 75 : 90}
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Slider>
            </div>
        </div>
    )
}
Brands.propTypes = {
    brands: PropTypes.array.isRequired,
}

export default injectIntl(Brands)

import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'

import productConnector from '@frontastic/catwalk/src/js/tastic/product/connector'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import { CSSTransition } from 'react-transition-group'
import { useCookies } from 'react-cookie'
import ProductDetails from '../../patterns/organisms/ProductDetail/ProductDetails'
import GtmService from '../../services/gtm'
import TagManager from '../../domain/TagManager'
import OldGtmService from '../../services/oldGtm'
import OldTagManager from '../../domain/OldTagManager'
import CartService from '../../services/cart'
import StoreSidebar from '../../patterns/organisms/OffcanvasStoreFinder'
import useQueryStore from '../../services/hook/useQueryStore'
import StoreService from '../../services/algolia/StoreService'
import {useRouter} from 'next/router';

const ProductDetailsTastic = ({
    product, context, data, node,
}) => {
    if (!product) {
        return null
    }

    const apiKey = context.project.configuration.googleMap.mapKey
    const [colorProducts, setColorProducts] = useState(product.projectSpecificData.colors)
    const [cookie] = useCookies(['store'])
    const [isOpenStoreSidebar, setIsOpenStoreSidebar] = useState(false)
    const refererStore = useQueryStore(context)
    const themeName = useSelector((state) => state['theme-status'].themeName)
    const gtmCategoryName = node.name
    const gtmCategoryId = node.nodeType
    const router = useRouter();

    const activeProductColor = useMemo(() => {
        if (!colorProducts || colorProducts.length === 0) {
            return null
        }

        return colorProducts.find((item) => item.isActive)?.product
    }, [colorProducts])

    useEffect(() => {
        setIsOpenStoreSidebar(false)
    }, [cookie])

    const skuVariant = router.query.sku
    const variantOfActiveProductColor = activeProductColor?.variants
    const defaultIndexVariantHasStock = variantOfActiveProductColor?.findIndex((variant) => variant.projectSpecificData.stock > 0)
    const indexVariantFromCart = variantOfActiveProductColor?.findIndex((variant) => variant.sku === skuVariant && variant.projectSpecificData.stock > 0)
    const selectedIndex = indexVariantFromCart !== -1 ? indexVariantFromCart : defaultIndexVariantHasStock;

    const [selectedVariantIndex, setSelectedVariantIndex] = useState(selectedIndex)
    const getLowestPriceVariantIndex = (variants) => {
        let lowestPriceIndex = -1
        let lowestPrice = Infinity

        variants.forEach((variant, index) => {
            if (variant.projectSpecificData.stock >= 1 && variant.price < lowestPrice) {
                lowestPrice = variant.price
                lowestPriceIndex = index
            }
        })

        return lowestPriceIndex
    }
    const indexVariantFromStock = useMemo(() => getLowestPriceVariantIndex(variantOfActiveProductColor), [variantOfActiveProductColor])
    const selectedVariant = useMemo(() => variantOfActiveProductColor?.[selectedVariantIndex ?? indexVariantFromStock], [selectedVariantIndex, variantOfActiveProductColor, indexVariantFromStock])

    useEffect(() => {
        if (selectedVariant) {
            const gtmService = new GtmService(null, selectedVariant)
            const productGTM = gtmService.createProductGTM('Product Detail')
            TagManager.productDetailImpressions(productGTM)

            // for universal analytics ( will be removed )
            const oldGtmService = new OldGtmService(null, selectedVariant)
            const oldProductGTM = oldGtmService.createProductWithOutPositionGTM()
            OldTagManager.productDetailImpressions(oldProductGTM)
        }
    }, [selectedVariant])

    const dynamicTitle = (title) => {
        const productBrand = selectedVariant?.attributes?.att_prod_brand || ''
        return title.replace('[brand]', productBrand)
    }

    const {
        defaultRecommendationProps,
        brandRecommendationProps,
        alignment,
        renderBlackBackground,
        renderDottedBorders,
        renderAsSlider,
        swiperColor,
        headline,
        brandHeadline,
        graphCampaign,
        textColor,
        bgColor,
        positionMode,
        alignText,
        renderDefaultRecommendation,
        renderBrandRecommendation,
    } = data
    const nameCategory = selectedVariant?.attributes?.att_prod_brand || ''

    const productRecommendationData = renderDefaultRecommendation ? {
        renderBlackBackground,
        renderDottedBorders,
        renderAsSlider,
        swiperColor,
        alignment,
        nameCategory,
        context,
        headline: dynamicTitle(headline),
        recommendationProps: defaultRecommendationProps,
    } : null

    const productBrandRecommendationData = renderBrandRecommendation ? {
        renderBlackBackground,
        renderDottedBorders,
        renderAsSlider,
        swiperColor,
        alignment,
        nameCategory,
        context,
        headline: dynamicTitle(brandHeadline),
        recommendationProps: brandRecommendationProps,
    } : null

    const { algoliaIndexName } = context?.projectConfiguration || {}

    const toggleOffCanvasStoreFinder = () => {
        if (window?.google?.maps) {
            setIsOpenStoreSidebar(true)
            return
        }

        StoreService.loadGoogleScript(apiKey).then(() => {
            setIsOpenStoreSidebar(true)
        })
    }

    return (
        <div>
            <CSSTransition
                in={isOpenStoreSidebar}
                mountOnEnter
                timeout={500}
                classNames={'off-canvas-store'}
            >
                <StoreSidebar
                    context={context}
                    isOpen={isOpenStoreSidebar}
                    onClose={() => setIsOpenStoreSidebar(false)}
                    selectedVariant={selectedVariant}
                    themeName={themeName}
                />
            </CSSTransition>
            <ProductDetails
                productDefault={product}
                productColor={activeProductColor}
                setColorProducts={setColorProducts}
                colorProducts={colorProducts}
                handleAddToCart={CartService.addToCart}
                onOpenOffCanvasStoreFinder={toggleOffCanvasStoreFinder}
                setSelectedVariantIndex={setSelectedVariantIndex}
                selectedVariant={selectedVariant}
                indexVariantFromCart={indexVariantFromCart}
                productRecommendationData={productRecommendationData}
                productBrandRecommendationData={productBrandRecommendationData}
                algoliaIndexName={algoliaIndexName}
                graphCampaign={graphCampaign}
                textColor={textColor}
                bgColor={bgColor}
                positionMode={positionMode}
                alignText={alignText}
                refererStore={refererStore}
                gtmCategoryName={gtmCategoryName}
                gtmCategoryId={gtmCategoryId}
                context={context}
            />
        </div>
    )
}

ProductDetailsTastic.propTypes = {
    product: PropTypes.object,
    context: PropTypes.objectOf(PropTypes.any).isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    node: PropTypes.objectOf(PropTypes.any).isRequired,
}

ProductDetailsTastic.defaultProps = {}

export default tastify({
    translate: true,
    connect: {
        tastic: true, route: true, context: true, node: true,
    },
})(
    connect(productConnector)(ProductDetailsTastic),
)

import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
    FormattedMessage, WrappedComponentProps, injectIntl, IntlShape,
} from 'react-intl'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import classnames from 'classnames'
import app from '@frontastic/catwalk/src/js/app/app'
import FacebookLogin from 'react-facebook-login'
import GoogleLogin from 'react-google-login'
import ErrorMessage from '../../../atoms/errorMessage/index'
import ButtonWithLoader from '../../../atoms/button/WithLoader'
import Button from '../../../atoms/button/Button'
import IconButton from '../../../atoms/button/IconButton'
import { ArrowLIcon } from '../../../atoms/icon'
import { ReactComponent as IconGoogleRound } from '../../../../../icons/sport2000-icons/icon-google-round.svg'
import { ReactComponent as IconFacebookRound } from '../../../../../icons/sport2000-icons/icon-facebook-round.svg'
import { ReactComponent as EyeOpen } from '../../../../../icons/sport2000-icons/eye-open.svg'
import { ReactComponent as EyeClosed } from '../../../../../icons/sport2000-icons/eye-closed.svg'
import { LoginFormTypes } from './types'
import fetch from '@frontastic/bridge/js/fetch'

export type Props = {
    intl: IntlShape
    showRegisterForm: Function
    showForgottenPasswordForm: Function
    handleLogin: (email: string, password: string) => Promise<any>
    showLoader: boolean
    originIsCart?: boolean
    redirectRoute: string
} & WrappedComponentProps

const Login: React.FC<Props> = ({
    intl,
    showRegisterForm,
    showForgottenPasswordForm,
    handleLogin,
    showLoader,
    originIsCart,
    redirectRoute,
}: Props) => {
    const {
        register,
        handleSubmit,

        formState: {
            errors,
        },
    } = useForm()
    const requiredFieldMessage = intl.formatMessage({ id: 'validation.required' })
    const validEmailMessage = intl.formatMessage({ id: 'validation.email' })
    const isMobile = useDeviceType() === 'mobile'
    const [inputPasswordType, setInputPasswordType] = useState('password')

    const onSubmit: SubmitHandler<LoginFormTypes> = ({ email, password }) => {
        handleLogin(email.toLowerCase(), password)
    }

    const toggleInputPasswordType = () => {
        if (inputPasswordType === 'password') {
            return setInputPasswordType('text')
        }

        return setInputPasswordType('password')
    }

    const responseFacebook = async (response): Promise<any> => {
        await fetch('/api/facebook/login/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: response.userID,
                token: response.accessToken,
                email: response.email,
                firstName: response.first_name,
                lastName: response.last_name,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.loggedIn) {
                    app.getLoader('context').refresh()
                    app.getRouter().replace(redirectRoute)
                }
            })
    }

    const renderGoogleLoginButton = (renderProps) => (
        <IconButton
            disabled
            className={'account--login-btn-social btn btn-secondary icon--left w-full mb-3 lg:mb-4 xl:mb-0 lg:mr-2'}
            ariaLabel={intl.formatMessage({ id: 'account.login.loginWithGoogle' })}
            type={'button'}
            icon={<IconGoogleRound className={'no-overrides'} />}
            onClick={renderProps.onClick}
        >
            <FormattedMessage id={'account.login.loginWithGoogle'} />
        </IconButton>
    )

    const responseGoogle = async (response): Promise<any> => {
        if (typeof (response.profileObj) !== 'undefined') {
            await fetch('/api/google/login/', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    googleId: response.profileObj.googleId,
                    email: response.profileObj.email,
                    firstName: response.profileObj.givenName,
                    lastName: response.profileObj.familyName,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.loggedIn) {
                        app.getLoader('context').refresh()
                        app.getRouter().replace(redirectRoute)
                    }
                })
        }
    }

    return (
        <section className={classnames('account account--login grid grid-cols-12 gap-4 mt-6', {
            'md:mt-20': !originIsCart,
            'md:mt-16': originIsCart,
        })}
        >
            {!isMobile && originIsCart
                && (
                    <h2 className={'col-span-12 flex items-center'}>
                        <FormattedMessage id={'account.login.cartContext.headline'} />
                        <IconButton
                            type={'button'}
                            className={'btn btn-text icon--left read-more-link mb-2 ml-10'}
                            ariaLabel={intl.formatMessage({ id: 'account.login.cartContext.LinkBackToCart' })}
                            onClick={() => {
                                app.getRouter().push('Frontastic.Frontend.Master.Checkout.cart')
                            }}
                            icon={<ArrowLIcon className={'icon-arrow-l'} width={20} height={20} />}
                        >
                            <span className={'text'}>
                                <FormattedMessage id={'account.login.cartContext.LinkBackToCart'} />
                            </span>
                        </IconButton>
                    </h2>
                )}

            <div className={'col-span-12 lg:col-span-6 px-4 py-6 lg:p-8 bg-white'}>
                <h3 className={'mx-0 mb-8'}>
                    {originIsCart
                        ? <FormattedMessage id={'account.login.welcomeBack'} />
                        : <FormattedMessage id={'account.login.login'} />}
                </h3>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={classnames('mb-10 input--wrapper', {
                        'is--invalid': errors?.email,
                    })}
                    >
                        <input
                            id={'login-email'}
                            {...register('email', {
                                required: requiredFieldMessage,
                                pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: validEmailMessage,
                                },
                            })}
                            type={'email'}
                            className={'form-input'}
                            placeholder={intl.formatMessage({ id: 'account.form.emailLabel' })} />
                        <label htmlFor={'login-email'}>
                            <FormattedMessage id={'account.form.emailLabel'} />
                        </label>
                        <span className={'input--border'} />
                        {errors?.email
                            && (
                                <span className={'input--hint'}>
                                    <ErrorMessage
                                        errors={errors}
                                        name={'email'}
                                    />
                                </span>
                            )}
                    </div>

                    <div className={classnames('mb-5 input--wrapper', {
                        'is--invalid': errors?.password,
                    })}
                    >
                        <input
                            id={'login-password'}
                            {...register('password', {
                                required: requiredFieldMessage,
                            })}
                            type={inputPasswordType}
                            placeholder={intl.formatMessage({ id: 'account.form.passwordLabel' })}
                            className={'form-input'} />
                        <label htmlFor={'login-password'}>
                            <FormattedMessage id={'account.form.passwordLabel'} />
                        </label>
                        <Button
                            className={'account--password-reveal absolute p-4'}
                            ariaLabel={intl.formatMessage({ id: 'account.login.revealPassword' })}
                            onClick={() => toggleInputPasswordType()}
                        >
                            {inputPasswordType === 'password'
                                ? <EyeOpen width={'16'} height={'16'} />
                                : <EyeClosed width={'16'} height={'16'} />}
                        </Button>
                        <span className={'input--border'} />
                        {errors?.password
                            && (
                                <span className={'input--hint'}>
                                    <ErrorMessage
                                        errors={errors}
                                        name={'password'}
                                    />
                                </span>
                            )}
                    </div>

                    <Button
                        className={'btn btn-text read-more-link mb-6 lg:mb-8'}
                        ariaLabel={intl.formatMessage({ id: 'account.login.forgotPassword' })}
                        onClick={showForgottenPasswordForm}
                    >
                        <span className={'text'}>
                            <FormattedMessage id={'account.login.forgotPassword'} />
                        </span>
                    </Button>
                    <br />

                    <ButtonWithLoader
                        className={'btn btn-default w-full mb-3 lg:mb-4 flex'}
                        ariaLabel={intl.formatMessage({ id: 'account.login.login' })}
                        showLoader={showLoader}
                        type={'submit'}
                    >
                        <FormattedMessage id={'account.login.login'} />
                    </ButtonWithLoader>
                </form>

                <GoogleLogin
                    clientId={app.getApi().router.context.projectConfiguration.googleClientId}
                    buttonText={intl.formatMessage({ id: 'account.login.loginWithGoogle' })}
                    render={renderGoogleLoginButton}
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                />

                <FacebookLogin
                    isDisabled
                    appId={app.getApi().router.context.projectConfiguration.frontasticFacebookAppId}
                    autoLoad={false}
                    callback={responseFacebook}
                    version={app.getApi().router.context.projectConfiguration.frontasticFacebookApiVersion}
                    scope={app.getApi().router.context.projectConfiguration.frontasticFacebookScope}
                    fields={app.getApi().router.context.projectConfiguration.frontasticFacebookFields}
                    cssClass={'account--login-btn-social btn btn-secondary icon--left inline-flex w-full xl:ml-2'}
                    textButton={intl.formatMessage({ id: 'account.login.loginWithFacebook' })}
                    icon={<IconFacebookRound className={'no-overrides'} />}
                    lang={'de_DE'}
                />
            </div>

            <div className={'col-span-12 lg:col-span-6 self-start px-4 py-6 lg:p-8 bg-white'}>
                <h3 className={'mx-0 mb-8'}>
                    <FormattedMessage id={'account.login.newToSport2000'} />
                </h3>

                <Button
                    className={'btn btn-secondary w-full mb-3 lg:mb-4'}
                    ariaLabel={intl.formatMessage({ id: 'account.register.createAccount' })}
                    onClick={showRegisterForm}
                >
                    <FormattedMessage id={'account.register.createAccount'} />
                </Button>

                {originIsCart
                    && (
                        <Button
                            className={'btn btn-secondary w-full'}
                            ariaLabel={intl.formatMessage({ id: 'account.register.continueAsGuest' })}
                            onClick={() => {
                                app.getRouter().push('Frontastic.Frontend.Master.Checkout.checkout')
                            }}
                        >
                            <FormattedMessage id={'account.register.continueAsGuest'} />
                        </Button>
                    )}
            </div>

        </section>
    )
}

export default injectIntl(Login)

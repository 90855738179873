import React from 'react'
import PropTypes from 'prop-types'
import Teaser from '../../patterns/organisms/Teaser'

const TeaserTastic = ({
    data: {
        title,
        tagHeadline,
        teaserList,
        btnLinkText,
        btnVariant,
        btnColorDefault,
        btnColorHover,
        isDarkmode,
        isSlider,
        isDotted,
        bgcolor,
        swiperColor,
    },
}) => (
    <Teaser
        swiperColor={swiperColor}
        title={title}
        tagHeadline={tagHeadline}
        teaserList={teaserList}
        btnLinkText={btnLinkText}
        btnVariant={btnVariant}
        btnColorDefault={btnColorDefault}
        btnColorHover={btnColorHover}
        isDarkmode={isDarkmode}
        isSlider={isSlider}
        isDotted={isDotted}
        bgcolor={bgcolor}
    />
)

TeaserTastic.propTypes = {
    data: PropTypes.object.isRequired,
}

export default TeaserTastic

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import classnames from 'classnames'
import debounce from '@frontastic/common/src/js/helper/debounce'
import FullPageWidthWrapper from '../../../molecules/Layout/FullPageWidthWrapper'
import IconButton from '../../../atoms/button/IconButton'
import { ReactComponent as ArrowDown } from '../../../../../icons/sport2000-icons/arrow-down.svg'
import { ReactComponent as ArrowUp } from '../../../../../icons/sport2000-icons/arrow-up.svg'

const BlogFullDescription = ({ intl, content }) => {
    if (!content || !content.content_brand_full) {
        return null
    }

    const isMobile = useDeviceType() === 'mobile'

    const [showMoreStatus, setShowMoreStatus] = useState(true)

    const showMore = () => {
        const element = document.getElementById('blog-full-content-box')

        if (isMobile && element) {
            element.classList.toggle('blog-full-desc-content-mobile-show')
            setShowMoreStatus(!showMoreStatus)
        }
    }

    const showLessByDefault = () => {
        setShowMoreStatus(true)
    }

    useEffect(() => {
        window.addEventListener('resize', debounce(() => showLessByDefault(), 50), { passive: true })

        return window.removeEventListener('resize', debounce(() => showLessByDefault(), 50))
    }, [])

    return (
        <FullPageWidthWrapper
            customClasses={'blog-full-desc'}
            withWrapper
        >
            <div className={'blog-full-desc-content'} id={'blog-full'}>
                <div
                    className={classnames('blog-full-desc-content-box', {
                        'blog-full-desc-content-mobile': isMobile,
                    })}
                    id={'blog-full-content-box'}
                >
                    {parse(content.content_brand_full.html)}
                </div>

                {isMobile && (
                    <IconButton
                        className={'btn btn-link blog-full-desc-content-button'}
                        icon={(
                            <>
                                {showMoreStatus ? (
                                    <ArrowDown width={16} height={16} />
                                ) : (
                                    <ArrowUp width={16} height={16} />
                                )}
                            </>
                        )}
                        type={'button'}
                        ariaLabel={intl.formatMessage({ id: 'brandDetail.showMore' })}
                        onClick={() => showMore()}
                    >
                        <>
                            {showMoreStatus ? (
                                <FormattedMessage id={'brandDetail.showMore'} />
                            ) : (
                                <FormattedMessage id={'brandDetail.showLess'} />
                            )}
                        </>

                    </IconButton>
                )}
            </div>
        </FullPageWidthWrapper>
    )
}

BlogFullDescription.propTypes = {
    intl: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
}

export default injectIntl(BlogFullDescription)

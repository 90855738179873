import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import Error from '@frontastic/theme-boost/src/js/patterns/organisms/Account/AccountWishlist/error'
import { Cookies, withCookies } from 'react-cookie'
import { Configure, InstantSearch } from 'react-instantsearch-dom'
import EmptyWishlist from './EmptyWishlist'
import Hits from '../../Algolia/ProductTile/Hits'
import ClientFactory from '../../../../services/algolia/ClientFactory'
import {useSelector} from 'react-redux';

const AccountWishlist = ({
    wishlist, returnToHomePage, node,
}) => {
    const context = useSelector((state) => state.app.context || {})
    const client = ClientFactory.getSearchClient(context)
    const { indexName } = context.project.configuration.algolia.languages[context.locale]

    const [, setWishlistChanging] = useState(false)
    const [wishlistItems, setWishlistItems] = useState([])

    const gtmCategoryName = node.name
    const gtmCategoryId = node.nodeType

    useEffect(() => {
        if (wishlist.data && wishlist.data.lineItems) {
            setWishlistItems(wishlist.data.lineItems)
            setWishlistChanging(true)
        } else {
            setWishlistChanging(false)
        }
    }, [wishlist])

    const getSkuWishlist = wishlistItems.map((item) => item.variant.sku)
    const objectID = ClientFactory.getObjectIdWishlist(getSkuWishlist)

    const renderHTML = () => {
        if (!wishlistItems.length) {
            return null
        }

        return (
            <InstantSearch
                searchClient={client}
                indexName={indexName}
            >
                <Configure
                    facetFilters={[
                        objectID,
                    ]}
                    userToken={context.session.account.accountId}
                />
                <div className={'product--tiles--algolia'}>
                    <Hits
                        context={context}
                        gtmCategoryName={gtmCategoryName}
                        gtmCategoryId={gtmCategoryId}
                        renderAsSlider={false}
                        node={node}
                    />
                </div>
            </InstantSearch>
        )
    }

    return (
        <div className={'account-wishlist'}>
            <div className={'wishlist-detail-menu mt-6 mb-4 md:mb-8 lg:mt-8'}>
                <h1 className={'wishlist-detail-menu-headline'}>
                    <FormattedMessage id={'wishlist.headlineWishlistDetail'} />
                </h1>
                <div className={'wishlist-detail-menu-count'}>
                    {wishlistItems.length === 1 ? (
                        <FormattedMessage id={'wishlist.product'} />
                    ) : (
                        <FormattedMessage
                            id={'wishlist.products'}
                            values={{ count: wishlistItems.length }}
                        />
                    )}
                </div>
            </div>

            {wishlist.error && <Error returnToHomePage={returnToHomePage} />}

            {wishlist.isComplete() && wishlistItems.length === 0 && (
                <EmptyWishlist returnToHomePage={returnToHomePage} />
            )}

            <div className={'wishlist-detail-content'}>
                {renderHTML()}
            </div>

        </div>
    )
}

AccountWishlist.propTypes = {
    wishlist: PropTypes.object.isRequired,
    returnToHomePage: PropTypes.func.isRequired,
    handleAddToCart: PropTypes.func.isRequired,
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    context: PropTypes.object.isRequired,
    node: PropTypes.object.isRequired,
}

export default withCookies(injectIntl(AccountWishlist))

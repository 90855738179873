import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'

function AdventCalendar({
    data: {
        campaignUrl,
    },
    tastic,
}) {
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        setIsLoaded(true)
    }, [])

    return (
        <>
            {isLoaded && (
                <Helmet>
                    <script
                        src={'https://www.happymo.re/resources/redcode/js/campaign-embedding.js'}
                        data-campaign-url={campaignUrl}
                        data-embedding-target={`#redcode-special-embed-${tastic?.tasticId || ''}`}
                        data-script-id={'redcode-campaign-embedder'}
                    />
                </Helmet>
            )}
            <div id={`redcode-special-embed-${tastic?.tasticId || ''}`} />
        </>
    )
}

AdventCalendar.propTypes = {
    data: PropTypes.object.isRequired,
    tastic: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default tastify({
    connect: {
        tastic: true,
    },
})(AdventCalendar)

import { sha256 } from 'js-sha256'

class CriteoManager {
    constructor(initCriteo = 'criteo_q') {
        this.initCriteo = initCriteo
    }

    sendEmail = (email) => {
        const sha256Email = sha256(email)
        this.push(sha256Email)
    }

    push = (email) => {
        const criteo = this.getCriteoLayer()
        if (!criteo) {
            return
        }
        const deviceType = /iPad/.test(navigator.userAgent) ? 't' : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? 'm' : 'd'
        criteo.push(
            { event: 'setAccount', account: 103969 },
            { event: 'setEmail', email, hash_method: 'sha256' },
            { event: 'setSiteType', type: deviceType },
            { event: 'viewHome' },
        )
    }

    getCriteoLayer = () => {
        if (!window || !window[this.initCriteo]) {
            return null
        }

        return window[this.initCriteo]
    }
}

export default new CriteoManager()

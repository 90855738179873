class Webgains {
    constructor(identifier = 'ITCVRQ') {
        this.identifier = identifier
    }

    getProjectSchema(data) {
        let lineItem = ''
        data.stream.lineItems.forEach((item) => {
            const lineItemData = `1080645::${item.projectSpecificData.taxedPrice.totalNet / 100}::${item.name}::${item.lineItemId}::|`
            lineItem += lineItemData
        })
        return lineItem.slice(0, -1)
    }

    sendDataToTrack(data) {
        const identifier = this.getIdentifier()
        if (!identifier) {
            return
        }

        identifier('set', 'trk.programId', 291605)
        identifier('set', 'cvr', {
            value: data.stream.taxed.amount / 100,
            currency: data.stream.currency,
            language: 'de_DE',
            eventId: 1080645,
            orderReference: data.stream.orderId,
            comment: '',
            multiple: '',
            checksum: '',
            items: this.getProjectSchema(data),
            customerId: '',
            location: 'https://sport2000.de',
            voucherId: '',
        })
        identifier('conversion')
    }

    getIdentifier() {
        if (!window || !window[this.identifier]) {
            return null
        }

        return window[this.identifier]
    }
}

export default new Webgains()

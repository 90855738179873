import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import Message from '@frontastic/catwalk/src/js/app/message'
import classnames from 'classnames'
import Products from './Info/Products'
import CheckoutSummary from '../Forms/CheckoutSummary'
import { ReactComponent as IconChevronDown } from '../../../../icons/sport2000-icons/arrow-left.svg'
import IconButton from '../../atoms/button/IconButton'
import PaymentService from '../../../services/payment'

const OverviewPanel = ({
    app,
    intl,
    data,
    policy,
    paymentInfo,
    active,
    setActive,
    validNextStep,
    validNextStep2,
    paymentMethods,
    adyenComponentRef,
    paymentIdRef,
    buttonConfirmDisabled,
    setButtonConfirmDisabled,
    contextSession,
}) => {
    const [checked, setChecked] = useState(false)
    const buttonRef = useRef(null)

    const onChangeCollapseContent = () => {
        if (validNextStep && validNextStep2) {
            setActive(!active)
        }
    }

    const handleAdyenResult = useCallback((paymentId, action, resultCode) => {
        if (action) {
            paymentIdRef.current = paymentId
            // convert new version response to old version
            if (action.type == 'threeDS2') {
                if (action.subtype == 'challenge') {
                    action.type = 'threeDS2Challenge'
                }
                if (action.subtype == 'fingerprint') {
                    action.type = 'threeDS2Fingerprint'
                }
            }

            switch (action.type) {
                case 'redirect':
                    switch (action.method) {
                        case 'GET':
                            window.location = action.url
                            return
                        case 'POST':
                            const form = document.createElement('form')
                            form.method = 'POST'
                            form.action = action.url
                            Object.entries(action.data).forEach(([key, value]) => {
                                const input = document.createElement('input')
                                input.type = 'hidden'
                                input.name = key
                                input.value = value
                                form.appendChild(input)
                            })
                            document.body.appendChild(form)
                            form.submit()
                            return
                        default:
                            throw { message: `Unknown redirect method ${action.method}` }
                    }
                case 'voucher':
                    throw { message: 'Voucher action not yet supported' }

                case 'threeDS2Challenge':

                    // initial div contains form password
                    const paymentContainer = document.getElementById('adyen-challenge-container')
                    paymentContainer.innerHTML = ''
                    const paymentFormElement = document.createElement('div')
                    paymentFormElement.setAttribute('id', 'adyen-challenge-inner')
                    paymentContainer.appendChild(paymentFormElement)

                    const threeDSConfiguration = {
                        challengeWindowSize: '02',
                    }

                    const configurationChallengeShopper = {
                        ...paymentMethods.configuration,
                        onAdditionalDetails: (state) => {
                            PaymentService.updateAdyenPayment(paymentIdRef.current, state.data.details, state.data.paymentData)
                                .then((body) => {
                                    handleAdyenResult(body.paymentId, body.action, body.resultCode)
                                })
                                .catch((error) => {
                                    app.getLoader('context').notifyUser(<Message {...error} />, 'error')
                                    setButtonConfirmDisabled(false)
                                })
                        },
                    }
                    const adyenCheckoutChallengeShopper = new AdyenCheckout(configurationChallengeShopper)
                    adyenCheckoutChallengeShopper.createFromAction(action, threeDSConfiguration).mount(paymentFormElement)

                    return

                case 'threeDS2Fingerprint':
                    PaymentService.fingerprintAdyenPayment(paymentIdRef.current, action.paymentData)
                        .then((body) => {
                            handleAdyenResult(body.paymentId, body.action, body.resultCode)
                        })
                        .catch((error) => {
                            app.getLoader('context').notifyUser(<Message {...error} />, 'error')
                            setButtonConfirmDisabled(false)
                        })
                    return

                default:
                    if (adyenComponentRef && adyenComponentRef.current) {
                        adyenComponentRef.current.handleAction(action)
                    }
                    return
            }
        }

        switch (resultCode) {
            case 'Authorised':
            case 'Received':
                app.getApi().request(
                    'POST',
                    'Frontastic.CartApi.Cart.checkout',
                    { ownErrorHandler: true },
                    null,
                    (data) => {
                        const url = `/checkout/finished?order=${data.order.orderId}`
                        window.location.href = url
                        return data
                    },
                    (error) => {
                        // app.getLoader('context').notifyUser(<Message
                        //     message={intl.formatMessage({ id: 'order.orderFailed' })}
                        // />, 'error')
                        PaymentService.cancelAdyenPayment(paymentId)
                            .then(() => {
                                window.location.href = '/checkout/cart'
                            })
                            .catch((error) => {
                                app.getLoader('context').notifyUser(<Message {...error} />, 'error')
                                setButtonConfirmDisabled(false)
                            })
                    },
                )
                break
            default:
                app.getLoader('context').notifyUser(<Message message={resultCode} />, 'error')
                setButtonConfirmDisabled(false)
        }
    })

    const makePayment = useCallback((paymentInfo) => {
        if (paymentInfo === null) {
            return
        }

        const { paymentMethod, browserInfo } = paymentInfo
        setButtonConfirmDisabled(true)

        if (PaymentService.isPayever(paymentInfo.paymentMethod.provider)) {
            PaymentService.createPayeverPayment(paymentMethod)
                .then((body) => {
                    if (body.redirectUrl) {
                        window.location = body.redirectUrl
                    } else {
                        app.getLoader('context').notifyUser(<Message
                            message={intl.formatMessage({ id: 'order.paymentFailed' })}
                        />, 'error')
                        setButtonConfirmDisabled(false)
                    }
                })
                .catch((error) => {
                    app.getLoader('context').notifyUser(<Message
                        message={intl.formatMessage({ id: 'order.paymentFailed' })}
                    />, 'error')
                    setButtonConfirmDisabled(false)
                })
            return
        }

        PaymentService.createAdyenPayment(paymentMethod, browserInfo)
            .then((body) => {
                handleAdyenResult(body.paymentId, body.action, body.resultCode)
            })
            .catch((error) => {
                app.getLoader('context').notifyUser(<Message
                    message={intl.formatMessage({ id: 'order.paymentFailed' })}
                />, 'error')
                setButtonConfirmDisabled(false)
            })
    })

    useEffect(() => {
        if (paymentInfo === null) {
            return
        }
        const paymentMethodType = paymentInfo.paymentMethod.type
        if (paymentMethods === null || paymentMethodType == null) {
            return
        }

        if (PaymentService.isPayever(paymentInfo.paymentMethod.provider)) {
            return
        }

        if (paymentMethodType === 'scheme' || paymentMethodType === 'applepay' || paymentMethodType === 'googlepay') {
            return
        }

        const configuration = {
            ...paymentMethods.configuration,
            showPayButton: false,
            onChange: (state) => {
                setPaymentDetailsValid(state.isValid)
                setPaymentDetails(state.data)
            },
            onSubmit: (state) => {
                makePayment(paymentInfo)
            },
            onAdditionalDetails: (state) => {
                PaymentService.updateAdyenPayment(paymentIdRef.current, state.data.details, state.data.paymentData)
                    .then((body) => {
                        handleAdyenResult(body.paymentId, body.action, body.resultCode)
                    })
                    .catch((error) => {
                        app.getLoader('context').notifyUser(<Message {...error} />, 'error')
                    })
            },
        }

        const adyenCheckout = new AdyenCheckout(configuration)
        if (adyenComponentRef && adyenComponentRef.current && buttonRef && buttonRef.current) {
            adyenComponentRef.current = adyenCheckout.create(paymentMethodType)
            adyenComponentRef.current.mount(buttonRef.current)
        }
    }, [paymentInfo, paymentMethods]) // eslint-disable-line react-hooks/exhaustive-deps

    const productIDs = data.lineItems.map((item) => item.variant.sku)
    const { algoliaIndexName } = contextSession?.projectConfiguration || {}

    return (
        <div className={classnames('confirm-checkout-wrapper overview-checkout-wrapper bg-white', {
            'is--active': active,
        })}
        >
            <IconButton
                type={'button'}
                ariaLabel={intl.formatMessage({ id: 'checkout.overviewPage' })}
                className={'btn btn-text icon--right delivery-title checkout-title mb-0 w-full justify-between overflow-visible'}
                onClick={onChangeCollapseContent}
                icon={<IconChevronDown width={'16'} height={'16'} className={'order-2'} />}
            >
                <FormattedMessage id={'checkout.overviewPage'} />
            </IconButton>
            <div className={`shipping-checkout-content mt-3 lg:mt-6 ${active ? 'block' : 'hidden'}`}>

                {algoliaIndexName && (
                    <div className={'product-checkout-overview'} data-insights-index={algoliaIndexName}>
                        <Products products={data.lineItems} />
                    </div>
                )}

                <CheckoutSummary
                    buttonLabel={intl.formatMessage({ id: 'checkout.nextOverview' })}
                    vouchersLabel={policy}
                    onClick={() => {
                        const directPaymentMethods = ['paypal', 'scheme', 'applepay', 'googlepay']
                        if (!directPaymentMethods.includes(paymentInfo.paymentMethod.type)
                            && PaymentService.isAdyen(paymentInfo.paymentMethod.provider) && adyenComponentRef && adyenComponentRef.current) {
                            adyenComponentRef.current.submit()
                        } else {
                            makePayment(paymentInfo)
                        }
                    }}
                    ref={buttonRef}
                    disabled={buttonConfirmDisabled}
                    paymentInfo={paymentInfo}
                />

                <div id={'adyen-challenge-container'} className={'summary-checkout-wrapper'} />
            </div>
        </div>
    )
}

OverviewPanel.propTypes = {
    app: PropTypes.object.isRequired,
    contextSession: PropTypes.object,
    products: PropTypes.array.isRequired,
    paymentInfo: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    setPaymentInfo: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    goToNextPanel: PropTypes.func.isRequired,
    setActive: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
    validNextStep: PropTypes.bool.isRequired,
    validNextStep2: PropTypes.bool.isRequired,
    policy: PropTypes.string,
    paymentMethods: PropTypes.object.isRequired,
    adyenComponentRef: PropTypes.any,
    paymentIdRef: PropTypes.any,
    buttonConfirmDisabled: PropTypes.bool,
    setButtonConfirmDisabled: PropTypes.func,
}

export default injectIntl(OverviewPanel)

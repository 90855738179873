import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useForm } from 'react-hook-form'

import Select from 'react-select'
import _ from 'lodash'
import ButtonWithLoader from '../../atoms/button/WithLoader'
import ErrorMessage from '../../atoms/errorMessage'
import { patternValueWithoutBlank } from '../../../services/validate'

const DEFAULT_CODE = 'DE'

const AddressForm = ({
    intl, countries, defaultValues = {}, onSubmit, showLoader,
}) => {
    const requiredField = intl.formatMessage({ id: 'validation.required' })
    const validPostalCode = intl.formatMessage({ id: 'validation.postalCode' })

    const [selectedCountry, setSelectedCountry] = useState(_.isEmpty(defaultValues) ? DEFAULT_CODE : defaultValues.country)
    const [disableShippingDefault, setDisableShippingDefault] = useState(_.isEmpty(defaultValues) ? false : defaultValues.country !== DEFAULT_CODE)
    const [checkedShippingDefault, setCheckedShippingDefault] = useState(_.isEmpty(defaultValues) ? false : defaultValues.isDefaultShippingAddress)
    const [disabledSelectCountry] = useState(_.isEmpty(defaultValues) ? false : defaultValues.isDefaultShippingAddress)
    const [isDisabledSubmitButton, setIsDisabledSubmitButton] = useState(false)

    const {
        register,
        getValues,
        handleSubmit,
        setValue,
        clearError,
        errors,
        formState,
    } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues || {},
    })

    const options = countries.map((value) => ({
        value: value.code,
        label: value.name,
    }))

    const onFormSubmit = () => {
        onSubmit(getValues(), selectedCountry)
    }

    const handleChange = (value) => {
        setValue('postalCode', '')
        clearError('postalCode', '')

        setSelectedCountry(value.value)

        if (value.value !== DEFAULT_CODE) {
            setDisableShippingDefault(true)
            setCheckedShippingDefault(false)

            return
        }

        setDisableShippingDefault(false)
    }

    const getSelectedCountry = () => {
        if (!_.isEmpty(defaultValues)) {
            const selectedValue = {}
            countries.map((item) => {
                if (item.code === defaultValues.country) {
                    selectedValue.value = item.code
                    selectedValue.label = item.name
                }
            })

            if (_.isEmpty(selectedValue)) {
                return {
                    value: countries[0].code,
                    label: countries[0].name,
                }
            }

            return selectedValue
        }

        return {
            value: countries[0].code,
            label: countries[0].name,
        }
    }

    useEffect(() => {
        setIsDisabledSubmitButton(!formState?.isValid)
    }, [formState.isValid, setIsDisabledSubmitButton])

    return (
        <form className={'w-11/12 mx-4 my-8 md:my-6s'} onSubmit={handleSubmit(onFormSubmit)}>
            <input type={'hidden'} {...register('addressId')} />

            <div className={'md:grid-cols-2 md:gap-6 md:grid'}>
                <div className={classnames('mb-8 input--wrapper', {
                    'is--invalid': errors?.firstName,
                })}
                >
                    <input
                        id={'address-firstName'}
                        {...register('firstName', {
                            required: requiredField,
                            pattern: {
                                value: patternValueWithoutBlank,
                                message: requiredField,
                            },
                        })}
                        type={'text'}
                        placeholder={`${intl.formatMessage({ id: 'checkout.form.firstName' })}*`} />
                    <label htmlFor={'address-firstName'}>
                        <FormattedMessage id={'checkout.form.firstName'} />
                    </label>
                    <span className={'input--border'} />
                    {errors?.firstName
                        && (
                            <span className={'input--hint'}>
                                <ErrorMessage errors={errors} name={'firstName'} />
                            </span>
                        )}
                </div>

                <div className={classnames('mb-8 input--wrapper', {
                    'is--invalid': errors?.lastName,
                })}
                >
                    <input
                        id={'address-lastName'}
                        {...register('lastName', {
                            required: requiredField,
                            pattern: {
                                value: patternValueWithoutBlank,
                                message: requiredField,
                            },
                        })}
                        type={'text'}
                        placeholder={`${intl.formatMessage({ id: 'checkout.form.lastName' })}*`} />
                    <label htmlFor={'address-lastName'}>
                        <FormattedMessage id={'checkout.form.lastName'} />
                    </label>
                    <span className={'input--border'} />
                    {errors?.lastName
                        && (
                            <span className={'input--hint'}>
                                <ErrorMessage errors={errors} name={'lastName'} />
                            </span>
                        )}
                </div>
            </div>

            <div className={classnames('mb-8 input--wrapper', {
                'is--invalid': errors?.phone,
            })}
            >
                <input
                    id={'address-phone'}
                    {...register('phone')}
                    type={'tel'}
                    placeholder={`${intl.formatMessage({ id: 'checkout.form.phone' })}*`} />
                <label htmlFor={'address-phone'}>
                    <FormattedMessage id={'checkout.form.phone'} />
                </label>
                <span className={'input--border'} />
                {errors?.phone
                    && (
                        <span className={'input--hint'}>
                            <ErrorMessage errors={errors} name={'phone'} />
                        </span>
                    )}
            </div>

            <div className={'md:grid-cols-2 md:gap-6 md:grid'}>
                <div className={classnames('mb-8 input--wrapper', {
                    'is--invalid': errors?.streetName,
                })}
                >
                    <input
                        id={'address-streetName'}
                        {...register('streetName', {
                            required: requiredField,
                            pattern: {
                                value: patternValueWithoutBlank,
                                message: requiredField,
                            },
                        })}
                        type={'text'}
                        placeholder={`${intl.formatMessage({ id: 'checkout.form.address' })}*`} />
                    <label htmlFor={'address-streetName'}>
                        <FormattedMessage id={'checkout.form.address'} />
                    </label>
                    <span className={'input--border'} />
                    {errors?.streetName
                        && (
                            <span className={'input--hint'}>
                                <ErrorMessage errors={errors} name={'streetName'} />
                            </span>
                        )}
                </div>

                <div className={classnames('mb-8 input--wrapper', {
                    'is--invalid': errors?.streetNumber,
                })}
                >
                    <input
                        id={'address-streetNumber'}
                        {...register('streetNumber', {
                            required: requiredField,
                            pattern: {
                                value: patternValueWithoutBlank,
                                message: requiredField,
                            },
                        })}
                        type={'text'}
                        placeholder={`${intl.formatMessage({ id: 'checkout.form.streetNumber' })}*`} />
                    <label htmlFor={'address-streetNumber'}>
                        <FormattedMessage id={'checkout.form.streetNumber'} />
                    </label>
                    <span className={'input--border'} />
                    {errors?.streetNumber
                        && (
                            <span className={'input--hint'}>
                                <ErrorMessage errors={errors} name={'streetNumber'} />
                            </span>
                        )}
                </div>
            </div>

            <div className={'md:grid-cols-2 md:gap-6 md:grid'}>
                <div className={classnames('mb-8 input--wrapper', {
                    'is--invalid': errors?.postalCode,
                })}
                >
                    {selectedCountry === DEFAULT_CODE ? (
                        <>
                            <input
                                id={'address-postalCode-de'}
                                {...register('postalCode', {
                                    required: requiredField,
                                    pattern: {
                                        value: /^[0-9]{5}$/,
                                        message: validPostalCode,
                                    },
                                })}
                                type={'number'}
                                placeholder={`${intl.formatMessage({ id: 'checkout.form.zipCode' })}*`} />
                            <label htmlFor={'address-postalCode-de'}>
                                <FormattedMessage id={'checkout.form.zipCode'} />
                            </label>
                            <span className={'input--border'} />
                            {errors?.postalCode
                                && (
                                    <span className={'input--hint'}>
                                        <ErrorMessage errors={errors} name={'postalCode'} />
                                    </span>
                                )}
                        </>
                    ) : (
                        <>
                            <input
                                id={'address-postalCode'}
                                {...register('postalCode', {
                                    required: requiredField,
                                    pattern: {
                                        value: /^(?!\s*$).+/,
                                    },
                                })}
                                type={'text'}
                                placeholder={`${intl.formatMessage({ id: 'checkout.form.zipCode' })}*`} />
                            <label htmlFor={'address-postalCode'}>
                                <FormattedMessage id={'checkout.form.zipCode'} />
                            </label>
                            <span className={'input--border'} />
                            {errors?.postalCode
                                && (
                                    <span className={'input--hint'}>
                                        <ErrorMessage errors={errors} name={'postalCode'} />
                                    </span>
                                )}
                        </>
                    )}
                </div>

                <div className={classnames('mb-8 input--wrapper', {
                    'is--invalid': errors?.city,
                })}
                >
                    <input
                        id={'address-city'}
                        {...register('city', {
                            required: requiredField,
                            pattern: {
                                value: patternValueWithoutBlank,
                                message: requiredField,
                            },
                        })}
                        type={'text'}
                        placeholder={`${intl.formatMessage({ id: 'checkout.form.city' })}*`} />
                    <label htmlFor={'address-city'}>
                        <FormattedMessage id={'checkout.form.city'} />
                    </label>
                    <span className={'input--border'} />
                    {errors?.city
                        && (
                            <span className={'input--hint'}>
                                <ErrorMessage errors={errors} name={'city'} />
                            </span>
                        )}
                </div>
            </div>

            <div className={classnames('mb-8 input--wrapper')}>
                <input
                    id={'billing-companyName'}
                    {...register('additionalAddressInfo')}
                    type={'text'}
                    placeholder={`${intl.formatMessage({ id: 'checkout.form.companyName' })}`} />
                <label htmlFor={'billing-companyName'}>
                    <FormattedMessage id={'checkout.form.companyName'} />
                </label>
                <span className={'input--border'} />
            </div>

            <div className={classnames('mb-8 select--wrapper', {
                'is--invalid': errors?.country,
            })}
            >
                <Select
                    isDisabled={disabledSelectCountry}
                    defaultValue={getSelectedCountry}
                    options={options}
                    placeholder={'Country'}
                    id={'address-country'}
                    {...register('country', { required: requiredField })}
                    className={'select-container'}
                    classNamePrefix={'select'}
                    onChange={(value) => handleChange(value)} />
                <span className={'input--border'} />
                {errors?.country
                    && (
                        <span className={'input--hint'}>
                            <ErrorMessage errors={errors} name={'country'} />
                        </span>
                    )}
            </div>

            <div className={'mb-6 flex items-center checkbox-radio--wrapper'}>
                <input
                    id={'shipping-default'}
                    type={'checkbox'}
                    {...register('isDefaultShippingAddress')}
                    disabled={disableShippingDefault}
                    checked={checkedShippingDefault}
                    onClick={() => setCheckedShippingDefault(!checkedShippingDefault)} />

                <label className={'text-sm text-neutral-900 leading-tight'} htmlFor={'shipping-default'}>
                    <FormattedMessage id={'account.address.standardShipping'} />
                </label>
            </div>

            <div className={'flex items-center checkbox-radio--wrapper'}>
                <input
                    id={'billing-default'}
                    type={'checkbox'}
                    {...register('isDefaultBillingAddress')} />

                <label className={'text-sm text-neutral-900 leading-tight'} htmlFor={'billing-default'}>
                    <FormattedMessage id={'account.address.standardBilling'} />
                </label>
            </div>

            <div className={'text-center mt-8'}>
                <ButtonWithLoader
                    className={'btn btn-default account--save-button w-auto'}
                    showLoader={showLoader}
                    type={'submit'}
                    disabled={isDisabledSubmitButton}
                    ariaLabel={intl.formatMessage({ id: 'account.save' })}
                >
                    <FormattedMessage id={'account.save'} />
                </ButtonWithLoader>
            </div>
        </form>
    )
}

AddressForm.propTypes = {
    intl: PropTypes.object.isRequired,
    countries: PropTypes.array.isRequired,
    defaultValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    showLoader: PropTypes.bool.isRequired,
}

export default injectIntl(AddressForm)

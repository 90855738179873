import React, { useState } from 'react'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import classnames from 'classnames'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
    FormattedHTMLMessage, FormattedMessage, WrappedComponentProps, injectIntl, IntlShape,
} from 'react-intl'
import app from '@frontastic/catwalk/src/js/app/app'
import Message from '@frontastic/catwalk/src/js/app/message'
import { useSelector } from 'react-redux'
import { ConfigurationSchema } from '@frontastic/common/src/js/frontastic-common'
import ErrorMessage from '../../patterns/atoms/errorMessage'
import { ContactFormTypes } from './types'
import ButtonWithLoader from '../../patterns/atoms/button/WithLoader'
import FullPageWidthWrapper from '../../patterns/molecules/Layout/FullPageWidthWrapper'
import BorderOnTopAndBottom from '../../patterns/atoms/dotted/BorderOnTopAndBottom'
import ContactService from '../../services/contact'
import SubscribeService from '../../services/subscribe'
import NewsletterForm from '../../patterns/organisms/Forms/NewsletterForm'
import { patternValueWithoutBlank } from '../../services/validate'

export type Props = {
    data: {
        renderBlackBackground: boolean
        renderDottedBorders: boolean
        renderNewsletterRegistrationCheckbox: boolean
    }
    intl: IntlShape
} & WrappedComponentProps

const ContactForm: React.FC<Props> = ({ data, intl }: Props) => {
    const {
        register,
        handleSubmit,

        formState: {
            errors,
        },
    } = useForm()

    const context = useSelector((globalState: any) => globalState.app && globalState.app.context)
    const projectSchema = new ConfigurationSchema(
        context.projectConfigurationSchema,
        context.projectConfiguration,
    )

    const { requireTopicValue, checkAllTopicValue } = projectSchema.configuration

    const topicRequired = requireTopicValue.replace(/\s/g, '').split(',')

    const requiredFieldMessage = intl.formatMessage({ id: 'validation.required' })
    const validEmailMessage = intl.formatMessage({ id: 'validation.email' })
    const [showLoader, setShowLoader] = useState(false)
    const [isSubscibeChecked, setIsSubscribeChecked] = useState(false)
    const [isRequireTopicChecked, setIsRequireTopicChecked] = useState(true)
    const [topics, setTopics] = useState(null)
    const [invalidTopic, setInvalidTopic] = useState('')
    const [isCheckAll, setIsCheckAll] = useState(true)

    const onUpdateSubscribe = () => {
        if (!topics) {
            SubscribeService.getSubscribeInfo().then((res) => {
                setTopics(SubscribeService.initTopics(res.topics, checkAllTopicValue))
            })
        }
        setIsSubscribeChecked(!isSubscibeChecked)
    }

    const onChangeTopics = (item, title) => {
        if (topicRequired.indexOf(title) > -1) {
            setIsRequireTopicChecked(true)
        }

        const newTopics = SubscribeService.updateTopics(topics, item.name, title, topicRequired)
        const shouldUpdateCheckAll = SubscribeService.updateAllTopicValueByChild(newTopics, isCheckAll, title)
        setTopics(newTopics)
        setIsCheckAll(shouldUpdateCheckAll)
    }

    const onCheckAllTopic = (isChecked, title) => {
        setTopics(SubscribeService.checkAllTopicValue(isCheckAll, title, topics))
        setIsCheckAll(!isCheckAll)
    }

    const onSubmitButton: SubmitHandler<ContactFormTypes> = ({
        salutation,
        firstName,
        lastName,
        email,
        subject,
        message,
        checkboxNewsletter,
        checkboxTOS,
    }) => {
        if (isSubscibeChecked) {
            const resultValidateTopic = SubscribeService.validateRequireTopics(topics, topicRequired)

            if (resultValidateTopic.length > 0) {
                setIsRequireTopicChecked(false)
                // @ts-ignore
                setInvalidTopic(resultValidateTopic)
                return
            }
        }

        setIsRequireTopicChecked(true)
        setShowLoader(true)

        ContactService.sendFormData({
            form: {
                salutation,
                firstName,
                lastName,
                email,
                subject,
                message,
                checkboxNewsletter,
                checkboxTOS,
            },
        }).then((response) => {
            if (response.error) {
                app.getLoader('context').notifyUser(<Message
                    message={intl.formatMessage({ id: 'contactForm.errorMsg' })}
                />, 'error')
            } else {
                if (isSubscibeChecked) {
                    const dataSubscribe = {
                        email,
                        topics: SubscribeService.collectTopicValue(topics),
                    }
                    SubscribeService.subscribe(dataSubscribe).then(() => {
                        setShowLoader(false)
                        app.getLoader('context').notifyUser(<Message
                            message={intl.formatMessage({ id: 'contactForm.successMsg' })}
                        />, 'success')
                    })
                    return null
                }

                setShowLoader(false)
                app.getLoader('context').notifyUser(<Message
                    message={intl.formatMessage({ id: 'contactForm.successMsg' })}
                />, 'success')
            }
        })
    }

    return (
        <FullPageWidthWrapper customClasses={classnames('contact-form', {
            'my-comp': !data.renderBlackBackground && !data.renderDottedBorders,
            'py-8 md:py-10 lg:py-12 xl:py-16 xxl:py-20': data.renderBlackBackground || data.renderDottedBorders,
            'dark-mode text-white bg-blacksport-100': data.renderBlackBackground,
        })}
        >
            {data.renderDottedBorders
                && <BorderOnTopAndBottom renderAtTop renderBlackBackground={data.renderBlackBackground} />}

            <section className={'o-wrapper'}>
                <form
                    onSubmit={handleSubmit(onSubmitButton)}
                    className={'flex flex justify-center flex-row px-4 md:px-0'}
                >
                    <div
                        className={'contact-form--right w-full lg:w-1/2 flex flex-row flex-wrap mt-10 lg:mt-0'}
                    >
                        <div className={'flex flex-row flex-wrap w-full mb-8'}>
                            <span className={'mr-10 mb-3'}>
                                <FormattedMessage id={'contactForm.salutation.label'} />
                            </span>

                            <div className={classnames('mr-10 mb-2 items-center checkbox-radio--wrapper', {
                                'is--invalid': errors?.salutation,
                            })}
                            >
                                <input
                                    id={'contact-form--salutation-option1'}
                                    type={'radio'}
                                    {...register('salutation', {
                                        required: false,
                                    })}
                                    value={'Mr'}
                                    tabIndex={0} />
                                <label
                                    htmlFor={'contact-form--salutation-option1'}
                                    className={'text-sm whitespace-no-wrap'}
                                >
                                    <FormattedMessage id={'contactForm.salutation.option1'} />
                                </label>
                            </div>

                            <div className={classnames('mr-10 mb-2 items-center checkbox-radio--wrapper', {
                                'is--invalid': errors?.salutation,
                            })}
                            >
                                <input
                                    id={'contact-form--salutation-option2'}
                                    type={'radio'}
                                    {...register('salutation', {
                                        required: false,
                                    })}
                                    value={'Ms'}
                                    tabIndex={0} />
                                <label
                                    htmlFor={'contact-form--salutation-option2'}
                                    className={'text-sm whitespace-no-wrap'}
                                >
                                    <FormattedMessage id={'contactForm.salutation.option2'} />
                                </label>
                            </div>

                            <div className={classnames('mb-2 items-center checkbox-radio--wrapper', {
                                'is--invalid': errors?.salutation,
                            })}
                            >
                                <input
                                    id={'contact-form--salutation-option3'}
                                    type={'radio'}
                                    {...register('salutation', {
                                        required: false,
                                    })}
                                    value={'Mx'}
                                    tabIndex={0} />
                                <label
                                    htmlFor={'contact-form--salutation-option3'}
                                    className={'text-sm whitespace-no-wrap'}
                                >
                                    <FormattedMessage id={'contactForm.salutation.option3'} />
                                </label>
                            </div>
                        </div>

                        <div className={'flex w-full gap-6 mb-10'}>
                            <div className={classnames('w-1/2 input--wrapper', {
                                'is--invalid': errors?.firstName,
                            })}
                            >
                                <input
                                    id={'contact-form--firstname'}
                                    {...register('firstName', {
                                        required: false,
                                    })}
                                    type={'text'}
                                    placeholder={intl.formatMessage({ id: 'contactForm.address.firstNameLabel' })}
                                    tabIndex={0} />
                                <label htmlFor={'contact-form--firstname'}>
                                    <FormattedMessage id={'contactForm.address.firstNameLabel'} />
                                </label>
                                <span className={'input--border'} />
                                {errors?.firstName
                                    && (
                                        <span className={'input--hint'}>
                                            <ErrorMessage
                                                errors={errors}
                                                name={'firstName'}
                                            />
                                        </span>
                                    )}
                            </div>

                            <div className={classnames('w-1/2 input--wrapper', {
                                'is--invalid': errors?.lastName,
                            })}
                            >
                                <input
                                    id={'contact-form--lastname'}
                                    {...register('lastName', {
                                        required: false,
                                    })}
                                    type={'text'}
                                    placeholder={intl.formatMessage({ id: 'contactForm.address.lastNameLabel' })}
                                    tabIndex={0} />
                                <label htmlFor={'contact-form--lastname'}>
                                    <FormattedMessage id={'contactForm.address.lastNameLabel'} />
                                </label>
                                <span className={'input--border'} />
                                {errors?.lastName
                                    && (
                                        <span className={'input--hint'}>
                                            <ErrorMessage
                                                errors={errors}
                                                name={'lastName'}
                                            />
                                        </span>
                                    )}
                            </div>
                        </div>

                        <div className={classnames('mb-10 w-full input--wrapper', {
                            'is--invalid': errors?.email,
                        })}
                        >
                            <input
                                id={'contact-form--email'}
                                {...register('email', {
                                    required: requiredFieldMessage,
                                    pattern: {
                                        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: validEmailMessage,
                                    },
                                })}
                                type={'email'}
                                placeholder={intl.formatMessage({ id: 'contactForm.address.emailLabel' })}
                                tabIndex={0} />
                            <label htmlFor={'contact-form--email'}>
                                <FormattedMessage id={'contactForm.address.emailLabel'} />
                            </label>
                            <span className={'input--border'} />
                            {errors?.email
                                && (
                                    <span className={'input--hint'}>
                                        <ErrorMessage
                                            errors={errors}
                                            name={'email'}
                                        />
                                    </span>
                                )}
                        </div>

                        <div className={'flex w-full gap-6 mb-10'}>
                            <div className={classnames('w-full input--wrapper', {
                                'is--invalid': errors?.subject,
                            })}
                            >
                                <input
                                    id={'contact-form--subject'}
                                    {...register('subject', {
                                        required: requiredFieldMessage,
                                        pattern: {
                                            value: patternValueWithoutBlank,
                                            message: requiredFieldMessage,
                                        },
                                    })}
                                    type={'text'}
                                    placeholder={intl.formatMessage({ id: 'contactForm.message.subjectLabel' })}
                                    tabIndex={0} />
                                <label htmlFor={'contact-form--subject'}>
                                    <FormattedMessage id={'contactForm.message.subjectLabel'} />
                                </label>
                                <span className={'input--border'} />
                                {errors?.subject
                                    && (
                                        <span className={'input--hint'}>
                                            <ErrorMessage
                                                errors={errors}
                                                name={'subject'}
                                            />
                                        </span>
                                    )}
                            </div>
                        </div>

                        <div className={'flex w-full gap-6 mb-10'}>
                            <div className={classnames('w-full input--wrapper', {
                                'is--invalid': errors?.message,
                            })}
                            >
                                <textarea
                                    id={'contact-form--message'}
                                    {...register('message', {
                                        required: requiredFieldMessage,
                                        pattern: {
                                            value: patternValueWithoutBlank,
                                            message: requiredFieldMessage,
                                        },
                                    })}
                                    placeholder={intl.formatMessage({ id: 'contactForm.message.messageLabel' })}
                                    tabIndex={0} />
                                <label htmlFor={'contact-form--message'}>
                                    <FormattedMessage id={'contactForm.message.messageLabel'} />
                                </label>
                                <span className={'input--border'} />
                                {errors?.message
                                    && (
                                        <span className={'input--hint'}>
                                            <ErrorMessage
                                                errors={errors}
                                                name={'message'}
                                            />
                                        </span>
                                    )}
                            </div>
                        </div>

                        {data.renderNewsletterRegistrationCheckbox && (
                            <div className={'contents w-full checkbox-radio--wrapper'}>
                                <input
                                    type={'checkbox'}
                                    {...register('checkboxNewsletter', {
                                        required: false,
                                    })}
                                    id={'contact--checkbox-newsletter'}
                                    tabIndex={0} />
                                <label
                                    className={'text-sm mb-6'}
                                    htmlFor={'contact--checkbox-newsletter'}
                                >
                                    <FormattedMessage id={'contactForm.checkbox.newsletterLabel'} />
                                </label>
                            </div>
                        )}

                        <div className={classnames('contents w-full checkbox-radio--wrapper', {
                            'is--invalid': errors?.checkboxTOS,
                        })}
                        >
                            <input
                                type={'checkbox'}
                                {...register('checkboxTOS', {
                                    required: requiredFieldMessage,
                                    pattern: {
                                        value: patternValueWithoutBlank,
                                        message: requiredFieldMessage,
                                    },
                                })}
                                id={'contact--checkbox-tos'}
                                tabIndex={0} />
                            <label
                                className={'text-sm mb-6'}
                                htmlFor={'contact--checkbox-tos'}
                            >
                                <FormattedHTMLMessage id={'contactForm.checkbox.tosLabel'} />
                            </label>
                        </div>

                        <div className={'contents w-full checkbox-radio--wrapper'}>
                            <input
                                type={'checkbox'}
                                name={'checkboxSubscribe'}
                                id={'contact--checkbox-subscribe'}
                                checked={isSubscibeChecked}
                                onChange={() => onUpdateSubscribe()}
                            />
                            <label
                                className={'text-sm mb-6'}
                                htmlFor={'contact--checkbox-subscribe'}
                            >
                                <FormattedHTMLMessage id={'contactForm.checkbox.subsribeLabel'} />
                            </label>
                        </div>

                        {topics && isSubscibeChecked && (
                            <div className={'w-full'}>
                                <NewsletterForm
                                    topics={topics}
                                    requireTopicValue={requireTopicValue}
                                    checkAllTopicValue={checkAllTopicValue}
                                    isRequireTopicChecked={isRequireTopicChecked}
                                    pageName={'contact'}
                                    onChange={(item, title) => {
                                        onChangeTopics(item, title)
                                    }}
                                    isCheckAll={isCheckAll}
                                    onCheckAll={(isCheckAll, title) => {
                                        onCheckAllTopic(isCheckAll, title)
                                    }}
                                    invalidTopic={invalidTopic}
                                />
                            </div>
                        )}

                        <div className={'w-full mt-3'}>
                            <ButtonWithLoader
                                className={'btn btn-cta w-1/3 flex'}
                                ariaLabel={intl.formatMessage({ id: 'contactForm.submitLabel' })}
                                showLoader={showLoader}
                                type={'submit'}
                                darkTheme={data.renderBlackBackground}
                            >
                                <FormattedMessage id={'contactForm.submitLabel'} />
                            </ButtonWithLoader>
                        </div>
                    </div>
                </form>
            </section>

            {data.renderDottedBorders
                && <BorderOnTopAndBottom renderAtTop={false} renderBlackBackground={data.renderBlackBackground} />}
        </FullPageWidthWrapper>
    )
}

export default tastify({ translate: true })(injectIntl(ContactForm))

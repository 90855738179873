import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Link from '@frontastic/catwalk/src/js/app/link'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import Reference from '@frontastic/catwalk/src/js/component/reference'
import FrontasticImage from '../../../atoms/frontasticImage'
import Sport2000Logo from '../../../atoms/sport2000Logo'
import { categoryTreeType } from '../types'
import { ReactComponent as IconRightArrow } from '../../../../../icons/sport2000-icons/arrow-right-around.svg'

const NavigationFlyout = ({
    item, itemId, expandedItemId, onClose, categorySidebar, themeName, textLinkAllProduct,
}) => {
    const trending = categorySidebar.filter((category) => item.key.toLowerCase() === category.key.toLowerCase())
    const {
        blogs, campaigns, headline, logos,
    } = trending[0] || {}
    const [isActiveEntry, setIsActiveEntry] = useState(null)
    const isDesktop = useDeviceType() === 'desktop'
    const MIN_SUB_ITEMS = 3
    const MAX_SUB_ITEMS = 6
    const limitSubItemByTheme = themeName ? MAX_SUB_ITEMS : MIN_SUB_ITEMS
    const SPORT_CATEGORY = 'Sportarten'
    const bannedCategory = ['fussball', 'running', 'gesamtes', 'gesamte']
    const isSportCategory = item.key === SPORT_CATEGORY
    const isAbsoluteOrRunning = window.location.pathname === '/absolute-teamsport'
        || window.location.pathname === '/running-experts'
    const hasNoBlogOrCampaign = (!blogs || !blogs.length) && (!campaigns || !campaigns.length)

    useEffect(() => {
        setIsActiveEntry(null)
    }, [item, onClose])

    const keyboardHandler = (event) => {
        if (event.key === 'Escape') {
            onClose()
        }
    }

    const bodyClickHandler = (event) => {
        if (event.target.closest('.main-menu-wrapper, .flyout')) {
            return
        }

        onClose()
    }

    const checkIsSpecialCategory = (name) => {
        let result = false
        const indexOfName = bannedCategory.indexOf(name.toLowerCase())

        if (indexOfName !== -1) {
            result = true
        }

        return result
    }

    const renderAllCategoryLink = (customClassName = '') => {
        const genderCategoryUrl = ['Damen', 'Herren', 'Kinder']
        const isGenderCategory = genderCategoryUrl.includes(item.key)
        const isAlleCategory = item.key.toLowerCase() === 'alle'

        const renderAllCategoryText = () => {
            if (themeName) {
                return (
                    textLinkAllProduct ? <div>{textLinkAllProduct}</div>
                        : <FormattedMessage id={'header.specialCategory'} />
                )
            }

            if (isAlleCategory) {
                return (
                    <FormattedMessage id={'header.allCategoryForAlle'} />
                )
            }

            if (!isGenderCategory) {
                return (
                    <FormattedMessage id={'header.allCategory'} values={{ category: item.name }} />
                )
            }

            return (
                <FormattedMessage id={'header.allCategoryGender'} values={{ category: item.name }} />
            )
        }

        return (
            <li className={customClassName}>
                <a
                    href={item.url ? item.url : `/c/${item.id}/${item.slug}`}
                    onClick={onClose}
                    key={item.id || item.nodeId}
                    className={'sport-category'}
                >

                    {renderAllCategoryText()}
                    <div className={'is--arrow-sport'}>
                        <IconRightArrow width={30} height={25} />
                    </div>
                </a>
            </li>
        )
    }

    const renderOnlyParentCategory = (parentCategory) => {
        if (!parentCategory || !parentCategory.length) {
            return renderAllCategoryLink('is--only-top')
        }

        const PER_CATEGORY_COLUMN = 4
        const filteredParentCategory = parentCategory.filter((value) => value.name.toLowerCase() !== 'fussball' && value.name.toLowerCase() !== 'running')
        const firstCategory = filteredParentCategory.slice(0, PER_CATEGORY_COLUMN)

        return (
            <>
                <li className={'is--only-top'}>
                    <ul>
                        {firstCategory.map((itemTop, index) => {
                            if (index > PER_CATEGORY_COLUMN) {
                                return null
                            }

                            return (
                                <>
                                    <li>
                                        <Link
                                            path={itemTop.url ? itemTop.url : `/c/${itemTop.id}/${itemTop.slug}`}
                                            onClick={onClose}
                                            key={itemTop.id || itemTop.nodeId}
                                        >
                                            {itemTop.name}
                                        </Link>
                                    </li>
                                </>
                            )
                        })}

                        {renderAllCategoryLink()}
                    </ul>
                </li>
            </>
        )
    }

    useEffect(() => {
        if (isDesktop) {
            window.addEventListener('keydown', keyboardHandler)
            document.body.addEventListener('click', bodyClickHandler)
        }

        return () => {
            window.removeEventListener('keydown', keyboardHandler)
            document.body.removeEventListener('click', bodyClickHandler)
        }
    }, [])

    const renderFlyoutLeft = () => {
        let LIMIT_ITEM = 6

        if (hasNoBlogOrCampaign && (!isSportCategory || isAbsoluteOrRunning)) {
            LIMIT_ITEM = 10
        }

        const itemWithOutSubItem = item.children?.slice(LIMIT_ITEM + 1)

        return (
            <div className={classnames('flyout-left', {
                'is--sport-category': hasNoBlogOrCampaign && (!isSportCategory || isAbsoluteOrRunning),
                'is--normal-category': !hasNoBlogOrCampaign || (isSportCategory || !isAbsoluteOrRunning),
            })}
            >
                <ul className={'flyout-left--list'}>
                    {item && item.children && item.children.map((nav, index) => {
                        if (index > LIMIT_ITEM || checkIsSpecialCategory(nav.name)) {
                            return null
                        }

                        return (
                            <li key={nav.id || nav.nodeId}>
                                <a
                                    className={(isActiveEntry === nav.id || nav.nodeId) && 'is--active'}
                                    href={nav.url ? nav.url : `/c/${nav.id}/${nav.slug}`}
                                    onClick={onClose}
                                >
                                    {nav.name}
                                </a>
                                {nav.children && nav.children.length > 0 && (
                                    <ul>
                                        {nav.children.map((sub, i) => {
                                            if (i >= limitSubItemByTheme) {
                                                return null
                                            }

                                            return (
                                                <li
                                                    className={'nav-item'}
                                                    key={sub.id || sub.nodeId}
                                                >
                                                    <a
                                                        href={sub.url ? sub.url : `/c/${sub.id}/${sub.slug}`}
                                                        onClick={onClose}
                                                    >
                                                        {sub.name}
                                                    </a>
                                                </li>
                                            )
                                        })}
                                        {nav.children.length >= limitSubItemByTheme && (
                                            <li>
                                                <a
                                                    className={'is--more'}
                                                    href={nav.url ? nav.url : `/c/${nav.id}/${nav.slug}`}
                                                    onClick={onClose}
                                                >
                                                    <FormattedMessage id={'header.moreCategory'} />
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                )}
                            </li>
                        )
                    })}

                    {renderOnlyParentCategory(itemWithOutSubItem)}
                </ul>
            </div>
        )
    }

    const renderFlyoutRight = () => {
        if (hasNoBlogOrCampaign && (!isSportCategory || isAbsoluteOrRunning)) {
            return null
        }

        if (isSportCategory) {
            const fussballCategory = item.children?.filter((child) => child.key === 'Fussball')
            const runningCategory = item.children?.filter((child) => child.key === 'Running')

            const specialCategories = [{
                logo: <Sport2000Logo name={'AbsoluteTeamsport_1line'} alt={'logo Absolute Teamsport'} height={'42px'} />,
                items: fussballCategory,
                key: 'team-sport',
                custom: 'is--teamsport',
                url: '/absolute-teamsport',
            }, {
                logo: <Sport2000Logo name={'RunningExperts_1line'} alt={'logo Running Expert'} height={'42px'} />,
                items: runningCategory,
                key: 'running-experts',
                custom: 'is--running-experts',
                url: '/running-experts',
            }]

            return (
                <div className={'flyout-right is--sport-category'}>
                    <div className={'flyout-right--sport'}>
                        {specialCategories.map((specialCategory) => {
                            if (!specialCategory.items.length) {
                                return null
                            }

                            return (
                                <div className={'sport-category-detail'} key={specialCategory.key}>
                                    {specialCategory.items.map((item) => (
                                        <>
                                            <div key={item.id || item.nodeId} className={'sport-category-detail--left'}>
                                                <Link
                                                    className={classnames(`${specialCategory.custom}`, {
                                                        'is--active': isActiveEntry === item.id || item.nodeId,
                                                    })}
                                                    path={specialCategory.url}
                                                    onClick={onClose}
                                                >
                                                    {item.name}
                                                </Link>
                                                <ul>
                                                    {item.children.map((sub, i) => {
                                                        if (i > limitSubItemByTheme) {
                                                            return null
                                                        }

                                                        return (
                                                            <li
                                                                className={'nav-item'}
                                                                key={sub.id || sub.nodeId}
                                                            >
                                                                <Link
                                                                    path={sub.url ? sub.url : `/c/${sub.id}/${sub.slug}`}
                                                                    onClick={onClose}
                                                                >
                                                                    {sub.name}
                                                                </Link>
                                                            </li>
                                                        )
                                                    })}
                                                    <li>
                                                        <Link
                                                            className={`is--more ${specialCategory.custom}`}
                                                            path={item.url ? item.url : `/c/${item.id}/${item.slug}`}
                                                            onClick={onClose}
                                                        >
                                                            <FormattedMessage id={'header.moreCategory'} />
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className={'flex items-center'}>
                                                <Link
                                                    path={specialCategory.url}
                                                    onClick={onClose}
                                                >
                                                    {specialCategory.logo}
                                                </Link>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        }

        return (
            <div className={'flyout-right'}>
                {headline && <p className={'flyout-right--headline'}>{headline}</p>}

                {blogs && blogs.length > 0 && (
                    <div className={'flyout-right--blogs'}>
                        {blogs.map((blog) => (
                            <Reference reference={blog.reference} key={blog.title} onClick={onClose}>
                                {blog.title}
                            </Reference>
                        ))}
                    </div>
                )}

                {campaigns && campaigns.length > 0 && (
                    <div className={'flyout-right--campaigns'}>
                        {campaigns.map((campaign) => (
                            <div key={campaign.title} className={'campaign-item'}>
                                <FrontasticImage media={campaign.image.media} width={100} height={100} style={{objectFit:"cover"}} />
                                <div className={'flex align-middle flex-col'}>
                                    <p>{campaign.title}</p>
                                    <Reference reference={campaign.reference} onClick={onClose}>
                                        {campaign.linkTitle}
                                    </Reference>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        )
    }

    const renderFlyoutBrand = () => {
        if (!logos || !logos.length) {
            return null
        }

        return (
            <div className={'flyout-brand'}>
                {logos.map((logo) => (
                    <Reference reference={logo.reference} onClick={onClose}>
                        <FrontasticImage media={logo.image.media} />
                    </Reference>
                ))}
            </div>
        )
    }

    return (
        <div className={classnames('flyout flex flex-wrap', {
            flyout__open: expandedItemId === itemId,
        })}
        >
            {renderFlyoutLeft()}
            {renderFlyoutRight()}
            {renderFlyoutBrand()}
        </div>
    )
}

NavigationFlyout.propTypes = {
    expandedItemId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    item: categoryTreeType,
    itemId: PropTypes.string.isRequired,
    categorySidebar: PropTypes.any,
    themeName: PropTypes.any,
    textLinkAllProduct: PropTypes.string,
}

export default injectIntl(NavigationFlyout)

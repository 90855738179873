import React from 'react'
import PropTypes from 'prop-types'
import FullPageWidthWrapper from '../../molecules/Layout/FullPageWidthWrapper'
import Button from '../../atoms/button/Button'

const Tile = ({
    media,
    topic,
    title,
    buttonLabel,
    verAlign,
    horAlign,
    reference,
    btnVariant,
    btnColorDefault,
    btnColorHover,
    colorText,
}) => {
    if (!media && !media.file) {
        return null
    }

    const styleBackground = {}
    const styleText = {}

    if (media && media.media) {
        styleBackground.backgroundImage = `url(${media.media.file})`
    }

    if (colorText) {
        styleText.color = colorText
    }

    return (
        <FullPageWidthWrapper customClasses={'tile-component'} withWrapper>
            <div className={'tile-component-background'} style={styleBackground}>
                <div className={`tile-component-content tile--content-${verAlign} tile--content-${horAlign}`}>
                    {topic && (
                        <div className={'tile-component--topic'} style={styleText}>
                            {topic}
                        </div>
                    )}

                    {title && (
                        <div className={'tile-component--title'} style={styleText}>
                            {title}
                        </div>
                    )}

                    {buttonLabel && (
                        <Button
                            className={`btn btn-${btnVariant} teaser-block--button btn--color-${btnColorDefault} btn--hover-${btnColorHover}`}
                            type={'button'}
                            reference={reference}
                            ariaLabel={buttonLabel}
                        >
                            {buttonLabel}
                        </Button>
                    )}
                </div>
            </div>
        </FullPageWidthWrapper>
    )
}

Tile.propTypes = {
    media: PropTypes.object,
    topic: PropTypes.string,
    title: PropTypes.string,
    verAlign: PropTypes.string,
    horAlign: PropTypes.string,
    buttonLabel: PropTypes.string,
    reference: PropTypes.object,
    btnVariant: PropTypes.string,
    btnColorDefault: PropTypes.string,
    btnColorHover: PropTypes.string,
    colorText: PropTypes.string,
}

export default Tile

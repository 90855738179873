import React from 'react'
import PropTypes from 'prop-types'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import Stores from '../../patterns/organisms/Stores'

const StoreDetail = ({
    data: {
        headline, headlinePos, storeItem, isDarkMode,
    },
}) => (
    <Stores headline={headline} headlinePos={headlinePos} storeItem={storeItem} isDarkMode={isDarkMode} />
)

StoreDetail.propTypes = {
    data: PropTypes.object.isRequired,
}

export default tastify()(StoreDetail)

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { isEmpty } from 'lodash'
import app from '@frontastic/catwalk/src/js/app/app'
import TagManager from '../../../../domain/TagManager'
import Product from './Product'

const Products = ({ products }) => {
    useEffect(() => {
        TagManager.checkoutImpressions()
    })

    const handleSizeLabel = (item) => {
        const sizeLabel = item.variant.attributes.attr_size

        if (isEmpty(sizeLabel)) {
            return null
        }

        return sizeLabel
    }

    const getProductLink = (item) => `${item.variant.projectSpecificData.productUrl}?sku=${item.variant.sku}`

    return (
        <div
            className={'product-checkout-item'}
        >
            {
                products.map((item, index) => (
                    <div key={item.name + index} className={classnames('product-item', { 'py-4': index > 0 })}>
                        <Product
                            name={item.name}
                            sku={item.variant.sku}
                            link={getProductLink(item)}
                            image={item.variant.images[0]}
                            count={item.count}
                            price={item.price}
                            productCategory={item.variant.projectSpecificData.categoryIds}
                            discountedPrice={item.discountedPrice}
                            color={item.variant.attributes.att_prod_color_name_sport2000}
                            size={handleSizeLabel(item)}
                        />
                    </div>
                ))
            }
        </div>
    )
}
Products.propTypes = {
    products: PropTypes.array.isRequired,
}

export default Products

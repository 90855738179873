import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import 'react-sliding-pane/dist/react-sliding-pane.css'
import debounce from '@frontastic/common/src/js/helper/debounce'
import { FormattedMessage } from 'react-intl'
import IconButton from '../../atoms/button/IconButton'
import { ReactComponent as ArrowLIcon } from '../../../../icons/sport2000-icons/arrowleft.svg'

const SlideLeftMobile = ({
    isOpen, onClose, title = '', children,
}) => {
    useEffect(() => {
        function closeMobileModalOnResize() {
            if (window.innerWidth >= 768 && onClose) {
                onClose()
            }
        }
        closeMobileModalOnResize()
        window.addEventListener('resize', debounce(() => closeMobileModalOnResize(), 50), { passive: true })

        return window.removeEventListener('resize', debounce(() => closeMobileModalOnResize(), 50))
    }, [])

    return (
        <div className={`${isOpen ? 'hidden' : ''} account--panel-mobile`}>
            <IconButton
                type={'button'}
                ariaLabel={'title'}
                onClick={onClose}
                icon={<ArrowLIcon />}
                className={'account--back-button w-full flex'}
            >
                <span className={'w-full'}>
                    <FormattedMessage id={title} />
                </span>
            </IconButton>

            <>
                {children}
            </>
        </div>
    )
}

SlideLeftMobile.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]).isRequired,
    children: PropTypes.node.isRequired,
}

export default SlideLeftMobile

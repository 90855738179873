import React from 'react'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import PropTypes from 'prop-types'
import MinimalHeader from '../../patterns/organisms/MinimalHeader'
import MetaData from '../../node/metaData'

const MinimalHeaderTastic = ({
    data: {
        media, mediaAbsolute, mediaRunning, nodeConfiguration,
    }, node, context,
}) => {
    if (nodeConfiguration?.name) {
        node.name = nodeConfiguration.name
    }
    if (nodeConfiguration?.seoTitle) {
        node.configuration.seoTitle = nodeConfiguration.seoTitle
    }
    if (nodeConfiguration?.seoDescription) {
        node.configuration.seoDescription = nodeConfiguration.seoDescription
    }

    return (
        <>
            <MetaData node={node} context={context}/>
            <MinimalHeader
                media={media}
                mediaAbsolute={mediaAbsolute}
                mediaRunning={mediaRunning}
            />
        </>
    );
}

MinimalHeaderTastic.propTypes = {
    data: PropTypes.object.isRequired,
    node: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
}

export default tastify({ translate: true, connect: { node: true, context: true } })(MinimalHeaderTastic)

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { SwiperSlide } from 'swiper/react'
import classnames from 'classnames'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import app from '@frontastic/catwalk/src/js/app/app'
import { useSelector } from 'react-redux'
import Slider from '../../../atoms/slider'
import Price from '../../../atoms/price'
import Button from '../../../atoms/button/Button'
import ProductTags from '../../../atoms/tag/productTags'
import tagManager from '../../../../domain/TagManager'
import GtmService from '../../../../services/gtm'
import PriceHelper from '../../../../services/price'

import { ReactComponent as LogoAbsoluteRun } from '../../../../../icons/sport2000-icons/logos/absoluterun.svg'
import { ReactComponent as LogoAbsoluteTeamsport } from '../../../../../icons/sport2000-icons/logos/absoluteteamsport.svg'
import { ReactComponent as CloseIcon } from '../../../../../icons/sport2000-icons/close.svg'
import { ReactComponent as HeartIcon } from '../../../../../icons/sport2000-icons/heart.svg'
import { ReactComponent as HeartFillIcon } from '../../../../../icons/sport2000-icons/heart-filled.svg'
import IconButton from '../../../atoms/button/IconButton'
import OldGtmService from '../../../../services/oldGtm'
import OldTagManager from '../../../../domain/OldTagManager'
import FrontasticImage from '../../../atoms/frontasticImage'
import { dynamicPageRoute } from 'types/routePaths'

const ProductTile = ({
    position,
    nameCategory,
    product,
    colors,
    from,
    node,
    algoliaIndexName,
    className = '',
    refererStore,
    hasSidebarCategory,
    isDisplaySidebar,
}) => {
    const getActiveColorIndex = product.colors?.findIndex((color) => color.isWishList || color.isActive) || 0
    const productAttributesDefault = product.colors && product.colors[getActiveColorIndex]?.attributes || product.attributes
    const channelSearchUrl = refererStore ? `objectId=${refererStore.id}` : ''
    const isDesktop = useDeviceType() === 'desktop'
    const route = useSelector((state) => state.app.route || {})

    const getProductQuery = () => {
        const queries = []

        if (channelSearchUrl) {
            queries.push(channelSearchUrl)
        }

        // if (product.sku) {
        //     queries.push(`sku=${product.sku}`)
        // }
        //
        // if (product.__queryID) {
        //     queries.push(`queryId=${product.__queryID}`)
        // }

        return queries
    }

    const productQueries = getProductQuery()
    const productQueriesUrl = productQueries.length > 0 ? `?${productQueries.join('&')}` : ''
    const initValueProductUrl = `${product.product_url}${productQueriesUrl}`
    const [priceVariants, setPriceVariants] = useState(PriceHelper.getPrice(product, refererStore))
    const [image, setImage] = useState(product.image)
    const [activeColor, setActiveColor] = useState(getActiveColorIndex)
    const [productUrl, setProductUrl] = useState(initValueProductUrl)
    const [productAttributes, setProductAttributes] = useState(productAttributesDefault)
    const [productTags, setProductTags] = useState(productAttributes?.att_internal_category_sport2000 || [])

    const isAbsoluteRun = product.attributes.att_internal_category_absolute_run
    const isAbsoluteSport = product.attributes.att_internal_category_absolute_teamsport
    const isProductOnSale = PriceHelper.isProductOnSale(priceVariants)

    const wishlist = useSelector((state) => state.wishlist.wishlist)

    const handleMouseOverVariant = (color, index) => {
        setImage(color.image)
        setPriceVariants(color.price_variants)
        setProductUrl(`${color.product_url}${productQueriesUrl}`)
        setActiveColor(index)
        setProductAttributes(color?.attributes)
    }

    const renderColorSlider = () => (
        colors.map((color, index) => (
            <SwiperSlide key={`${color.product_id}_${index}`}>
                <div
                    className={classnames('product-teaser-item', {
                        active: activeColor === index,
                    })}
                >

                    <Button
                        type={'button'}
                        className={'btn p-1 w-full'}
                        ariaLabel={color.name}
                        onClick={(event) => {
                            handleMouseOverVariant(color, index)
                            event.preventDefault()
                            event.stopPropagation()
                        }}
                    >
                        <FrontasticImage
                            title={color.name}
                            alt={color.name}
                            width={48}
                            height={48}
                            src={color.image}
                        />
                    </Button>
                </div>
            </SwiperSlide>
        ))
    )

    const renderPrice = () => {
        const { lowestPrice, hasMultiplePrices } = PriceHelper.findLowestAndCheckPrices(priceVariants)

        return (
            <>
                {hasMultiplePrices && (<span className={classnames({
                    'text-red-600': isProductOnSale,
                })}>
                        <FormattedMessage id="productTeaser.from"/>
                    {' '}
                </span>)}
                <Price variant={`${isProductOnSale && 'text-red-600'} text-xl font-bold`} value={lowestPrice}/>
            </>
        )
    }

    const themeLogo = () => {
        if (isAbsoluteRun) {
            return (
                <div className={'product-teaser-logo-run'}>
                    <LogoAbsoluteRun width={isDesktop ? 120 : 90} height={24} />
                </div>
            )
        }

        if (isAbsoluteSport) {
            return (
                <div className={'product-teaser-logo-teamsport'}>
                    <LogoAbsoluteTeamsport width={isDesktop ? 170 : 120} height={24} />
                </div>
            )
        }

        return null
    }

    const handleProductClickGTM = () => {
        const pageName = from || 'Product List'
        const gtmService = new GtmService(null, product, position)
        const productGTM = gtmService.createProductGTM(pageName)
        tagManager.productClick(productGTM, nameCategory)

        // for universal analytics ( will be removed )
        const oldGtmService = new OldGtmService(null, product, position)
        const oldProductGTM = oldGtmService.createProductGTM()
        OldTagManager.productClick(oldProductGTM, nameCategory)
    }

    const sliderColorsSettings = {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 6,
    }

    const renderColors = () => {
        if (!colors.length || !isDesktop) {
            return null
        }

        return (
            <>
                {isDesktop && (
                    <div className={'product-teaser--slider-wrapper'}>
                        <div className={'product-teaser--slider-variant is--algolia'}>
                            <Slider
                                {...sliderColorsSettings}
                            >
                                {renderColorSlider()}
                            </Slider>
                        </div>
                    </div>
                )}
                <p className={'product-teaser--count'}>
                    {product.colors?.length}
                    {' '}
                    <FormattedMessage
                        id={product.colors?.length === 1 ? 'productTeaser.color' : 'productTeaser.colors'}
                    />
                </p>
            </>
        )
    }

    let wishlistItem = null

    if (wishlist.loaded) {
        wishlistItem = wishlist.data.lineItems.find((item) => item.variant.attributes.product_key === product.product_key)
    }

    const filterAttributeShoeWidth = (product) => {
        const attributeShoeWidth = product.attributes.att_prop_shoe_general_width

        if (!attributeShoeWidth) {
            return null
        }

        if (attributeShoeWidth.toLowerCase() === 'normal') {
            return null
        }

        return attributeShoeWidth
    }

    useEffect(() => {
        if (isProductOnSale) {
            setProductTags([...productTags, 'sale'])
        } else {
            setProductTags(productAttributes?.att_internal_category_sport2000 || [])
        }
    }, [isProductOnSale])

    return (
        <article
            className={classnames('product-teaser search-insights-hit', {
                'has--no-sidebar-items': !hasSidebarCategory && isDisplaySidebar,
            })}
            data-product-id={product.product_id}
            data-insights-object-id={product.objectID}
            data-insights-position={product.__position}
            data-insights-query-id={product.__queryID}
            data-insights-index={algoliaIndexName}
        >
            <a
                href={productUrl}
                onClick={(event) => {
                    event.stopPropagation()
                    if (product.__queryID) {
                        window.localStorage.setItem('productQueryIds', product.__queryID)
                    }
                    handleProductClickGTM()
                }}
            >
                <div className={'product-teaser--visual-content'}>
                    <div className={'product-teaser-logo'}>
                        {themeLogo()}
                    </div>
                    {wishlistItem ? (
                        <IconButton
                            className={'wishlist-icon absolute right-0 top-0'}
                            type={'button'}
                            icon={route.route.includes(dynamicPageRoute.wishlist)
                                ? <CloseIcon width={isDesktop ? 20 : 15} height={isDesktop ? 20 : 15} />
                                : <HeartFillIcon width={isDesktop ? 20 : 15} height={24} />}
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                app.getLoader('wishlist').removeLineItem(wishlist.data.wishlistId, {
                                    lineItemId: wishlistItem.lineItemId,
                                })
                            }}
                        />
                    ) : (
                        <IconButton
                            className={'wishlist-icon absolute right-0 top-0'}
                            type={'button'}
                            icon={<HeartIcon width={isDesktop ? 20 : 15} height={24} />}
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                app.getLoader('wishlist').add({ sku: product.sku }, { sku: product.sku }, 1, null)
                            }}
                        />
                    )}
                    <ProductTags
                        tags={productTags}
                        className={'product-teaser--tags'}
                    />

                    {image
                        ? (
                            <figure className={'product-teaser--photo'}>
                                <FrontasticImage
                                    title={product.name}
                                    alt={product.name}
                                    src={image}
                                    height={320}
                                    width={320}
                                />
                            </figure>
                        )
                        : (
                            <div className={'product-teaser--placeholder'} />
                        )}

                    <div className={'btn btn-default btn-add-to-cart'}>
                        <FormattedMessage id={'productTeaser.buttonIntoCart'} />
                    </div>
                </div>

                <p className={'product-teaser--brand'}>{product.brand}</p>

                <h3 className={'product-teaser--name'}>
                    {product.attributes.att_prod_product_name && (
                        <span className={'mr-1'}>{product.attributes.att_prod_product_name}</span>
                    )}
                    {filterAttributeShoeWidth(product) && (
                        <span className={'mr-1'}>{filterAttributeShoeWidth(product)}</span>
                    )}
                    {product.attributes.att_prod_gender && (
                        <span>{product.attributes.att_prod_gender}</span>
                    )}
                </h3>

                {product.attributes.att_internal_category_online_shop && (
                    <p className={'product-teaser--category-name'}>
                        {product.attributes.att_internal_category_online_shop}
                    </p>
                )}

                <div>
                    <p className={'product-teaser--price'}>
                        {renderPrice()}
                    </p>
                </div>

                {/* {product.colors?.length > 0 */}
                {/* && ( */}
                <div className={'product-teaser--foot'}>
                    {renderColors()}
                </div>
                {/* )} */}
            </a>
        </article>
    )
}

ProductTile.propTypes = {
    refererStore: PropTypes.any,
    node: PropTypes.object.isRequired,
    position: PropTypes.number.isRequired,
    nameCategory: PropTypes.string.isRequired,
    product: PropTypes.objectOf(PropTypes.any).isRequired,
    colors: PropTypes.arrayOf(PropTypes.any).isRequired,
    from: PropTypes.string,
    algoliaIndexName: PropTypes.any,
    className: PropTypes.any,
    hasSidebarCategory: PropTypes.any,
    isDisplaySidebar: PropTypes.any,
}

export default injectIntl(ProductTile)

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Step = ({ current = false, completed = false, onSelect }) => (
    <div className={'flex flex-col items-center'} onClick={onSelect}>
        <div
            className={classnames('steps rounded-full mx-1 h-5 w-5 cursor-pointer z-10', {
                'step-disabled cursor-not-allowed': !current && !completed,
                'step-current': current,
                'step-completed': completed,
            })}
        />
    </div>
)

Step.propTypes = {
    current: PropTypes.bool.isRequired,
    completed: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired,
}

export default Step

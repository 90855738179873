import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import parse from 'html-react-parser'
import debounce from '@frontastic/common/src/js/helper/debounce'
import classnames from 'classnames'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import Button from '../../../atoms/button/Button'
import ProductAttribute from './ProductAttribute'
import ProductOverview from './ProductOverview'

const ProductInfo = ({ intl, productColor, selectedVariant }) => {
    const scrollMaxWidth = 1024
    const scrollPositionDelta = 64
    const productDescription = selectedVariant.attributes.att_prod_product_description_editorial
        || selectedVariant.attributes.att_prod_product_description_long
        || selectedVariant.attributes.att_prod_product_description_short
    const isMobile = useDeviceType() === 'mobile'

    // Get data of Product Overview to show hide nav-tab
    const [haveDetailOverview, setHaveDetailOverview] = useState(false)

    const getWindowDimensions = () => {
        // @COFIXME[SSR](FLBML-116): window is not defined at SSR
        if (typeof window === 'undefined') {
            return {width: 0, height: 0};
        }

        const { innerWidth: width, innerHeight: height } = window

        return {
            width,
            height,
        }
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener('resize', debounce(() => handleResize(), 50), { passive: true })

        handleResize()

        return window.removeEventListener('resize', debounce(() => handleResize(), 50))
    }, [])

    const handleScrollClick = (target) => {
        const scrollTarget = document.getElementById(target)

        const getPositionDelta = () => {
            const position = scrollTarget.offsetTop

            if (windowDimensions.width < scrollMaxWidth && position > 0) {
                return position - scrollPositionDelta
            }

            return position
        }

        if (scrollTarget) {
            setTimeout(() => {
                window.scrollTo({
                    behavior: scrollTarget ? 'smooth' : 'auto',
                    top: getPositionDelta(scrollTarget),
                })
            }, 100)
        }
    }

    const stickyJumpMark = () => {
        const targetDiv = document.getElementById('jump-mark-component')

        if (!targetDiv) {
            return
        }

        if (document.documentElement.scrollTop >= targetDiv.offsetTop) {
            document.body.classList.add('is--sticky')
            return
        }

        if (document.documentElement.scrollTop < targetDiv.offsetTop) {
            document.body.classList.remove('is--sticky')
        }
    }

    const [navState, setNavState] = useState([])

    const activeJumpMark = () => {
        const descriptionDiv = document.getElementById('#product-description')
        const materialDiv = document.getElementById('#product-details-materials')
        const { scrollY: scrollPosition } = window
        if (materialDiv && descriptionDiv) {
            if (scrollPosition >= descriptionDiv.offsetTop) {
                setNavState('active--description')
            }
            if (scrollPosition >= materialDiv.offsetTop) {
                setNavState('active--materials')
            }
            if (scrollPosition < descriptionDiv.offsetTop || scrollPosition > materialDiv.offsetTop + materialDiv.offsetHeight) {
                setNavState([])
            }
        } else {
            setNavState([])
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', debounce(() => {
            stickyJumpMark()
            activeJumpMark()
        }, 25), { passive: true })
        return window.removeEventListener('scroll', () => {
            stickyJumpMark()
            activeJumpMark()
        })
    }, [])

    return (
        <div className={'product-info'}>
            {/* Jump Mark Bar */}
            <div className={'jump-mark'} id={'jump-mark-component'}>
                <nav id={'nav'} className={'jump-mark--bar'}>
                    {productDescription
                        && (
                            <div className={`jump-mark--item product-description ${navState}`}>
                                <Button
                                    type={'button'}
                                    onClick={() => handleScrollClick('#product-description')}
                                    className={'btn btn-text jump-mark--item-link py-5 h-full text-blacksport-100 text-base mb-0 tracking-normal'}
                                    ariaLabel={intl.formatMessage({ id: 'detail.description' })}
                                >
                                    <FormattedMessage id={'detail.description'} />
                                </Button>
                            </div>
                        )}

                    {haveDetailOverview && (
                        <div className={`jump-mark--item product-description ${navState}`}>
                            <Button
                                type={'button'}
                                onClick={() => handleScrollClick('#product-overview')}
                                className={'btn btn-text jump-mark--item-link py-5 h-full text-blacksport-100 text-base mb-0 tracking-normal'}
                                ariaLabel={intl.formatMessage({ id: 'detail.description' })}
                            >
                                <FormattedMessage id={'detail.overview'} />
                            </Button>
                        </div>
                    )}

                    {selectedVariant.attributes.att_txt_youtube_video_link
                        && (
                            <div className={`jump-mark--item product-details-materials ${navState}`}>
                                <Button
                                    type={'button'}
                                    onClick={() => handleScrollClick('#product-details-youtube')}
                                    className={'btn btn-text jump-mark--item-link py-5 h-full text-blacksport-100 text-base mb-0 tracking-normal'}
                                    ariaLabel={intl.formatMessage({ id: 'detail.material' })}
                                >
                                    <FormattedMessage id={'detail.youtubeVideo'} />
                                </Button>
                            </div>
                        )}

                    {selectedVariant.attributes.att_text_long_material_textile
                        && (
                            <div className={`jump-mark--item product-details-materials ${navState}`}>
                                <Button
                                    type={'button'}
                                    onClick={() => handleScrollClick('#product-details-materials')}
                                    className={'btn btn-text jump-mark--item-link py-5 h-full text-blacksport-100 text-base mb-0 tracking-normal'}
                                    ariaLabel={intl.formatMessage({ id: 'detail.material' })}
                                >
                                    <FormattedMessage id={'detail.material'} />
                                </Button>
                            </div>
                        )}
                </nav>
            </div>
            {/* End Jump Mark Bar */}
            <div className={classnames('product-desc-and-mate', {
                'product-desc-and-mate-only': !productDescription || !selectedVariant.attributes.att_text_long_material_textile,
            })}
            >
                {/* Product Description */}
                {productDescription
                    && (
                        <div id={'#product-description'} className={'description-teaser py-comp'}>
                            <div className={'description-teaser--content'}>
                                <h3 className={'description-teaser--headline'}>
                                    <FormattedMessage id={'detail.description'} />
                                </h3>

                                <div className={'description-teaser-information'}>
                                    {parse(productDescription)}
                                </div>
                            </div>
                        </div>
                    )}

                {/* Product Materials */}
                {selectedVariant.attributes.att_text_long_material_textile
                    && (
                        <div id={'#product-details-materials'} className={'description-teaser py-comp'}>
                            <div className={'description-teaser--content'}>
                                <h3 className={'description-teaser--headline'}>
                                    <FormattedMessage id={'detail.material'} />
                                </h3>

                                <div className={'description-teaser-information'}>
                                    {parse(selectedVariant.attributes.att_text_long_material_textile)}
                                </div>
                            </div>
                        </div>
                    )}
            </div>

            {/* Product Overview */}
            <div id={'#product-overview'}>
                <ProductOverview
                    selectedVariant={selectedVariant}
                    setHaveDetailOverview={setHaveDetailOverview}
                />
            </div>

            {/* Product Attribute */}
            <ProductAttribute
                productColor={productColor}
                selectedVariant={selectedVariant}
            />

            {/* Youtube video */}
            {selectedVariant.attributes.att_txt_youtube_video_link && (
                <div className={'product-detail-video'} id={'#product-details-youtube'}>
                    <iframe
                        data-cmp-vendor={'s30'}
                        src={'about:blank'}
                        className={'cmplazyload'}
                        width={'100%'}
                        height={isMobile ? '350' : '450'}
                        data-cmp-src={selectedVariant.attributes.att_txt_youtube_video_link}
                        frameBorder={'0'}
                        allow={'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'}
                        allowFullScreen
                        title={'Embedded youtube'}
                    />
                </div>
            )}

            {/* Loadbee Content */}
            {selectedVariant.attributes.att_prod_gtin && (
                <div
                    className={'loadbeeTabContent lg:px-8'}
                    id={'loadbeeTabContentId'}
                    data-loadbee-apikey={'H6cV9Tw5G6M8G3uyNYZnHRvMSTwKRxSr'}
                    data-loadbee-gtin={selectedVariant.attributes.att_prod_gtin}
                    data-loadbee-gtintype={'ean'}
                    data-loadbee-locale={'de_DE'}
                />
            )}
        </div>
    )
}

ProductInfo.propTypes = {
    intl: PropTypes.object.isRequired,
    productColor: PropTypes.object.isRequired,
    selectedVariant: PropTypes.object.isRequired,
}

export default injectIntl(ProductInfo)

import React from 'react'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import BrandList from '../../patterns/organisms/Brand'

const BrandListTastic = ({ data }) => {
    const { brands, isDarkMode } = data

    return (
        <>
            <BrandList
                brands={brands}
                isDarkMode={isDarkMode}
            />
        </>
    )
}
BrandListTastic.propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
}
export default tastify({ translate: true, connect: { cart: true, node: true } })(injectIntl(BrandListTastic))

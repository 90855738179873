import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import app from '@frontastic/catwalk/src/js/app/app'
import useMdBreakpoint from '../../../molecules/Layout/Breakpoints/useMdBreakpoint'
import PanelModalResponsive from '../../../molecules/Layout/PanelModalResponsive'
import PanelBlockResponsive from '../../../molecules/Layout/PanelBlockResponsive'
import AccountDetailsOverview from './Overview'
import ChangePasswordForm from '../Forms/ChangePasswordForm'
import PersonalDetailsForm from '../Forms/PersonalDetailsForm'
import CriteoManager from '../../../../domain/CriteoManager'

const AccountDetails = ({
    firstName,
    lastName,
    email,
    openPanel,
    onClose,
}) => {
    const [showEditDetailsPanel, setShowEditDetailsPanel] = useState(false)
    const [showEditDetailsModal, setShowEditDetailsModal] = useState(false)
    const [showChangePasswordPanel, setShowChangePasswordPanel] = useState(false)
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [hasScript, setHasScript] = useState(false)
    const { showPanel, showModal } = useMdBreakpoint()

    const notifications = useSelector((state) => state.user.notifications || {})

    useEffect(() => {
        setShowLoader(false)
    }, [notifications])

    useEffect(() => {
        const interval = setInterval(() => {
            if (window.criteo_q && !hasScript) {
                setHasScript(true)
            }
        }, 500)
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        if (hasScript) {
            CriteoManager.sendEmail(email)
        }
    }, [hasScript])

    return (
        <>
            <PanelBlockResponsive
                title={'account.accountProfile'}
                isOpen={openPanel}
                onClose={onClose}
            >
                <AccountDetailsOverview
                    firstName={firstName}
                    lastName={lastName}
                    email={email}
                    openAccountDetailsForm={() => {
                        showPanel && setShowEditDetailsPanel(true)
                        showModal && setShowEditDetailsModal(true)
                    }}
                    openChangePasswordForm={() => {
                        showPanel && setShowChangePasswordPanel(true)
                        showModal && setShowChangePasswordModal(true)
                    }}
                />
            </PanelBlockResponsive>

            <PanelModalResponsive
                title={'account.editDetails'}
                openPanel={showEditDetailsPanel}
                closePanel={() => setShowEditDetailsPanel(false)}
                openModal={showEditDetailsModal}
                closeModal={() => setShowEditDetailsModal(false)}
            >
                <PersonalDetailsForm
                    firstName={firstName}
                    lastName={lastName}
                    showLoader={showLoader}
                    onSubmit={(data) => {
                        setShowLoader(true)

                        app.getLoader('context').updateUser(data).then(() => {
                            setShowEditDetailsPanel(false)
                            setShowEditDetailsModal(false)
                        })
                    }}
                />
            </PanelModalResponsive>

            <PanelModalResponsive
                title={'account.changePassword'}
                openPanel={showChangePasswordPanel}
                closePanel={() => setShowChangePasswordPanel(false)}
                openModal={showChangePasswordModal}
                closeModal={() => setShowChangePasswordModal(false)}
            >
                <ChangePasswordForm
                    showLoader={showLoader}
                    onSubmit={(oldPassword, newPassword) => {
                        setShowLoader(true)

                        app.getLoader('context').updatePassword(oldPassword, newPassword).then(() => {
                            setShowChangePasswordPanel(false)
                            setShowChangePasswordModal(false)
                        })
                    }}
                />
            </PanelModalResponsive>
        </>
    )
}

AccountDetails.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    openPanel: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default AccountDetails

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import app from '@frontastic/catwalk/src/js/app/app'

import AccountMenu from '../../../patterns/molecules/Account/Menu'
import WelcomeOverview from '../../../patterns/organisms/Account/AccountOverview/welcomeOverview'
import AccountDetails from '../../../patterns/organisms/Account/AccountDetails'

import MENU_ITEMS from '../../../patterns/molecules/Account/Menu/MENU_ITEMS'
import SessionService from '../../../services/session'
import ConfirmationRegister from '../../../patterns/molecules/Account/Confirmation'
import {useDeviceType} from "@frontastic/catwalk/src/js/helper/hooks/useDeviceType";

const AccountProfileTastic = ({ context, route }) => {
    const isMobile = useDeviceType() === 'mobile'
    const [openPanel, setOpenPanel] = useState(!isMobile)
    const [isConfirm, setIsConfirm] = useState(context.session.projectSpecificData.showRegisterMsg)
    const {
        session: {
            loggedIn,
            account: { firstName, lastName, email },
        },
    } = context

    useEffect(() => {
        if (!loggedIn && route.route !== 'Frontastic.Frontend.Master.Account.index') {
            app.getRouter().push('Frontastic.Frontend.Master.Account.index')
        }
    }, [loggedIn, route.route]);


    if (!loggedIn && route.route !== 'Frontastic.Frontend.Master.Account.index') {
        return undefined;
    }

    return (
        <>
            {isConfirm && (
                <ConfirmationRegister
                    onClick={() => {
                        SessionService.remove('showRegisterMsg')
                        setIsConfirm(false)
                    }}
                />
            )}
            <AccountMenu
                selectedMenuItem={MENU_ITEMS.ACCOUNT_DETAILS}
                welcome={<WelcomeOverview firstName={firstName} />}
                handleLogout={app.getLoader('context').logout}
                openPanel={() => {
                    setOpenPanel(false)
                }}
                onClose={() => {
                    setOpenPanel(true)
                }}
                isOpenPanel={openPanel}
            >
                <AccountDetails
                    firstName={firstName}
                    lastName={lastName}
                    email={email}
                    openPanel={openPanel}
                    onClose={() => {
                        setOpenPanel(true)
                    }}
                />
            </AccountMenu>
        </>
    )
}

AccountProfileTastic.propTypes = {
    context: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
}

export default tastify({
    translate: true,
    connect: {
        context: true,
        route: true,
    },
})(AccountProfileTastic)

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import getTranslation from '@frontastic/catwalk/src/js/getTranslation'
import { ConfigurationSchema } from 'frontastic-common'
import { useSelector } from 'react-redux'
import BlogArticleElements from '../../../patterns/organisms/GraphCMS/BlogArticleElement'
import SeeMoreBlogs from '../../../patterns/organisms/GraphCMS/SeeMoreBlogs'

export const BlogDetail = ({ data, rawData }) => {
    let article = data?.stream?.attributes[0]?.content?.blogArticle

    if (!article) {
        article = rawData?.stream?.__master?.attributes[0]?.content?.blogArticle

        if (!article) {
            return null
        }
    }

    const context = useSelector((globalState) => globalState.app && globalState.app.context)
    const TITLE_PREFIX_FIELD = 'frontasticPageTitlePrefix'
    const TITLE_SUFFIX_FIELD = 'frontasticPageTitleSuffix'
    const DEFAULT_TITLE_PREFIX = ''
    const DEFAULT_TITLE_SUFFIX = ' | Built on Frontastic'

    const getSchemaValue = (projectSchema, field, defaultValue) => {
        if (!projectSchema.has(field)) {
            return defaultValue
        }

        return getTranslation(
            projectSchema.get(field),
            context.locale,
            context.project.defaultLanguage,
        ).text
    }

    const getTitle = () => {
        const projectSchema = new ConfigurationSchema(
            context.projectConfigurationSchema,
            context.projectConfiguration,
        )

        return getSchemaValue(projectSchema, TITLE_PREFIX_FIELD, DEFAULT_TITLE_PREFIX)
            + article.seo_text_title
            + getSchemaValue(projectSchema, TITLE_SUFFIX_FIELD, DEFAULT_TITLE_SUFFIX)
    }

    return (
        <div>
            <Helmet>
                <title>{getTitle()}</title>
                <meta name={'description'} content={article.seo_text_description} />
            </Helmet>
            <BlogArticleElements blogArticle={article} />
            <SeeMoreBlogs blogArticle={article} />
        </div>
    )
}

BlogDetail.propTypes = {
    data: PropTypes.object.isRequired,
    rawData: PropTypes.object.isRequired,
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import app from '@frontastic/catwalk/src/js/app/app'
import Button from '../../../atoms/button/Button'

const CheckoutAddressCard = ({
    onSelect,
    onEditClicked,
    onDeleteClicked,
    addresses,
    defaultValue,
    intl,
}) => {
    const { register, getValues } = useForm({
        mode: 'onChange',
    })
    const [currentId, setCurrentId] = useState(null)
    const [isDeleted, setIsDeleted] = useState(false)
    const [listCountries] = useState(app.getApi().router.context.projectConfiguration.countries)

    const onChange = () => {
        onSelect(getValues())
    }

    const getDefaultId = () => {
        let id = ''
        addresses.map((item, index) => {
            if (item.addressId === defaultValue.addressId) {
                id = item.addressId
            }
        })

        if (id === '') {
            id = addresses[0].addressId
        }

        return id
    }

    const getDefaultChecked = (address) => (!currentId ? address.addressId === getDefaultId() : currentId === address.addressId)

    const getToCountryNameByCode = (code, list) => {
        let name = code
        list.forEach((item) => {
            if (item.code === code) {
                name = item.name
            }
        })

        return name
    }

    return (
        <form className={'checkout-address-content'} onChange={onChange}>
            {addresses.map((address, key) => (
                <div
                    key={address.addressId}
                    className={'address-type checkbox-radio--wrapper cursor-pointer'}
                    onClick={() => {
                        onChange()
                    }}
                >
                    <input
                        type={'radio'}
                        aria-label={'shipping address'}
                        {...register('id')}
                        value={address.addressId}
                        id={`checkout-shipping-${address.addressId}`}
                        className={'mr-3 mt-1'}
                        checked={isDeleted ? key === 0 : getDefaultChecked(address)}
                        onChange={() => {
                            setCurrentId(address.addressId)
                            setIsDeleted(false)
                            getValues()
                        }} />

                    <div className={'flex flex-col lg:flex-row w-full justify-between'}>
                        <div className={'shipping-address-content mb-3 lg:mb-0'}>
                            <div
                                className={'description-checkout-shipping font-sans'}
                            >
                                <label htmlFor={`checkout-shipping-${address.firstName}`}>
                                    {address.firstName}
                                    {' '}
                                    {address.lastName}
                                </label>
                            </div>
                            <div
                                className={'description-checkout-shipping font-sans'}
                            >
                                <label htmlFor={`checkout-shipping-${address.streetName}`}>
                                    {address.streetName}
                                    {' '}
                                    {address.streetNumber}
                                </label>
                            </div>
                            <div
                                className={'description-checkout-shipping font-sans'}
                            >
                                <label htmlFor={`checkout-shipping-${address.city}`}>
                                    {address.postalCode}
                                    {' '}
                                    {address.city}
                                </label>
                            </div>
                            <div
                                className={'description-checkout-shipping font-sans'}
                            >
                                <label htmlFor={`checkout-shipping-${address.additionalAddressInfo}`}>
                                    {address.additionalAddressInfo}
                                </label>
                            </div>
                            <div
                                className={'description-checkout-shipping font-sans'}
                            >
                                <label htmlFor={`checkout-shipping-${address.country}`}>
                                    {getToCountryNameByCode(address.country, listCountries)}
                                </label>
                            </div>
                        </div>
                        {(isDeleted || getDefaultChecked(address)) && (
                            <div className={'shipping-address-edit flex flex-row lg:flex-col'}>
                                <Button
                                    type={'button'}
                                    ariaLabel={intl.formatMessage({ id: 'account.edit' })}
                                    className={'font-sans edit-address cursor-pointer capitalize lg:justify-end underline font-normal tracking-normal mr-4 p-0 lg:mr-0 text-base mb-0'}
                                    onClick={onEditClicked}
                                >
                                    <FormattedMessage id={'account.edit'} />
                                </Button>

                                <Button
                                    type={'button'}
                                    ariaLabel={intl.formatMessage({ id: 'account.delete' })}
                                    className={'font-sans delete-address cursor-pointer capitalize lg:justify-end font-normal tracking-normal p-0 underline text-base mb-0'}
                                    disabled={addresses.length === 1}
                                    onClick={() => {
                                        setIsDeleted(true)
                                        onDeleteClicked()
                                    }}
                                >
                                    <FormattedMessage id={'account.delete'} />
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </form>
    )
}

CheckoutAddressCard.propTypes = {
    addresses: PropTypes.array.isRequired,
    defaultValue: PropTypes.object.isRequired,
    onEditClicked: PropTypes.func.isRequired,
    onDeleteClicked: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired,
}

export default injectIntl(CheckoutAddressCard)

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useForm } from 'react-hook-form'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { ConfigurationSchema } from '@frontastic/common/src/js/frontastic-common'
import Select from 'react-select'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { convertToCountryNameByCode } from '../countries'
import ErrorMessage from '../../../atoms/errorMessage'
import SubscribeService from '../../../../services/subscribe'
import NewsletterForm from '../../Forms/NewsletterForm'
import { patternValueWithoutBlank } from '../../../../services/validate'
import {
    setIsRequireTopicChecked,
    setIsSubscribeChecked,
    setTopics,
} from '../../../../store/actions/SubscribeEmailAction'
import { validateEmail } from '../../../../utils/helpers/accountHelper'

const DEFAULT_COUNTRY_CODE = 'DE'

const Billing = ({
    intl,
    countries,
    defaultEmail = '',
    defaultValues = {},
    onSubmit,
    isEmailDuplicated,
    restrictEmailDomains,
}) => {
    const [isCheckAll, setIsCheckAll] = useState(true)
    const context = useSelector((globalState) => globalState.app && globalState.app.context)
    const {
        isRequireTopicChecked,
        isSubscibeChecked,
        topics,
        invalidTopic,
    } = useSelector((state) => state['subscribe-email'])

    const validEmailMessage = intl.formatMessage({ id: 'validation.email' })
    const validPhoneMessage = intl.formatMessage({ id: 'validation.phone' })
    const validPostalCodeMessage = intl.formatMessage({ id: 'validation.postalCode' })
    const requiredFieldMessage = intl.formatMessage({ id: 'validation.required' })
    const [selectedCountry, setSelectedCountry] = useState(_.isEmpty(defaultValues) ? DEFAULT_COUNTRY_CODE : defaultValues.country)

    const projectSchema = new ConfigurationSchema(
        context.projectConfigurationSchema,
        context.projectConfiguration,
    )

    const { requireTopicValue, checkAllTopicValue } = projectSchema.configuration
    const topicRequired = requireTopicValue.replace(/\s/g, '').split(',')

    if (defaultEmail) {
        defaultValues = {
            ...defaultValues,
            email: defaultEmail,
        }
    }

    const {
        register,
        getValues,
        setValue,
        clearError,
        setError,

        formState: {
            errors,
        },
    } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues || {},
    })

    if (isEmailDuplicated) {
        setError('email', 'notMatch', intl.formatMessage({ id: 'account.register.EmailExisted' }))
    }

    const options = countries.map((value) => ({
        value: value.code,
        label: convertToCountryNameByCode(intl, value.code),
    }))

    const onChange = () => {
        onSubmit(getValues(), selectedCountry)
    }

    const handleChange = (value) => {
        setValue('postalCode', '')
        clearError('postalCode')

        setSelectedCountry(value.value)
        onSubmit(getValues(), value.value)
    }

    const getSelectedCountry = () => {
        if (!_.isEmpty(defaultValues)) {
            let selectedValue = {}
            options.map((item) => {
                if (item.value === defaultValues.country) {
                    selectedValue = item
                }
            })

            if (_.isEmpty(selectedValue)) {
                return options[0]
            }

            return selectedValue
        }

        return options[0]
    }

    const onChangeTopics = (item, title) => {
        if (topicRequired.indexOf(title) > -1) {
            setIsRequireTopicChecked(true)
        }

        const newTopics = SubscribeService.updateTopics(topics, item.name, title, topicRequired)
        const shouldUpdateCheckAll = SubscribeService.updateAllTopicValueByChild(newTopics, isCheckAll, title)
        setTopics(newTopics)
        setIsCheckAll(shouldUpdateCheckAll)
    }

    const onUpdateSubscribe = () => {
        if (!topics) {
            SubscribeService.getSubscribeInfo().then((res) => {
                setTopics(SubscribeService.initTopics(res.topics, checkAllTopicValue))
            })
        }
        setIsSubscribeChecked(!isSubscibeChecked)
    }

    const onCheckAllTopic = (isChecked, title) => {
        setTopics(SubscribeService.checkAllTopicValue(isCheckAll, title, topics))
        setIsCheckAll(!isCheckAll)
    }

    const [symbolsArr] = useState(['e', 'E', '+', '-', '.'])

    const validPhoneNumber = (e) => {
        if (_.isEmpty(e.target.value)) {
            return
        }

        const isValid = e.target.value.toString().match(/^[\d ()+-]+$/)

        if (_.isEmpty(isValid)) {
            e.target.value = e.target.value.replace(/^[\d ()+-]+$/, '')
        }
    }

    return (
        <form onChange={onChange}>
            <div className={'lg:grid-cols-2 lg:gap-6 lg:grid'}>
                <div className={classnames('mb-8 input--wrapper', {
                    'is--invalid': errors?.email,
                })}
                >
                    <input
                        id={'billing-email'}
                        {...register('email', {
                            required: requiredFieldMessage,
                            validate: (email) => validateEmail(email, restrictEmailDomains, validEmailMessage),
                        })}
                        type={'email'}
                        placeholder={`${intl.formatMessage({ id: 'checkout.form.email' })}`} />
                    <label htmlFor={'billing-email'}>
                        <FormattedMessage id={'checkout.form.email'} />
                    </label>
                    <span className={'input--border'} />
                    {errors?.email
                        && (
                            <span className={'input--hint'}>
                                <ErrorMessage errors={errors} name={'email'} />
                            </span>
                        )}
                    <div className={'my-4 text-xxs'}>
                        <FormattedHTMLMessage
                            id={'checkout.informationSubscribeEmail'}
                        />
                    </div>
                </div>
                <div className={'mb-8 input--wrapper'}>
                    <div className={'w-full checkbox-radio--wrapper'}>
                        <input
                            type={'checkbox'}
                            name={'checkboxSubscribe'}
                            id={'contact--checkbox-subscribe'}
                            checked={isSubscibeChecked}
                            onChange={() => onUpdateSubscribe()}
                        />
                        <label
                            className={'text-sm mb-6'}
                            htmlFor={'contact--checkbox-subscribe'}
                        >
                            <FormattedHTMLMessage id={'contactForm.checkbox.subsribeLabel'} />
                        </label>
                    </div>

                    {isSubscibeChecked && topics && (
                        <NewsletterForm
                            topics={topics}
                            requireTopicValue={requireTopicValue}
                            checkAllTopicValue={checkAllTopicValue}
                            isRequireTopicChecked={isRequireTopicChecked}
                            pageName={'checkout'}
                            onChange={(item, title) => {
                                onChangeTopics(item, title)
                            }}
                            isCheckAll={isCheckAll}
                            onCheckAll={(isCheckAll, title) => {
                                onCheckAllTopic(isCheckAll, title)
                            }}
                            invalidTopic={invalidTopic}
                        />
                    )}
                </div>
            </div>

            <div className={'lg:grid-cols-2 lg:gap-6 lg:grid'}>
                <div className={classnames('mb-8 input--wrapper', {
                    'is--invalid': errors?.firstName,
                })}
                >
                    <input
                        id={'billing-firstName'}
                        {...register('firstName', {
                            required: requiredFieldMessage,
                            pattern: {
                                value: patternValueWithoutBlank,
                                message: requiredFieldMessage,
                            },
                        })}
                        type={'text'}
                        placeholder={`${intl.formatMessage({ id: 'checkout.form.firstName' })}*`} />
                    <label htmlFor={'billing-firstName'}>
                        <FormattedMessage id={'checkout.form.firstName'} />
                    </label>
                    <span className={'input--border'} />
                    {errors?.firstName
                        && (
                            <span className={'input--hint'}>
                                <ErrorMessage errors={errors} name={'firstName'} />
                            </span>
                        )}
                </div>
                <div className={classnames('mb-8 input--wrapper', {
                    'is--invalid': errors?.lastName,
                })}
                >
                    <input
                        id={'billing-lastName'}
                        {...register('lastName', {
                            required: requiredFieldMessage,
                            pattern: {
                                value: patternValueWithoutBlank,
                                message: requiredFieldMessage,
                            },
                        })}
                        type={'text'}
                        placeholder={`${intl.formatMessage({ id: 'checkout.form.lastName' })}*`} />
                    <label htmlFor={'billing-lastName'}>
                        <FormattedMessage id={'checkout.form.lastName'} />
                    </label>
                    <span className={'input--border'} />
                    {errors?.lastName
                        && (
                            <span className={'input--hint'}>
                                <ErrorMessage errors={errors} name={'lastName'} />
                            </span>
                        )}
                </div>
            </div>

            <div className={classnames('mb-8 input--wrapper')}>
                <input
                    id={'billing-companyName'}
                    {...register('additionalAddressInfo', {
                        required: requiredFieldMessage,
                        pattern: {
                            value: patternValueWithoutBlank,
                            message: requiredFieldMessage,
                        },
                    })}
                    type={'text'}
                    placeholder={`${intl.formatMessage({ id: 'checkout.form.companyName' })}`} />
                <label htmlFor={'billing-companyName'}>
                    <FormattedMessage id={'checkout.form.companyName'} />
                </label>
                <span className={'input--border'} />
            </div>

            <div className={'lg:grid-cols-2 lg:gap-6 lg:grid'}>
                <div className={classnames('mb-8 input--wrapper', {
                    'is--invalid': errors?.streetName,
                })}
                >
                    <input
                        id={'billing-streetName'}
                        {...register('streetName', {
                            required: requiredFieldMessage,
                            pattern: {
                                value: patternValueWithoutBlank,
                                message: requiredFieldMessage,
                            },
                        })}
                        type={'text'}
                        placeholder={`${intl.formatMessage({ id: 'checkout.form.address' })}*`} />
                    <label htmlFor={'billing-streetName'}>
                        <FormattedMessage id={'checkout.form.address'} />
                    </label>
                    <span className={'input--border'} />
                    {errors?.streetName
                        && (
                            <span className={'input--hint'}>
                                <ErrorMessage errors={errors} name={'streetName'} />
                            </span>
                        )}
                </div>
                <div className={classnames('mb-8 input--wrapper', {
                    'is--invalid': errors?.streetNumber,
                })}
                >
                    <input
                        id={'billing-streetNumber'}
                        {...register('streetNumber', {
                            required: requiredFieldMessage,
                            pattern: {
                                value: patternValueWithoutBlank,
                                message: requiredFieldMessage,
                            },
                        })}
                        type={'text'}
                        placeholder={`${intl.formatMessage({ id: 'checkout.form.streetNumber' })}*`} />
                    <label htmlFor={'billing-streetNumber'}>
                        <FormattedMessage id={'checkout.form.streetNumber'} />
                    </label>
                    <span className={'input--border'} />
                    {errors?.streetNumber
                        && (
                            <span className={'input--hint'}>
                                <ErrorMessage errors={errors} name={'streetNumber'} />
                            </span>
                        )}
                </div>
            </div>

            <div className={'lg:grid-cols-2 lg:gap-6 lg:grid'}>
                <div className={classnames('mb-8 input--wrapper', {
                    'is--invalid': errors?.postalCode,
                })}
                >
                    {selectedCountry === DEFAULT_COUNTRY_CODE ? (
                        <>
                            <input
                                id={'billing-postalCode-de'}
                                {...register('postalCode', {
                                    required: requiredFieldMessage,
                                    pattern: {
                                        value: patternValueWithoutBlank,
                                        message: requiredFieldMessage,
                                    },
                                })}
                                type={'number'}
                                placeholder={`${intl.formatMessage({ id: 'checkout.form.zipCode' })}*`}
                                onKeyDown={(event) => symbolsArr.includes(event.key) && event.preventDefault()} />
                            <label htmlFor={'billing-postalCode-de'}>
                                <FormattedMessage id={'checkout.form.zipCode'} />
                            </label>
                            <span className={'input--border'} />
                            {errors?.postalCode
                                && (
                                    <span className={'input--hint'}>
                                        <ErrorMessage errors={errors} name={'postalCode'} />
                                    </span>
                                )}
                        </>
                    ) : (
                        <>
                            <input
                                id={'billing-postalCode'}
                                {...register('postalCode', {
                                    required: requiredFieldMessage,
                                    pattern: {
                                        value: /^(?!\s*$).+/,
                                    },
                                })}
                                type={'text'}
                                placeholder={`${intl.formatMessage({ id: 'checkout.form.zipCode' })}*`} />
                            <label htmlFor={'billing-postalCode'}>
                                <FormattedMessage id={'checkout.form.zipCode'} />
                            </label>
                            <span className={'input--border'} />
                            {errors?.postalCode
                                && (
                                    <span className={'input--hint'}>
                                        <ErrorMessage errors={errors} name={'postalCode'} />
                                    </span>
                                )}
                        </>
                    )}

                </div>
                <div className={classnames('mb-8 input--wrapper', {
                    'is--invalid': errors?.city,
                })}
                >
                    <input
                        id={'billing-city'}
                        {...register('city', {
                            required: requiredFieldMessage,
                            pattern: {
                                value: patternValueWithoutBlank,
                                message: requiredFieldMessage,
                            },
                        })}
                        type={'text'}
                        placeholder={`${intl.formatMessage({ id: 'checkout.form.city' })}*`} />
                    <label htmlFor={'billing-city'}>
                        <FormattedMessage id={'checkout.form.city'} />
                    </label>
                    <span className={'input--border'} />
                    {errors?.city
                        && (
                            <span className={'input--hint'}>
                                <ErrorMessage errors={errors} name={'city'} />
                            </span>
                        )}
                </div>
            </div>

            <div className={'lg:grid-cols-2 lg:gap-6 lg:grid'}>
                <div className={classnames('mb-8 select--wrapper', {
                    'is--invalid': errors?.country,
                })}
                >
                    <Select
                        defaultValue={getSelectedCountry}
                        options={options}
                        placeholder={'Country'}
                        className={'select-container'}
                        classNamePrefix={'select'}
                        onChange={(value) => handleChange(value)}
                    />
                    <span className={'input--border'} />
                    {errors?.country
                        && (
                            <span className={'input--hint'}>
                                <ErrorMessage errors={errors} name={'country'} />
                            </span>
                        )}
                </div>
                <div className={classnames('mb-8 input--wrapper', {
                    'is--invalid': errors?.phone,
                })}
                >
                    <input
                        id={'billing-phone'}
                        {...register('phone', {
                            pattern: {
                                value: /^[\d ()+-]+$/,
                                message: validPhoneMessage,
                            },
                        })}
                        type={'tel'}
                        placeholder={`${intl.formatMessage({ id: 'checkout.form.phone' })}`}
                        onChange={validPhoneNumber} />
                    <label htmlFor={'billing-phone'}>
                        <FormattedMessage id={'checkout.form.phone'} />
                    </label>
                    <span className={'input--border'} />
                    {errors?.phone
                        && (
                            <span className={'input--hint'}>
                                <ErrorMessage errors={errors} name={'phone'} />
                            </span>
                        )}
                </div>
            </div>
        </form>
    )
}

Billing.propTypes = {
    intl: PropTypes.object.isRequired,
    countries: PropTypes.array.isRequired,
    defaultValues: PropTypes.object.isRequired,
    defaultEmail: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    isEmailDuplicated: PropTypes.bool.isRequired,
}

export default Billing

import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'

const Payments = ({ payments }) => {
    const paymentArray = payments.filter((payment) => !!payment.logoUrl)

    if (!paymentArray || !paymentArray.length) {
        return null
    }

    return (
        <div className={'store-detail-payments'}>
            <h2 className={'store-detail-payments-headline mb-7 lg:mb-8'}>
                <FormattedMessage id={'storeDetail.paymentHeadline'} />
            </h2>
            <div className={'store-detail-payments-wrap'}>
                {paymentArray.map((payment) => (
                    <div className={'store-detail-payment'} key={payment.logoUrl}>
                        <img
                            src={payment.logoUrl}
                            alt={payment.logoUrl}
                            className={'store-detail-payment-img'}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

Payments.propTypes = {
    payments: PropTypes.array.isRequired,
}

export default injectIntl(Payments)

import app from '@frontastic/catwalk/src/js/app/app'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import PriceHelper from 'anwr_sport2000/src/js/services/price'
import { ReactComponent as RunningExpertLogo } from '../../../../../icons/sport2000-icons/RunningExperts_CMYK.svg'
import { ReactComponent as Sport2000 } from '../../../../../icons/sport2000-icons/logos/Sport_2000_logo.svg'
import { ReactComponent as AbsoluteSport } from '../../../../../icons/sport2000-icons/logos/absoluteteamsport.svg'
import FrontasticImage from '../../../atoms/frontasticImage'
import Price from '../../../atoms/price'

const Product = ({
    image,
    name,
    sku,
    link,
    count,
    price,
    discountedPrice,
    color,
    size,
    productCategory,
}) => {
    const isDesktop = useDeviceType() === 'desktop'

    const { runningCategoryId } = app.getApi().router.context.projectConfiguration
    const { absoluteCategoryId } = app.getApi().router.context.projectConfiguration

    const productRunningCategory = productCategory.findIndex((item) => item === runningCategoryId) > -1
    const productAbsoluteCategory = productCategory.findIndex((item) => item === absoluteCategoryId) > -1

    const renderLogoTheme = () => {
        if (productRunningCategory) {
            return (
                <>
                    <RunningExpertLogo width={isDesktop ? 160 : 115} height={28} />
                </>
            )
        }

        if (productAbsoluteCategory) {
            return (
                <>
                    <AbsoluteSport width={isDesktop ? 220 : 185} height={24} />
                </>
            )
        }

        return (
            <>
                <Sport2000 width={isDesktop ? 120 : 112} height={28} />
            </>
        )
    }

    return (
        <div className={'flex lg:justify-between'}>
            <div className={'product--image'}>
                {image && (
                    <FrontasticImage
                        src={image}
                        width={180}
                        height={200}
                    />

                )}
            </div>

            <div className={'my-auto product--information flex flex-col-reverse lg:flex-row lg:justify-between'}>
                <div className={'product--info'}>
                    <div className={'product-name text-md font-bold leading-tight'}>
                        <a
                            href={link}
                            title={name}
                        >
                            {name}
                        </a>
                    </div>
                    <table className={'w-full my-4 information--detail'}>
                        {sku && (
                            <tr className={'text-sm font-sans'} data-insights-object-id={sku}>
                                <th className={'mt-2 lg:mt-4 product-text text-left font-normal'}>
                                    <FormattedMessage id={'sku'} />
                                </th>
                                <td>
                                    {sku}
                                </td>
                            </tr>
                        )}

                        {color && (
                            <tr className={'text-sm font-sans'}>
                                <th className={'product-text text-sm text-left font-normal'}>
                                    <FormattedMessage id={'colorProduct'} />
                                </th>
                                <td>
                                    {color}
                                </td>
                            </tr>
                        )}

                        {size && (
                            <tr className={'text-sm leading-5 font-sans'}>
                                <th className={'product-text text-left font-normal'}>
                                    <FormattedMessage id={'product.sizeProduct'} />
                                </th>
                                <td>
                                    {size}
                                </td>
                            </tr>
                        )}

                        {count && (
                            <tr className={'text-sm font-sans'}>
                                <th className={'product-text text-left font-normal'}>
                                    <FormattedMessage id={'quantityProduct'} />
                                </th>
                                <td>
                                    {count}
                                </td>
                            </tr>
                        )}
                    </table>

                    <div>
                        <Price
                            variant={'product-price product-text product-price font-sans font-bold'}
                            value={discountedPrice || price}
                            className={PriceHelper.isDiscountOverThreshold(discountedPrice, price) && 'text-red-600'}
                        />
                    </div>
                </div>
                <div className={'product--logos mb-2 lg:mb-0'}>
                    {renderLogoTheme()}
                </div>
            </div>
        </div>
    )
}

Product.propTypes = {
    image: PropTypes.string,
    sku: PropTypes.string,
    link: PropTypes.string,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    discountedPrice: PropTypes.number,
    count: PropTypes.number.isRequired,
    color: PropTypes.string,
    size: PropTypes.string,
}

export default injectIntl(Product)

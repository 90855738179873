import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet';

import Canonical from './canonical'
import Robots from './robots'
import Base from './base'
import OpenGraph from './openGraph'

class MetaData extends Component {
    getNodeTitle() {
        if (this.props.node.configuration.seoTitle) {
            return this.props.node.configuration.seoTitle
        }

        if (this.props.node.name) {
            return this.props.node.name
        }

        return this.props.node.configuration.path ?? ''
    }

    getTitle() {
        return (this.props.context.projectConfiguration.frontasticPageTitlePrefix ?? '') +
            (this.getNodeTitle()) +
            (this.props.context.projectConfiguration.frontasticPageTitleSuffix ?? '')
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.getTitle()}</title>
                </Helmet>
                <Base node={this.props.node} context={this.props.context} />
                <Canonical node={this.props.node} context={this.props.context} />
                <OpenGraph node={this.props.node} context={this.props.context} />
                <Robots node={this.props.node} context={this.props.context} />
            </>
        )
    }
}

MetaData.propTypes = {
    node: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
}

MetaData.defaultProps = {}

export default connect(
    (globalState, props) => ({
        ...props,
        context: globalState.app.context,
    }),
)(MetaData)

import fetch from '@frontastic/bridge/js/fetch'

class SessionService {
    async remove(sessionName) {
        try {
            const res = await fetch(`/api/session/remove/${sessionName}/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            return await res.json()
        } catch (e) {
            throw e
        }
    }
}

export default new SessionService()

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import NoOrders from './NoOrders'
import PanelBlockResponsive from '../../../molecules/Layout/PanelBlockResponsive'
import SelectedOrder from './SelectedOrder'
import PanelModalResponsive from '../../../molecules/Layout/PanelModalResponsive'
import ListOrders from './ListOrders'

const AccountOrders = ({
    openPanel, onClose, orders,
}) => {
    const [selectedOrder, setSelectedOrder] = useState(null)

    return (
        <>
            <PanelBlockResponsive
                title={'account.placedOrders'}
                isOpen={openPanel}
                onClose={onClose}
            >
                {orders.length ? (
                    !selectedOrder && <ListOrders orders={orders} selectOrder={setSelectedOrder} />
                ) : (
                    <NoOrders />
                )}
            </PanelBlockResponsive>

            <PanelModalResponsive
                subTitle={selectedOrder && `#${selectedOrder?.orderId}`}
                title={'account.order'}
                openPanel={!!selectedOrder}
                openModal={!!selectedOrder}
                closeModal={() => {
                    setSelectedOrder(null)
                }}
            >
                {selectedOrder && <SelectedOrder order={selectedOrder} />}
            </PanelModalResponsive>
        </>
    )
}

AccountOrders.propTypes = {
    openPanel: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    orders: PropTypes.array.isRequired,
}

export default AccountOrders

import { ConfigurationSchema } from 'frontastic-common'
import { useSelector } from 'react-redux'

class Gtag {
    getId = () => {
        const context = useSelector((globalState) => globalState.app && globalState.app.context)
        const projectSchema = new ConfigurationSchema(
            context.projectConfigurationSchema,
            context.projectConfiguration,
        )
        return projectSchema.configuration.tagManagerId
    }
}

export default new Gtag()

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import app from '@frontastic/catwalk/src/js/app/app'
import Message from '@frontastic/catwalk/src/js/app/message'
import classnames from 'classnames'
import BlogCategoryITem from '../../../patterns/organisms/GraphCMS/BlogCategory'
import FullPageWidthWrapper from '../../../patterns/molecules/Layout/FullPageWidthWrapper'
import BaseDotted from '../../../patterns/atoms/dotted/BaseDotted'
import { DottedFadebarVariantB5mm } from '../../../patterns/atoms/dotted'
import fetch from '@frontastic/bridge/js/fetch'

const BlogList = ({
    node, data, tastic, route,
}) => {
    if (!data?.content?.data?.blogArticles) {
        return null
    }
    const amount = 9
    const [blogs, setBlogs] = useState([...data.content.data.blogArticles])
    const [isLoading, setLoading] = useState(false)
    const [isFinish, setIsFinish] = useState(data.content.data.blogArticles.length < amount)
    const isBlogCategory = node.nodeType === 'blog-category'

    const queryBlogArticles = (url) => fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            setLoading(false)
            app.getLoader('context').notifyUser(<Message {...error} />, 'error')
        })

    const updateQueryString = (offset, limit) => {
        const url = app.getRouter().location(route.route, {
            ...route.parameters,
            offset,
            limit,
        })
        window.history.replaceState(null, '', `${url.pathname}${url.search}`)
    }

    const loadMore = () => {
        if (isLoading) {
            return
        }

        setLoading(true)
        const originParameter = new URLSearchParams(window.location.search)
        const currentOffset = +originParameter.get('offset') || 0
        const offset = currentOffset + amount
        const baseApiUrl = `/api/graphcms/bloglist/pagination?offset=${offset}&amount=${amount}`
        const entitySlug = node?.configuration?.entity?.slug
        const apiUrl = entitySlug && isBlogCategory ? `${baseApiUrl}&blogCategorySlug=${entitySlug}` : baseApiUrl

        queryBlogArticles(apiUrl).then((response) => {
            setLoading(false)
            const blogArticleItems = response?.data?.blogArticles

            if (blogArticleItems && blogArticleItems.length) {
                const newBlogs = [...blogs, ...blogArticleItems]
                setBlogs(newBlogs)

                if (blogArticleItems.length < amount) {
                    setIsFinish(true)
                }
            } else {
                setIsFinish(true)
            }
        })
        updateQueryString(offset, amount)
    }

    const loadMoreHref = () => {
        const originParameter = new URLSearchParams(window.location.search)
        const currentOffset = +originParameter.get('offset') || 0
        const offset = currentOffset + amount
        const url = `${window.location.pathname}?offset=${offset}&amount=${amount}`

        return url
    }

    const renderDottedBar = () => {
        if (!data.isDotted) {
            return null
        }
        const dottedColor = data.bgColor

        return (
            <div className={'teaser--dotted-bar'}>
                <BaseDotted
                    dottedSVG={<DottedFadebarVariantB5mm fill={dottedColor} />}
                    height={14}
                    isTop
                    isRotate
                />
                <BaseDotted
                    dottedSVG={<DottedFadebarVariantB5mm fill={dottedColor} />}
                    height={14}
                    isTop
                    from={'right'}
                />
            </div>
        )
    }

    const style = {
        backgroundColor: data.bgColor,
    }

    const headline = isBlogCategory ? node.name : data.headline
    return (
        <>
            <>
                {headline && (
                    <FullPageWidthWrapper
                        customClasses={classnames('blog-category-headline-bg relative', {
                            'py-comp': data.bgColor && data.isBackground,
                            'mb-4': data.isDotted,
                        })}
                        style={data.isBackground ? style : null}
                    >
                        <div className={'o-wrapper'}>
                            {renderDottedBar()}
                            <h1 className={'blog-category-headline'}>{headline}</h1>
                        </div>
                    </FullPageWidthWrapper>
                )}
            </>

            <BlogCategoryITem
                blogArticles={blogs}
                handleLoadMore={loadMore}
                amount={amount}
                loadMoreHref={loadMoreHref}
                isLoading={isLoading}
                isFinish={isFinish}
                headline={headline}
            />
        </>
    )
}

BlogList.propTypes = {
    node: PropTypes.objectOf(PropTypes.any).isRequired,
    data: PropTypes.object.isRequired,
    route: PropTypes.any,
    tastic: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default tastify({
    translate: true,
    connect: {
        route: true,
        node: true
    },
})(BlogList)

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import BREAKPOINTS from '../../../../config/breakpoints'

const UseMdBreakpoint = () => {
    const [windowWidth, setWindowWidth] = useState(0)

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }

        setWindowWidth(window.innerWidth)

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const { showPanel, showModal } = useSelector((state) => {
        const width = state.renderContext.viewportDimension
            ? state.renderContext.viewportDimension.width
            : windowWidth

        return {
            showPanel: width < BREAKPOINTS.md,
            showModal: width >= BREAKPOINTS.md,
        }
    })

    return { showPanel, showModal }
}

export default UseMdBreakpoint

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    FormattedHTMLMessage, FormattedMessage, injectIntl,
} from 'react-intl'
import PropTypes from 'prop-types'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import Select from 'react-select'
import classnames from 'classnames'
import app from '@frontastic/catwalk/src/js/app/app'
import Message from '@frontastic/catwalk/src/js/app/message'
import { useCookies } from 'react-cookie'
import debounce from 'lodash.debounce'
import CartService from '../../../services/cart'
import Button from '../../atoms/button/Button'
import Price from '../../atoms/price'
import ColorSelector from './Selectors/ColorSelector'
import SizeSelector from './Selectors/SizeSelector'
import { ReactComponent as CheckCircleIcon } from '../../../../icons/sport2000-icons/check-circle.svg'
import { ReactComponent as CartIcon } from '../../../../icons/sport2000-icons/cart.svg'
import { ReactComponent as HeartFillIcon } from '../../../../icons/sport2000-icons/heart-filled.svg'
import { ReactComponent as HeartIcon } from '../../../../icons/sport2000-icons/heart.svg'
import { ReactComponent as TruckIcon } from '../../../../icons/sport2000-icons/truck.svg'
import { ReactComponent as StoreIcon } from '../../../../icons/sport2000-icons/store.svg'
import IconButton from '../../atoms/button/IconButton'
import ProductTags from '../../atoms/tag/productTags'
import LoaderButton from '../../molecules/Loaders/LoaderButton'
import GtmService from '../../../services/gtm'
import TagManager from '../../../domain/TagManager'
import StoreService, { StatusStockOfStore } from '../../../services/algolia/StoreService'
import PriceHelper from '../../../services/price'

const ProductData = ({
    intl,
    colorProducts,
    setColorProducts,
    variants,
    selectedVariant,
    onChange,
    handleAddToCart,
    setOpenPanel,
    brandSizeTable,
    onTrackPurchaseGTM,
    indexVariantFromCart,
    wishlisted = false,
    wishlistItem,
    refererStore,
    onOpenOffCanvasStoreFinder,
    onUpdateSkuToUrl,
    queryId,
    context,
}) => {
    if (!selectedVariant) {
        return null
    }
    const [showLoader, setShowLoader] = useState(false)
    const [selectedQuantity, setSelectedQuantity] = useState({})
    const [quantityOptions, setQuantityOptions] = useState({})
    const isLoading = useSelector((globalState) => globalState.cart && globalState.cart.cart.loading)
    const loading = showLoader && isLoading
    const initSizeVariants = []
    const [isChooseSize, setIsChooseSize] = useState(false)
    const [isChooseColor, setIsChooseColor] = useState(false)
    const [isClicked, setIsClicked] = useState(false)
    const isDesktop = useDeviceType() === 'desktop'
    const isMobile = useDeviceType() === 'mobile'
    const [changeLabelSize, setChangeLabelSize] = useState(false)
    const [isAddedStatus, setIsAddedStatus] = useState(false)
    const [stock, setStock] = useState(0)
    const [productTags, setProductTags] = useState(selectedVariant?.attributes?.att_internal_category_sport2000 || [])
    const [variantCheckedStockInStore, setVariantCheckedStockInStore] = useState(selectedVariant)
    const [isProductOnSale, setIsProductOnSale] = useState(false)
    const [cookie] = useCookies()
    const { store } = cookie
    const mainStore = refererStore || store || ''
    const { algoliaIndexName } = context?.projectConfiguration || {}

    const wishlistData = useSelector((state) => state.wishlist?.wishlist?.data || [])

    const gtmService = new GtmService(null, selectedVariant, null, null)
    const productGTM = gtmService.createProductGTM('Product Detail', {
        quantity: 1,
    })

    const handleLoadBeeScript = () => {
        const LOAD_BEE_SCRIP = 'https://cdn.loadbee.com/js/loadbee_integration.js'
        const el = document.createElement('script')
        el.src = LOAD_BEE_SCRIP

        document.body.appendChild(el)

        return new Promise((resolve) => {
            el.addEventListener('load', (r) => resolve(r))
        })
    }

    useEffect(() => {
        if (onUpdateSkuToUrl) {
            onUpdateSkuToUrl(selectedVariant.sku)
        }
    }, [selectedVariant])

    useEffect(() => {
        if (window.loadbeeIntegration && window.loadbeeIntegration.checkAvailability) {
            window.loadbeeIntegration.checkAvailability(selectedVariant.attributes.att_prod_gtin)
            return
        }

        let delay
        handleLoadBeeScript().then(() => {
            delay = setTimeout(() => {
                if (window.loadbeeIntegration && window.loadbeeIntegration.checkAvailability) {
                    window.loadbeeIntegration.checkAvailability(selectedVariant.attributes.att_prod_gtin)
                }
            }, 200)
        })

        return () => {
            window.clearTimeout(delay)
        }
    }, [])

    useEffect(() => {
        setSelectedQuantity({ value: 1 })
        let tmpStock = selectedVariant.projectSpecificData.stock
        let storeStock = tmpStock

        if (refererStore && selectedVariant) {
            tmpStock = 0

            if (refererStore.channelId) {
                tmpStock = StoreService.checkProductStockInStore(refererStore, selectedVariant).stock
                storeStock = tmpStock
            }
        } else if (store && selectedVariant) {
            storeStock = StoreService.checkProductStockInStore(store, selectedVariant).stock
        }

        setStock(storeStock)
        setQuantityOptions(Array.from(Array(tmpStock).keys()).map((i) => ({
            value: i + 1, label: i + 1,
        })).slice(0, 10))

        const variantSelectedCheckedStockInStore = StoreService.checkProductStockInStore(mainStore, selectedVariant)
        const isSelectedVariantOnSale = PriceHelper.isDiscountOverThreshold(variantSelectedCheckedStockInStore.discountedPrice, variantSelectedCheckedStockInStore.price)
        setIsProductOnSale(isSelectedVariantOnSale)
        setVariantCheckedStockInStore(variantSelectedCheckedStockInStore)

        if (isSelectedVariantOnSale) {
            setProductTags([...productTags, 'sale'])
        } else {
            setProductTags(selectedVariant?.attributes?.att_internal_category_sport2000 || [])
        }
    }, [selectedVariant, cookie, refererStore])

    const delayShowAddToCartStatus = debounce(() => {
        if (isAddedStatus) {
            setIsAddedStatus(false)
        }
    }, 1500)

    useEffect(() => {
        delayShowAddToCartStatus()

        return () => delayShowAddToCartStatus.cancel()
    }, [isAddedStatus])

    useEffect(() => {
        if (indexVariantFromCart !== -1) {
            setIsChooseSize(true)
        }
    }, [indexVariantFromCart])

    variants.map((item, index) => {
        if (item.attributes.att_prod_color_name_sport2000 === selectedVariant.attributes.att_prod_color_name_sport2000) {
            initSizeVariants[index] = item
        }

        return item
    })

    const [variantsData, setVariantsData] = useState(initSizeVariants)

    const handleToggleMiniCart = () => {
        CartService.openMiniCart()

        setTimeout(() => {
            CartService.closeMiniCart()
        }, 3000)
    }

    const checkHaveSize = (variantToCheck) => (variantToCheck.attributes.attr_size)

    const checkIsEmptyDiscountPrice = (variantToCheck) => {
        if (variantToCheck.discountedPrice === 0) {
            return null
        }

        return variantToCheck.discountedPrice
    }

    const renderAddToCartBtnContent = () => {
        if (isLoading) {
            return <LoaderButton />
        }

        if (isAddedStatus) {
            return <FormattedMessage id={'productDetail.added'} />
        }

        if (!quantityOptions.length) {
            return <FormattedMessage id={'product.outOfStock'} />
        }

        return <FormattedMessage id={'inCartProduct'} />
    }

    const renderStore = () => {
        if (!mainStore) {
            return (
                <button
                    type={'button'}
                    aria-label={intl.formatMessage({ id: 'productDetail.selectedStoreWithStockStatus' })}
                    onClick={onOpenOffCanvasStoreFinder}
                >
                    <FormattedMessage id={'productDetail.selectedStoreWithStockStatus'} />
                </button>
            )
        }

        return (
            <>
                {selectedVariant && (
                    <>
                        <FormattedMessage
                            id={`productDetail.${StoreService.checkProductStockInStore(mainStore, selectedVariant).status}`}
                        >
                            {(txt) => (
                                <span
                                    className={classnames('status-stock-store capitalize mr-1', {
                                        available: StoreService.checkProductStockInStore(mainStore, selectedVariant).status === StatusStockOfStore.available,
                                        'out-of-stock': StoreService.checkProductStockInStore(mainStore, selectedVariant).status === StatusStockOfStore.outOfStock,
                                        'no-store': StoreService.checkProductStockInStore(mainStore, selectedVariant).status === StatusStockOfStore.noStore,
                                    })}
                                >
                                    {txt}
                                </span>
                            )}
                        </FormattedMessage>
                        <FormattedMessage id={'productDetail.in'} />
                    </>
                )}
                <span className={'underline ml-1'}>
                    {mainStore.name}
                </span>
                {!refererStore && (
                    <button
                        type={'button'}
                        aria-label={intl.formatMessage({ id: 'productDetail.selectedStoreWithStockStatus' })}
                        onClick={onOpenOffCanvasStoreFinder}
                    >
                        <FormattedMessage id={'productDetail.changeStore'} />
                    </button>
                )}
            </>
        )
    }

    const renderPrice = () => {
        if (!selectedVariant) {
            return null
        }

        if (isProductOnSale) {
            return (
                <>
                    <div className={'product--price-discount font-bold'}>
                        <Price
                            value={variantCheckedStockInStore.discountedPrice || variantCheckedStockInStore.price}
                            currency={selectedVariant.currency}
                        />
                    </div>
                </>
            )
        }

        return (
            <div className={'product--price-normal font-bold'}>
                <Price
                    value={variantCheckedStockInStore.discountedPrice || variantCheckedStockInStore.price}
                    currency={selectedVariant.currency}
                />
            </div>
        )
    }

    if (variantsData.length === 1) {
        // If there is only one variant, we can skip the size selection
        onChange(0)
    }

    const getProductName = () => {
        const attrShoeWidth = selectedVariant?.attributes?.att_prop_shoe_general_width?.toLowerCase() !== 'normal' ? selectedVariant.attributes?.att_prop_shoe_general_width : ''
        const attrGender = selectedVariant?.attributes?.att_prod_gender
        const attrCategoryOnlineShop = selectedVariant?.attributes?.att_internal_category_online_shop
        const attrProductName = selectedVariant?.attributes?.att_prod_product_name || selectedVariant?.name

        let productName = attrProductName

        if (attrShoeWidth) {
            productName += ` ${attrShoeWidth}`
        }

        if (attrGender) {
            productName += ` ${attrGender}`
        }

        if (attrCategoryOnlineShop) {
            productName += ` ${attrCategoryOnlineShop}`
        }

        return productName
    }

    const renderColorProduct = () => {
        if (colorProducts?.length <= 1 && !selectedVariant.attributes.att_prod_color_name_sport2000) {
            return null
        }

        return (
            <div className={classnames('product-variant-color', {
                '-mx-4': !isDesktop,
            })}
            >
                {isDesktop
                    && (
                        <div className={'product--color-label font-normal'}>
                            {selectedVariant.attributes.att_prod_color_name_sport2000}
                        </div>
                    )}

                <ColorSelector
                    value={selectedVariant.attributes.att_prod_color_name_sport2000}
                    variants={colorProducts}
                    setColorProducts={setColorProducts}
                    onChange={() => onChange(null)}
                    setVariantsData={setVariantsData}
                    isColor
                    setIsChooseSize={setIsChooseSize}
                    setIsClicked={setIsClicked}
                    isChooseColor={isChooseColor}
                    setIsChooseColor={setIsChooseColor}
                    onUpdateSkuToUrl={onUpdateSkuToUrl}
                    context={context}
                />
            </div>
        )
    }

    return (
        <div className={classnames('product--data', {
            'product--data-desktop': isDesktop,
            'product--data-mobile': !isDesktop,
        })}
        >
            {isDesktop
                && <ProductTags tags={productTags} className={'mx-0 mb-3'} />}

            {!isDesktop && renderColorProduct()}

            {selectedVariant.attributes.att_prod_brand
                && (
                    <p className={'product--brand mx-0 mb-1'}>
                        <a
                            href={selectedVariant.attributes.att_prod_brand_url}
                            title={selectedVariant.attributes.att_prod_brand}
                        >
                            {selectedVariant.attributes.att_prod_brand}
                        </a>
                    </p>
                )}

            <h1 className={classnames('product--name mx-0', {
                'mb-3': isDesktop,
                'mb-2': !isDesktop,
            })}
            >
                {getProductName()}
            </h1>

            {(colorProducts.length > 0) && (
                <>
                    {(colorProducts.length === 1 && !isDesktop) && (
                        <div className={'product--color-label font-normal'}>
                            <FormattedHTMLMessage id={'productDetail.colorLabel'} />
                            {' '}
                            {selectedVariant.attributes.att_prod_color_name_sport2000}
                        </div>
                    )}

                    {(colorProducts.length === 1 && isDesktop) && (
                        <div className={'product--one-color text-sm mb-2'}>
                            <FormattedHTMLMessage id={'productDetail.colorLabel'} />
                            {' '}
                            {selectedVariant.attributes.att_prod_color_name_sport2000}
                        </div>
                    )}

                    {(!isDesktop && colorProducts.length > 1)
                        && (
                            <div className={'product--color-label font-normal'}>
                                {selectedVariant.attributes.att_prod_color_name_sport2000}
                            </div>
                        )}
                </>
            )}

            <div className={'product--price--tax'}>
                <div className={'product--price-wrapper'}>
                    {renderPrice()}
                </div>
                <div className={'product--tax'}>
                    <FormattedHTMLMessage id={'productDetail.tax'} />
                </div>
            </div>

            {isDesktop && renderColorProduct()}

            {(variantsData.length === 1) && (
                <div className={'flex flex-col'}>
                    <div>
                        {brandSizeTable.brandSizeTables && brandSizeTable.brandSizeTables.length > 0 && (
                            <Button
                                type={'button'}
                                className={classnames('button-table-size label-name leading-4 underline mt-2 mr-3 p-0 h-auto font-normal capitalize tracking-normal justify-start', {
                                    'order-10': !isDesktop,
                                })}
                                onClick={() => {
                                    setOpenPanel(true)
                                }}
                            >
                                <FormattedMessage id={'product.sizeProduct'} />

                            </Button>
                        )}
                        {selectedVariant.attributes.att_prod_size_mf && (
                            <Button
                                type={'button'}
                                className={'button-table-size label-name leading-4 underline mr-3 p-0 h-auto font-normal normal-case tracking-normal'}
                                onClick={() => {
                                    setChangeLabelSize(!changeLabelSize)
                                }}
                            >
                                <FormattedMessage
                                    id={changeLabelSize ? 'product.sizeManufacture' : 'product.sizeNormal'}
                                />
                            </Button>
                        )}
                    </div>

                    <div className={classnames('product--one-color text-sm', {
                        'product--size-label': !isDesktop,
                    })}
                    >
                        <FormattedHTMLMessage id={'productDetail.sizeLabel'} />
                        {' '}
                        {changeLabelSize ? selectedVariant.attributes.att_prod_size_mf : selectedVariant.attributes.attr_size}
                    </div>
                </div>
            )}

            {(variantsData.length > 1 && selectedVariant.attributes.attr_size) && (
                <div className={classnames('product-variant-size', {
                    'mb-8': !isDesktop,
                })}
                >
                    <SizeSelector
                        value={selectedVariant.attributes.attr_size}
                        colorValue={selectedVariant.attributes.att_prod_color_name_sport2000}
                        variants={variantsData}
                        onChange={onChange}
                        isChooseSize={isChooseSize}
                        setIsChooseSize={setIsChooseSize}
                        setIsClicked={setIsClicked}
                        isClicked={isClicked}
                        isChooseColor={isChooseColor}
                        setIsChooseColor={setIsChooseColor}
                        setOpenPanel={setOpenPanel}
                        brandSizeTable={brandSizeTable}
                        selectedSkuFromCart={selectedVariant.sku}
                        refererStore={refererStore}
                        context={context}
                    />
                </div>
            )}

            <div className={'product--store'}>
                <StoreIcon width={20} height={20} />
                <div className={'product--store__information'}>
                    {renderStore()}
                </div>
            </div>

            {quantityOptions.length > 0
                && (
                    <div className={'product--data-status'}>
                        <div className={'flex data-status-wrapper'}>
                            <TruckIcon width={20} height={20} />
                            <span className={'status-text status-delivery-text'}>
                                <FormattedHTMLMessage id={'product.availableForDelivery'} />
                            </span>
                        </div>
                    </div>
                )}

            <div className={classnames('product--add-to-cart flex gap-4 mb-4', {
                'mb-8': !isDesktop,
            })}
            >
                {quantityOptions.length > 1 && (
                    <Select
                        aria-label={intl.formatMessage({ id: 'product.quantityLabel' })}
                        defaultValue={{ value: 1, label: 1 }}
                        options={quantityOptions}
                        className={'product--quantity select-container flex-shrink-0 z-10'}
                        classNamePrefix={'select'}
                        onChange={setSelectedQuantity}
                        isDisabled={loading}
                    />
                )}

                <IconButton
                    ariaLabel={intl.formatMessage({ id: 'inCartProduct' })}
                    className={'btn btn-cta btn-add-to-cart icon--left full-width flex-1'}
                    onClick={() => {
                        setShowLoader(true)
                        const options = {}
                        if (isDesktop) {
                            const cartProduct = {
                                ...selectedVariant,
                                ...variantCheckedStockInStore,
                            }

                            CartService.saveAddedProduct(cartProduct, getProductName(), selectedQuantity.value)
                        }

                        if (stock) {
                            if (refererStore || store) {
                                options.store = refererStore || store
                            }

                            if (queryId) {
                                options.queryId = queryId
                            }
                        }

                        handleAddToCart(selectedVariant, selectedQuantity.value, options).then((response) => {
                            if (!response.ok && response.code) {
                                app.getLoader('context').notifyUser(<Message code={response.code} />, 'error')
                                app.getStore().dispatch({
                                    type: 'CartApi.Cart.add.error',
                                    error: response,
                                })

                                return
                            }

                            app.getStore().dispatch({
                                type: 'CartApi.Cart.add.success',
                                data: response,
                            })

                            if (isMobile) {
                                app.getLoader('context').notifyUser(<Message
                                    message={intl.formatMessage({ id: 'account.message.productAdd' })}
                                />,
                                'success')
                            }

                            setIsAddedStatus(true)

                            if (isDesktop) {
                                setIsClicked(false)
                                handleToggleMiniCart()
                            }

                            setShowLoader(false)
                            onTrackPurchaseGTM()
                            TagManager.addedToCartObjectIDsAfterSearch(response.addedItems[0], algoliaIndexName)
                        })
                    }}
                    disabled={loading || quantityOptions.length === 0}
                    icon={!loading && (quantityOptions.length > 0 && !isAddedStatus
                        ? <CartIcon width={20} height={20} /> : <CheckCircleIcon width={20} height={20} />)}
                    type={'button'}
                >
                    {renderAddToCartBtnContent()}
                </IconButton>
            </div>
            <div className={'product--add-to-wishlist mb-8'}>
                <IconButton
                    type={'button'}
                    ariaLabel={intl.formatMessage({ id: 'wishlist.myWishlist' })}
                    className={'btn btn-secondary icon--left full-width flex-1'}
                    icon={wishlisted ? <HeartFillIcon width={20} height={20} /> : <HeartIcon width={20} height={20} />}
                    onClick={() => {
                        if (!wishlisted) {
                            app.getLoader('wishlist').add(selectedVariant, selectedVariant, 1, null)
                            TagManager.addWishList(productGTM)
                        }

                        if (wishlisted) {
                            app.getLoader('wishlist').removeLineItem(wishlistData.wishlistId, {
                                lineItemId: wishlistItem.lineItemId,
                            })
                        }
                    }}
                >
                    {wishlisted ? <FormattedMessage id={'wishlist.removeToWishlist'} />
                        : <FormattedMessage id={'wishlist.addToWishlist'} />}
                </IconButton>
            </div>
        </div>
    )
}

ProductData.propTypes = {
    intl: PropTypes.object.isRequired,
    variants: PropTypes.array.isRequired,
    colorProducts: PropTypes.array.isRequired,
    selectedVariant: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    handleAddToCart: PropTypes.func.isRequired,
    setColorProducts: PropTypes.func.isRequired,
    setOpenPanel: PropTypes.func.isRequired,
    brandSizeTable: PropTypes.object.isRequired,
    onTrackPurchaseGTM: PropTypes.func.isRequired,
    indexVariantFromCart: PropTypes.number.isRequired,
    wishlisted: PropTypes.bool,
    wishlistItem: PropTypes.object,
    refererStore: PropTypes.any,
    onUpdateSkuToUrl: PropTypes.any,
    onOpenOffCanvasStoreFinder: PropTypes.any,
    queryId: PropTypes.any,
    context: PropTypes.any,
}

export default injectIntl(ProductData)

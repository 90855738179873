export type StoreAction = {
    type: string,
    data: StoreActionData | null,
}

export type StoreActionData = {
    isSelect?: boolean
    store?: object[]
    themeStore?: string
}

export const setIsSelectStore = (state: StoreActionData): StoreAction => ({
    type: 'ACTION_SELECT_STORE',
    data: state,
})

export const setThemeStore = (state: StoreActionData): StoreAction => ({
    type: 'ACTION_THEME_STORE',
    data: state,
})

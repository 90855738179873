import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../atoms/button/Button'

const SingleButton = ({
    label, reference, variant, color, colorHover,
}) => (
    <>
        {label && (
            <Button
                className={`btn btn-${variant} single--button btn--color-${color} btn--hover-${colorHover}`}
                reference={reference}
            >
                {label}
            </Button>
        )}
    </>
)

SingleButton.propTypes = {
    label: PropTypes.string.isRequired,
    reference: PropTypes.object,
    variant: PropTypes.oneOf(['cta', 'secondary', 'default']),
    color: PropTypes.oneOf(['default', 'white', 'black', 'green', 'blue', 'red']),
    colorHover: PropTypes.oneOf(['default', 'white', 'black', 'green', 'blue', 'red']),
}

export default SingleButton

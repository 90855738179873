import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import app from '@frontastic/catwalk/src/js/app/app'
import Register from './Register'
import Login from './Login'
import ForgottenPassword from './ForgottenPassword'
import { RegisterFormTypes } from './types'
import Message from '@frontastic/catwalk/src/js/app/message'
import {injectIntl, IntlShape} from 'react-intl'

export type Props = {
    intl: IntlShape
    handleRegister: (data: RegisterFormTypes, redirect: Boolean) => Promise<any>
    handleLogin: (email: string, password: string) => Promise<any>
    handleRequestPasswordReset: (email: string) => Promise<any>
    redirectRoute: string
    restrictEmailDomains?: Array<{ emailDomain: string }>
}

const FORM_TYPE = {
    LOGIN: 'LOGIN',
    REGISTER: 'REGISTER',
    FORGOTTEN_PASSWORD: 'FORGOTTEN_PASSWORD',
}

const AccountAccess: React.FC<Props> = ({
    intl,
    handleRegister,
    handleLogin,
    handleRequestPasswordReset,
    redirectRoute,
    restrictEmailDomains,
}: Props) => {
    const [showLoader, setShowLoader] = useState(false)
    const [form, setFrom] = useState(FORM_TYPE.LOGIN)
    const [originIsCart, setOriginIsCart] = useState(false)
    const notifications = useSelector((state: { user: any }) => state.user.notifications || {})

    useEffect(() => {
        setShowLoader(false)
    }, [notifications])

    useEffect(() => {
        const originParameter = new URLSearchParams(window.location.search)

        setOriginIsCart(originParameter.has('origin-cart'))
    }, [])

    return (
        <>
            {form === FORM_TYPE.LOGIN && (
                <Login
                    originIsCart={originIsCart}
                    showLoader={showLoader}
                    showRegisterForm={() => {
                        setFrom(FORM_TYPE.REGISTER)
                    }}
                    showForgottenPasswordForm={() => {
                        setFrom(FORM_TYPE.FORGOTTEN_PASSWORD)
                    }}
                    handleLogin={async (email, password) => {
                        setShowLoader(true)

                        await handleLogin(email, password).finally(() => setShowLoader(false))
                    }}
                    redirectRoute={redirectRoute}
                />
            )}

            {form === FORM_TYPE.REGISTER && (
                <Register
                    showLoader={showLoader}
                    showLoginForm={() => {
                        setFrom(FORM_TYPE.LOGIN)
                    }}
                    handleRegister={async (data: RegisterFormTypes) => {
                        setShowLoader(true)

                        await handleRegister(data, true).then((response) => {
                            if (response.ok) {
                                app.getLoader('context').refresh()
                                app.getRouter().replace('Anwr.Frontend.Master.Account.registerSuccess')
                            } else {
                                app.getLoader('context').notifyUser(<Message
                                    message={intl.formatMessage({ id: 'account.message.registeredFailed' })}
                                />, 'error')
                            }

                            setShowLoader(false)
                        })
                    }}
                    restrictEmailDomains={restrictEmailDomains}
                />
            )}

            {form === FORM_TYPE.FORGOTTEN_PASSWORD && (
                <ForgottenPassword
                    showLoader={showLoader}
                    showLoginForm={() => {
                        setFrom(FORM_TYPE.LOGIN)
                    }}
                    handleRequestPasswordReset={async (email) => {
                        setShowLoader(true)

                        await handleRequestPasswordReset(email).finally(() => setShowLoader(false))
                    }}
                />
            )}
        </>
    )
}

export default injectIntl(AccountAccess)

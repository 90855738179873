import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import StoreService from '../../../services/algolia/StoreService'

const ProductSchemaSeo = ({ productColor, selectedVariant, refererStore }) => {
    const priceStockData = StoreService.checkProductStockInStore(refererStore, selectedVariant)
    const productSchema = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: productColor.name,
        image: selectedVariant.images,
        description: productColor.description,
        sku: selectedVariant.sku,
        brand: {
            '@type': 'Brand',
            name: selectedVariant.attributes.att_prod_brand,
        },
        offers: {
            '@type': 'Offer',
            url: typeof window !== 'undefined' ? `${window.location.href}` : productColor._url,
            priceCurrency: selectedVariant.currency,
            price: priceStockData.discountedPrice ? priceStockData.discountedPrice / 100 : priceStockData.price / 100,
            availability: priceStockData.stock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
        },
    }

    return (
        <Helmet>
            <script type={'application/ld+json'}>{JSON.stringify(productSchema)}</script>
        </Helmet>
    )
}

ProductSchemaSeo.propTypes = {
    productColor: PropTypes.object.isRequired,
    selectedVariant: PropTypes.object.isRequired,
    refererStore: PropTypes.any,
}

export default ProductSchemaSeo

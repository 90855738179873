import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import AccordionItem from '../../atoms/accordion'
import HeadlineComponent from '../../atoms/headline'
import FullPageWidthWrapper from '../../molecules/Layout/FullPageWidthWrapper'

const Accordion = ({
    data, isBgMode, isDarkMode, headline, tagHeadline, className,
}) => (
    <FullPageWidthWrapper
        customClasses={classnames(className, {
            'bg-blacksport-100 relative py-comp': isDarkMode,
            'my-comp': !isDarkMode,
        })}
        withWrapper
    >
        <div
            className={classnames('my-comp accordion-box', {
                'dark-mode': isDarkMode, 'bg-mode': isBgMode,
            })}
        >
            <HeadlineComponent
                className={'accordions-headline'}
                text={headline}
                tagHeadline={tagHeadline}
            />
            {data && data.map((item, index) => {
                const key = item.title + index
                return (
                    <AccordionItem
                        description={item.content}
                        title={item.title}
                        ctaButton={item.ctaButton}
                        ctaLink={item.ctaLink}
                        key={key}
                        btnColorDefault={item.btnColorDefault}
                        btnColorHover={item.btnColorHover}
                    />
                )
            })}
        </div>
    </FullPageWidthWrapper>
)

Accordion.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
        btnColorHover: PropTypes.string,
        btnColorDefault: PropTypes.string,
    })).isRequired,
    isBgMode: PropTypes.bool,
    isDarkMode: PropTypes.bool,
    headline: PropTypes.string,
    tagHeadline: PropTypes.string,
    className: PropTypes.string,
}

export default Accordion

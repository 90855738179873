import React from 'react'
import PropTypes from 'prop-types'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import classnames from 'classnames'
import FullPageWidthWrapper from '../../../patterns/molecules/Layout/FullPageWidthWrapper'
import BaseDotted from '../../../patterns/atoms/dotted/BaseDotted'
import { DottedFadebarVariantB5mm } from '../../../patterns/atoms/dotted'

export const BlogCategoryListTastic = ({ data }) => {
    const { content } = data
    if (!content) {
        return null
    }

    const renderDottedBar = () => {
        if (!data.isDotted) {
            return null
        }
        const dottedColor = data.bgColor

        return (
            <div className={'teaser--dotted-bar'}>
                <BaseDotted
                    dottedSVG={<DottedFadebarVariantB5mm fill={dottedColor} />}
                    height={14}
                    isTop
                    isRotate
                />
                <BaseDotted
                    dottedSVG={<DottedFadebarVariantB5mm fill={dottedColor} />}
                    height={14}
                    isTop
                    from={'right'}
                />
            </div>
        )
    }

    const style = {
        backgroundColor: data.bgColor,
    }

    const styleText = {
        color: data.textColor,
    }

    const styleBackgroundItem = {
        backgroundColor: data.backgroundItem,
    }

    return (
        <>
            {data.headline && (
                <FullPageWidthWrapper
                    customClasses={classnames('blog-category-headline-bg relative', {
                        'py-comp': data.bgColor && data.isBackground,
                        'mb-4': data.isDotted,
                    })}
                    withWrapper
                    style={data.isBackground ? style : null}
                >
                    {renderDottedBar()}
                    <h3 className={'blog-category-headline'} style={styleText}>{data.headline}</h3>

                    <div className={'blog-category-list'}>
                        {content.map((blogCategory) => (
                            <div
                                className={'blog-category-list-item'}
                                style={styleBackgroundItem}
                                key={blogCategory.name}
                            >
                                <a
                                    href={blogCategory.url}
                                    title={blogCategory.name}
                                    style={styleText}
                                    className={'blog-category-list-item-link'}
                                >
                                    {blogCategory.name}
                                </a>
                            </div>
                        ))}
                    </div>
                </FullPageWidthWrapper>
            )}
        </>
    )
}
BlogCategoryListTastic.propTypes = {
    data: PropTypes.object.isRequired,
}

export default tastify({ translate: true })(BlogCategoryListTastic)

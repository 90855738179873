import React from 'react'
import PropTypes from 'prop-types'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import EventSlider from '../../../patterns/organisms/sliders/EventSlider'

const EventTeaserSlider = ({
    data: {
        headline,
        buttonLabelViewAll,
        referenceButtonViewAll,
        btnVariantViewAll,
        eventList,
        swiperColor,
        isDarkMode,
    },
}) => (
    <EventSlider
        headline={headline}
        buttonLabelViewAll={buttonLabelViewAll}
        referenceButtonViewAll={referenceButtonViewAll}
        btnVariantViewAll={btnVariantViewAll}
        eventList={eventList}
        swiperColor={swiperColor}
        isDarkMode={isDarkMode}
    />
)

EventTeaserSlider.propTypes = {
    data: PropTypes.object.isRequired,
}

export default tastify()(EventTeaserSlider)

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import Button from '../../atoms/button/Button'

const ImageContainer = ({
    title,
    desc,
    label,
    btnVariant,
    btnColorDefault,
    btnColorHover,
    bgColor,
    textColor,
    reference,
    align,
    positionMode,
    positionMobile,
    mobile,
    tablet,
    desktop,
    mode,
}) => {
    const deviceType = useDeviceType()
    const [height, setHeight] = useState(0)
    const [media, setMedia] = useState('')

    const style = {}
    const styleText = {}
    const styleBackground = () => {
        const tmpStyle = {
            minHeight: `${height}px`,
        }

        if (media && media.media && media.media.file) {
            tmpStyle.backgroundImage = `url(${media.media.file})`
        }

        return tmpStyle
    }

    const heightByDevice = () => {
        if (deviceType === 'mobile') {
            setHeight(mobile.minHeight)
            setMedia(mobile.media)
        } else if (deviceType === 'tablet') {
            setHeight(tablet.minHeight)
            setMedia(tablet.media)
        } else {
            setHeight(desktop.minHeight)
            setMedia(desktop.media)
        }
    }

    useEffect(() => {
        heightByDevice()
    }, [])

    if (bgColor) {
        style.backgroundColor = bgColor
    }

    if (textColor) {
        styleText.color = textColor
    }

    return (
        <div className={'my-comp image-container-component'}>
            <div className={'image-content-wrapper'}>
                <div className={'image-container-wrapper'} style={styleBackground()}>
                    <div className={'o-grid o-region'}>
                        <div className={'o-cell o-cell--12'}>
                            <div className={classnames('image-container-content', {
                                'image-background-padding': bgColor,
                                'image-content-right': positionMode && positionMode === 'right',
                                'image-content-bottom': deviceType === 'mobile' && positionMobile && positionMobile === 'bottom',
                            })}
                            >
                                <div
                                    className={`group image-content markdown--align__${align}`}
                                    style={style}
                                >
                                    <h3 style={styleText}>{title}</h3>
                                    <p style={styleText}>{desc}</p>
                                    {label && (
                                        <Button
                                            className={`btn btn-${btnVariant} teaser-block--button btn--color-${btnColorDefault} btn--hover-${btnColorHover}`}
                                            reference={reference}
                                            darkTheme={mode}
                                        >
                                            {label}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

ImageContainer.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    label: PropTypes.string,
    positionMode: PropTypes.string,
    positionMobile: PropTypes.string,
    media: PropTypes.object,
    bgColor: PropTypes.object,
    textColor: PropTypes.object,
    align: PropTypes.object,
    reference: PropTypes.object,
    btnVariant: PropTypes.oneOf(['cta', 'secondary', 'default']),
    btnColorDefault: PropTypes.string,
    btnColorHover: PropTypes.string,
    mode: PropTypes.oneOf(['dark']),
    mobile: PropTypes.any,
    tablet: PropTypes.any,
    desktop: PropTypes.any,
}

export default ImageContainer

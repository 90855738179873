import HorizontalSpacerTastic from '@frontastic/catwalk/src/js/tastic/horisontal-spacer/tastic';
import BoostContentMarkdownTastic from '@frontastic/theme-boost/src/js/tastic/content/markdown/tastic';
import BoostContentShowcaseTextTastic from '@frontastic/theme-boost/src/js/tastic/content/showcase-text/tastic';
import BoostContentSimpleTileTastic from '@frontastic/theme-boost/src/js/tastic/content/simple-tile/tastic';
import BoostContentTileTastic from '@frontastic/theme-boost/src/js/tastic/content/tile/tastic';
import BoostContentTileV2Tastic from '@frontastic/theme-boost/src/js/tastic/content/tile-v2/tastic';
import BoostHorizontalSpacerTastic from '@frontastic/theme-boost/src/js/tastic/helpers/horizontal-spacer/tastic';
import MarkdownPanelTastic from '@frontastic/theme-boost/src/js/tastic/product/product-details/markdown-panel/tastic';
import BoostProductListingPageTastic from '@frontastic/theme-boost/src/js/tastic/product-listing/product-listing-page/tastic';
import AccountConfirmTastic from 'anwr_sport2000/src/js/tastic/account/confirm/tastic';
import AlgoliaProductListTastic from 'anwr_sport2000/src/js/tastic/algolia/productList/tastic';
import AlgoliaProductTilesTastic from 'anwr_sport2000/src/js/tastic/algolia/productTiles/tastic';
import NotFound from './not-found';
import AccordionsTastic from '../../legacy/js/tastic/accordions/tastic';
import BoostAccountAccessTastic from '../../legacy/js/tastic/account/access/tastic';
import AccountAddressesTastic from '../../legacy/js/tastic/account/addresses/tastic';
import AccountOrdersTastic from '../../legacy/js/tastic/account/orders/tastic';
import AccountProfileTastic from '../../legacy/js/tastic/account/profile/tastic';
import BoostResetPasswordTastic from '../../legacy/js/tastic/account/resetPassword/tastic';
import AdvantageTastic from '../../legacy/js/tastic/advantage/tastic';
import AdventCalendarTastic from '../../legacy/js/tastic/advent-calendar/tastic';
import AlgoliaProductRecommendationListTastic from '../../legacy/js/tastic/algolia/productRecommendationList/tastic';
import AlgoliaStoreDetailTastic from '../../legacy/js/tastic/algolia/storeDetail/tastic';
import AlgoliaStoreFinderTastic from '../../legacy/js/tastic/algolia/storeFinder/tastic';
import AlgoliaTrendingProductTilesTastic from '../../legacy/js/tastic/algolia/trendingProductTiles/tastic';
import BannersTastic from '../../legacy/js/tastic/banners/tastic';
import BannerTilesTastic from '../../legacy/js/tastic/bannertiles/tastic';
import BranchMapTastic from '../../legacy/js/tastic/branchMap/tastic';
import BranchMapEuropeTastic from '../../legacy/js/tastic/branchMapEurope/tastic';
import branchMapIndividualMapTastic from '../../legacy/js/tastic/branchMapIndividualMap/tastic';
import BrandListTastic from '../../legacy/js/tastic/brandList/tastic';
import BreadcrumbTastic from '../../legacy/js/tastic/breadcrumb/tastic';
import CartTastic from '../../legacy/js/tastic/cart/tastic';
import CheckoutTastic from '../../legacy/js/tastic/checkout/tastic';
import ContactFormTastic from '../../legacy/js/tastic/contactForm/tastic';
import MailjetNewsletterTastic from '../../legacy/js/tastic/forms/mailjetNewsletter/tastic';
import NewsletterTastic from '../../legacy/js/tastic/forms/newsletter/tastic';
import BlogCategoryListTastic from '../../legacy/js/tastic/graphcms/blog-category-list/tastic';
import { BlogDetail as BlogDetailTastic } from '../../legacy/js/tastic/graphcms/blog-detail/tastic';
import BlogListTastic from '../../legacy/js/tastic/graphcms/blog-list/tastic';
import BrandContentFullTastic from '../../legacy/js/tastic/graphcms/brand-content-full-desc/tastic';
import BrandContentShortTastic from '../../legacy/js/tastic/graphcms/brand-content-short-desc/tastic';
import BrandSizeTableTastic from '../../legacy/js/tastic/graphcms/brand-size-table/tastic';
import ProductCategoryContentTastic from '../../legacy/js/tastic/graphcms/product-category-content/tastic';
import HeroBannerTastic from '../../legacy/js/tastic/heroBanner/tastic';
import ImageContainerTastic from '../../legacy/js/tastic/imagecontainer/tastic';
import ImageGalleryTastic from '../../legacy/js/tastic/imageGallery/tastic';
import Markdown from '../../legacy/js/tastic/markdown/tastic';
import MinimalFooterTastic from '../../legacy/js/tastic/minimalfooter/tastic';
import MinimalHeaderTastic from '../../legacy/js/tastic/minimalheader/tastic';
import OrganisationSchemaTastic from '../../legacy/js/tastic/organisationSchema/tastic';
import ProductCategoryTilesTastic from '../../legacy/js/tastic/productCategoryTiles/tastic';
import ProductDetailsTastic from '../../legacy/js/tastic/productDetail/tastic';
import ProductListingPageTastic from '../../legacy/js/tastic/productList/tastic';
import ProductTilesTastic from '../../legacy/js/tastic/productTiles/tastic';
import SimpleTileTastic from '../../legacy/js/tastic/simpleTile/tastic';
import SingleButtonTastic from '../../legacy/js/tastic/singleButton/tastic';
import SitelinksSearchBoxTastic from '../../legacy/js/tastic/siteLinkSearch/tastic';
import BrandSliderTastic from '../../legacy/js/tastic/sliders/brand-slider/tastic';
import EventTeaserSliderTastic from '../../legacy/js/tastic/sliders/event-teaser-slider/tastic';
import SmallTileSliderTastic from '../../legacy/js/tastic/sliders/small-tile-slider/tastic';
import SpacerTastic from '../../legacy/js/tastic/spacer/tastic';
import SportFooterTastic from '../../legacy/js/tastic/sportFooter/tastic';
import SportHeaderTastic from '../../legacy/js/tastic/sportHeader/tastic';
import StoreDetailTastic from '../../legacy/js/tastic/storeDetail/tastic';
import StoreDetailHeaderTastic from '../../legacy/js/tastic/storeDetailHeader/tastic';
import StoreFinderTastic from '../../legacy/js/tastic/storeFinder/tastic';
import StyleGuideTastic from '../../legacy/js/tastic/styleguide/tastic';
import SweepstakesFormTastic from '../../legacy/js/tastic/sweepstakesForm/tastic';
import TeamGalleryTastic from '../../legacy/js/tastic/teamGallery/tastic';
import TeaserTastic from '../../legacy/js/tastic/teaser/tastic';
import TextImageTastic from '../../legacy/js/tastic/textImage/tastic';
import ThankYouTastic from '../../legacy/js/tastic/thankYou/tastic';
import TileTastic from '../../legacy/js/tastic/tile/tastic';
import VideosTeaserTastic from '../../legacy/js/tastic/videoTeaser/tastic';
import WishlistTastic from '../../legacy/js/tastic/wishlist/tastic';

export const tastics = {
  'advantage-bar': AdvantageTastic,
  'anwr/blogDetail': BlogDetailTastic,
  'anwr/graphcms/blogCategoryList': BlogCategoryListTastic,
  'anwr/graphcms/blogList': BlogListTastic,
  'anwr/graphcms/brandContentFull': BrandContentFullTastic,
  'anwr/graphcms/brandContentShort': BrandContentShortTastic,
  'anwr/graphcms/brandSizeTable': BrandSizeTableTastic,
  'anwr/graphcms/productCategoryContent': ProductCategoryContentTastic,
  'frontastic/boost/account/access': BoostAccountAccessTastic,
  'frontastic/boost/account/confirm': AccountConfirmTastic,
  'frontastic/boost/account/reset-password': BoostResetPasswordTastic,
  'frontastic/boost/content/simple-tile': BoostContentSimpleTileTastic,
  'frontastic/boost/content/markdown': BoostContentMarkdownTastic,
  'frontastic/boost/content/showcase-text': BoostContentShowcaseTextTastic,
  'frontastic/boost/content/tile': BoostContentTileTastic,
  'frontastic/boost/content/tile-v2': BoostContentTileV2Tastic,
  'frontastic/boost/helpers/horizontal-spacer': BoostHorizontalSpacerTastic,
  'frontastic/boost/horizontal-spacer': HorizontalSpacerTastic,
  'frontastic/boost/product-details/markdown-panel': MarkdownPanelTastic,
  'frontastic/boost/product-listing/product-listing-page': BoostProductListingPageTastic,
  'frontastic/boost/style-guide': StyleGuideTastic,
  'frontastic/boost/account/addresses': AccountAddressesTastic,
  'frontastic/boost/account/orders': AccountOrdersTastic,
  'frontastic/boost/account/profile': AccountProfileTastic,
  'frontastic/boost/checkout-sm': CheckoutTastic,
  'sm-general/branchMapEurope': BranchMapEuropeTastic,
  'sm-general/branchMapIndividualMap': branchMapIndividualMapTastic,
  'horizontal-spacer': HorizontalSpacerTastic,
  'slider/brand-slider': BrandSliderTastic,
  'sm-algolia/product-list': AlgoliaProductListTastic,
  'sm-algolia/product-recommendation-list': AlgoliaProductRecommendationListTastic,
  'sm-algolia/product-tiles': AlgoliaProductTilesTastic,
  'sm-algolia/store-finder-algolia': AlgoliaStoreFinderTastic,
  'sm-algolia/trending-product-tiles': AlgoliaTrendingProductTilesTastic,
  'sm-algolia/store-detail': AlgoliaStoreDetailTastic,
  'sm-general/ProductTiles': ProductTilesTastic,
  'sm-general/SimpleTile': SimpleTileTastic,
  'sm-general/accordions': AccordionsTastic,
  'sm-general/advent-calendar': AdventCalendarTastic,
  'sm-general/banner-tiles': BannerTilesTastic,
  'sm-general/banners': BannersTastic,
  'sm-general/branchMap': BranchMapTastic,
  'sm-general/brandList': BrandListTastic,
  'sm-general/breadCrumb': BreadcrumbTastic,
  'sm-general/cart': CartTastic,
  'sm-general/contact-form': ContactFormTastic,
  'sm-general/forms/mailjetNewsletter': MailjetNewsletterTastic,
  'sm-general/forms/newsletter': NewsletterTastic,
  'sm-general/hero-banner': HeroBannerTastic,
  'sm-general/horizontal-spacer': HorizontalSpacerTastic,
  'sm-general/image-container': ImageContainerTastic,
  'sm-general/image-gallery': ImageGalleryTastic,
  'sm-general/markdown': Markdown,
  'sm-general/organisation-schema': OrganisationSchemaTastic,
  'sm-general/product-details': ProductDetailsTastic,
  'sm-general/product-list': ProductListingPageTastic,
  'sm-general/singleButton': SingleButtonTastic,
  'sm-general/sitelink-searchbox': SitelinksSearchBoxTastic,
  'sm-general/spacer': SpacerTastic,
  'sm-general/store-detail': StoreDetailTastic,
  'sm-general/store-detail-header': StoreDetailHeaderTastic,
  'sm-general/storeFinder': StoreFinderTastic,
  'sm-general/sweepstakes-form': SweepstakesFormTastic,
  'sm-general/team-gallery': TeamGalleryTastic,
  'sm-general/teaser': TeaserTastic,
  'sm-general/text-image-box': TextImageTastic,
  'sm-general/tile': TileTastic,
  'sm-general/video-teaser': VideosTeaserTastic,
  'sm-general/wishlists': WishlistTastic,
  'sm-general/thank-you': ThankYouTastic,
  'sm-slider/event-teaser-slider': EventTeaserSliderTastic,
  'sm-slider/small-tile-slider': SmallTileSliderTastic,
  'sport2k-footer': SportFooterTastic,
  'sport2k-header': SportHeaderTastic,
  'sport2k-minimal-footer': MinimalFooterTastic,
  'sport2k-minimal-header': MinimalHeaderTastic,
  'sm-general/product-category-tiles': ProductCategoryTilesTastic,
  default: NotFound,
};

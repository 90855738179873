import React from 'react'
import PropTypes from 'prop-types'
import BrandSlider from '../../../patterns/organisms/sliders/BrandSlider'

const BrandSliderTastic = ({
    data: {
        brand, text, tagHeadline, headlinePos, reference, isDarkMode, swiperColor, rel,
    },
}) => (
    <div className={'brand-slider'}>
        <BrandSlider
            swiperColor={swiperColor}
            headline={text}
            headlinePos={headlinePos}
            brand={brand}
            tagHeadline={tagHeadline}
            reference={reference}
            isDarkMode={isDarkMode}
            rel={rel}
        />
    </div>
)

BrandSliderTastic.propTypes = {
    data: PropTypes.object.isRequired,
}

export default BrandSliderTastic

import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
    FormattedMessage, WrappedComponentProps, injectIntl, IntlShape,
} from 'react-intl'
import classNames from 'classnames'
import ErrorMessage from '../../../atoms/errorMessage/index'
import ButtonWithLoader from '../../../atoms/button/WithLoader'
import Button from '../../../atoms/button/Button'
import { LoginFormTypes } from './types'

export type Props = {
    intl: IntlShape
    handleRequestPasswordReset: (email: string) => Promise<any>
    showLoginForm: Function
    showLoader: boolean
} & WrappedComponentProps

const ForgottenPassword: React.FC<Props> = ({
    intl, handleRequestPasswordReset, showLoginForm, showLoader,
}: Props) => {
    const {
        register,
        handleSubmit,

        formState: {
            errors,
        },
    } = useForm()
    const requiredFieldMessage = intl.formatMessage({ id: 'validation.required' })
    const validEmailMessage = intl.formatMessage({ id: 'validation.email' })

    const onSubmit: SubmitHandler<LoginFormTypes> = ({ email }) => {
        handleRequestPasswordReset(email.toLowerCase())
    }

    return (
        <section className={'account account--forgotten-password grid grid-cols-12 gap-4 mt-6 md:mt-20'}>
            <div
                className={'col-span-12 lg:col-span-6 self-start order-1 lg:order-0 px-4 py-6 lg:p-8 bg-white'}
            >
                <h3 className={'mx-0 mb-8'}>
                    <FormattedMessage id={'account.register.alreadyRegistered'} />
                </h3>

                <Button
                    type={'button'}
                    className={'btn btn-secondary w-full flex'}
                    ariaLabel={intl.formatMessage({ id: 'account.login.login' })}
                    onClick={showLoginForm}
                >
                    <FormattedMessage id={'account.login.login'} />
                </Button>
            </div>

            <div
                className={'col-span-12 lg:col-span-6 self-start px-4 py-6 lg:p-8 bg-white'}
            >
                <h3 className={'mx-0 mb-8'}>
                    <FormattedMessage id={'account.login.forgotPasswordHeadline'} />
                </h3>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={classNames('mb-10 input--wrapper', {
                        'is--invalid': errors?.email,
                    })}
                    >
                        <input
                            id={'login-email'}
                            {...register('email', {
                                required: requiredFieldMessage,
                                pattern: {
                                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: validEmailMessage,
                                },
                            })}
                            type={'email'}
                            className={'form-input'}
                            placeholder={intl.formatMessage({ id: 'account.form.emailLabel' })} />
                        <label htmlFor={'login-email'}>
                            <FormattedMessage id={'account.form.emailLabel'} />
                        </label>
                        <span className={'input--border'} />
                        {errors?.email
                            && (
                                <span className={'input--hint'}>
                                    <ErrorMessage
                                        errors={errors}
                                        name={'email'}
                                    />
                                </span>
                            )}
                    </div>

                    <ButtonWithLoader
                        className={'btn btn-default w-full flex'}
                        ariaLabel={intl.formatMessage({ id: 'account.requestResetPassword' })}
                        showLoader={showLoader}
                        type={'submit'}
                    >
                        <FormattedMessage id={'account.requestResetPassword'} />
                    </ButtonWithLoader>
                </form>
            </div>
        </section>
    )
}

export default injectIntl(ForgottenPassword)

import React from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import getTranslation from '@frontastic/catwalk/src/js/getTranslation'
import { useRouter } from "next/router";

type Props = {
    node: any
    nameCategory?: string
    headline: string
    customClassName?: boolean,
    context: any
}

const Headline: React.FC<Props> = ({
    node, nameCategory, headline, customClassName, context,
}) => {
    const isSearchPage = node.nodeType === 'search'

    const {
        categorySeoTitle, categorySeoDescription,
    } = useSelector((state) => state['product-list-algolia'])

    const { query } = useRouter()

    if (!isSearchPage && !nameCategory && !headline) {
        return null
    }

    if (isSearchPage && headline) {
        return (
            <h1 className={`${customClassName} px-4 py-2 lg:px-0 lg:py-0`}>
                {headline}
                {query && (
                    <>
                        {' '}
                        »
                        {decodeURIComponent(`${query.query ?? ''}`)}
                        «
                    </>
                )}
            </h1>
        )
    }

    let titlePrefix = ''
    let titleSuffix = ' | Built on Frontastic'

    if (context.projectConfiguration.frontasticPageTitlePrefix) {
        titlePrefix = getTranslation(
            context.projectConfiguration.frontasticPageTitlePrefix,
            context.locale,
            context.project.defaultLanguage,
        ).text
    }

    if (context.projectConfiguration.frontasticPageTitleSuffix) {
        titleSuffix = getTranslation(
            context.projectConfiguration.frontasticPageTitleSuffix,
            context.locale,
            context.project.defaultLanguage,
        ).text
    }

    const title = titlePrefix + categorySeoTitle + titleSuffix

    return (
        <>
            <h1 className={`${customClassName} px-4 py-2 lg:px-0 lg:py-0`}>{headline || nameCategory}</h1>
            {categorySeoTitle && (
                <Helmet>
                    <title>{title}</title>
                    <meta name={'description'} content={categorySeoDescription} />
                    <meta name={'title'} content={categorySeoTitle} />
                </Helmet>
            )}
        </>
    )
}

export default Headline

import qs from 'qs'
import {SearchState} from 'react-instantsearch-core'
import ProductListService from './ProductListService'

export type QueryParams = {
  query?: string
  page?: number
  sortBy?: string
  range?: {
    [key: string]: {
      min?: number
      max?: number
    }
  }
  categoryoption?: any
  categoryfilter?: any
  categorykey?: any
  categoryname?: any
  filters?: {
    [key: string]: string[];
  };
  categorySidebar?: any
}

export default class RoutingService {
  public static createURL(state: SearchState) {
    if (state.page) {
      ProductListService.addRefinedPage(state.page)
    }

    const isDefaultRoute = !state.query
      && state.page === 1
      && (!state.sortBy || Object.keys(state.sortBy).length === 0)
      && (!state.refinementList || Object.keys(state.refinementList).length === 0)
      && (!state.range || Object.keys(state.range).length === 0)

    if (isDefaultRoute) {
      return ''
    }

    const queryParameters: QueryParams = {}

    if (state.query) {
      queryParameters.query = encodeURIComponent(state.query)
    }

    if (state.page !== 1) {
      queryParameters.page = state.page
    }

    if (state.sortBy) {
      queryParameters.sortBy = state.sortBy
    }

    if (state.range && Object.keys(state.range).length > 0) {
      const rangeList = state.range

      Object.keys(rangeList).forEach((key) => {
        if (rangeList?.hasOwnProperty(key)
          && !(rangeList[key].max || rangeList[key].min)
        ) {
          delete rangeList[key]
        }
      })

      if (Object.keys(rangeList).length > 0) {
        queryParameters.range = rangeList
      }
    }

    if (state.refinementList && Object.keys(state.refinementList).length > 0) {
      const list = state.refinementList

      Object.keys(list).forEach((key) => {
        if (list?.hasOwnProperty(key) && list[key].length === 0) {
          delete list[key]
        }
      })

      if (Object.keys(list).length > 0) {
        queryParameters.filters = list
      }
    }

    const urlParameters = qs.parse(
      window.location.search.slice(1),
    )
    const {
      categoryoption, categoryfilter, categorykey, categoryname, categorysidebar,
    } = urlParameters
    if (categoryoption) {
      queryParameters.categoryoption = categoryoption
      queryParameters.categoryfilter = categoryfilter
      queryParameters.categorykey = categorykey
      queryParameters.categoryname = categoryname
    }

    if (categorysidebar) {
      queryParameters.categorySidebar = categorysidebar
    }

    const queryToGenerate = {
      ...queryParameters,
      filters: {
        ...queryParameters.filters,
        brand: null,
      },
    }

    const str = qs.stringify(queryToGenerate, {
      addQueryPrefix: true,
      // Netlify "normalizes" query parameters. Multiple parameters with the same name are combined into a single
      // comma separated values. For that reason we can't use 'brackets' here but have to use 'indices'
      arrayFormat: 'indices',
      skipNulls: true,
    })

    return str
  }

  public static generateBrandQueryUrl(searchState: SearchState | null) {
    const brandQuery = searchState.refinementList?.brand

    if (!brandQuery) {
      return ''
    }

    return `_${brandQuery.join('_')}`
  }

  public static brandQueryToUrl(pathname, brandQueryUrl) {
    const pathnameSegments = pathname.split('/')

    if (pathnameSegments[pathnameSegments.length - 1].includes('_')) {
      pathnameSegments[pathnameSegments.length - 1] = brandQueryUrl
    } else {
      pathnameSegments.push(brandQueryUrl)
    }

    // Remove '/' at the end of pathname by regex
    return pathnameSegments.join('/').replace(/\/$/, '')
  }

  public static searchStateToUrl(searchState: SearchState | null) {
    return searchState ? this.createURL(searchState) : ''
  }

  public static queryToSearchState(q: any) {
    const {
      query = '', page = 1, filters, sortBy, range,
    } = q

    ProductListService.addRefinedPage(parseFloat(page.toString(10)))

    const state: any = {
      query: decodeURIComponent(query.toString()),
      page,
      refinementList: filters,
      range,
    }

    if (sortBy) {
      state.sortBy = sortBy
    }

    return state
  }

  public static brandUrlToSearchState(pathname: string) {
    if (!pathname) {
      return
    }

    const slugs = pathname.split('/')
    const brandSlug = slugs.pop()

    if (brandSlug.includes('_')) {
      return brandSlug.split('_').slice(1)
    }

    return null
  }

  public static urlToSearchState(searchStateQueryOnUrl: SearchState, query: string | null, initFacets: any, count: number) {
    const searchState = RoutingService.queryToSearchState({
      ...qs.parse(searchStateQueryOnUrl, {comma: true, parseArrays: true}),
      query,
    })

    const brand = RoutingService.brandUrlToSearchState(searchStateQueryOnUrl.path)
    const refinementList = {...searchState.refinementList}

    if (brand) {
      refinementList.brand = brand
    }

    /*
        This is for re-update current refinement list with correct properties name ( capitalize or uppercase )
        when turning url to search state at the beginning
    */
    if (count < 1 && initFacets) {
      Object.keys(refinementList).forEach((key) => {
        const propertiesName = Object.keys(initFacets[key]).map((initKey) => initKey)
        refinementList[key] = propertiesName.filter((propertyName) => refinementList[key].indexOf(propertyName.toLowerCase()) !== -1)
      })
    }

    return {
      ...searchState,
      refinementList,
    }
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import classnames from 'classnames'
import FullPageWidthWrapper from '../../../patterns/molecules/Layout/FullPageWidthWrapper'
import BorderOnTopAndBottom from '../../../patterns/atoms/dotted/BorderOnTopAndBottom'
import ProductRecommendationTile from '../../../patterns/organisms/Algolia/ProductTile/ProductRecommendationTile'

function AlgoliaTrendingProductTilesTastic({
    node,
    context,
    data,
}) {
    if (!data || !context || !data?.stream || !data?.stream.length) {
        return null
    }

    const gtmCategoryName = node.name
    const gtmCategoryId = node.nodeType
    const { indexName } = context.project.configuration.algolia.languages[context.locale]

    return (
        <FullPageWidthWrapper
            customClasses={classnames('product-list--wrapper product-tiles', {
                'bg-white': !data.renderBlackBackground,
                'my-comp': !data.renderBlackBackground && !data.renderDottedBorders,
                'py-8 md:py-10 lg:py-12 xl:py-16 xxl:py-20': data.renderBlackBackground || data.renderDottedBorders,
                'dark-mode bg-blacksport-100': data.renderBlackBackground,
            })}
        >
            <div className={'o-wrapper'}>
                <div className={'o-grid o-region'}>
                    <div className={'o-cell o-cell--12'}>
                        {data.renderDottedBorders
                        && (
                            <BorderOnTopAndBottom
                                renderAtTop
                                renderBlackBackground={data.renderBlackBackground}
                            />
                        )}
                        <div className={'product-list--wrapper product-list-algolia product-tiles-algolia-wrapper'}>
                            <div className={'product-list--component'} data-insights-index={indexName}>
                                <div className={'product--tiles--algolia'}>
                                    <ProductRecommendationTile
                                        context={context}
                                        gtmCategoryName={gtmCategoryName}
                                        gtmCategoryId={gtmCategoryId}
                                        renderAsSlider={data.renderAsSlider}
                                        renderBlackBackground={data.renderBlackBackground}
                                        renderDottedBorders={data.renderDottedBorders}
                                        swiperColor={data.swiperColor}
                                        alignment={data.alignment}
                                        hits={data.stream}
                                    />
                                </div>
                            </div>
                        </div>
                        {data.renderDottedBorders
                        && (
                            <BorderOnTopAndBottom
                                renderAtTop={false}
                                renderBlackBackground={data.renderBlackBackground}
                            />
                        )}
                    </div>
                </div>
            </div>
        </FullPageWidthWrapper>
    )
}

AlgoliaTrendingProductTilesTastic.propTypes = {
    node: PropTypes.objectOf(PropTypes.any).isRequired,
    tastic: PropTypes.objectOf(PropTypes.any).isRequired,
    route: PropTypes.objectOf(PropTypes.any).isRequired,
    context: PropTypes.objectOf(PropTypes.any).isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    query: PropTypes.any,
}

export default tastify({
    translate: true,
    connect: {
        node: true, tastic: true, route: true, context: true,
    },
})(AlgoliaTrendingProductTilesTastic)

import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import Entity from '@frontastic/catwalk/src/js/app/entity'
import Message from '@frontastic/catwalk/src/js/app/message'
import classnames from 'classnames'
import Button from '../../atoms/button/Button'
import PaymentMethod from './Forms/PaymentMethod'
import { ReactComponent as IconChevronDown } from '../../../../icons/sport2000-icons/arrow-left.svg'
import IconButton from '../../atoms/button/IconButton'
import PaymentService from '../../../services/payment'
import GtmService from '../../../services/gtm'
import TagManager from '../../../domain/TagManager'

const PaymentPanel = ({
    app,
    cart,
    intl,
    setCurrent,
    data,
    goToPanelIndex,
    setPaymentInfo,
    currentIndex,
    active,
    setActive,
    setNextActive,
    validNextStep,
    setValidNextStep,
    setValidNextStep2,
    setDivider1,
    setDivider2,
    paymentMethods,
    setActiveStep1,
    setActiveStep2,
    setActiveStep3,
    adyenComponentRef,
    paymentIdRef,
    setButtonConfirmDisabled,
}) => {
    const buttonLabel = intl.formatMessage({ id: 'checkout.pay' })

    const [paymentMethodType, setPaymentMethodType] = useState(null)
    const [paymentDetailsValid, setPaymentDetailsValid] = useState(false)
    const [paymentDetails, setPaymentDetails] = useState(null)
    const containerElement = useRef(null)

    const [showDetail, setShowDetail] = useState(false)

    const [currentPayment, setCurrentPayment] = useState(null)
    const isTestMode = window.location.search.indexOf('testPayment=true') >= 0
    const onChangeCollapseContent = () => {
        if (validNextStep) {
            setActive(!active)
        }
    }

    const handleGoogleTracking = () => {
        const gtmService = new GtmService(data.lineItems, null, null, data)
        const productListGTM = gtmService.createProductListGTM('Checkout')
        const paymentInfoGTM = gtmService.createPaymentInfoGTM(currentPayment)
        TagManager.addPaymentInfo(paymentInfoGTM, productListGTM)
        TagManager.hitViewdImpressions()
    }

    const onNextClicked = () => {
        setNextActive(true)
        setDivider2(true)
        setShowDetail(true)
        setCurrent(currentIndex + 1)
        setValidNextStep(true)
        setValidNextStep2(true)
    }

    const handleAdyenResult = useCallback((paymentId, action, resultCode) => { // eslint-disable-line react-hooks/exhaustive-deps
        if (action) {
            paymentIdRef.current = paymentId

            switch (action.type) {
                case 'redirect':
                    switch (action.method) {
                        case 'GET':
                            window.location = action.url
                            return
                        case 'POST':
                            const form = document.createElement('form')
                            form.method = 'POST'
                            form.action = action.url
                            Object.entries(action.data).forEach(([key, value]) => {
                                const input = document.createElement('input')
                                input.type = 'hidden'
                                input.name = key
                                input.value = value
                                form.appendChild(input)
                            })
                            document.body.appendChild(form)
                            form.submit()
                            return
                        default:
                            throw { message: `Unknown redirect method ${action.method}` } // eslint-disable-line no-throw-literal
                    }
                case 'voucher':
                    throw { message: 'Voucher action not yet supported' } // eslint-disable-line no-throw-literal

                case 'threeDS2Challenge':

                    // initial div contains form password
                    const paymentContainer = document.getElementById('adyen-challenge-container')
                    paymentContainer.innerHTML = ''
                    const paymentFormElement = document.createElement('div')
                    paymentFormElement.setAttribute('id', 'adyen-challenge-inner')
                    paymentContainer.appendChild(paymentFormElement)

                    const threeDSConfiguration = {
                        challengeWindowSize: '02',
                        // Set to any of the following:
                        // '02': ['390px', '400px'] -  The default window size
                        // '01': ['250px', '400px']
                        // '03': ['500px', '600px']
                        // '04': ['600px', '400px']
                        // '05': ['100%', '100%']
                    }

                    const configurationChallengeShopper = {
                        ...paymentMethods.configuration,
                        onAdditionalDetails: (state) => {
                            PaymentService.updateAdyenPayment(paymentIdRef.current, state.data.details, state.data.paymentData)
                                .then((body) => {
                                    handleAdyenResult(body.paymentId, body.action, body.resultCode)
                                })
                                .catch((error) => {
                                    app.getLoader('context').notifyUser(<Message {...error} />, 'error')
                                    setButtonConfirmDisabled(false)
                                })
                        },
                    }
                    const adyenCheckoutChallengeShopper = new AdyenCheckout(configurationChallengeShopper)
                    adyenCheckoutChallengeShopper.createFromAction(action, threeDSConfiguration).mount(paymentFormElement)

                    return
                default:
                    adyenComponentRef.current.handleAction(action)
                    return
            }
        }

        switch (resultCode) {
            case 'Authorised':
            case 'Received':
                app.getApi().request(
                    'POST',
                    'Frontastic.CartApi.Cart.checkout',
                    { ownErrorHandler: true },
                    null,
                    (data) => {
                        const url = `/checkout/finished?order=${data.order.orderId}`
                        window.location.href = url
                        return data
                    },
                    (error) => {
                        // app.getLoader('context').notifyUser(<Message
                        //     message={intl.formatMessage({ id: 'order.orderFailed' })}
                        // />, 'error')
                        PaymentService.cancelAdyenPayment(paymentId)
                            .then(() => {
                                window.location.href = '/checkout/cart'
                            })
                            .catch((error) => {
                                app.getLoader('context').notifyUser(<Message {...error} />, 'error')
                                setButtonConfirmDisabled(false)
                            })
                    },
                )
                break
            default:
                app.getLoader('context').notifyUser(<Message message={resultCode} />, 'error')
                setButtonConfirmDisabled(false)
        }
    }) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setPaymentDetailsValid(false)
        if (paymentMethods === null || paymentMethodType == null) {
            return
        }

        if (paymentMethodType == 'applepay') {
            const applePayMethod = paymentMethods.configuration.paymentMethodsResponse.paymentMethods
                .filter((paymentMethod) => paymentMethod.type === 'applepay')
            const applePayConfiguration = {
                originKey: paymentMethods.configuration.originKey,
                locale: paymentMethods.configuration.locale,
                environment: paymentMethods.configuration.environment,
                clientKey: paymentMethods.configuration.clientKey,
                amount: paymentMethods.configuration.amount,
                configuration: {
                    merchantName: applePayMethod[0].configuration.merchantName,
                    merchantId: applePayMethod[0].configuration.merchantId,
                },
                onSubmit: (state) => {
                    state.data.paymentMethod.name = currentPayment
                    setPaymentInfo(state.data)
                    setPaymentDetailsValid(true)
                    onNextClicked()
                },
            }

            if (isTestMode) {
                console.log('applePayConfiguration', applePayConfiguration)
            }

            try {
                const adyenCheckout = new AdyenCheckout(applePayConfiguration)
                const applePayComponent = adyenCheckout.create('applepay', applePayConfiguration)
                applePayComponent
                    .isAvailable()
                    .then(() => {
                        containerElement.current.style.display = 'block'
                        applePayComponent.mount(containerElement.current)
                    })
                    .catch((e) => {
                        app.getLoader('context').notifyUser(<Message
                            message={'Your Apple Pay has been disabled'}
                        />, 'error')
                        setButtonConfirmDisabled(false)
                    })
            } catch (e) {
                console.log('Adyen set up apple pay failed', e)
            }
            return
        }

        if (paymentMethodType == 'googlepay') {
            const adyenCheckout = new AdyenCheckout()
            const googlePayMethod = paymentMethods.configuration.paymentMethodsResponse.paymentMethods
                .filter((paymentMethod) => paymentMethod.type === 'googlepay')
            const googlePayConfiguration = {
                ...paymentMethods.configuration,
                configuration: {
                    merchantName: 'SPORT2000',
                    merchantId: googlePayMethod[0].configuration.merchantId,
                    gatewayMerchantId: googlePayMethod[0].configuration.gatewayMerchantId,
                },
                onSubmit: (state) => {
                    state.data.paymentMethod.name = currentPayment
                    setPaymentInfo(state.data)
                    setPaymentDetailsValid(true)
                },
            }

            if (isTestMode) {
                console.log('googlePayConfiguration', googlePayConfiguration)
            }

            const googlePayComponent = adyenCheckout.create('googlepay', googlePayConfiguration)
            googlePayComponent
                .isAvailable()
                .then(() => {
                    googlePayComponent.mount(containerElement.current)
                    containerElement.current.style.display = 'block'
                })
                .catch((e) => {
                    console.log('googlepay is unable to mount error', e)
                    app.getLoader('context').notifyUser(<Message
                        message={'Your Google Pay has been disabled'}
                    />, 'error')
                    setButtonConfirmDisabled(false)
                })

            return
        }

        // only bind when user select credit card
        if (paymentMethodType !== 'scheme') {
            setPaymentDetailsValid(true)
            return
        }

        containerElement.current.style.display = 'block'

        const configuration = {
            ...paymentMethods.configuration,
            showPayButton: false,
            // shopperLocale: 'de_DE',
            // debug: true,
            onChange: (state) => {
                setPaymentDetailsValid(state.isValid)
                state.data.paymentMethod.name = currentPayment
                setPaymentDetails(state.data)
            },
            onSubmit: (state) => {
                state.data.paymentMethod.name = currentPayment
                setPaymentInfo(state.data)
                onNextClicked()
            },
            onAdditionalDetails: (state) => {
                PaymentService.updateAdyenPayment(paymentIdRef.current, state.data.details, state.data.paymentData)
                    .then((body) => {
                        handleAdyenResult(body.paymentId, body.action, body.resultCode)
                    })
                    .catch((error) => {
                        app.getLoader('context').notifyUser(<Message {...error} />, 'error')
                        setButtonConfirmDisabled(false)
                    })
            },
        }

        // eslint-disable-next-line no-undef
        const adyenCheckout = new AdyenCheckout(configuration)
        adyenComponentRef.current = adyenCheckout.create(paymentMethodType)
        adyenComponentRef.current.mount(containerElement.current)
    }, [paymentMethodType, paymentMethods]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!cart.isComplete() || containerElement.current == null) {
            return
        }

        const urlParameters = new URLSearchParams(window.location.search)
        const adyenPaymentId = urlParameters.get('adyenPaymentId')
        const payeverPaymentId = urlParameters.get('payeverPaymentId')
        const paymentId = adyenPaymentId || payeverPaymentId
        if (paymentId === null) {
            return
        }

        const payment = data.payments.find((payment) => payment.id === paymentId)
        if (payment === undefined) {
            return
        }

        let resultCode = payment.paymentStatus
        if (adyenPaymentId) {
            resultCode = payment.paymentDetails.adyenResultCode
        }

        if (resultCode === 'Authorised' || resultCode === 'Received' || resultCode === 'paid') {
            setActiveStep1(false)
            setActiveStep2(false)
            setActiveStep3(true)

            app.getApi().request(
                'POST',
                'Frontastic.CartApi.Cart.checkout',
                { ownErrorHandler: true, adyenPaymentIdUrl: paymentId },
                null,
                (data) => {
                    const url = `/checkout/finished?order=${data.order.orderId}`
                    window.location.href = url
                    return data
                },
                (error) => {
                    if (adyenPaymentId) {
                        PaymentService.cancelAdyenPayment(paymentId)
                            .then(() => {
                                window.location.href = '/checkout/cart'
                            })
                            .catch((error) => {
                                app.getLoader('context').notifyUser(<Message {...error} />, 'error')
                            })
                    }
                    if (payeverPaymentId) {
                        PaymentService.cancelPayeverPayment(paymentId)
                            .then(() => {
                                window.location.href = '/checkout/cart'
                            })
                            .catch((error) => {
                                app.getLoader('context').notifyUser(<Message {...error} />, 'error')
                            })
                    }
                },
            )
        } else {
            setActiveStep2(true)
            setActiveStep1(false)
            setActive(true)
            setCurrent(currentIndex)
            setDivider1(true)
            app.getLoader('context').notifyUser(<Message
                message={intl.formatMessage({ id: 'order.paymentFailed' })}
            />, 'error')
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={classnames('confirm-checkout-wrapper payment-checkout-wrapper bg-white', {
            'is--active': active,
        })}
        >
            <IconButton
                type={'button'}
                ariaLabel={intl.formatMessage({ id: 'checkout.paymentMethod' })}
                className={'btn btn-text icon--right delivery-title checkout-title mb-0 w-full justify-between overflow-visible'}
                onClick={onChangeCollapseContent}
                icon={<IconChevronDown width={'16'} height={'16'} className={'order-2'} />}
            >
                <FormattedMessage id={'checkout.paymentMethod'} />
            </IconButton>
            <div className={`shipping-checkout-content ${active ? 'block' : 'hidden'}`}>
                <div className={`'payment-content ${!showDetail ? 'block' : 'hidden'}`}>
                    <PaymentMethod
                        paymentMethods={paymentMethods}
                        onSubmit={(paymentMethod) => {
                            setPaymentMethodType(paymentMethod.type)
                            setCurrentPayment(paymentMethod.name)
                            setPaymentInfo({ paymentMethod: { type: paymentMethod.type, name: paymentMethod.name, provider: paymentMethod.provider } })

                            if (paymentMethod.type !== 'scheme' && containerElement.current !== null) {
                                containerElement.current.style.display = 'none'
                            }
                        }}
                    />

                    <div className={'mt-2'} ref={containerElement} />

                    <span className={'mt-4 flex pb-6 lg:pb-0'}>
                        <Button
                            type={'button'}
                            ariaLabel={buttonLabel}
                            className={'btn btn-default btn-cart-submit w-full lg:w-auto'}
                            disabled={!paymentDetailsValid}
                            onClick={() => {
                                if (paymentMethodType === 'scheme') {
                                    setPaymentInfo(paymentDetails)
                                }

                                handleGoogleTracking()
                                onNextClicked()
                            }}
                        >
                            <span>{buttonLabel}</span>
                        </Button>
                    </span>
                </div>

                <div className={`mt-6 lg:mt-8 ${showDetail ? 'block' : 'hidden'}`}>
                    <div className={'payment-content-wrapper bg-white'}>
                        <div className={'payment-selected'}>
                            <div className={'checkbox-radio--wrapper'}>
                                <input
                                    type={'radio'}
                                    aria-label={'Payment method'}
                                    name={'type'}
                                    value={currentPayment}
                                    className={'mr-3 payment-method-type'}
                                    defaultChecked
                                />
                                <label className={'font-bold text-base'} htmlFor={'payment-method-type'}>
                                    {currentPayment}
                                </label>
                            </div>
                            <div
                                className={'mt-2 cursor-pointer underline payment-text-changed font-sans'}
                                onClick={() => {
                                    setShowDetail(false)
                                    setActiveStep3(false)
                                    setValidNextStep2(false)
                                }}
                            >
                                <FormattedMessage id={'checkout.edit'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

PaymentPanel.propTypes = {
    app: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    cart: PropTypes.instanceOf(Entity).isRequired,
    intl: PropTypes.object.isRequired,
    setCurrent: PropTypes.func.isRequired,
    setNextActive: PropTypes.func.isRequired,
    setActive: PropTypes.func.isRequired,
    setPaymentInfo: PropTypes.func.isRequired,
    goToPanelIndex: PropTypes.func.isRequired,
    goToNextPanel: PropTypes.func.isRequired,
    setValidNextStep: PropTypes.func.isRequired,
    validNextStep: PropTypes.bool.isRequired,
    active: PropTypes.bool,
    currentIndex: PropTypes.number,
    setDivider1: PropTypes.func.isRequired,
    setDivider2: PropTypes.func.isRequired,
    setValidNextStep2: PropTypes.func.isRequired,
    paymentMethods: PropTypes.object.isRequired,
    setActiveStep1: PropTypes.func.isRequired,
    setActiveStep2: PropTypes.func.isRequired,
    setActiveStep3: PropTypes.func.isRequired,
    adyenComponentRef: PropTypes.any,
    paymentIdRef: PropTypes.any,
    setButtonConfirmDisabled: PropTypes.func,
}
export default injectIntl(PaymentPanel)

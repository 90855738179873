import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { UserIcon } from '../../../atoms/icon'

const WelcomeOverview = ({ firstName, variant = '' }) => (
    <div className={`flex-auto md:shadow-lg md:order-2 md:ml-5 ${variant}`}>
        <div className={'flex flex-col text-center justify-center w-64 mx-auto mb-5 md:pb-56'}>
            <div className={'mx-auto mb-4'}>
                <UserIcon width={24} height={24} />
            </div>
            <div className={'account--welcome font-bold'}>
                <FormattedMessage id={'account.welcome'} />
                {firstName}
            </div>
        </div>
    </div>
)

WelcomeOverview.propTypes = {
    firstName: PropTypes.string.isRequired,
    variant: PropTypes.string,
}

export default WelcomeOverview

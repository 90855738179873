import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import Image from '../../../../atoms/image'

const BrandDetail = ({ brands }) => {
    const brandsArray = brands.filter((brand) => !!brand.logoUrl)
    const isMobile = useDeviceType() === 'mobile'

    if (!brandsArray.length) {
        return null
    }

    return (
        <div className={'store-detail-brand-page'}>
            <h2 className={'store-detail-brands-headline mb-7 lg:mb-8'}>
                <FormattedMessage id={'storeDetail.brandHeadline'} />
            </h2>
            <div className={'store-detail-brand-page-wrap'}>
                {brandsArray.map((brand) => (
                    <div className={'store-detail-brand-item'} key={brand.logoUrl}>
                        <Image
                            alt={brand.brand}
                            className={'store-detail-brand-item-img'}
                            title={brand.brand}
                            media={brand.logoUrl}
                            height={isMobile ? 55 : 70}
                            width={isMobile ? 75 : 90}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

BrandDetail.propTypes = {
    brands: PropTypes.array.isRequired,
}

export default injectIntl(BrandDetail)

import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { FormattedMessage } from 'react-intl'
import Modal from 'react-modal'
import { ReactComponent as ArrowLeft } from '../../../../icons/sport2000-icons/arrowleft.svg'
import { ReactComponent as ArrowRight } from '../../../../icons/sport2000-icons/arrowright.svg'
import Button from '../../atoms/button/Button'
import { ReactComponent as CLoseIcon } from '../../../../icons/sport2000-icons/close.svg'

const SizeTable = ({
    brandSizeTable, openPanel, setOpenPanel,
}) => {
    if (!brandSizeTable.brandSizeTables) {
        return null
    }

    return (
        <Modal
            isOpen={openPanel}
            onRequestClose={() => {
                setOpenPanel(false)
            }}
            contenLabel={'Size Table'}
            portalClassName={'size-table-modal'}
        >
            <div className={'size-table-modal-header'}>
                <h4 className={'size-table-modal-header-headline mb-0'}>
                    {brandSizeTable.brandSizeTables && brandSizeTable.brandSizeTables.length > 0 && brandSizeTable.brandSizeTables[0].headline && (
                        <>
                            {brandSizeTable.brandSizeTables[0].headline}
                        </>
                    )}
                    {' '}
                    <FormattedMessage id={'product.sizeProduct'} />
                </h4>
                <Button
                    className={'size-table-modal-header-close'}
                    onClick={() => {
                        setOpenPanel(false)
                    }}
                >
                    <CLoseIcon width={15} height={15} />
                </Button>
            </div>
            <div className={'size-table-modal-content'}>
                <div className={'brand-size-table'}>
                    {brandSizeTable.brandSizeTables && brandSizeTable.brandSizeTables.length > 0 && (
                        <div>
                            {parse(brandSizeTable.brandSizeTables[0].content_size_table.html)}
                        </div>
                    )}

                    <div className={'hint-required flex justify-around'}>
                        <ArrowLeft className={'md:hidden'} width={'20'} height={'20'} />
                        <FormattedMessage id={'product.sizeSwipe'} />
                        <ArrowRight className={'md:hidden'} width={'20'} height={'20'} />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

SizeTable.propTypes = {
    brandSizeTable: PropTypes.object.isRequired,
    openPanel: PropTypes.bool,
    setOpenPanel: PropTypes.func,
}

export default SizeTable

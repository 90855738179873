import React from 'react'
import { ReactComponent as Walking } from '../../../../../../icons/sport2000-icons/sports/icon_nordic_walking.svg'
import { ReactComponent as Running } from '../../../../../../icons/sport2000-icons/sports/icon_laufen.svg'
import { ReactComponent as LifeStyle } from '../../../../../../icons/sport2000-icons/sports/icon_shirt.svg'
import { ReactComponent as BeachWasser } from '../../../../../../icons/sport2000-icons/sports/icon_wasser.svg'
import { ReactComponent as Trekking } from '../../../../../../icons/sport2000-icons/sports/icon_trekking.svg'
import { ReactComponent as Fitness } from '../../../../../../icons/sport2000-icons/sports/icon_hantel.svg'
import { ReactComponent as BergSport } from '../../../../../../icons/sport2000-icons/sports/icon_berg.svg'
import { ReactComponent as Bikes } from '../../../../../../icons/sport2000-icons/sports/icon_bikes.svg'
import { ReactComponent as Skates } from '../../../../../../icons/sport2000-icons/sports/icon_inline_skates.svg'
import { ReactComponent as Scooter } from '../../../../../../icons/sport2000-icons/sports/icon_scooter.svg'
import { ReactComponent as Skateboard } from '../../../../../../icons/sport2000-icons/sports/icon_skateboard.svg'
import { ReactComponent as Golf } from '../../../../../../icons/sport2000-icons/sports/icon_golf.svg'
import { ReactComponent as Squash } from '../../../../../../icons/sport2000-icons/sports/icon_squash.svg'
import { ReactComponent as Tennis } from '../../../../../../icons/sport2000-icons/sports/icon_tennis.svg'
import { ReactComponent as Tischtennis } from '../../../../../../icons/sport2000-icons/sports/icon_tischtennis.svg'
import { ReactComponent as Handball } from '../../../../../../icons/sport2000-icons/sports/icon_handball.svg'
import { ReactComponent as Hockey } from '../../../../../../icons/sport2000-icons/sports/icon_hockey.svg'
import { ReactComponent as Volleyball } from '../../../../../../icons/sport2000-icons/sports/icon_volleyball.svg'
import { ReactComponent as AmericanFussball } from '../../../../../../icons/sport2000-icons/sports/icon_american_football-50.svg'
import { ReactComponent as Bogenschiessen } from '../../../../../../icons/sport2000-icons/sports/icon_bogenschiessen.svg'
import { ReactComponent as Schiedsrichter } from '../../../../../../icons/sport2000-icons/sports/icon_schiedsrichter.svg'
import { ReactComponent as Rugby } from '../../../../../../icons/sport2000-icons/sports/icon_rugby.svg'
import { ReactComponent as BaseSpieler } from '../../../../../../icons/sport2000-icons/sports/icon_baseball_spieler.svg'
import { ReactComponent as Schlittschuh } from '../../../../../../icons/sport2000-icons/sports/icon_schlittschuh.svg'
import { ReactComponent as Skifahren } from '../../../../../../icons/sport2000-icons/sports/icons_skifahren.svg'
import { ReactComponent as Schlitten } from '../../../../../../icons/sport2000-icons/sports/icon_schlitten.svg'
import { ReactComponent as Snowboarding } from '../../../../../../icons/sport2000-icons/sports/icon_snowboardensvg.svg'
import { ReactComponent as Ski } from '../../../../../../icons/sport2000-icons/sports/icon_ski.svg'
import { ReactComponent as Winterstiefel } from '../../../../../../icons/sport2000-icons/sports/icon_winterstiefel.svg'
import { ReactComponent as Badminton } from '../../../../../../icons/sport2000-icons/sports/icon_badminton.svg'
import { ReactComponent as Fussball } from '../../../../../../icons/sport2000-icons/sports/icon_fussball.svg'
import { ReactComponent as Kampfsport } from '../../../../../../icons/sport2000-icons/sports/icon_kampftraining.svg'
import { ReactComponent as Ringen } from '../../../../../../icons/sport2000-icons/sports/icon_ringen.svg'
import { ReactComponent as Skilanglauf } from '../../../../../../icons/sport2000-icons/sports/icon_skilanglauf.svg'
import { ReactComponent as WinterSport } from '../../../../../../icons/sport2000-icons/sports/icon_winter.svg'

const IconCategoryItem = ({ IconName, name }) => (
    <div className={'store-detail-sport'}>
        <IconName width={40} height={40} />
        <div className={'store-detail-sport-info'}>
            <b>{name}</b>
        </div>
    </div>
)

export default {
    Walking: {
        iconName: <IconCategoryItem IconName={Walking} name={'Walking'} />,
    },
    Running: {
        iconName: <IconCategoryItem IconName={Running} name={'Running'} />,
    },
    'Lifestyle / Freizeitmode': {
        iconName: <IconCategoryItem IconName={LifeStyle} name={'Lifestyle / Freizeitmode'} />,
    },
    'Beach / Wasser': {
        iconName: <IconCategoryItem IconName={BeachWasser} name={'Beach / Wasser'} />,
    },
    'Outdoor / Trekking': {
        iconName: <IconCategoryItem IconName={Trekking} name={'Outdoor / Trekking'} />,
    },
    'Fitness / Wellness': {
        iconName: <IconCategoryItem IconName={Fitness} name={'Fitness / Wellness'} />,
    },
    'Bergsport / Klettern': {
        iconName: <IconCategoryItem IconName={BergSport} name={'Bergsport / Klettern'} />,
    },
    Bikes: {
        iconName: <IconCategoryItem IconName={Bikes} name={'Bikes'} />,
    },
    'Inline Skates': {
        iconName: <IconCategoryItem IconName={Skates} name={'Inline Skates'} />,
    },
    Scooter: {
        iconName: <IconCategoryItem IconName={Scooter} name={'Scooter'} />,
    },
    Skateboards: {
        iconName: <IconCategoryItem IconName={Skateboard} name={'Skateboards'} />,
    },
    Golf: {
        iconName: <IconCategoryItem IconName={Golf} name={'Golf'} />,
    },
    Squash: {
        iconName: <IconCategoryItem IconName={Squash} name={'Squash'} />,
    },
    Tennis: {
        iconName: <IconCategoryItem IconName={Tennis} name={'Tennis'} />,
    },
    Tischtennis: {
        iconName: <IconCategoryItem IconName={Tischtennis} name={'Tischtennis'} />,
    },
    Handball: {
        iconName: <IconCategoryItem IconName={Handball} name={'Handball'} />,
    },
    Hockey: {
        iconName: <IconCategoryItem IconName={Hockey} name={'Hockey'} />,
    },
    Volleyball: {
        iconName: <IconCategoryItem IconName={Volleyball} name={'Volleyball'} />,
    },
    'American Football': {
        iconName: <IconCategoryItem IconName={AmericanFussball} name={'American Footbal'} />,
    },
    'Bogenschiessen / Waffensport': {
        iconName: <IconCategoryItem IconName={Bogenschiessen} name={'Bogenschiessen / Waffensport'} />,
    },
    'Schiedsrichter-Bedarf': {
        iconName: <IconCategoryItem IconName={Schiedsrichter} name={'Schiedsrichter-Bedarf'} />,
    },
    Rugby: {
        iconName: <IconCategoryItem IconName={Rugby} name={'Rugby'} />,
    },
    'Baseball / Softball': {
        iconName: <IconCategoryItem IconName={BaseSpieler} name={'Baseball / Softball'} />,
    },
    'Eis-Sport': {
        iconName: <IconCategoryItem IconName={Schlittschuh} name={'Eis-Sport'} />,
    },
    'Ski Alpin': {
        iconName: <IconCategoryItem IconName={Skifahren} name={'Ski Alpin'} />,
    },
    'Schlitteln / Rodeln': {
        iconName: <IconCategoryItem IconName={Schlitten} name={'Schlitteln / Rodeln'} />,
    },
    Snowboarding: {
        iconName: <IconCategoryItem IconName={Snowboarding} name={'Snowboarding'} />,
    },
    'Tourenskifahren / Telemark': {
        iconName: <IconCategoryItem IconName={Ski} name={'Tourenskifahren / Telemark'} />,
    },
    Wintermode: {
        iconName: <IconCategoryItem IconName={Winterstiefel} name={'Wintermode'} />,
    },
    Badminton: {
        iconName: <IconCategoryItem IconName={Badminton} name={'Badminton'} />,
    },
    Fussball: {
        iconName: <IconCategoryItem IconName={Fussball} name={'Fussball'} />,
    },
    Kampfsport: {
        iconName: <IconCategoryItem IconName={Kampfsport} name={'Kampfsport'} />,
    },
    Ringen: {
        iconName: <IconCategoryItem IconName={Ringen} name={'Ringen'} />,
    },
    Langlauf: {
        iconName: <IconCategoryItem IconName={Skilanglauf} name={'Langlauf'} />,
    },
    Wintersport: {
        iconName: <IconCategoryItem IconName={WinterSport} name={'Wintersport'} />,
    },
}

import React, { useEffect, useState } from 'react'
import classnames from 'classnames'

import { Link } from 'react-router-dom'
import _ from 'lodash'
import BorderOnTopAndBottom from '../../atoms/dotted/BorderOnTopAndBottom'
import ProductRecommendationTile from '../Algolia/ProductTile/ProductRecommendationTile'
import ClientFactory from '../../../services/algolia/ClientFactory'

type ProductRecommendationListProps = {
    renderBlackBackground?: boolean
    renderDottedBorders?: boolean
    renderAsSlider?: boolean
    isDisplayOnPDP?: boolean
    swiperColor?: 'icon-white' | 'icon-black' | 'icon-grey-dark' | 'icon-grey-light'
    alignment?: 'left' | 'center' | 'right'
    headline?: string
    nameCategory: string
    context: any
    selectedVariant?: any
    recommendationProps?: any
    brandName: string
    brandUrl: string
    gtmCategoryName: string
    gtmCategoryId: string
}

const ProductRecommendationList: React.FC<ProductRecommendationListProps> = ({
    renderBlackBackground = false,
    renderDottedBorders = false,
    renderAsSlider = true,
    isDisplayOnPDP = false,
    alignment = 'left',
    swiperColor = 'icon-black',
    nameCategory,
    context,
    headline,
    selectedVariant,
    recommendationProps,
    brandName,
    brandUrl,
    gtmCategoryName,
    gtmCategoryId,
}: ProductRecommendationListProps) => {
    const [productRecommendationTiles, setProductRecommendationTiles] = useState([])
    const recommendationConfigurations: any = ClientFactory.generateConfigure(recommendationProps)

    // TODO: keep deleting props in recommendationConfigurations after the feature go live
    delete recommendationConfigurations?.maxRecommendations
    delete recommendationConfigurations?.ruleContexts

    const updateHeadline = () => {
        const hasBrandNameInHeadline = headline.includes(brandName)

        if (!hasBrandNameInHeadline) {
            return headline
        }

        return headline.split(' ').map((word) => {
            if (word.toLocaleLowerCase() === brandName.toLocaleLowerCase()) {
                return (
                    <Link
                        itemProp={'url'}
                        to={brandUrl}
                        className={'underline'}
                    >

                        {word}
                        {' '}
                    </Link>
                )
            }

            return `${word} `
        })
    }

    useEffect(() => {
        if (_.isEmpty(recommendationConfigurations)) {
            return
        }

        try {
            ClientFactory.queryProductsForRecommendationSlider(context, recommendationConfigurations, selectedVariant.attributes).then((res) => {
                // @ts-ignore
                setProductRecommendationTiles(res.results[0]?.hits)
            })
        } catch (error) {
            console.warn('wrong attribute name')
        }
    }, [selectedVariant])

    if (!productRecommendationTiles || productRecommendationTiles.length <= 0) {
        return null
    }

    return (
        <div
            className={classnames('product-recommendation-list product-list--wrapper product-tiles', {
                'bg-white': !renderBlackBackground,
                'my-comp': !renderBlackBackground && !renderDottedBorders,
                'py-8 md:py-10 lg:py-12 xl:py-16 xxl:py-20': renderBlackBackground || renderDottedBorders,
                'dark-mode bg-blacksport-100': renderBlackBackground,
            })}
        >
            <div
                className={classnames('o-wrapper', { 'px-0 laptop:px-8 product-recommendation-list--on-pdp': isDisplayOnPDP })}
            >
                <div className={'o-grid o-region'}>
                    <div className={'o-cell o-cell--12'}>
                        {headline
                        && <h3 className={'markdown--align__left markdown--padding__none'}>{updateHeadline()}</h3>}
                        {renderDottedBorders
                        && (
                            <BorderOnTopAndBottom
                                renderAtTop
                                renderBlackBackground={renderBlackBackground}
                            />
                        )}
                        <div className={'product-list--component'}>
                            <div className={'product--tiles--algolia'}>
                                <ProductRecommendationTile
                                    context={context}
                                    gtmCategoryName={gtmCategoryName}
                                    gtmCategoryId={gtmCategoryId}
                                    renderAsSlider={renderAsSlider}
                                    // @ts-ignore
                                    renderBlackBackground={renderBlackBackground}
                                    renderDottedBorders={renderDottedBorders}
                                    swiperColor={swiperColor}
                                    alignment={alignment}
                                    hits={productRecommendationTiles}
                                    isDisplayOnPDP={isDisplayOnPDP}
                                />
                            </div>
                        </div>
                        {renderDottedBorders
                        && (
                            <BorderOnTopAndBottom
                                renderAtTop={false}
                                renderBlackBackground={renderBlackBackground}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductRecommendationList

import React from 'react'
import PropTypes from 'prop-types'
import MediaImage from '@frontastic/catwalk/src/js/mediaImage'
import { FormattedMessage, injectIntl } from 'react-intl'
import app from '@frontastic/catwalk/src/js/app/app'
import Sport2000Logo from '../../atoms/sport2000Logo'
import FullPageWidthWrapper from '../../molecules/Layout/FullPageWidthWrapper'
import IconButton from '../../atoms/button/IconButton'
import { ReactComponent as ArrowLWhiteIcon } from '../../../../icons/sport2000-icons/arrowleft_white.svg'

const MinimalHeader = (props) => {
    const {
        media, mediaAbsolute, mediaRunning, intl,
    } = props

    return (
        <FullPageWidthWrapper
            withWrapper
            customClasses={'minimal-header-component'}
        >
            <div
                className={'header-minimal'}
            >
                <div
                    className={'header-minimal-left'}
                >
                    <IconButton
                        type={'button'}
                        className={'btn-minimal-header btn'}
                        icon={<ArrowLWhiteIcon width={24} height={24} />}
                        ariaLabel={intl.formatMessage({ id: 'home.backToShop' })}
                        onClick={() => {
                            app.getRouter().history.push('/')
                        }}
                    >
                        <FormattedMessage id={'home.backToShop'} />
                    </IconButton>
                </div>
                <div className={'header-minimal-right'}>
                    <div className={'header-minimal-logo'}>
                        {media && (
                            <div className={'header-minimal-logo-sport'}>
                                <MediaImage
                                    media={media}
                                    title={'logo sport2000'}
                                    draggable={false}
                                />
                            </div>
                        )}

                        {(mediaAbsolute || mediaRunning) && (
                            <div className={'header-minimal-logo-absolute-wrap'}>
                                <div className={'header-minimal-logo-absolute'}>
                                    <Sport2000Logo name={'AbsoluteTeamsport_1line_neg'} alt={'logo Absolute Teamsport'} height={'42px'} />
                                </div>
                                <div className={'header-minimal-logo-absolute'}>
                                    <Sport2000Logo name={'RunningExperts_1line_neg'} alt={'logo Running Expert'} height={'42px'} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </FullPageWidthWrapper>
    )
}

MinimalHeader.propTypes = {
    intl: PropTypes.object.isRequired,
    media: PropTypes.object.isRequired,
    mediaAbsolute: PropTypes.object.isRequired,
    mediaRunning: PropTypes.object.isRequired,
}

export default injectIntl(MinimalHeader)

import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import StoreService from '../../../../../services/algolia/StoreService'

const StoreTime = ({ time, className = '' }) => {
    if (!time) {
        return null
    }

    const renderWorkingDays = () => {
        const workingDays = StoreService.formatWorkingTime(time)
        if (!workingDays) {
            return null
        }

        return workingDays.map((day, index) => (
            <p key={day.label + index} className={'capitalize'}>
                {day.label}
                {' '}
                {day.time ? (
                    <span className={'flex flex-col'}>
                        {day.time.map((value, i) => (
                            <time key={value + i} className={'flex justify-end'}>
                                {value}
                            </time>
                        ))}
                    </span>
                ) : <FormattedMessage id={'store.closed'} />}
            </p>
        ))
    }

    return (
        <div className={`openning-hours ${className}`}>
            {renderWorkingDays()}
        </div>
    )
}

StoreTime.propTypes = {
    time: PropTypes.any,
    className: PropTypes.any,
}

export default StoreTime

import React from 'react'
import PropTypes from 'prop-types'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import NewsletterBlock from '../../../patterns/organisms/NewsletterBlock'

const Newsletter = ({ data }) => {
    const {
        headline, desc, placeholder, btnSubmitText, btnVariant, bgcolor, isDotted,
    } = data

    return (
        <NewsletterBlock
            headline={headline}
            desc={desc}
            placeholder={placeholder}
            btnSubmitText={btnSubmitText}
            btnVariant={btnVariant}
            bgcolor={bgcolor}
            isDotted={isDotted}
        />
    )
}

Newsletter.propTypes = {
    data: PropTypes.object.isRequired,
}

export default tastify({ translate: true })(Newsletter)

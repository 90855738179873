import React from 'react'
import PropTypes from 'prop-types'
import SingleButton from '../../patterns/molecules/SingleButton'

const singleButtonTastic = ({
    data: {
        buttonLabel, reference, btnVariant, btnColorDefault, btnColorHover,
    },
}) => (
    <SingleButton
        label={buttonLabel}
        reference={reference}
        variant={btnVariant}
        color={btnColorDefault}
        colorHover={btnColorHover}
    />
)

singleButtonTastic.propTypes = {
    data: PropTypes.object.isRequired,
}

export default singleButtonTastic

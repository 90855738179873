import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Button from '../../../atoms/button/Button'

const GenderFilter = ({ categoryGenders }) => {
    if (!categoryGenders || !categoryGenders.length) {
        return null
    }

    return (
        <div className={'product-list-filter-gender'}>
            {categoryGenders.map((categoryGender) => (
                <Button
                    key={categoryGender.key}
                    type={'button'}
                    className={classnames('btn', {
                        active: categoryGender.isActive,
                    })}
                    onClick={() => {
                        // if change button to link use this for href
                        window.location.href = categoryGender.url + window.location.search
                    }}
                    aria-label={categoryGender.gender}
                >
                    {categoryGender.gender}
                </Button>
            ))}
        </div>
    )
}
GenderFilter.propTypes = {
    categoryGenders: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default GenderFilter

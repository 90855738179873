import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Markdown from '@frontastic/catwalk/src/js/component/markdown'
import useComponentVisible from '../../../utils/hooks/UseComponentVisible'
import Button from '../button/Button'
import { ReactComponent as ArrowThickDownIcon } from '../../../../icons/sport2000-icons/arrow-thick-down.svg'

const AccordionItem = ({
    title, description, ctaButton, ctaLink, btnColorDefault, btnColorHover,
}) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
    const [height, setHeight] = useState(0)
    const innerRef = useRef(null)

    useEffect(() => {
        if (isComponentVisible) {
            setHeight(innerRef.current.getBoundingClientRect().height)
        } else {
            setHeight(0)
        }
    }, [isComponentVisible])

    const onClick = () => {
        setIsComponentVisible(!isComponentVisible)
    }
    return (
        <div ref={ref} className={classnames('accordion-item', { 'accordion-item--opened': isComponentVisible })}>
            <div className={'accordion-item__line'} onClick={onClick}>
                <span className={'accordion-item__icon'}>
                    <ArrowThickDownIcon />
                </span>
                <div className={'accordion-item__title'}>
                    {title}
                </div>
            </div>
            <div
                className={'accordion-item__inner'}
                style={{ height }}
            >
                <div className={'accordion-item__content'} ref={innerRef}>
                    <div className={'accordion-item__paragraph'}>
                        <Markdown text={description} />
                    </div>
                    {ctaButton && ctaLink
                    && (
                        <a href={ctaLink}>
                            <Button
                                className={`btn btn-secondary teaser-block--button btn--color-${btnColorDefault} btn--hover-${btnColorHover}`}
                                type={'button'}
                                ariaLabel={ctaButton}
                            >
                                {ctaButton}
                            </Button>
                        </a>
                    )}
                </div>
            </div>
        </div>
    )
}

AccordionItem.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    ctaButton: PropTypes.string,
    ctaLink: PropTypes.string,
    btnColorDefault: PropTypes.string,
    btnColorHover: PropTypes.string,
}

export default AccordionItem

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import Message from '@frontastic/catwalk/src/js/app/message'
import { ConfigurationSchema } from '@frontastic/common/src/js/frontastic-common'
import ShippingForm from './Forms/Shipping'
import BillingForm from './Forms/Billing'
import Billing from './Info/Billing'
import Button from '../../atoms/button/Button'
import Shipping from './Info/Shipping'
import CheckoutSummary from '../Forms/CheckoutSummary'
import AccountAddresses from '../../molecules/Account/AccountAddresses'
import IconButton from '../../atoms/button/IconButton'
import { ReactComponent as IconChevronDown } from '../../../../icons/sport2000-icons/arrow-left.svg'
import ErrorMessageProduct from '../ErrorMessageProduct'
import CreateAccountForm from './Forms/CreateAccount'
import EmailService from '../../../services/email'
import RegisterService from '../../../services/register'
import GtmService from '../../../services/gtm'
import TagManager from '../../../domain/TagManager'
import SubscribeService from '../../../services/subscribe'
import { setInvalidTopic, setIsRequireTopicChecked } from '../../../store/actions/SubscribeEmailAction'
import { validateEmail, validPassword, validPhoneNumber } from '../../../utils/helpers/accountHelper'

const DEFAULT_COUNTRY_CODE = 'DE'
const FLAG_DEFAULT_BILLING_ADDRESS = 'isDefaultBillingAddress'
const FLAG_DEFAULT_SHIPPING_ADDRESS = 'isDefaultShippingAddress'

const ShippingPanel = ({
    app,
    intl,
    data,
    countries,
    setCurrent,
    currentIndex,
    active,
    setActive,
    setNextActive,
    setValidNextStep,
    contextSession,
    selectedShippingAddressId,
    setSelectedShippingAddressId,
    firstRun,
    setFirstRun,
    setDivider1,

}) => {
    const loggedIn = useSelector((state) => state.app.context.session.loggedIn)
    const [isUpdated, setIsUpdated] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const {
        isRequireTopicChecked,
        isSubscibeChecked,
        topics,
        invalidTopic,
    } = useSelector((state) => state['subscribe-email'])

    const context = useSelector((globalState) => globalState.app && globalState.app.context)
    const projectSchema = new ConfigurationSchema(
        context.projectConfigurationSchema,
        context.projectConfiguration,
    )

    const { requireTopicValue, checkAllTopicValue, restrictEmailDomains } = projectSchema.configuration

    const topicRequired = requireTopicValue.replace(/\s/g, '').split(',')

    const initAddress = (isDefaultAddress) => {
        if (!contextSession.session.account.addresses || contextSession.session.account.addresses.length <= 0) {
            return {}
        }

        const addressByDefault = contextSession.session.account.addresses.find((address) => address[isDefaultAddress] && address.country === DEFAULT_COUNTRY_CODE)

        if (addressByDefault) {
            return _.cloneDeep(addressByDefault)
        }

        if (contextSession.session.account.addresses[0].country === DEFAULT_COUNTRY_CODE) {
            return _.cloneDeep(contextSession.session.account.addresses[0])
        }

        return {}
    }

    const initValueShippingAddress = () => {
        if (loggedIn) {
            return initAddress(FLAG_DEFAULT_SHIPPING_ADDRESS)
        }

        if (!loggedIn && data.shippingAddress) {
            return data.shippingAddress
        }

        return null
    }

    const initValueBillingAddress = () => {
        if (loggedIn) {
            return initAddress(FLAG_DEFAULT_BILLING_ADDRESS)
        }

        if (!loggedIn && data.billingAddress) {
            return data.billingAddress ? data.billingAddress : {}
        }

        return null
    }

    const closeCollapseLoggedIn = () => {
        if (loggedIn) {
            return setActive(!active)
        }

        return setActive(active)
    }

    const [email, setEmail] = useState(data.email)
    const [shipping, setShipping] = useState(initValueShippingAddress())
    const [billing, setBilling] = useState(initValueBillingAddress())
    const [accountInfo, setAccountInfo] = useState(null)
    const [updateBilling, setUpdateBilling] = useState(false)
    const [updateShipping, setUpdateShipping] = useState(false)
    const filterCountryDE = countries.filter((country) => country.code === DEFAULT_COUNTRY_CODE)

    const isSameAddress = () => {
        if (shipping && shipping.firstName) {
            return ((shipping.firstName === billing.firstName
                    && shipping.lastName === billing.lastName
                    && shipping.streetName === billing.streetName
                    && shipping.postalCode === billing.postalCode
                    && shipping.country === billing.country
                    && shipping.state === billing.state
                    && shipping.streetNumber === billing.streetNumber
                    && shipping.phone === billing.phone)
                || (
                    _.isEmpty(billing)
                    && _.isEmpty(shipping.firstName)
                    && _.isEmpty(shipping.lastName)
                    && _.isEmpty(shipping.streetName)
                    && shipping.country === DEFAULT_COUNTRY_CODE
                    && _.isEmpty(shipping.state)
                    && _.isEmpty(shipping.streetNumber)
                    && _.isEmpty(shipping.phone)
                ))
        }

        if (!firstRun && !isUpdated) {
            setFirstRun(true)

            return true
        }

        return contextSession.session.account.addresses.length <= 1
    }

    const [isBillingSameAsShipping, setBillingIsSameAsShipping] = useState(isSameAddress())
    const [disableCheckbox, setDisableCheckbox] = useState(false)
    const [showDetail, setShowDetail] = useState(loggedIn ? '' : data.email && (!!data.billingAddress.streetName || !!data.shippingAddress.streetName))
    const [isEditShipping, setIsEditShipping] = useState(false)
    const [isEditBilling, setIsEditBilling] = useState(false)
    const [defaultAddress] = useState({})
    const [tempBilling, setTempBilling] = useState(null)
    const [tempShipping, setTempShipping] = useState(null)
    const [isCreateAccount, setIsCreateAccount] = useState(false)
    const [isEmailDuplicated, setIsEmailDuplicated] = useState(false)

    const validEmailMessage = intl.formatMessage({ id: 'validation.email' })

    const validPostalCode = (value, isShipping = false) => {
        if (_.isEmpty(value)) {
            return false
        }

        if (!isShipping && billing.country !== DEFAULT_COUNTRY_CODE && !_.isEmpty(value)) {
            return true
        }

        const isValid = value.toString().match(/^[0-9]{5}$/)

        return !_.isEmpty(isValid)
    }

    const onNextClick = () => {
        setNextActive(true)
        setDivider1(true)
        setShowDetail(true)
        setCurrent(currentIndex + 1)
        setValidNextStep(true)
        setIsUpdated(true)
        setFirstRun(false)
        setIsLoading(false)
    }

    const isValid = () => {
        const { addresses } = contextSession.session.account
        const shippingAddressDefault = addresses.filter((address) => address.country === DEFAULT_COUNTRY_CODE)
        let isValidAccount = true

        if (loggedIn && addresses.length && !shippingAddressDefault.length) {
            return false
        }

        if (isCreateAccount) {
            if (!(accountInfo
                && validPassword(accountInfo.password)
                && !validateEmail(email, restrictEmailDomains, validEmailMessage)
                && accountInfo.password === accountInfo.confirmPassword)) {
                isValidAccount = false
            }
        }

        if (isBillingSameAsShipping) {
            return (
                email
                && !validateEmail(email, restrictEmailDomains, validEmailMessage)
                && billing.firstName
                && billing.lastName
                && billing.streetNumber
                && billing.streetName
                && validPostalCode(billing.postalCode, false)
                && billing.city
                && billing.country
                && validPhoneNumber(billing.phone)
                && isValidAccount
            )
        }

        return (
            email
            && !validateEmail(email, restrictEmailDomains, validEmailMessage)
            && shipping.firstName
            && shipping.lastName
            && shipping.streetNumber
            && shipping.streetName
            && validPostalCode(shipping.postalCode, true)
            && shipping.city
            && shipping.country
            && validPhoneNumber(shipping.phone)
            && billing.firstName
            && billing.lastName
            && billing.streetNumber
            && billing.streetName
            && billing.postalCode
            && billing.city
            && billing.country
            && validPhoneNumber(billing.phone)
            && isValidAccount
        )
    }

    const updateCart = () => {
        const payload = {
            account: {
                email,
            },
            billing: {
                firstName: billing.firstName,
                lastName: billing.lastName,
                phone: billing.phone,
                streetName: billing.streetName,
                streetNumber: billing.streetNumber,
                postalCode: billing.postalCode,
                city: billing.city,
                country: billing.country,
                additionalAddressInfo: billing.additionalAddressInfo,
            },
            shipping: {
                firstName: shipping.firstName || billing.firstName,
                lastName: shipping.lastName || billing.lastName,
                phone: shipping.phone || billing.phone,
                streetName: shipping.streetName || billing.streetName,
                streetNumber: shipping.streetNumber || billing.streetNumber,
                postalCode: shipping.postalCode || billing.postalCode,
                city: shipping.city || billing.city,
                country: shipping.country || billing.country,
                additionalAddressInfo: shipping.additionalAddressInfo || billing.additionalAddressInfo,
            },
        }

        // Set shipping is same billing when user doesn't login and isBillingSameAsShipping = true
        if (!loggedIn && isBillingSameAsShipping) {
            payload.shipping = {
                firstName: billing.firstName,
                lastName: billing.lastName,
                phone: billing.phone,
                streetName: billing.streetName,
                streetNumber: billing.streetNumber,
                postalCode: billing.postalCode,
                city: billing.city,
                country: billing.country,
            }
        }

        app.getLoader('cart')
            .updateCart(payload)
            .then(() => {
                onNextClick()

                // update shipping which is same billing when user doesn't login and isBillingSameAsShipping = true
                if (!loggedIn && isBillingSameAsShipping) {
                    setShipping({
                        firstName: billing.firstName,
                        lastName: billing.lastName,
                        phone: billing.phone,
                        streetName: billing.streetName,
                        streetNumber: billing.streetNumber,
                        postalCode: billing.postalCode,
                        city: billing.city,
                        country: billing.country,
                        additionalAddressInfo: billing.additionalAddressInfo,
                    })
                }

                closeCollapseLoggedIn()
            })
    }

    useEffect(() => {
        if (!loggedIn && data.email && (!!data.billingAddress.streetName || !!data.shippingAddress.streetName)) {
            onNextClick()
        }
    }, [])

    const handleGoogleTracking = () => {
        const gtmService = new GtmService(data.lineItems, null, null, data)
        const productListGTM = gtmService.createProductListGTM('Checkout')
        const shippingInfoGTM = gtmService.createShippingInfoGTM()
        TagManager.addShippingInfo(shippingInfoGTM, productListGTM)
        TagManager.hitViewdImpressions()
    }

    const updateShippingWhenLoggedIn = () => {
        setIsLoading(true)
        const payload = _.cloneDeep(billing)
        payload.addressId = ''
        payload[FLAG_DEFAULT_BILLING_ADDRESS] = true

        if (isBillingSameAsShipping) {
            payload[FLAG_DEFAULT_SHIPPING_ADDRESS] = true
        }

        setFirstRun(false)

        return app.getLoader('context').addAddress(payload).then(() => {
            setFirstRun(false)

            if (isBillingSameAsShipping) {
                return updateCart()
            }

            const payloadShipping = _.cloneDeep(shipping)
            payloadShipping.addressId = ''
            payloadShipping[FLAG_DEFAULT_SHIPPING_ADDRESS] = true

            return app.getLoader('context').addAddress(payloadShipping).then(() => {
                setFirstRun(false)
                updateCart()
            })
        })
    }

    const subscribeEmail = () => {
        setIsRequireTopicChecked(true)

        const dataSubscribe = {
            email,
            topics: SubscribeService.collectTopicValue(topics),
        }

        SubscribeService.subscribe(dataSubscribe).then((res) => {
            if (!res.ok) {
                console.errors('Subscribe email failed')
            }
        })
    }

    const updateShippingInformation = async () => {
        if (isValid()) {
            handleGoogleTracking()

            if (isSubscibeChecked) {
                const resultValidateTopic = SubscribeService.validateRequireTopics(topics, topicRequired)

                if (resultValidateTopic.length > 0) {
                    setIsRequireTopicChecked(false)
                    setInvalidTopic(resultValidateTopic)
                    return
                }

                subscribeEmail()
            }

            if (!loggedIn && isCreateAccount && !showDetail) {
                // try {
                    // const responseEmailDuplicated = await EmailService.checkEmailDuplicated(email)
                    // if (!responseEmailDuplicated.valid) {
                    //     setIsEmailDuplicated(true)
                    //     return
                    // }
                    await RegisterService.register({
                        email: billing.email,
                        salutation: '',
                        firstName: billing.firstName,
                        lastName: billing.lastName,
                        password: accountInfo.password,
                        billingAddress: billing,
                        shippingAddress: shipping,
                    }, false).then((response) => {
                        if (response.ok) {
                            return updateCart()
                        } else {
                            app.getLoader('context').notifyUser(<Message
                                message={intl.formatMessage({ id: 'account.message.registeredFailed' })}
                            />, 'error')
                        }
                    })

                // } catch (err) {
                //     app.getLoader('context').notifyUser(<Message
                //         message={err}
                //     />, 'error')
                // }
            } else if (loggedIn && !contextSession.session.account.addresses.length) {
                return updateShippingWhenLoggedIn()
            } else {
                return updateCart()
            }
        }
    }

    // get default billing address from account data - checkout customer
    const getDefaultBillingAddress = () => {
        let defaultAddress = {}

        if (!loggedIn && data.billingAddress) {
            return data.billingAddress ? data.billingAddress : {}
        }

        if (loggedIn && contextSession.session.account.addresses.length) {
            defaultAddress = {}
            contextSession.session.account.addresses.map((address, index) => {
                if (address[FLAG_DEFAULT_BILLING_ADDRESS]) {
                    defaultAddress = address
                }
            })

            if (_.isEmpty(defaultAddress)) {
                defaultAddress = contextSession.session.account.addresses[0]
            }
        }

        return defaultAddress
    }

    // get default shipping address from account data - checkout customer
    const getShippingAddress = (isUpdateForm) => {
        if (isUpdateForm) {
            return shipping
        }

        let defaultShippingAddress = _.cloneDeep(data.shippingAddress)

        if (loggedIn && contextSession.session.account.addresses.length) {
            defaultShippingAddress = {}

            contextSession.session.account.addresses.map((address, index) => {
                if (address[FLAG_DEFAULT_SHIPPING_ADDRESS]) {
                    defaultShippingAddress = address
                }
            })

            if (_.isEmpty(defaultShippingAddress)) {
                defaultShippingAddress = contextSession.session.account.addresses[0]
            }
        }

        return defaultShippingAddress
    }

    const getNewShippingAddress = () => ({
        firstName: '',
        lastName: '',
        phone: '',
        country: '',
        postalCode: '',
        state: '',
        city: '',
        streetNumber: '',
        streetName: '',
    })

    const validateShipping = (data) => data.firstName.trim()
        && data.lastName.trim()
        && data.streetNumber.trim()
        && data.streetName.trim()
        && data.postalCode
        && validPostalCode(data.postalCode, true)
        && data.country.trim()
        && data.city.trim()

    const validateBilling = (data) => data.firstName.trim()
        && data.lastName.trim()
        && data.streetNumber.trim()
        && data.streetName.trim()
        && data.postalCode
        && validPostalCode(data.postalCode, false)
        && data.country.trim()
        && data.city.trim()

    const createOrEditShippingAddress = (isUpdate) => {
        if (!validateShipping(shipping)) {
            return
        }

        if (isUpdate) {
            contextSession.session.account.addresses.map((item) => {
                if (item.addressId === shipping.addressId) {
                    item.firstName = shipping.firstName
                    item.lastName = shipping.lastName
                    item.phone = shipping.phone
                    item.streetName = shipping.streetName
                    item.streetNumber = shipping.streetNumber
                    item.country = shipping.country
                    item.postalCode = shipping.postalCode
                    item.state = shipping.state
                    item.city = shipping.city
                    item.additionalAddressInfo = shipping.additionalAddressInfo // companyName
                }
            })

            app.getLoader('context').updateAddress(shipping)

            return
        }

        const payload = _.cloneDeep(shipping)
        payload.addressId = ''

        app.getLoader('context').addAddress(payload)
    }

    const updateShippingAddress = (isUpdate) => {
        if (isUpdate && !validateShipping(shipping)) {
            return
        }
        setShowDetail(true)
        setIsEditShipping(false)

        createOrEditShippingAddress(isUpdate)
    }

    const updateBillingAddress = () => {
        if (!validateBilling(billing)) {
            return
        }

        if (isSubscibeChecked) {
            const resultValidateTopic = SubscribeService.validateRequireTopics(topics, topicRequired)

            if (resultValidateTopic.length > 0) {
                setIsRequireTopicChecked(false)
                setInvalidTopic(resultValidateTopic)
                return
            }

            subscribeEmail()
        }

        setUpdateBilling(true)
        setShowDetail(true)
        setIsEditBilling(false)
    }

    const renderTemplateNotLoggedIn = () => (
        <div className={'checkout-guest-wrapper mt-6 lg:mt-8'}>
            {!showDetail && (
                <div className={'billing-checkout'}>
                    <BillingForm
                        intl={intl}
                        countries={countries}
                        defaultEmail={data.email}
                        defaultValues={data.billingAddress}
                        isEmailDuplicated={isEmailDuplicated}
                        restrictEmailDomains={restrictEmailDomains}
                        onSubmit={(data, selectedCountry) => {
                            data.country = selectedCountry
                            setEmail(data.email)
                            setBilling(data)
                            if (selectedCountry !== DEFAULT_COUNTRY_CODE) {
                                setBillingIsSameAsShipping(false)
                                setDisableCheckbox(true)
                            } else {
                                setDisableCheckbox(false)
                            }
                        }}
                    />
                </div>
            )}

            {!showDetail && (
                <div className={'create-account-form mt-5 md:mt-10'}>
                    <h3 className={'address-title checkout-title lg:mb-5'}>
                        <FormattedMessage id={'checkout.createAccount'} />
                    </h3>
                    <div className={'create-account-form--checkbox mb-6 lg:mb-8'}>
                        <div className={'text-sm text-neutral-900 flex items-center'}>
                            <div className={'flex w-full'}>
                                <input
                                    type={'checkbox'}
                                    id={'input--checkbox-create-account'}
                                    value={isCreateAccount}
                                    onClick={() => {
                                        setIsCreateAccount(!isCreateAccount)
                                    }}
                                    checked={isCreateAccount}
                                />
                                <label className={'font-sans'} htmlFor={'input--checkbox-create-account'}>
                                    <FormattedMessage id={'checkout.createAccountLabel'} />
                                </label>
                            </div>
                        </div>
                    </div>
                    <CreateAccountForm
                        isCreateAccount={isCreateAccount}
                        onChange={(data) => setAccountInfo(data)}
                    />
                </div>
            )}

            {!showDetail && (
                <div className={'address-checkout lg:pt-6'}>

                    <h3 className={'address-title checkout-title lg:mb-8'}>
                        <FormattedMessage id={'checkout.shippingTo'} />
                    </h3>

                    <div className={'checkbox-checkout mb-6 lg:mb-8'}>
                        <div className={'text-sm text-neutral-900 flex items-center'}>
                            <div className={'checkbox-radio--wrapper w-full'}>
                                <input
                                    type={'checkbox'}
                                    id={'input--checkbox-checkout'}
                                    checked={!isBillingSameAsShipping}
                                    value={!isBillingSameAsShipping}
                                    onClick={() => {
                                        setBillingIsSameAsShipping(!isBillingSameAsShipping)
                                    }}
                                    disabled={disableCheckbox}
                                />
                                <label className={'font-sans'} htmlFor={'input--checkbox-checkout'}>
                                    <FormattedMessage id={'checkout.billingDetailsLabel'} />
                                </label>
                            </div>
                        </div>
                    </div>

                    {!isBillingSameAsShipping && (
                        <div className={'py-2'}>
                            <ShippingForm
                                intl={intl}
                                countries={filterCountryDE}
                                defaultValues={data.shippingAddress}
                                onSubmit={(data, selectedCountry) => {
                                    data.country = selectedCountry
                                    setShipping(data)
                                }}
                            />
                        </div>
                    )}

                </div>
            )}

            <div className={`${showDetail ? 'block' : 'hidden'}`}>
                <div className={'delivery-content mt-6 lg:mt-8'}>
                    <div className={'lg:flex'}>
                        <div className={'delivery-billing lg:flex-1'}>
                            {data.billingAddress && (
                                <Billing
                                    address={data.billingAddress}
                                    countries={countries}
                                    onClick={() => {
                                        setShowDetail(false)
                                    }}
                                />
                            )}
                        </div>
                        <div className={'delivery-address mb-6 lg:mb-0 lg:flex-1 lg:mr-5'}>
                            {data.shippingAddress && (
                                <Shipping
                                    address={data.shippingAddress}
                                    countries={countries}
                                    onClick={() => {
                                        setShowDetail(false)
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {!showDetail && (
                <CheckoutSummary
                    buttonLabel={intl.formatMessage({ id: 'checkout.nextPayment' })}
                    disabled={!isValid()}
                    onClick={updateShippingInformation}
                />
            )}
        </div>
    )

    const renderTemplateLoggedIn = () => (
        <div className={'checkout-customer-wrapper'}>
            <div className={'billing-form'}>
                {(isEditBilling || !contextSession.session.account.addresses.length) ? (
                    <div className={'billing-checkout my-6 lg:my-8'}>
                        <BillingForm
                            intl={intl}
                            countries={countries}
                            defaultEmail={data.email}
                            defaultValues={!updateBilling ? getDefaultBillingAddress() : billing}
                            restrictEmailDomains={restrictEmailDomains}
                            onSubmit={(data, selectedCountry) => {
                                data.country = selectedCountry
                                setEmail(data.email)
                                setBilling(data)

                                if (selectedCountry !== DEFAULT_COUNTRY_CODE) {
                                    setBillingIsSameAsShipping(false)
                                    setDisableCheckbox(true)
                                } else {
                                    setDisableCheckbox(false)
                                }
                            }}
                        />
                        {isEditBilling && (
                            <div className={'flex'}>
                                <Button
                                    type={'button'}
                                    className={'btn checkout-save-btn btn-default mr-6'}
                                    ariaLabel={intl.formatMessage({ id: 'checkout.editDetails' })}
                                    disabled={!validateBilling(billing)}
                                    onClick={updateBillingAddress}
                                >
                                    <FormattedMessage id={'checkout.editDetails'} />
                                </Button>
                                <Button
                                    type={'button'}
                                    ariaLabel={intl.formatMessage({ id: 'checkout.cancelDetails' })}
                                    className={'checkout-cancel-btn btn-secondary'}
                                    onClick={() => {
                                        setShowDetail(false)
                                        setIsEditBilling(false)
                                        setBilling(tempBilling)
                                    }}
                                >
                                    <FormattedMessage id={'checkout.cancelDetails'} />
                                </Button>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className={'customer-billing-info mt-6 lg:mt-8 lg:flex lg:justify-between'}>
                        {defaultAddress && (
                            <Billing
                                address={!updateBilling ? getDefaultBillingAddress() : billing}
                                countries={countries}
                                onClick={() => {
                                    setIsUpdated(true)
                                    setFirstRun(false)
                                    setTempBilling(billing)
                                    setShowDetail(false)
                                    setIsEditBilling(true)
                                }}
                            />
                        )}
                    </div>
                )}
            </div>

            <div className={'shipping-form mt-5 md:mt-10'}>
                <h3 className={'address-title checkout-title lg:mb-5'}>
                    <FormattedMessage id={'checkout.shippingTo'} />
                </h3>

                {(!contextSession.session.account.addresses.length) && (
                    <div className={'checkbox-checkout mb-6 lg:mb-8'}>
                        <div className={'text-sm text-neutral-900 flex items-center'}>
                            <div className={'checkbox-radio--wrapper w-full'}>
                                <input
                                    type={'checkbox'}
                                    id={'input--checkbox-checkout'}
                                    value={!isBillingSameAsShipping}
                                    onClick={() => {
                                        setBillingIsSameAsShipping(!isBillingSameAsShipping)
                                    }}
                                    checked={!isBillingSameAsShipping}
                                    disabled={disableCheckbox}
                                />
                                <label className={'font-sans'} htmlFor={'input--checkbox-checkout'}>
                                    <FormattedMessage id={'checkout.billingDetailsLabel'} />
                                </label>
                            </div>
                        </div>
                    </div>
                )}

                {(isEditShipping || (!isBillingSameAsShipping && firstRun)) && (
                    <>
                        <div className={`my-6 lg:my-8${isLoading ? ' hidden' : ''}`}>
                            <ShippingForm
                                intl={intl}
                                countries={filterCountryDE}
                                defaultValues={updateShipping ? getShippingAddress(true) : getNewShippingAddress()}
                                onSubmit={(address, selectedCountry) => {
                                    address.country = selectedCountry
                                    setShipping(address)

                                    if (updateShipping && _.isEmpty(address.addressId)) {
                                        data.shippingAddress = address
                                    }
                                }}
                            />
                            {isEditShipping && (
                                <div className={'flex'}>
                                    <Button
                                        type={'button'}
                                        ariaLabel={intl.formatMessage({ id: 'checkout.editDetails' })}
                                        disabled={!validateShipping(shipping)}
                                        className={'btn checkout-save-btn btn-default mr-6'}
                                        onClick={() => {
                                            updateShippingAddress(updateShipping)
                                        }}
                                    >
                                        <FormattedMessage id={'checkout.editDetails'} />
                                    </Button>
                                    <Button
                                        type={'button'}
                                        ariaLabel={intl.formatMessage({ id: 'checkout.cancelDetails' })}
                                        className={'checkout-cancel-btn btn-secondary'}
                                        onClick={() => {
                                            setShowDetail(false)
                                            setIsEditShipping(false)
                                            setShipping(tempShipping)
                                        }}
                                    >
                                        <FormattedMessage id={'checkout.cancelDetails'} />
                                    </Button>
                                </div>

                            )}
                        </div>
                    </>
                )}

                {(!isEditShipping && contextSession.session.account.addresses.length > 0) && (
                    <div>
                        <div className={'customer-shipping-info'}>
                            <AccountAddresses
                                isUseAddressCart={false}
                                addresses={contextSession.session.account.addresses}
                                countries={countries}
                                setIsEditShipping={setIsEditShipping}
                                setUpdateShipping={setUpdateShipping}
                                setShipping={setShipping}
                                setSelectedShippingAddressId={setSelectedShippingAddressId}
                                selectedShippingAddressId={selectedShippingAddressId}
                                isDefault={false}
                                shippingAddressCart={data.shippingAddress}
                                defaultValue={getShippingAddress(false)}
                                onClick={() => {
                                    setTempShipping(shipping)
                                    setShowDetail(false)
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>

            {(!isEditBilling && !isEditShipping) && (
                <CheckoutSummary
                    buttonLabel={intl.formatMessage({ id: 'checkout.nextPayment' })}
                    disabled={!isValid()}
                    onClick={updateShippingInformation}
                />
            )}
        </div>
    )

    return (
        <>
            <div>
                <ErrorMessageProduct
                    removedItems={data.projectSpecificData.removedLineItems ?? []}
                    reducedLineItems={data.projectSpecificData.reducedLineItems ?? []}
                />
            </div>
            <div
                className={classnames('confirm-checkout-wrapper shipping-checkout-wrapper bg-white', {
                    'is--active': active,
                })}
            >
                <IconButton
                    type={'button'}
                    ariaLabel={intl.formatMessage({ id: 'checkout.billingDetails' })}
                    className={'btn btn-text icon--right billing-title checkout-title w-full justify-between mb-0 overflow-visible'}
                    icon={<IconChevronDown width={'16'} height={'16'} className={'order-2'} />}
                    onClick={() => {
                        setActive(!active)
                    }}
                >
                    <FormattedMessage id={'checkout.billingDetails'} />
                </IconButton>

                <div className={`shipping-checkout-content ${active ? 'block' : 'hidden'}`}>
                    {!loggedIn ? (
                        <>
                            {renderTemplateNotLoggedIn()}
                        </>
                    ) : (
                        <>
                            {renderTemplateLoggedIn()}
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

ShippingPanel.propTypes = {
    app: PropTypes.object.isRequired,
    contextSession: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    countries: PropTypes.array.isRequired,
    setCurrent: PropTypes.func.isRequired,
    setSelectedShippingAddressId: PropTypes.func.isRequired,
    selectedShippingAddressId: PropTypes.string.isRequired,
    setNextActive: PropTypes.func.isRequired,
    setActive: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
    setValidNextStep: PropTypes.bool.isRequired,
    currentIndex: PropTypes.number.isRequired,
    firstRun: PropTypes.bool.isRequired,
    setFirstRun: PropTypes.func.isRequired,
    setDivider1: PropTypes.func.isRequired,
}

export default injectIntl(ShippingPanel)

import React from 'react'

const Screen = () => {
    return (
        <div className='p10 my-20'>
            <p className='text-center text-3xl my-6'>Breakpoint</p>
            <table className='w-full bg-gray-200 p-10'>
                <thead>
                    <tr>
                        <th className='text-runsport-70 pt-5 text-xl'>Key</th>
                        <th className='pt-5 text-xl'><strong className='text-runsport-70'>@screen | screen()</strong></th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='border-b border-run-60'>
                        <td className='p-2'><code>xsm: '420px'</code></td>
                        <td className='p-2'><code>@screen xsm {} | @media screen(xsm){}</code></td>
                    </tr>
                    <tr className='border-b border-run-60'>
                        <td className='p-2'><code>sm: '640px'</code></td>
                        <td className='p-2'><code>@screen sm {} | @media screen(sm){}</code></td>
                    </tr>
                    <tr className='border-b border-run-60'>
                        <td className='p-2'><code>tablet | md: '768px'</code></td>
                        <td className='p-2'><code>@screen tablet {} | @media screen(tablet){}</code></td>
                        <td className='p-2'><code>@screen md {} | @media screen(md){}</code></td>
                    </tr>
                    <tr className='border-b border-run-60'>
                        <td className='p-2'><code>laptop | lg: '1024px'</code></td>
                        <td className='p-2'><code>@screen laptop {} | @media screen(laptop){}</code></td>
                        <td className='p-2'><code>@screen lg {} | @media screen(lg){}</code></td>
                    </tr>
                    <tr className='border-b border-run-60'>
                        <td className='p-2'><code>desktop | xl: '1280px'</code></td>
                        <td className='p-2'><code>@screen desktop {} | @media screen(desktop){}</code></td>
                        <td className='p-2'><code>@screen xl {} | @media screen(xl){}</code></td>
                    </tr>
                    <tr className='border-b border-run-60'>
                        <td className='p-2'><code>desktopx | xxl: '1624px'</code></td>
                        <td className='p-2'><code>@screen desktopx {} | @media screen(desktopx){}</code></td>
                        <td className='p-2'><code>@screen xxl {} | @media screen(xxl){}</code></td>
                    </tr>
                    <tr className='border-b border-run-60'>
                        <td className='p-2'><code>desktopxx | xxxl: '1840px'</code></td>
                        <td className='p-2'><code>@screen desktopx {} | @media screen(desktopx){}</code></td>
                        <td className='p-2'><code>@screen xxxl {} | @media screen(xxxl){}</code></td>
                    </tr>
                </tbody>
            </table>
            <br />
            <div className='bg-gray-200 p-5'>
                <p className='mb-5 text-xxl'>Resize the screen to see me: <br /><br />
                    <code>
                        <strong className='text-runsport-70'>classname='</strong>bg-fitness-60 tablet:bg-runsport-70 laptop:bg-teamsport-70 desktop:bg-blacksport-70 desktopx:bg-fitness-40 desktopxx:bg-teamsport-80<strong className='text-runsport-70'>'</strong>
                    </code>
                </p>
                <div className='h-12 w-12 rounded-lg text-center pt-4 shadow-inner text-white bg-fitness-60 tablet:bg-runsport-70 laptop:bg-teamsport-70 desktop:bg-blacksport-70 desktopx:bg-fitness-40 desktopxx:bg-teamsport-80'>Me</div>
                <br /><hr /><br />
                <p className='mb-3'>
                    <code>
                        <strong className='text-runsport-70'>classname='</strong>bg-fitness-60 md:bg-runsport-70 lg:bg-teamsport-70 xl:bg-blacksport-70 xxl:bg-fitness-40 xxxl:bg-teamsport-80<strong className='text-runsport-70'>'</strong>
                    </code></p>
                <div className='mt-5 h-12 w-12 rounded-lg text-center pt-4 shadow-inner text-white bg-fitness-60 md:bg-runsport-70 lg:bg-teamsport-70 xl:bg-blacksport-70 xxl:bg-fitness-40 xxxl:bg-teamsport-80'>Me</div>
            </div>
        </div>
    )
}
export default Screen

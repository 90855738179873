import React from 'react'
import PropTypes from 'prop-types'
import MediaImage from '@frontastic/catwalk/src/js/mediaImage'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import classnames from 'classnames'

const Stores = ({
    headline, headlinePos, storeItem, isDarkMode,
}) => (
    <section className={classnames('width--fullsize py-comp', {
        'bg-blacksport-100 text-white': isDarkMode,
    })}
    >
        <div
            className={'store-detail-component o-wrapper'}
        >
            <h2
                className={`headline ${headlinePos}`}
            >
                {' '}
                {headline}
            </h2>

            <div className={'grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 lg:gap-10'}>
                {storeItem.map((item) => (
                    <div key={item.title} className={'store-item'}>
                        <MediaImage
                            media={item.icon}
                            className={classnames('store-icon', {
                                'is--svg': item.icon?.media?.format === 'svg',
                            })}
                        />
                        <div className={'font-bold'}>{item.title}</div>
                        <div className={'mt-1'}>{item.desc}</div>
                    </div>
                ))}
            </div>
        </div>
    </section>
)

Stores.propTypes = {
    headline: PropTypes.string.isRequired,
    headlinePos: PropTypes.string,
    storeItem: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.object,
        title: PropTypes.string,
        desc: PropTypes.string,
    })).isRequired,
    isDarkMode: PropTypes.bool,
}

export default tastify()(Stores)

import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { LoadingIcon } from '../../../atoms/icon'
import Price from '../../../atoms/price'
import Button from '../../../atoms/button/Button'

const Summary = ({
    onClick, isLoading, intl,
}) => {
    const {
        sum, lineItems, shippingMethod,
    } = useSelector((state) => state.cart?.cart?.data || {})

    const productPrice = lineItems?.reduce((a, b) => {
        if (b.discountedPrice) {
            return a + b.discountedPrice * b.count
        }
        return a + b.price * b.count
    }, 0)

    const discountPrice = lineItems?.reduce((a, b) => (
        a
        + b.count
        * b.discounts.reduce((x, y) => x + y.discountedAmount, 0)
    ), 0)

    return (
        <div className={'cart-page--summary-total'}>
            <h4>
                <FormattedMessage id={'cart.totalSum'} />
            </h4>
            <p>
                <FormattedMessage id={'cart.subtotal'} />
                <Price value={productPrice + discountPrice} />
            </p>
            {shippingMethod ? (
                <p>
                    <FormattedMessage id={'cart.shipping'} />
                    {shippingMethod.price ? (
                        <Price value={shippingMethod.price} />
                    ) : (
                        <FormattedMessage id={'checkout.freeShipping'} />
                    )}
                </p>
            ) : null}
            <div className={'cart-page--last-price'}>
                <p>
                    <FormattedMessage id={'cart.totalAmount'} />
                    {' '}
                    <br />
                    <small>
                        <FormattedMessage id={'cart.totalAmountMore'} />
                    </small>
                    <Price value={sum} />
                </p>
                <Button
                    type={'button'}
                    className={'btn btn-default cart-page--btn-submit'}
                    onClick={onClick}
                    disabled={isLoading}
                    ariaLabel={intl.formatMessage({ id: 'cart.checkout' })}
                >
                    {isLoading ? <LoadingIcon width={20} height={20} fill={'var(--color-white-100)'} />
                        : <FormattedMessage id={'cart.checkout'} />}
                </Button>
            </div>
        </div>
    )
}

Summary.propTypes = {
    onClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    intl: PropTypes.object.isRequired,

}
Summary.defaultProps = {
    isLoading: false,
}

export default injectIntl(Summary)

import React, { forwardRef, Fragment } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Step from './Step'
import { stepObject } from './stepObject'

const Stepper = forwardRef(({
    steps, current, setCurrent,
    activeStep1,
    activeStep2,
    activeStep3,
    setActiveStep1,
    setActiveStep2,
    setActiveStep3,
    divider1,
    setDivider1,
    divider2,
    setDivider2,
}, ts) => {
    const handleSelect = (i, current) => {
        // Click to previous
        switch (i) {
            case 0:
                if (current > i) {
                    setActiveStep1(true)
                    setActiveStep2(false)
                    setActiveStep3(false)
                }
                setDivider1(false)
                setDivider2(false)
                break
            case 1:
                if (current > i) {
                    setActiveStep1(false)
                    setActiveStep2(true)
                    setActiveStep3(false)
                }
                setDivider1(true)
                setDivider2(false)
                break
            case 2:
                if (current > i) {
                    setActiveStep1(false)
                    setActiveStep2(false)
                    setActiveStep3(true)
                }
                setDivider1(true)
                setDivider2(true)
                break
            default:
                if (current > i) {
                    setActiveStep1(true)
                    setActiveStep2(false)
                    setActiveStep3(false)
                }
                setDivider1(false)
                setDivider2(false)
                break
        }
    }

    const handleClassDivider = (index) => {
        // check active progress bar
        let status = false

        switch (true) {
            case index === 0 && divider1 && activeStep2:
            case index === 1 && divider1 && divider2 && activeStep2 && activeStep3:
                status = true
                break
            default:
                status = false
                break
        }

        return status
    }

    return (
        <div className={'checkout-steppers'}>
            <div className={'md:shadow-md bg-white'}>
                <div className={'grid grid-rows-2 max-w-3/4 py-5 mx-auto h-20'}>
                    <div className={'grid grid-cols-1.25rem-1fr-1.25rem-1fr-1.25rem w-full m-auto px-4'}>
                        {steps.map(({ name }, i) => (
                            <Fragment key={name}>
                                <Step
                                    completed={i < current}
                                    current={i === current}
                                    onSelect={() => {
                                        if (current > i) {
                                            setCurrent(i)
                                        }

                                        handleSelect(i, current)
                                    }}
                                />
                                {i < steps.length - 1 && (
                                    <div
                                        className={classnames('line-default my-2 z-0', {
                                            'line-unactive': !handleClassDivider(i),
                                            'line-active': handleClassDivider(i),
                                        })}
                                    />
                                )}
                            </Fragment>
                        ))}
                    </div>
                    <div className={'flex justify-between dots-description'}>
                        {steps.map(({ name }, i) => (
                            <div className={'steps-text'} key={name}>
                                <div
                                    className={classnames('mt-2 text-xs label-step font-sans', {
                                        'text-black': i !== current,
                                        'font-bold text-black': i === current,
                                    })}
                                >
                                    {name}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
})

Stepper.propTypes = {
    steps: PropTypes.arrayOf(stepObject),
    current: PropTypes.oneOf([0, 1, 2]).isRequired,
    setCurrent: PropTypes.func.isRequired,
    setActiveStep1: PropTypes.func.isRequired,
    setActiveStep2: PropTypes.func.isRequired,
    setActiveStep3: PropTypes.func.isRequired,
    setDivider1: PropTypes.func.isRequired,
    setDivider2: PropTypes.func.isRequired,
    divider1: PropTypes.bool.isRequired,
    divider2: PropTypes.bool.isRequired,
    activeStep1: PropTypes.bool.isRequired,
    activeStep2: PropTypes.bool.isRequired,
    activeStep3: PropTypes.bool.isRequired,
}

export default Stepper

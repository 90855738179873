import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import app from '@frontastic/catwalk/src/js/app/app'
import { CSSTransition } from 'react-transition-group'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import { HeaderTasticData } from './types'
import MainMenu from '../../patterns/organisms/MainMenu'
import TopBar from '../../patterns/organisms/TopBar'
import CartService from '../../services/cart'
import MetaData from '../../node/metaData'
import GtmService from '../../services/gtm'
import TagManager from '../../domain/TagManager'
import { setTheme } from '../../store/actions/ThemeActions'

export type Props = {
    data: HeaderTasticData,
    cart: any,
    node: any,
    context: any,
}

const generateBrandForSpecialCategory = (name, titleBrandLogo, brandLogo, titleBrandList, brandList) => {
    const specialCategory = {
        name,
        itemId: `special_category_${name}`,
        brandLogo: {
            title: titleBrandLogo,
            data: brandLogo,
        },
        brandList: {
            title: titleBrandList,
            data: brandList,
        },
    }

    return specialCategory
}

const TopBarTastic: React.FC<Props> = ({
    data, cart, node, context,
}: Props) => {
    const { isOpen } = useSelector((state) => state['mini-cart'])
    const {
        mainCategories,
        customCategories,
        logoImageWithText,
        logoImageOnly,
        topCategories,
        headline,
        placeholderSearch,
        customBlogCategories,
        searchFAQ,
        categorySidebar,
        absoluteTeamsportTitleBrandLogo,
        absoluteTeamsportBrandLogo,
        absoluteTeamsportTitleBrandList,
        absoluteTeamsportBrandList,
        runningExpertTitleBrandLogo,
        runningExpertBrandLogo,
        runningExpertTitleBrandList,
        runningExpertBrandList,
        nbOfCharactersToTriggerSearch,
    } = data
    const [isFlyoutOpen, setIsFlyoutOpen] = useState(false)
    const [searchHitsOpen, setSearchHitsOpen] = useState(false)
    const [themeName, setThemeName] = useState('')
    const isLoggedIn = useSelector((state: any) => state.app?.context?.session?.loggedIn)
    const scrollYTriggerRemovePosition = 36
    const scrollYTriggerAddPosition = scrollYTriggerRemovePosition + 80
    const ref = useRef(null)
    const dispatcher = useDispatch()

    const sportCategory = customCategories.children.filter((main) => main.key === 'Sportarten')
    const absoluteTeamsportBrand = generateBrandForSpecialCategory('absolute_teamsport_brands', absoluteTeamsportTitleBrandLogo, absoluteTeamsportBrandLogo, absoluteTeamsportTitleBrandList, absoluteTeamsportBrandList)
    const runningExpertBrand = generateBrandForSpecialCategory('running_expert_brands', runningExpertTitleBrandLogo, runningExpertBrandLogo, runningExpertTitleBrandList, runningExpertBrandList)
    const isMobile = useDeviceType() === 'mobile'
    const isTablet = useDeviceType() === 'tablet'

    let fussballCategory = []
    let runningCategory = []

    if (sportCategory.length) {
        fussballCategory = sportCategory[0].children.filter((cate) => cate.key === 'Fussball')
        runningCategory = sportCategory[0].children.filter((cate) => cate.key === 'Running')
    }

    const category = [...fussballCategory, customCategories, ...runningCategory]

    if (!category.length) {
        return null
    }

    const handleScroll = () => {
        if (document.documentElement.scrollTop >= scrollYTriggerAddPosition) {
            document.body.classList.add('is--scrolled')

            return
        }

        if (document.documentElement.scrollTop < scrollYTriggerRemovePosition) {
            document.body.classList.remove('is--scrolled')
        }
    }

    const handleStickyFilterPanels = () => {
        const pathnamePatternPLP = /^\/c\/shop/
        const pathnamePLP = window.location.pathname
        const isPLPPage = pathnamePLP.match(pathnamePatternPLP)?.length > 0

        if (!isPLPPage) {
            return
        }

        const filterPanelsElement = document.querySelector('.filter-algolia--container-all-panels')
        const pageBodyElement: any = document.querySelector('.c-page-body')
        const { scrollY } = window

        if (filterPanelsElement) {
            const heightOfTopBar = 40
            const offsetToFixFilterPanels = 10
            const initialPosition = filterPanelsElement.getBoundingClientRect().top + heightOfTopBar + offsetToFixFilterPanels
            const shouldFixFilterPanels = scrollY >= initialPosition

            filterPanelsElement.classList.toggle('is--fixed', shouldFixFilterPanels)

            if (pageBodyElement) {
                pageBodyElement.classList.toggle('lg:pt-64', shouldFixFilterPanels)
            }
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', () => {
            handleScroll()
            handleStickyFilterPanels()
        })

        return window.removeEventListener('scroll', () => {
            handleScroll()
            handleStickyFilterPanels()
        })
    }, [])

    useEffect(() => {
        dispatcher(setTheme({
            themeName,
        }))
    }, [themeName])

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target) && isOpen) {
            CartService.closeMiniCart()
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)

        return document.removeEventListener('click', handleClickOutside, true)
    })

    const handleGoogleTracking = () => {
        const gtmService = new GtmService(cart.data.lineItems, null, null, cart.data)
        const productListGTM = gtmService.createProductListGTM(node.nodeType)
        const beginCheckoutInfoGTM = gtmService.createBeginCheckoutInfoGTM()
        TagManager.beginCheckout(beginCheckoutInfoGTM, productListGTM)
        TagManager.hitViewdImpressions()
    }

    const handleCloseMiniCart = () => {
        CartService.closeMiniCart()
    }

    const checkLoggedIn = () => {
        CartService.closeMiniCart()
        handleGoogleTracking()

        if (isLoggedIn) {
            return app.getRouter().push('Frontastic.Frontend.Master.Checkout.checkout')
        }

        return app.getRouter().push('Frontastic.Frontend.Master.Account.index', { 'origin-cart': true })
    }

    if (data.nodeConfiguration?.name) {
        node.name = data.nodeConfiguration.name
    }
    if (data.nodeConfiguration?.seoTitle) {
        node.configuration.seoTitle = data.nodeConfiguration.seoTitle
    }
    if (data.nodeConfiguration?.seoDescription) {
        node.configuration.seoDescription = data.nodeConfiguration.seoDescription
    }

    return (
        <>
            <CSSTransition
                in={isFlyoutOpen || searchHitsOpen}
                unmountOnExit
                timeout={500}
                classNames={'flyout-overlay'}
            >
                <div className={'flyout-overlay'} />
            </CSSTransition>
            <div className={'main-header lg:fixed lg:right-0 lg:left-0 bg-white'}>
                <MetaData node={node} context={context} />

                <TopBar
                    topCategories={topCategories}
                    headline={headline}
                    themeName={themeName}
                />

                <MainMenu
                    themeName={themeName}
                    setThemeName={setThemeName}
                    placeholderSearch={placeholderSearch}
                    cart={cart}
                    category={category}
                    absoluteTeamsportBrand={absoluteTeamsportBrand}
                    runningExpertBrand={runningExpertBrand}
                    logoImageWithText={logoImageWithText}
                    logoImageOnly={logoImageOnly}
                    goToCartPage={() => {
                        CartService.closeMiniCart()
                        app.getRouter().push('Frontastic.Frontend.Master.Checkout.cart')
                    }}
                    goToProfilePage={() => {
                        isLoggedIn ? app.getRouter().push('Frontastic.Frontend.Master.Account.profile') : app.getRouter().push('Frontastic.Frontend.Master.Account.index')
                    }}
                    goToCheckoutPage={() => checkLoggedIn()}
                    closeMiniCart={() => handleCloseMiniCart()}
                    isOpenMiniCart={isOpen}
                    // @ts-ignore
                    refMiniCart={ref}
                    node={node}
                    onFlyoutToggle={(itemId) => setIsFlyoutOpen(itemId)}
                    categorySidebar={categorySidebar}
                    context={context}
                    searchFAQ={searchFAQ}
                    setSearchHitsOpen={setSearchHitsOpen}
                    searchHitsOpen={searchHitsOpen}
                    goToWishlistPage={() => {
                        app.getRouter().push('Frontastic.Frontend.Master.Account.wishlists', { wishlist: null })
                    }}
                    nbOfCharactersToTriggerSearch={nbOfCharactersToTriggerSearch}
                />
            </div>
        </>
    )
}

export default tastify({ translate: true, connect: { cart: true, node: true, context: true } })(TopBarTastic)

import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import Reference from '@frontastic/catwalk/src/js/component/reference'
import PropTypes from 'prop-types'
import app from '@frontastic/catwalk/src/js/app/app'
import IconButton from '../../atoms/button/IconButton'
import { ArrowLWhiteIcon } from '../../atoms/icon'

const MinimalFooter = ({
    titleLegal,
    legalLinks,
    titleHelp,
    helpLinks,
    intl,
}) => (
    <div className={'minimal-footer-component'}>
        <div className={'footer-minimal'}>
            <div
                className={'footer-minimal-column'}
            >
                <IconButton
                    type={'button'}
                    className={'btn-minimal-footer btn'}
                    icon={<ArrowLWhiteIcon width={16} height={16} />}
                    ariaLabel={intl.formatMessage({ id: 'home.backToShop' })}
                    onClick={() => {
                        app.getRouter().history.push('/')
                    }}
                >
                    <FormattedMessage id={'home.backToShop'} />
                </IconButton>
            </div>

            <div className={'footer-minimal-column'}>
                {titleLegal && (
                    <div className={'title'}>
                        {titleLegal}
                    </div>
                )}

                {legalLinks && (
                    <div className={'list-legal'}>
                        <ul className={'list-legal-link list-footer-link'}>
                            {legalLinks.map((linkLegal, i) => (
                                <li key={`${i}-${linkLegal.label}`}>
                                    <Reference reference={linkLegal.reference}>
                                                           {linkLegal.label}
                                                       </Reference>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>

            <div className={'footer-minimal-column'}>
                {titleHelp && (
                    <div className={'title'}>
                        {titleHelp}
                    </div>
                )}

                {legalLinks && (
                    <div className={'list-legal'}>
                        <ul className={'list-help-link list-footer-link'}>
                            {helpLinks.map((linkHelp, i) => (
                                <li key={`${i}-${linkHelp.label}`}>
                                    <Reference reference={linkHelp.reference}>
                                                           {linkHelp.label}
                                                       </Reference>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    </div>
)

MinimalFooter.propTypes = {
    intl: PropTypes.object.isRequired,
    titleLegal: PropTypes.string.isRequired,
    titleHelp: PropTypes.string.isRequired,
    legalLinks: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        link: PropTypes.string,
    })).isRequired,
    helpLinks: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        link: PropTypes.string,
    })).isRequired,
}

export default injectIntl(MinimalFooter)

import React, { useCallback, useRef, useState } from 'react'
import SwiperCore, { Pagination, Thumbs } from 'swiper'
import LightGallery from 'lightgallery/react'
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-thumbnail.css'
import PropTypes from 'prop-types'
import Slider from '@ant-design/react-slick'
import classnames from 'classnames'
import Image from '../../atoms/image'

SwiperCore.use([Pagination, Thumbs])

const GallerySlider = (props) => {
    const {
        imageList, thumb, isLoop, autoSlide, headline, showDots,
    } = props

    if (!imageList || !imageList.length) {
        return null
    }

    const lightGallery = useRef(null)

    const [sliderImage, setSliderImage] = useState(null)
    const [thumbnailSliderImage, setThumbnailSliderImage] = useState(null)

    const getItem = () => imageList.map((item, index) => {
        const title = item.title ? item.title : ''
        const desc = item.desc ? item.desc : ''

        return ({
            id: index,
            src: item.image.media ? item.image.media.file : null,
            thumb: item.image.media ? item.image.media.file : null,
            subHtml: `<div class="lightGallery-captions">
                    <h4>${title}</h4>
                    <p>${desc}</p>
            </div>`,
        })
    })

    const onInit = useCallback((detail) => {
        if (detail) {
            lightGallery.current = detail.instance
        }
    }, [])

    return (
        <>
            {headline && (
                <h3>{headline}</h3>
            )}
            <div className={'gallery-slider'}>
                <Slider
                    asNavFor={thumbnailSliderImage}
                    ref={(slider1) => setSliderImage(slider1)}
                    infinite={isLoop}
                    autoplay={autoSlide}
                    autoplaySpeed={autoSlide ? 5000 : 0}
                    dots={showDots}
                    className={classnames('gallery-slider-images', {
                        'hide-arrow': showDots,
                    })}
                >
                    {imageList.map((item, index) => {
                        if (!item || !item.image.media) {
                            return null
                        }

                        return (
                            <div
                                key={item.image.media.mediaId}
                                onClick={() => lightGallery.current.openGallery(index)}
                            >
                                <Image
                                    media={item.image.media.file}
                                    width={item.image.media.width}
                                    height={item.image.media.height}
                                    name={item.title && item.title}
                                    alt={item.title && item.title}
                                    triggerImageDimensionUpdate={false}
                                />
                            </div>
                        )
                    })}
                </Slider>

                {thumb ? (
                    <div className={'thumbnail-slider-wrap'}>
                        <Slider
                            slidesToShow={3}
                            slidesToScroll={1}
                            centerMode
                            focusOnSelect
                            asNavFor={sliderImage}
                            ref={(slider2) => setThumbnailSliderImage(slider2)}
                        >
                            {imageList.map((item) => {
                                if (!item || !item.image.media) {
                                    return null
                                }

                                return (
                                    <div className={'gallery-slider-img'}>
                                        <img
                                            src={item.image.media.file}
                                            alt={item.title && item.title}
                                            style={{
                                                maxWidth: item.image.media.width,
                                            }}
                                        />
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                ) : (
                    <div className={'gallery-slider__pagination'} />
                )}

                <LightGallery
                    dynamic
                    dynamicEl={getItem()}
                    onInit={onInit}
                />
            </div>
        </>
    )
}
GallerySlider.propTypes = {
    imageList: PropTypes.array.isRequired,
    autoSlide: PropTypes.bool,
    thumb: PropTypes.bool,
    isLoop: PropTypes.bool,
    showDots: PropTypes.bool,
    headline: PropTypes.string,
}

GallerySlider.defaultProps = {
    autoSlide: false,
    thumb: false,
}

export default GallerySlider

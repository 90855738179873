import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import Price from '../../../atoms/price'
import { convertToCountryNameByCode } from '../../CheckoutPanels/countries'
import Image from '../../../atoms/image'

const SelectedOrder = ({ order, intl }) => {
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' }

    const renamePaymentLabel = (order) => {
        const paidPayment = order.payments.filter((payment) => payment.paymentStatus === 'paid')[0] || null
        if (paidPayment) {
            return paidPayment.projectSpecificData.name
        }
        const lastPayment = order.payments[order.payments.length - 1]
        return lastPayment.projectSpecificData.name
    }

    return (
        <div className={'text-black mx-4'}>
            <div className={'text-sm text-black mb-4 mt-4 md:mt-0'}>
                {order.createdAt && (
                    <div>
                        <span className={'text-black mr-1'}>
                            <FormattedMessage id={'order.date'} />
                        </span>
                        <span
                            className={'font-bold mb-4'}
                        >
                            {new Date(order.createdAt).toLocaleString([], dateOptions)}
                        </span>
                    </div>
                )}

                {order.payments[0].paymentMethod && (
                    <div>
                        <span className={'text-black mr-1'}>
                            <FormattedMessage id={'order.paymentMethod'} />
                        </span>
                        <span className={'font-bold'}>{renamePaymentLabel(order)}</span>
                    </div>
                )}
            </div>
            <div className={'borders'} />

            <div className={'mt-4 text-black font-bold'}>
                <FormattedMessage id={'order.yourOrderCaps'} />
            </div>
            {order
                && order.lineItems.map((item) => (
                    <div
                        key={item.lineItemId}
                        className={'text-sm grid grid-cols-120-1xfr grid-rows-auto-1fr sm:grid-rows-1 col-gap-4 row-gap-3 my-4 md:mb-6 pb-3 borders'}
                    >
                        {item.variant.images[0] && (
                            <div className={'flex m-auto'}>
                                <Image
                                    media={item.variant.images[0]}
                                    alt={'image'}
                                    width={180}
                                    height={200}
                                    className={'object-cover'}
                                    triggerImageDimensionUpdate={false}
                                />
                            </div>
                        )}

                        <div className={'text-sm mt-2'}>
                            {item.name && (
                                <div className={'font-bold mb-2'}>
                                    {item.name}
                                </div>
                            )}
                            <div className={'selected-order-item my-4'}>
                                {item.variant.attributes.att_prod_color_name_sport2000 && (
                                    <div>
                                        <span>
                                            <FormattedMessage id={'order.colorOrder'} />
                                        </span>
                                        <span>
                                            {item.variant.attributes.att_prod_color_name_sport2000}
                                        </span>
                                    </div>
                                )}
                                {item.variant.attributes?.commonSize && (
                                    <div>
                                        <span>
                                            <FormattedMessage id={'order.sizeOrder'} />
                                        </span>
                                        <span>
                                            {item.variant.attributes.commonSize?.label
                                                || item.variant.attributes.commonSize}
                                        </span>
                                    </div>
                                )}
                                {(item.variant.attributes.attr_size) && (
                                    <div>
                                        <span>
                                            <FormattedMessage id={'order.sizeOrder'} />
                                        </span>
                                        <span>
                                            {item.variant.attributes.attr_size}
                                        </span>
                                    </div>
                                )}
                                {item.count && (
                                    <div>
                                        <span>
                                            <FormattedMessage id={'account.quantity'} />
                                        </span>
                                        <span>{item.count}</span>
                                    </div>
                                )}

                            </div>
                            {item.totalPrice && (
                                <div className={'font-bold'}>
                                    <Price value={item.totalPrice} />
                                </div>
                            )}
                        </div>
                    </div>
                ))}

            <div className={'m-0'}>
                <div className={'mt-4 text-black font-bold'}>
                    <FormattedMessage id={'order.shippingInformationCaps'} />
                </div>
                <div className={'my-3'}>

                    {(order.shippingAddress.firstName || order.shippingAddress.lastName) && (
                        <div className={'text-neutral-900 font-bold'}>
                            {order.shippingAddress.firstName}
                            {' '}
                            {order.shippingAddress.lastName}
                        </div>
                    )}

                    {(order.shippingAddress.streetName || order.shippingAddress.streetNumber) && (
                        <div>
                            {order.shippingAddress.streetName}
                            {' '}
                            {order.shippingAddress.streetNumber}
                        </div>
                    )}

                    {(order.shippingAddress.city || order.shippingAddress.postalCode) && (
                        <div>
                            {order.shippingAddress.postalCode}
                            {' '}
                            {order.shippingAddress.city}
                        </div>
                    )}

                    {order.shippingAddress.additionalAddressInfo && (
                        <div>
                            {order.shippingAddress.additionalAddressInfo}
                        </div>
                    )}

                    {order.shippingAddress.country && (
                        <div className={'mb-3'}>
                            {convertToCountryNameByCode(intl, order.shippingAddress.country)}
                        </div>
                    )}
                </div>
            </div>
            <div className={'borders'} />

            <div className={'m-0'}>
                <div className={'mt-4 text-black font-bold'}>
                    <FormattedMessage id={'order.billingInformationCaps'} />
                </div>
                <div className={'my-3'}>
                    {(order.billingAddress.firstName || order.billingAddress.lastName) && (
                        <div className={'text-neutral-900 font-bold'}>
                            {order.billingAddress.firstName}
                            {' '}
                            {order.billingAddress.lastName}
                        </div>
                    )}

                    {(order.billingAddress.streetName || order.billingAddress.streetNumber) && (
                        <div>
                            {order.billingAddress.streetName}
                            {' '}
                            {order.billingAddress.streetNumber}
                        </div>
                    )}

                    {(order.billingAddress.city || order.billingAddress.postalCode) && (
                        <div>
                            {order.billingAddress.postalCode}
                            {' '}
                            {order.billingAddress.city}
                        </div>
                    )}

                    {order.billingAddress.additionalAddressInfo && (
                        <div>
                            {order.billingAddress.additionalAddressInfo}
                        </div>
                    )}

                    {order.billingAddress.country && (
                        <div className={'mb-3'}>
                            {convertToCountryNameByCode(intl, order.billingAddress.country)}
                        </div>
                    )}
                </div>
            </div>
            <div className={'borders'} />

            <div className={'my-4'}>
                <div className={'font-bold flex justify-between'}>
                    <span className={'text--total-amount'}>
                        <FormattedMessage id={'account.totalAmountOrder'} />
                    </span>
                    {order.sum && (
                        <Price value={order.sum} />
                    )}
                </div>
                <div className={'text-sm VAT-text'}>
                    <FormattedMessage id={'order.includesVAT'} />
                </div>
            </div>
        </div>
    )
}

SelectedOrder.propTypes = {
    intl: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
}

export default injectIntl(SelectedOrder)

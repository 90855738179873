import React, { useEffect, useState } from 'react'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import classnames from 'classnames'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import { SwiperSlide } from 'swiper/react'
import ProductTile from '../../patterns/organisms/ProductTile'
import { Product } from '../../types/frontastic'
import FullPageWidthWrapper from '../../patterns/molecules/Layout/FullPageWidthWrapper'
import Slider from '../../patterns/atoms/slider'
import ColorService from '../../services/color'
import BorderOnTopAndBottom from '../../patterns/atoms/dotted/BorderOnTopAndBottom'

export type Props = {
    data: {
        dataSource: {
            _type: string
            count: number
            facets: Array<Object>
            items: Product
            nextCursor: string
            offset: number
            previousCursor?: string
            projectSpecificData: Array<any>
            query: Object
            rawApiInput: Array<any>
            total: number
        }
        desktop: boolean
        mobile: boolean
        name: string
        numberOfProducts: number
        renderAsSlider: boolean
        renderBlackBackground: boolean
        renderDottedBorders: boolean
        tablet: boolean
        tasticId: string
        swiperColor: string
    }
    node: any
}

const productTiles: React.FC<Props> = ({ data, node }: Props) => {
    const isMobile = useDeviceType() === 'mobile'
    const isTablet = useDeviceType() === 'tablet'
    const isDesktop = useDeviceType() === 'desktop'
    if (!data || !data.dataSource || !data.dataSource.items || (isMobile && !data.mobile) || (isTablet && !data.tablet) || (isDesktop && !data.desktop)) {
        return null
    }
    const nodeName = node.name || ''
    const [products, setProducts] = useState({ ...data.dataSource.items })

    const handleUpdateColors = (items) => {
        ColorService.getColors(items).then((color) => {
            items = ColorService.bindColors(items, color)
            setProducts(items)
        })
    }

    useEffect(() => {
        const controller = new AbortController()

        handleUpdateColors(data.dataSource.items)

        return () => controller.abort()
    }, [])

    const renderProductTilesAsList = () => (
        Object.values(products).slice(0, data.numberOfProducts).map((product, index) => {
            const { productId }: any = product

            return (
                <ProductTile
                    key={productId}
                    // @ts-ignore
                    product={product}
                    position={index + 1}
                    nameCategory={nodeName}
                />
            )
        })
    )

    const renderProductTilesAsSlider = () => (
        <Slider
            swiperColor={data.swiperColor}
            spaceBetween={0}
            slidesPerView={2}
            slidesPerGroup={2}
            loop={false}
            breakpoints={{
                768: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                    spaceBetween: 12,
                },
                1024: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 20,
                },
            }}
        >
            {Object.values(data.dataSource.items).slice(0, data.numberOfProducts).map((product, index) => {
                const { productId }: any = product

                return (
                    <SwiperSlide key={productId}>
                        <ProductTile
                            // @ts-ignore
                            product={product}
                            position={index + 1}
                            nameCategory={nodeName}
                        />
                    </SwiperSlide>
                )
            })}
        </Slider>
    )

    return (
        <FullPageWidthWrapper customClasses={classnames('product-tiles', {
            'bg-white': !data.renderBlackBackground,
            'my-comp': !data.renderBlackBackground && !data.renderDottedBorders,
            'py-8 md:py-10 lg:py-12 xl:py-16 xxl:py-20': data.renderBlackBackground || data.renderDottedBorders,
            'dark-mode bg-blacksport-100': data.renderBlackBackground,
        })}
        >
            {data.renderDottedBorders
            && <BorderOnTopAndBottom renderAtTop renderBlackBackground={data.renderBlackBackground} />}

            <div className={classnames('o-wrapper grid', {
                'grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:gap-3 lg:gap-5': data.numberOfProducts > 1 && !data.renderAsSlider,
                'grid-cols-1 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 border-0': data.numberOfProducts === 1 && !data.renderAsSlider,
                'grid-cols-1 w-full': data.renderAsSlider,
            })}
            >
                {data.renderAsSlider
                    ? renderProductTilesAsSlider()
                    : renderProductTilesAsList()}
            </div>

            {data.renderDottedBorders
            && <BorderOnTopAndBottom renderAtTop={false} renderBlackBackground={data.renderBlackBackground} />}
        </FullPageWidthWrapper>
    )
}

export default tastify({ translate: true, connect: { node: true } })(productTiles)

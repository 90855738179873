import fetch from '@frontastic/bridge/js/fetch'

class ContactService {
    async sendFormData(payload) {
        try {
            const res = await fetch('/api/contact/form/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
            return await res.json()
        } catch (e) {
            throw e
        }
    }
}

export default new ContactService()

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import app from '@frontastic/catwalk/src/js/app/app'
import Message from '@frontastic/catwalk/src/js/app/message'
import { LoadingIcon } from '../../atoms/icon'
import ProductTile from '../ProductTile'
import ColorService from '../../../services/color'

const ProductListing = ({
    intl,
    items,
    total,
    offset,
    onLoadNextPage,
    hrefAttrLoadNext,
    hrefAttrTotal,
    isLoading,
    blogTiles,
    nameCategory,
    onError,
    onLoading,
    onSuccess,
    isActiveScrollLoading,
}) => {
    if (!items.length) {
        return null
    }
    const count = items.length || 0
    let initPositionBlog = 10
    const limitProduct = 48
    const [initItems, setInitItems] = useState([...items])
    const handleUpdateColors = (products) => {
        if (!products) {
            return
        }
        if (onLoading) {
            onLoading()
        }
        ColorService.getColors(products).then((color) => {
            products = ColorService.bindColors(products, color)
            setInitItems([...products])
            if (onSuccess) {
                onSuccess()
            }
        }).catch((error) => {
            if (onError) {
                onError()
            }
            app.getLoader('context').notifyUser(<Message message={error} />, 'error')
        })
    }

    useEffect(() => {
        const controller = new AbortController()
        handleUpdateColors(items)
        return () => controller.abort()
    }, [items])

    const renderLoadMoreText = () => {
        if (total - offset < limitProduct * 2) {
            const productLeft = total - (offset + limitProduct)
            return (
                <FormattedMessage
                    id={'filters.loadProductLeft'}
                    values={{ num: productLeft }}
                />
            )
        }
        return (
            <FormattedMessage
                id={'filters.loadMore.products'}
                values={{ num: `${limitProduct}` }}
            />
        )
    }

    const renderBtnLoadMore = () => {
        if (total - offset < limitProduct) {
            return null
        }

        if (isActiveScrollLoading) {
            return isLoading
                ? (
                    <div className={'flex justify-center mt-5'}>
                        <LoadingIcon width={30} height={30} />
                    </div>
                ) : null
        }

        return (
            <div className={'flex justify-center'}>
                <a
                    onClick={(event) => {
                        event.preventDefault()
                        onLoadNextPage()
                    }}
                    className={'btn btn-secondary btn-load-more'}
                    href={hrefAttrLoadNext()}
                >
                    {isLoading ? <LoadingIcon width={30} height={30} />
                        : renderLoadMoreText()}
                </a>
                <a
                    onClick={(event) => {
                        event.preventDefault()
                        onLoadNextPage(true)
                    }}
                    className={'btn btn-default btn-load-more'}
                    href={hrefAttrTotal()}
                >
                    {isLoading ? <LoadingIcon width={30} height={30} />
                        : <FormattedMessage id={'filters.loadAll'} />}
                </a>
            </div>
        )
    }

    return (
        <div className={'product-list--wrapper'}>
            <div className={'product-list--component'}>
                <div
                    loadMore={onLoadNextPage}
                    hasMore={isActiveScrollLoading}
                    threshold={400}
                >
                    <div
                        className={'product-teaser-list'}
                    >
                        {initItems.map((product, index) => {
                            if (!product.variants.length) {
                                return null
                            }

                            if (blogTiles.length && index === initPositionBlog) {
                                initPositionBlog += 11
                                const blogTile = blogTiles[Math.floor(initPositionBlog % 10)]
                                if (!blogTile) {
                                    return
                                }
                                const {
                                    id, url, image, title,
                                } = blogTile
                                return (
                                    <>
                                        <ProductTile
                                            key={product.productId}
                                            product={product}
                                            position={index + 1}
                                            nameCategory={nameCategory}
                                        />
                                        {blogTile && (
                                            <Link to={{ pathname: url }} target={'_blank'} key={id}>
                                                <div className={'blog-tile'}>
                                                    <img src={image.url} alt={title} />
                                                    <div className={'blog-tile--content'}>
                                                        <h3>{title}</h3>
                                                    </div>
                                                </div>
                                            </Link>
                                        )}
                                    </>
                                )
                            }

                            return (
                                <ProductTile
                                    key={product.productId + index}
                                    product={product}
                                    position={index + 1}
                                    nameCategory={nameCategory}
                                />
                            )
                        })}
                    </div>
                </div>

                {count > 0 && total > 0 && (
                    <div className={'product-list--load-more'}>
                        <div className={'product-status'}>
                            <FormattedMessage
                                id={'filters.productStatus'}
                                values={{ count, total }}
                            />
                        </div>

                        <progress max={total} value={count}>
                            {' '}
                            {total}
                        </progress>
                        {renderBtnLoadMore()}
                    </div>
                )}
            </div>
        </div>
    )
}

ProductListing.propTypes = {
    intl: PropTypes.object.isRequired,
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    blogTiles: PropTypes.arrayOf(PropTypes.any).isRequired,
    onLoadNextPage: PropTypes.func.isRequired,
    hrefAttrLoadNext: PropTypes.string.isRequired,
    hrefAttrTotal: PropTypes.string.isRequired,
    onError: PropTypes.func.isRequired,
    onLoading: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    total: PropTypes.number.isRequired,
    offset: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    nameCategory: PropTypes.string.isRequired,
    isActiveScrollLoading: PropTypes.bool.isRequired,
}

export default injectIntl(ProductListing)

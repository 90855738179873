export function getEmailDomain(emailAddress) {
    const parts = emailAddress.split('@')

    // Ensure that the email address is valid and contains two parts
    if (parts.length === 2) {
        return parts[1]
    }
    // If the email address doesn't have exactly one '@' symbol, it's not valid
    return null
}

export const validateEmail = (email, restrictEmailDomains = [], validEmailMessage = 'invalid email') => {
    if (!email) {
        return validEmailMessage // Empty or undefined email is not valid
    }
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const isValidEmail = emailRegex.test(email)
    if (!isValidEmail) {
        return validEmailMessage
    }

    const emailDomain = getEmailDomain(email)
    if (restrictEmailDomains === null || restrictEmailDomains === undefined) {
        return undefined // Edge case when there is no restricted emails
    }

    const isEmailDomainRestricted = Array.isArray(restrictEmailDomains) && restrictEmailDomains.map((item) => item.emailDomain.trim().toLowerCase()).includes(emailDomain)
    if (isEmailDomainRestricted) {
        return validEmailMessage // Email domain is restricted
    }

    return undefined
}

export const validPhoneNumber = (phoneNumber) => {
    if (_.isEmpty(phoneNumber)) {
        return true
    }

    const isValid = phoneNumber.toString().match(/^[\d ()+-]+$/)

    return !_.isEmpty(isValid)
}

export const validPassword = (password) => {
    if (!password) {
        return false
    }

    const isValid = password.toString().match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,}$/)
    return isValid
}

import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import FrontasticImage from '../../../atoms/frontasticImage'

const Payments = ({ data }) => {
    if (!data || !data.length) {
        return null
    }

    return (
        <div className={'cart-page--payment-list'}>
            <h5><FormattedMessage id={'cart.titleListPayment'} /></h5>
            <div className={'cart-page--payment-list-wrapper'}>
                {data.map((payment) => (
                    <div className={'payment-item'} key={payment.media?.media?.mediaId}>
                        <FrontasticImage media={payment.media.media} width={100} />
                    </div>
                ))}
            </div>
        </div>
    )
}

Payments.prototype = {
    data: PropTypes.any,
}

export default injectIntl(Payments)

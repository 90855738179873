import React from 'react'
import PropTypes from 'prop-types'
import Gallery from '../../patterns/organisms/Gallery'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'

const TeamGallery = ({data: {headlineColor, headline, galleryItem, isDarkMode}}) => {
    return (
        <Gallery headlineColor={headlineColor} headline={headline} galleryItem={galleryItem} isDarkMode={isDarkMode}/>
    )
}

TeamGallery.propTypes = {
    data: PropTypes.object.isRequired,
}

export default tastify()(TeamGallery)

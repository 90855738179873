import React from 'react'
import { FormattedMessage } from 'react-intl'
import { LocationIcon } from '../../../atoms/icon'

const EmptyList = () => (
    <div className={'empty-list-wrapper text-center font-semibold'}>
        <LocationIcon
            width={50}
            height={50}
            className={'location-empty-icon'}
        />
        <FormattedMessage id={'account.address.addressEmpty'} />
    </div>
)

export default EmptyList

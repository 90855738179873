import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import classnames from 'classnames'
import StoreService, { StatusStockOfStore } from '../../../services/algolia/StoreService'
import useStoreCookie from '../../../services/hook/useStoreCookie'
import { ReactComponent as LoadingIcon } from '../../../../icons/sport2000-icons/loading.svg'

const Hits = ({
    hits, location, selectedVariant, context,
}) => {
    const handleCalculateDistance = (lat, lng) => StoreService.calculateDistance({ lat, lng }, location)

    const { cookieStore, updateCookieStore, idStoreToSave } = useStoreCookie(context)

    const handleSelectStore = (hit) => {
        updateCookieStore(hit)
    }

    return (
        <div className={'off-canvas-store__list-store'}>
            {hits.map((hit) => (
                <div
                    className={'off-canvas-store-item'}
                    key={hit.account_id}
                    data-account-id={hit.account_id}
                    onClick={() => handleSelectStore(hit)}
                >
                    <div className={classnames('off-canvas-store-item__checkbox', {
                        active: cookieStore?.id === hit.objectID,
                    })}
                    >
                        {idStoreToSave === hit.objectID ? <LoadingIcon width={20} height={20} /> : <span />}
                    </div>
                    <div className={'relative flex'}>
                        <p className={'off-canvas-store-item__name'}>
                            {hit.name}
                        </p>
                        <span className={'off-canvas-store-item__distance'}>
                            {handleCalculateDistance(hit._geoloc.lat, hit._geoloc.lng)}
                            <FormattedMessage id={'storeFinder.distance'} />
                        </span>
                    </div>
                    <p>
                        {hit.street}
                        {' '}
                        {hit.street_number}
                        <br />
                        {hit.post_code}
                        {' '}
                        {hit.city}
                    </p>
                    {hit.is_click_collect && (
                        <>
                            <FormattedMessage id={'storeFinder.onlineStore'}>
                                {(txt) => (
                                    <span className={'font-semibold text-sm'}>
                                        {txt}
                                    </span>
                                )}
                            </FormattedMessage>
                            {' - '}
                        </>
                    )}

                    {selectedVariant && (
                        <span
                            className={classnames('off-canvas-store-item__status', {
                                available: StoreService.checkProductStockInStore(hit, selectedVariant).status === StatusStockOfStore.available,
                                'out-of-stock': StoreService.checkProductStockInStore(hit, selectedVariant).status === StatusStockOfStore.outOfStock,
                                'no-store': StoreService.checkProductStockInStore(hit, selectedVariant).status === StatusStockOfStore.noStore,
                            })}
                        >
                            <FormattedMessage
                                id={`productDetail.${StoreService.checkProductStockInStore(hit, selectedVariant).status}`}
                            />
                        </span>
                    )}
                </div>
            ))}
        </div>
    )
}

Hits.propTypes = {
    hits: PropTypes.any,
    location: PropTypes.any,
    selectedVariant: PropTypes.any,
    context: PropTypes.any,
}

export default Hits

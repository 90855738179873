import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

const EmptyCart = () => (
    <div className={'cart-page--empty-state'}>
        <h4><FormattedMessage id={'cart.empty'} /></h4>
    </div>
)

export default injectIntl(EmptyCart)

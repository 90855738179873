import React from 'react'
import { useSelector } from 'react-redux'

type Sport2000LogoType =
  'TeamsportExperts'
  | 'OutdoorExperts_1line_neg'
  | 'TeamsportExperts_neg'
  | 'TeamsportExperts_1line'
  | 'TeamsportExperts_1line_neg'
  | 'AbsoluteTeamsport_1line_neg'
  | 'AbsoluteTeamsport_1line'
  | 'AbsoluteTeamsport_2line_neg'
  | 'AbsoluteTeamsport_2line'
  | 'SkiExperts'
  | 'SkiExperts_1line_neg'
  | 'SkiExperts_1line'
  | 'SkiExperts_neg'
  | 'RunningExperts_1line'
  | 'RunningExperts'
  | 'RunningExperts_neg'
  | 'RunningExperts_1line_neg'
  | 'AbsoluteRun_1line'
  | 'AbsoluteRun_2line_neg'
  | 'AbsoluteRun_1line_neg'
  | 'AbsoluteRun_2line'
  | 'OutdoorExperts_1line'
  | 'OutdoorExperts'
  | 'OutdoorExperts_neg'

type Sport2000LogoProps = {
    name: Sport2000LogoType
    alt?: string
    width?: string
    height?: string
    className?: string
}

type LogoItem = {
    name: string;
    url: string;
}

const Sport2000Logo: React.FC<Sport2000LogoProps> = ({
    name, alt, width = 'auto', height = 'auto', className = '',
}) => {
    if (!name) {
        return null
    }

    const context = useSelector((state: any) => state.app.context)
    const sport2000LogoUrls: LogoItem[] = context?.projectConfiguration?.sportLogoUrls || []
    const logoItem = sport2000LogoUrls.find((item: LogoItem) => item.name.toLowerCase() === name.toLowerCase())

    return <img style={{ width, height }} src={logoItem.url} alt={alt || `logo ${logoItem.name}`} className={className} />
}

export default Sport2000Logo

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import SlidingPane from 'react-sliding-pane'
import Scrollbars from 'react-custom-scrollbars'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as PanelClose } from '../../../../icons/tailwind-icons/icon-panel-close.svg'

import 'react-sliding-pane/dist/react-sliding-pane.css'

// needed for console warnings
import Modal from 'react-modal'

const SlideBottom = ({
    isOpen, onClose, title = '', overlayVariant = '', children,
}) => {
    useEffect(() => {
        Modal.setAppElement(document.getElementById('app'))
    }, [])

    return (
        <SlidingPane
            overlayClassName={`boost-slide-bottom-panel bg-background-primary ${overlayVariant} `}
            from={'bottom'}
            width={'100%'}
            isOpen={isOpen}
            title={<div className={'self-center text-2xl text-neutral-900 font-bold mx-6 my-4 py-2'}>{title}</div>}
        >
            <div className={'flex align-center justify-between text-neutral-900 m-4 md:mx-4 relative'}>
                <div className={'flex items-center text-2xl font-bold'}>
                    <FormattedMessage id={title} />
                </div>
                <PanelClose className={'text-xl font-color font-bold cursor-pointer my-auto'} onClick={onClose} />
            </div>
            <div className={'borders'} />
            <Scrollbars style={{ height: 'calc(100vh - 120px)' }}>{children}</Scrollbars>
        </SlidingPane>
    )
}

SlideBottom.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
    overlayVariant: PropTypes.string,
    children: PropTypes.any.isRequired,
}

export default SlideBottom

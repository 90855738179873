import React from 'react'
import PropTypes from 'prop-types'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import parse from 'html-react-parser'

export const ProductCategoryContentTastic = ({ data }) => {
    const { content } = data
    if (!content) {
        return null
    }

    return (
        <div className={'product-list-cms-content'}>
            {parse(content)}
        </div>
    )
}

ProductCategoryContentTastic.propTypes = {
    data: PropTypes.object.isRequired,
}

export default tastify({ translate: true })(ProductCategoryContentTastic)

import React, { useRef, useState } from 'react'
import {
    FormattedMessage, WrappedComponentProps, injectIntl, IntlShape,
} from 'react-intl'
import { connect } from 'react-redux'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import app from '@frontastic/catwalk/src/js/app/app'
import classNames from 'classnames'
import { SubmitHandler, useForm } from 'react-hook-form'
import Button from '../../../patterns/atoms/button/Button'
import ErrorMessage from '../../../patterns/atoms/errorMessage'
import { ResetPasswordFormTypes } from './types'
import { ReactComponent as EyeOpen } from '../../../../icons/sport2000-icons/eye-open.svg'
import { ReactComponent as EyeClosed } from '../../../../icons/sport2000-icons/eye-closed.svg'

export type Props = {
    intl: IntlShape,
    confirmationToken: string
} & WrappedComponentProps

const ResetPassword: React.FC<Props> = ({ intl, confirmationToken }: Props) => {
    const {
        register,
        handleSubmit,
        watch,

        formState: {
            errors,
        },
    } = useForm()
    const requiredField = intl.formatMessage({ id: 'validation.required' })
    const passwordMatch = intl.formatMessage({ id: 'validation.passwordMatch' })
    const validPasswordLength = intl.formatMessage({ id: 'validation.minLength' })
    const validPasswordPattern = intl.formatMessage({ id: 'validation.passwordPattern' })
    const watchPassword = useRef({})
    const [inputPasswordType, setInputPasswordType] = useState('password')

    watchPassword.current = watch('newPassword', '')

    const onSubmit: SubmitHandler<ResetPasswordFormTypes> = ({
        newPassword,
    }) => {
        app.getLoader('context').resetPassword(
            confirmationToken,
            newPassword,
        )
    }

    const toggleInputPasswordType = () => {
        if (inputPasswordType === 'password') {
            return setInputPasswordType('text')
        }

        return setInputPasswordType('password')
    }

    return (
        <section className={'account account--reset-password flex justify-center mt-6 md:mt-20'}>
            <div
                className={'w-full lg:w-1/2 px-4 py-6 lg:p-8 bg-white'}
            >
                <h3 className={'mx-0 mb-8'}>
                    <FormattedMessage id={'account.resetPassword.headline'} />
                </h3>

                <p className={'mb-10'}>
                    <FormattedMessage id={'validation.passwordPattern'} />
                </p>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={classNames('mb-10 input--wrapper', {
                        'is--invalid': errors?.newPassword,
                    })}
                    >
                        <input
                            id={'reset-password--new-password'}
                            {...register('newPassword', {
                                required: requiredField,
                                minLength: {
                                    value: 8,
                                    message: validPasswordLength,
                                },
                                pattern: {
                                    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,}$/,
                                    message: validPasswordPattern,
                                },
                            })}
                            type={inputPasswordType}
                            className={'form-input'}
                            placeholder={intl.formatMessage({ id: 'account.resetPassword.newPasswordLabel' })} />
                        <label htmlFor={'reset-password--new-password'}>
                            <FormattedMessage id={'account.resetPassword.newPasswordLabel'} />
                        </label>
                        <Button
                            type={'button'}
                            className={'account--password-reveal absolute p-4'}
                            ariaLabel={intl.formatMessage({ id: 'account.login.revealPassword' })}
                            onClick={() => toggleInputPasswordType()}
                        >
                            {inputPasswordType === 'password'
                                ? <EyeOpen width={'16'} height={'16'} />
                                : <EyeClosed width={'16'} height={'16'} />}
                        </Button>
                        <span className={'input--border'} />
                        {errors?.newPassword
                            && (
                                <span className={'input--hint'}>
                                    <ErrorMessage
                                        errors={errors}
                                        name={'newPassword'}
                                    />
                                </span>
                            )}
                    </div>

                    <div className={classNames('mb-10 input--wrapper', {
                        'is--invalid': errors?.newPasswordRepeat,
                    })}
                    >
                        <input
                            id={'reset-password--new-password-repeat'}
                            {...register('newPasswordRepeat', {
                                validate: (value) => value === watchPassword.current || passwordMatch,
                            })}
                            type={inputPasswordType}
                            className={'form-input'}
                            placeholder={intl.formatMessage({ id: 'account.resetPassword.newPasswordRepeatLabel' })} />
                        <label htmlFor={'reset-password--new-password-repeat'}>
                            <FormattedMessage id={'account.resetPassword.newPasswordRepeatLabel'} />
                        </label>
                        <Button
                            type={'button'}
                            className={'account--password-reveal absolute p-4'}
                            ariaLabel={intl.formatMessage({ id: 'account.login.revealPassword' })}
                            onClick={() => toggleInputPasswordType()}
                        >
                            {inputPasswordType === 'password'
                                ? <EyeOpen width={'16'} height={'16'} />
                                : <EyeClosed width={'16'} height={'16'} />}
                        </Button>
                        <span className={'input--border'} />
                        {errors?.newPasswordRepeat
                            && (
                                <span className={'input--hint'}>
                                    <ErrorMessage
                                        errors={errors}
                                        name={'newPasswordRepeat'}
                                    />
                                </span>
                            )}
                    </div>

                    <Button
                        className={'btn btn-default w-full flex'}
                        type={'submit'}
                        ariaLabel={intl.formatMessage({ id: 'account.resetPassword.submit' })}
                    >
                        <FormattedMessage id={'account.resetPassword.submit'} />
                    </Button>
                </form>
            </div>
        </section>
    )
}

export default tastify({ translate: true })(
    connect((globalState, props) => ({
        ...props,
        // @ts-ignore
        confirmationToken: globalState.app.route.get('confirmationToken', null),
    }))(injectIntl(ResetPassword)),
)

import React, { useEffect, useState } from 'react'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import Button from '../../../../atoms/button/Button'
import SportSection from '../SportSection'
import Services from '../Services'
import Payments from '../Payments'
import Brands from '../Brands'
import Teams from '../Teams'
import AlgoliaProductListTastic from '../../../../../tastic/algolia/productList/tastic'
import BrandDetail from './BrandDetail'
import RatingTab from './RatingTab'
import StoreService from '../../../../../services/algolia/StoreService'
import { findSelectedCategoryInStorePage } from '../../SidebarMenu/categoryHelper'

const StoreDetailTab = ({
    data, intl, node, tastic, context,
}) => {
    if (!data) {
        return null
    }

    const store = data.storeStream
    const numberProduct = data.stream?.rawResults[0]?.nbHits
    const sidebarCategories = data.stream?.sidebarCategories
    const conditionShowAllSection = numberProduct > 0
        || store.categories.length > 0
        || store.standard_services.length > 0
        || store.payment_methods.length > 0
        || store.brands.length > 0
        || store.team_images.length > 0
        || (store.rating && store.interviewees)
    const conditionShowAboutStoreTab = store.categories.length > 0 || store.standard_services.length > 0 || store.payment_methods.length > 0 || store.brands.length > 0 || store.team_images.length > 0

    const [activeTabName, setActiveTabName] = useState('')
    const [categorySidebarQuery, setCategorySidebarQuery] = useState('')

    const {
        selectedCategory,
        parentOfSelectedCategory,
    } = findSelectedCategoryInStorePage(sidebarCategories, categorySidebarQuery)

    // Case:if the last active category dont have child, banner name is the category name of its parent
    // Case: if the last active have many child, banner name is its name, if dont have parent name, take the title props
    const bannerTitle = () => {
        const selectedCategoryHasChild = Boolean(selectedCategory?.childCount > 0 || selectedCategory?.children?.length > 0)
        if (selectedCategoryHasChild) {
            return selectedCategory.name
        }

        return parentOfSelectedCategory?.name || (<FormattedMessage id={'storeDetail.productHeadline'} />)
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location?.search)
        setCategorySidebarQuery(queryParams.get('categorySidebar'))
        if (numberProduct > 0) {
            setActiveTabName('tab-1')
            return
        }

        if (conditionShowAboutStoreTab) {
            setActiveTabName('tab-2')
            return
        }

        if (store.brands.length > 0) {
            setActiveTabName('tab-3')
            return
        }

        setActiveTabName('tab-4')
    }, [])

    const renderHTML = () => {
        if (activeTabName === 'tab-1') {
            return (
                <div className={'store-detail-tab-content-product'}>
                    <h2 className={'store-detail-tab-content-product-headline mb-4 lg:mb-10'}>
                        {bannerTitle()}

                    </h2>
                    <AlgoliaProductListTastic
                        node={node}
                        context={context}
                        data={{ ...data, refererStore: StoreService.formatStoreForCookie(store) }}
                        tastic={tastic}
                    />
                </div>
            )
        }
        if (activeTabName === 'tab-2') {
            return (
                <div className={'store-detail-tab-content-info'}>
                    {store.categories && (
                        <SportSection categories={store.categories} />
                    )}

                    {store.standard_services && (
                        <Services services={store.standard_services} />
                    )}

                    {store.payment_methods && (
                        <Payments payments={store.payment_methods} />
                    )}

                    {store.brands && (
                        <Brands brands={store.brands} />
                    )}

                    {store.team_images && (
                        <Teams teams={store.team_images} />
                    )}
                </div>
            )
        }
        if (activeTabName === 'tab-3') {
            return (
                store.brands && store.brands.length > 0 && (
                    <div className={'store-detail-tab-content-marken'}>
                        <BrandDetail brands={store.brands} />
                    </div>
                )
            )
        }
        if (activeTabName === 'tab-4') {
            return (
                (store.rating && store.interviewees) && (
                    <div className={'store-detail-tab-content-marken'}>
                        <RatingTab
                            rating={store.rating}
                            viewer={store.interviewees}
                            storeName={store.name}
                            storeCity={store.city}
                        />
                    </div>
                )
            )
        }
        return null
    }

    return (
        <div className={'store-detail-tabs'}>
            {conditionShowAllSection && (
                <div className={'store-detail-tab'}>
                    <nav id={'nav'} className={'store-detail-tab-nav'}>
                        {numberProduct > 0 && (
                            <div className={'store-detail-tab-nav-item'}>
                                <Button
                                    type={'button'}
                                    id={'tab-1'}
                                    className={classnames('store-detail-tab-nav-item-button', {
                                        active: activeTabName === 'tab-1',
                                    })}
                                    onClick={() => {
                                        setActiveTabName('tab-1')
                                    }}
                                    ariaLabel={intl.formatMessage({ id: 'storeDetail.product' })}
                                >
                                    <FormattedMessage id={'storeDetail.product'} />
                                </Button>
                            </div>
                        )}

                        {(conditionShowAboutStoreTab) && (
                            <div className={'store-detail-tab-nav-item'}>
                                <Button
                                    type={'button'}
                                    id={'tab-2'}
                                    className={classnames('store-detail-tab-nav-item-button', {
                                        active: activeTabName === 'tab-2',
                                    })}
                                    onClick={() => {
                                        setActiveTabName('tab-2')
                                    }}
                                    ariaLabel={intl.formatMessage({ id: 'storeDetail.information' })}
                                >
                                    <FormattedMessage id={'storeDetail.information'} />
                                </Button>
                            </div>
                        )}

                        {(store.rating && store.interviewees) && (
                            <div className={'store-detail-tab-nav-item'}>
                                <Button
                                    type={'button'}
                                    id={'tab-4'}
                                    className={classnames('store-detail-tab-nav-item-button', {
                                        active: activeTabName === 'tab-4',
                                    })}
                                    onClick={() => {
                                        setActiveTabName('tab-4')
                                    }}
                                    ariaLabel={intl.formatMessage({ id: 'storeDetail.rating' })}
                                >
                                    <FormattedMessage id={'storeDetail.rating'} />
                                </Button>
                            </div>
                        )}

                        {store.brands && store.brands.length > 0 && (
                            <div className={'store-detail-tab-nav-item'}>
                                <Button
                                    type={'button'}
                                    id={'tab-3'}
                                    className={classnames('store-detail-tab-nav-item-button', {
                                        active: activeTabName === 'tab-3',
                                    })}
                                    onClick={() => {
                                        setActiveTabName('tab-3')
                                    }}
                                    ariaLabel={intl.formatMessage({ id: 'storeDetail.marken' })}
                                >
                                    <FormattedMessage id={'storeDetail.marken'} />
                                </Button>
                            </div>
                        )}
                    </nav>
                </div>
            )}
            <div className={'store-detail-tab-content'}>
                {renderHTML()}
            </div>
        </div>
    )
}

StoreDetailTab.propTypes = {
    data: PropTypes.object.isRequired,
    intl: intlShape,
}

export default tastify({
    translate: true,
    connect: {
        node: true, tastic: true, route: true, context: true,
    },
})(injectIntl(StoreDetailTab))

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import FullPageWidthWrapper from '../../../patterns/molecules/Layout/FullPageWidthWrapper'
import StoreDetailTab from '../../../patterns/organisms/Algolia/StoreDetail/Tabs/StoreDetailTab'
import Information from '../../../patterns/organisms/Algolia/StoreDetail/Information'
import Map from '../../../patterns/organisms/Algolia/StoreDetail/Map'
import RouteAndLogo from '../../../patterns/organisms/Algolia/StoreDetail/RouteAndLogo'
import OpeningHours from '../../../patterns/organisms/Algolia/StoreDetail/OpeningHours'
import { setThemeStore } from '../../../store/actions/StoreActions'

const ABSOLUTE_THEME = 'absolute_teamsport'
const RUNNING_THEME = 'running_expert'

function AlgoliaStoreDetailTastic({
    node, tastic, context, data,
}) {
    if (!data || !context || !data.storeStream || !data.contentTooltip) {
        return null
    }

    const store = data.storeStream
    const tooltip = data.contentTooltip

    const dispatcher = useDispatch()

    useEffect(() => {
        if (!store || !store.specialization_logos) {
            return null
        }

        const storeLogos = store.specialization_logos

        const storeConcept = () => {
            let concept = ''

            if (storeLogos.includes(ABSOLUTE_THEME) && storeLogos.includes(RUNNING_THEME)) {
                return storeLogos[0]
            }

            storeLogos.map((theme) => {
                if (theme.indexOf(ABSOLUTE_THEME) !== -1 || theme.indexOf(RUNNING_THEME) !== -1) {
                    concept = theme
                }
            })

            return concept
        }

        dispatcher(setThemeStore({
            themeStore: storeConcept(),
        }))
    }, [])

    return (
        <FullPageWidthWrapper>
            <div className={'store-detail-page'}>
                <div className={'store-detail-page-wrap'}>
                    <div className={'store-detail-page-wrap-background'}>
                        {store.name && (
                            <h2 className={'store-detail-page-wrap-headline'}>
                                {store.name}
                            </h2>
                        )}
                        <div className={'store-detail-page-wrap-content'}>
                            {store && (
                                <div className={'store-detail-page-wrap-information'}>
                                    <Information data={store} tooltip={tooltip} context={context} />
                                </div>
                            )}

                            {(!_.isEmpty(store.opening_hours)) && (
                                <div className={'store-detail-page-wrap-open-hours'}>
                                    <OpeningHours hours={store.opening_hours} />
                                </div>
                            )}

                            {store._geoloc && (
                                <div className={'store-detail-page-wrap-map'}>
                                    <Map location={store._geoloc} context={context} />
                                </div>
                            )}

                            <div className={'store-detail-page-wrap-route'}>
                                <RouteAndLogo
                                    route={store._geoloc}
                                    telephone={store.telephone}
                                    logos={store.specialization_logos}
                                    email={store.email}
                                />
                            </div>
                        </div>
                    </div>

                    <StoreDetailTab data={data} tastic={tastic} context={context} node={node} />
                </div>
            </div>
        </FullPageWidthWrapper>
    )
}

AlgoliaStoreDetailTastic.propTypes = {
    node: PropTypes.objectOf(PropTypes.any).isRequired,
    tastic: PropTypes.objectOf(PropTypes.any).isRequired,
    context: PropTypes.objectOf(PropTypes.any).isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default tastify({
    translate: true,
    connect: {
        node: true, tastic: true, route: true, context: true,
    },
})(AlgoliaStoreDetailTastic)

import { useEffect, useState } from 'react'
import ClientFactory from '../algolia/ClientFactory'
import StoreService from '../algolia/StoreService'
import { Context } from '../../types/frontastic'

export default (context: Context): string | null => {
    const [refererStore, setRefererStore] = useState(null)

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search)
        const objectIdParam = searchParams.get('objectId')

        if (objectIdParam) {
            ClientFactory.queryStore(context, '', {
                facetFilters: `objectID:${objectIdParam}`,
            }).then((res) => {
                // @ts-ignore
                if (res.results[0]?.hits.length) {
                    // @ts-ignore
                    setRefererStore(StoreService.formatStoreForCookie(res.results[0]?.hits[0]))
                }
            })
        }
    }, [])

    return refererStore
}

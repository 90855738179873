import React from 'react'
import PropTypes from 'prop-types'
import { uniqBy } from 'lodash'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'

import FullPageWidthWrapper from '../../../patterns/molecules/Layout/FullPageWidthWrapper'
import ProductRecommendationList from '../../../patterns/organisms/productRecommendationList'

function AlgoliaProductRecommendationListTastic({
    context,
    data,
    node,
}) {
    if (!data || !context || !data.stream) {
        return null
    }

    const { stream } = data
    const hits = stream?.results && stream?.results[0]?.hits || []
    const hitsUnique = uniqBy(hits, 'product_id')

    if (!hitsUnique || hitsUnique.length === 0) {
        return null
    }

    return (
        <FullPageWidthWrapper>
            <ProductRecommendationList {...data} hits={hitsUnique} context={context} nameCategory={node.name} />
        </FullPageWidthWrapper>
    )
}

AlgoliaProductRecommendationListTastic.propTypes = {
    node: PropTypes.objectOf(PropTypes.any).isRequired,
    tastic: PropTypes.objectOf(PropTypes.any).isRequired,
    route: PropTypes.objectOf(PropTypes.any).isRequired,
    context: PropTypes.objectOf(PropTypes.any).isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default tastify({
    translate: true,
    connect: {
        node: true, tastic: true, route: true, context: true,
    },
})(AlgoliaProductRecommendationListTastic)

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'

import app from '@frontastic/catwalk/src/js/app/app'

import useMdBreakpoint from '../../Layout/Breakpoints/useMdBreakpoint'
import PanelModalResponsive from '../../Layout/PanelModalResponsive'
import PanelBlockResponsive from '../../Layout/PanelBlockResponsive'
import IconButton from '../../../atoms/button/IconButton'
import RemoveAddressForm from '../../../organisms/Forms/RemoveAddressForm'
import AddressForm from '../../../organisms/Forms/AddressForm'
import AddressCard from './AddressCard'
import CheckoutAddressCard from './CheckoutAddressCard'
import { AddIcon } from '../../../atoms/icon'
import Button from '../../../atoms/button/Button'
import { convertToCountryNameByCode } from '../../../organisms/CheckoutPanels/countries'
import EmptyList from './EmptyList'

const AccountAddresses = ({
    intl,
    openPanel,
    onClose,
    addresses,
    countries,
    isDefault = true,
    onClick = null,
    setIsEditShipping,
    setUpdateShipping,
    setShipping,
    defaultValue,
    selectedShippingAddressId,
    setSelectedShippingAddressId,
    isUseAddressCart,
    shippingAddressCart,
}) => {
    const [showCreateAddressPanel, setShowCreateAddressPanel] = useState(false)
    const [showCreateAddressModal, setShowCreateAddressModal] = useState(false)
    const [showEditAddressPanel, setShowEditAddressPanel] = useState(false)
    const [showEditAddressModal, setShowEditAddressModal] = useState(false)
    const [showDeleteAddressPanel, setShowDeleteAddressPanel] = useState(false)
    const [showDeleteAddressModal, setShowDeleteAddressModal] = useState(false)
    const [customCountry, setCustomCountry] = useState([])
    const [showLoader, setShowLoader] = useState(false)
    const [activeAddress, setActiveAddress] = useState(null)
    const { showPanel, showModal } = useMdBreakpoint()

    const notifications = useSelector((state) => state.user.notifications || {})

    const editClicked = () => {
        // Custom in shipping panel
        if (isDefault === false && onClick !== null) {
            setIsEditShipping(true)
            setUpdateShipping(true)
            onClick()
        }
    }

    const createNewForm = () => {
        setIsEditShipping(true)
        setUpdateShipping(false)
        setShipping({
            firstName: '',
            lastName: '',
            phone: '',
            country: '',
            postalCode: '',
            state: '',
            city: '',
            streetNumber: '',
            streetName: '',
        })
        onClick()
    }

    const getAddressById = (id) => {
        if (_.isEmpty(id)) {
            return shippingAddressCart
        }

        let data = {}

        addresses.map((item, index) => {
            if (item.addressId === id) {
                data = item
            }
        })

        return data
    }

    useEffect(() => {
        setShowLoader(false)
    }, [notifications])

    return (
        <>
            <PanelBlockResponsive
                title={'account.accountDetails'}
                isOpen={openPanel}
                onClose={onClose}
            >
                <div className={'account-addresses bg-white relative'}>
                    <div className={'mx-4 md:mx-0 button-add-address'}>
                        {isDefault ? (
                            <Button
                                type={'button'}
                                ariaLabel={intl.formatMessage({ id: 'account.address.newAddress' })}
                                className={'btn btn-secondary account--address-button w-full md:w-auto lg:absolute'}
                                onClick={() => {
                                    showPanel && setShowCreateAddressPanel(true)
                                    showModal && setShowCreateAddressModal(true)
                                }}
                            >
                                <FormattedMessage id={'account.address.newAddress'} />
                            </Button>
                        ) : (
                            <IconButton
                                ariaLabel={intl.formatMessage({ id: 'account.address.newAddress' })}
                                className={'btn btn-address'}
                                icon={<AddIcon width={24} height={24} fill={'var(--color-black-100)'} />}
                                onClick={(address) => {
                                    createNewForm()
                                }}
                            >
                                <FormattedMessage id={'account.address.newAddress'} />
                            </IconButton>
                        )}
                    </div>

                    {isDefault ? (
                        <>
                            {addresses.length > 0 ? (
                                <div>
                                    {addresses.map((address, index) => (
                                        <div key={index}>
                                            <div className={'account-address-card mx-4 py-4 md:mx-0'}>
                                                <AddressCard
                                                    address={address}
                                                    countries={countries}
                                                    onEditClicked={() => {
                                                        showPanel && setShowEditAddressPanel(true)
                                                        showModal && setShowEditAddressModal(true)
                                                        setActiveAddress(address)
                                                    }}
                                                    onDeleteClicked={() => {
                                                        showPanel && setShowDeleteAddressPanel(true)
                                                        showModal && setShowDeleteAddressModal(true)
                                                        setActiveAddress(address)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <>
                                    <EmptyList />
                                </>
                            )}
                        </>
                    ) : (
                        <div>
                            <CheckoutAddressCard
                                isUseAddressCart={isUseAddressCart}
                                addresses={addresses.filter((address) => address.country === 'DE')}
                                defaultValue={!selectedShippingAddressId ? defaultValue : getAddressById(selectedShippingAddressId)}
                                onSelect={(data) => {
                                    setSelectedShippingAddressId(data.id)
                                    setShipping(getAddressById(data.id))
                                }}
                                onEditClicked={() => {
                                    editClicked()
                                }}
                                onDeleteClicked={() => {
                                    showPanel && setShowDeleteAddressPanel(true)
                                    showModal && setShowDeleteAddressModal(true)

                                    setCustomCountry(countries.map((value) => ({
                                        code: value.code,
                                        name: convertToCountryNameByCode(intl, value),
                                    })))
                                    setActiveAddress(!selectedShippingAddressId ? defaultValue : getAddressById(selectedShippingAddressId))
                                }}
                            />
                        </div>
                    )}

                    {/* {addresses.length === 0 && <EmptyList />} */}
                </div>
            </PanelBlockResponsive>

            <PanelModalResponsive
                title={'account.address.newAddress'}
                openPanel={showCreateAddressPanel}
                closePanel={() => setShowCreateAddressPanel(false)}
                openModal={showCreateAddressModal}
                closeModal={() => setShowCreateAddressModal(false)}
            >
                <AddressForm
                    countries={countries}
                    showLoader={showLoader}
                    onSubmit={(address, selectedCountry) => {
                        address.country = selectedCountry
                        setShowLoader(true)

                        app.getLoader('context').addAddress(address).then(() => {
                            setShowCreateAddressPanel(false)
                            setShowCreateAddressModal(false)
                        })
                    }}
                />
            </PanelModalResponsive>

            {activeAddress && (
                <PanelModalResponsive
                    title={'account.address.editAddress'}
                    openPanel={showEditAddressPanel}
                    closePanel={() => setShowEditAddressPanel(false)}
                    openModal={showEditAddressModal}
                    closeModal={() => setShowEditAddressModal(false)}
                >
                    <AddressForm
                        countries={countries}
                        defaultValues={activeAddress}
                        showLoader={showLoader}
                        onSubmit={(address, selectedCountry) => {
                            address.country = selectedCountry
                            setShowLoader(true)

                            app.getLoader('context').updateAddress(address).then(() => {
                                setShowEditAddressPanel(false)
                                setShowEditAddressModal(false)
                            })
                        }}
                    />
                </PanelModalResponsive>
            )}

            {activeAddress && (
                <PanelModalResponsive
                    title={'account.address.removeAddress'}
                    openPanel={showDeleteAddressPanel}
                    closePanel={() => setShowDeleteAddressPanel(false)}
                    openModal={showDeleteAddressModal}
                    closeModal={() => setShowDeleteAddressModal(false)}
                >
                    <RemoveAddressForm
                        countries={customCountry.length ? customCountry : countries}
                        address={activeAddress}
                        showLoader={showLoader}
                        onRemoveAddressClicked={() => {
                            setShowLoader(true)

                            app.getLoader('context').removeAddress(activeAddress).then(() => {
                                setShowDeleteAddressPanel(false)
                                setShowDeleteAddressModal(false)
                            })
                        }}
                    />
                </PanelModalResponsive>
            )}
        </>
    )
}

AccountAddresses.propTypes = {
    intl: PropTypes.object.isRequired,
    defaultValue: PropTypes.object.isRequired,
    shippingAddressCart: PropTypes.object.isRequired,
    selectedShippingAddressId: PropTypes.string.isRequired,
    openPanel: PropTypes.bool.isRequired,
    isDefault: PropTypes.bool.isRequired,
    isUseAddressCart: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    setIsEditShipping: PropTypes.func.isRequired,
    setUpdateShipping: PropTypes.func.isRequired,
    setSelectedShippingAddressId: PropTypes.func.isRequired,
    setShipping: PropTypes.func.isRequired,
    addresses: PropTypes.array.isRequired,
    countries: PropTypes.array.isRequired,
}

export default injectIntl(AccountAddresses)

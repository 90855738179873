import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl'
import app from '@frontastic/catwalk/src/js/app/app'
import Markdown from '@frontastic/catwalk/src/js/component/markdown'
import Button from '../../atoms/button/Button'
import Price from '../../atoms/price'
import ButtonWithLoader from '../../atoms/button/WithLoader'

const CheckoutSummary = React.forwardRef(({
    disabled,
    isLoading = false,
    onClick,
    buttonLabel,
    vouchersLabel,
    paymentInfo,
}, ref) => {
    const {
        sum, lineItems, taxed, shippingMethod, discountCodes,
    } = useSelector((state) => state.cart?.cart?.data || {})

    const totalTaxes = taxed?.taxPortions?.reduce((a, b) => a + b.amount, 0)

    const productPrice = lineItems?.reduce((a, b) => {
        if (b.discountedPrice) {
            return a + b.discountedPrice * b.count
        }
        return a + b.price * b.count
    }, 0)

    const discountPrice = lineItems?.reduce((a, b) => (
        a
        + b.count
        * b.discounts.reduce((x, y) => x + y.discountedAmount, 0)
    ), 0)

    const onRemoveDiscount = (discountId) => {
        app.getLoader('cart').removeDiscount(discountId)
    }

    const handleSubmit = () => {
        if (onClick) {
            return onClick()
        }
    }

    return (
        <section className={'summary-checkout-wrapper'}>
            <div className={'mb-4 grid grid-cols-2 col-gap-6 row-gap-2 summary-checkout'}>
                <p className={'text-md text-neutral-900 leading-normal'}>
                    <FormattedMessage id={'cart.subtotal'} />
                </p>
                <p className={'text-md text-right text-neutral-900 uppercase leading-normal'}>
                    <Price value={productPrice + discountPrice} />
                </p>

                {shippingMethod ? (
                    <>
                        <p className={'text-md text-neutral-900 leading-normal'}>
                            <FormattedMessage id={'cart.shippingCosts'} />
                        </p>
                        <p className={'text-md text-right text-neutral-900 uppercase leading-normal'}>
                            {shippingMethod.price ? (
                                <Price value={shippingMethod.price} />
                            ) : (
                                <FormattedMessage id={'checkout.freeShipping'} />
                            )}
                        </p>
                    </>
                ) : null}

                {discountCodes && discountCodes.length > 0 && (
                    <>
                        <p className={'mt-2 text-md text-neutral-900 leading-none font-bold'}>
                            <FormattedMessage id={'cart.discounts'} />
                        </p>
                        <p className={'text-md text-right text-green-800 uppercase leading-normal'}>
                            <Price value={-discountPrice} />
                        </p>

                        {discountCodes.map((discount, i) => (
                            <React.Fragment key={i}>
                                <p className={'text-md text-neutral-900 leading-normal'}>{discount.code}</p>
                                <p className={'text-md text-right text-neutral-900 uppercase leading-normal'}>
                                    <Button
                                        className={'ml-auto w-5 h-5 bg-neutral-300 rounded-full focus:outline-none'}
                                        aria-label={<FormattedMessage id={'cart.removeDiscount'} />}
                                        onClick={() => onRemoveDiscount(discount.discountId)}
                                    />
                                </p>
                            </React.Fragment>
                        ))}
                    </>
                )}

                <span className={'mb-3 block w-full h-px bg-neutral-300 col-start-auto col-end-span-2 line-summary'} />

                <p className={'sum-text text-md text-neutral-900 leading-none font-bold'}>
                    <FormattedMessage id={'cart.totalAmount'} />
                </p>

                {sum && (
                    <p className={'sum-price text-md text-right text-neutral-900 leading-none font-bold'}>
                        <Price value={sum} />
                    </p>
                )}

                <div className={'text-sm text-neutral-600 col-start-auto col-end-span-2'}>
                    <FormattedMessage id={'cart.inclVat'} />
                </div>
            </div>

            <div className={'btn-checkout'}>
                <ButtonWithLoader
                    type={'button'}
                    className={'btn btn-default btn-cart-submit cart-page--btn-submit'}
                    showLoader={isLoading}
                    onClick={handleSubmit}
                    disabled={disabled}
                    ariaLabel={buttonLabel}
                    ref={ref}
                >
                    {buttonLabel}
                </ButtonWithLoader>

                {paymentInfo && (
                    <div className={'text-xxs mt-4'}>
                        <FormattedHTMLMessage
                            id={'checkout.summaryPolicy'}
                        />
                    </div>
                )}
            </div>

            {vouchersLabel && (
                <div className={'mt-4 text-xs text-neutral-600 text-center'}>
                    <Markdown text={vouchersLabel} />
                </div>
            )}
        </section>
    )
})

CheckoutSummary.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    vouchersLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isLoading: PropTypes.bool,
    paymentInfo: PropTypes.any,
}

export default injectIntl(CheckoutSummary)

import React, { useEffect, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import parse from 'html-react-parser'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { ReactComponent as InfoIcon } from '../../../../../icons/sport2000-icons/info.svg'
import Image from '../../../atoms/image'
import Button from '../../../atoms/button/Button'
import { ReactComponent as FileIcon } from '../../../../../icons/sport2000-icons/file.svg'
import fetch from '@frontastic/bridge/js/fetch'

const ProductOverview = ({ selectedVariant, setHaveDetailOverview }) => {
    const context = useSelector((state) => state.app.context || {})
    const { productDetailOverview } = context.projectConfiguration

    if (!productDetailOverview || !productDetailOverview.length) {
        return null
    }

    const matchedAttrs = productDetailOverview
        .filter((attribute) => attribute.productType === selectedVariant.attributes.att_prod_family && attribute.enable)
        .filter((item) => _.has(selectedVariant.attributes, item.attribute))

    if (matchedAttrs && matchedAttrs.length > 0) {
        setHaveDetailOverview(true)
    }

    const hasIcon = matchedAttrs.filter((matchedAtt) => matchedAtt.displayType === 'icon')
    const hasFile = matchedAttrs.filter((matchedAtt) => matchedAtt.displayType === 'file')
    const hasText = matchedAttrs.filter((matchedAtt) => matchedAtt.displayType === 'text')
    const [attrCmsInfo, setAttrCmsInfo] = useState({})

    useEffect(() => {
        if (attrCmsInfo.length) {
            return
        }

        const graphCmsKeys = []
        matchedAttrs.map((attr) => {
            if (attr.graphCMSKey) {
                graphCmsKeys.push(attr.graphCMSKey)
            }
        })

        const payload = {
            keys: graphCmsKeys,
        }

        fetch('/api/graphcms/product/attribute-info/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then((response) => response.json())
            .then((body) => {
                setAttrCmsInfo(body)
            })
    }, matchedAttrs)

    const getGraphCMSInfo = (matchedAttr) => {
        if (!matchedAttr.graphCMSKey) {
            return null
        }
        const description = attrCmsInfo[matchedAttr.graphCMSKey]

        if (description === undefined) {
            return null
        }

        return description
    }

    const [contentModal, setContentModal] = useState('')

    const isHaveGraphCMSInfo = (matchedAttr) => {
        if (!matchedAttr.graphCMSKey) {
            return false
        }
        const description = attrCmsInfo[matchedAttr.graphCMSKey]
        if (description === undefined) {
            return false
        }

        return true
    }

    const isMobile = useDeviceType() === 'mobile'
    const [openPanel, setOpenPanel] = useState(false)

    const renderHeadline = (matchedAttr) => (
        <div className={'product-overview-headline font-bold'}>
            {matchedAttr.label ? matchedAttr.label : matchedAttr.attribute}

            {isHaveGraphCMSInfo(matchedAttr) && (
                <Button
                    type={'button'}
                    tabIndex={'0'}
                    className={'relative tooltip-content ml-2'}
                    onMouseEnter={() => {
                        setContentModal(getGraphCMSInfo(matchedAttr))
                    }}
                >
                    <InfoIcon width={20} height={20} className={'info-icon'} />
                    <div className={classnames('tooltip-message tooltip-top', {
                        'tooltip-message-mobile': isMobile && !openPanel,
                    })}
                    >
                        <div className={'tooltip-text'}>
                            {parse(contentModal)}
                        </div>
                    </div>
                </Button>
            )}
        </div>
    )

    const renderText = (matchedAttr, attrValue) => {
        let value = attrValue?.display_metric_value ?? attrValue
        if (value === true) {
            value = 'Ja'
        }
        if (value === false) {
            value = 'Nein'
        }
        if (Array.isArray(value) && value.length > 0) {
            value = value.join(', ')
        }
        const symbol = attrValue?.display_metric_symbol ?? ''
        return (
            <div className={'product-overview-item product-overview-text md:flex'}>
                {renderHeadline(matchedAttr)}
                <div className={'product-overview-item-content'}>
                    {value}
                    {symbol && (
                        <span>
                            {symbol}
                        </span>
                    )}
                </div>
            </div>
        )
    }

    const renderFile = (matchedAttr, attrValue) => {
        let value = attrValue?.display_metric_value ?? attrValue
        if (value === true) {
            value = 'Ja'
        }
        if (value === false) {
            value = 'Nein'
        }
        const symbol = attrValue?.display_metric_symbol ?? ''
        return (
            <div className={'product-overview-item product-overview-file md:flex'}>
                <FileIcon width={30} height={30} />
                <a className={'product-overview-item-content underline ml-4 my-auto'} href={value} download>
                    {value}
                    {symbol && (
                        <span>
                            {symbol}
                        </span>
                    )}
                </a>
            </div>
        )
    }

    const renderIcon = (matchedAttr, matchedAttrValue) => {
        if (!matchedAttr.mapping) {
            return null
        }
        const attrValue = matchedAttrValue?.display_metric_value ?? matchedAttrValue
        const matchedIcons = matchedAttr.mapping.filter((mapping) => {
            if (!mapping.type) {
                mapping.type = 'equal'
            }

            if (mapping.type === 'equal' && (mapping.value === attrValue || parseInt(mapping.value) == parseInt(attrValue))) {
                return true
            }

            if (mapping.type === 'equal' && Array.isArray(attrValue) && attrValue.indexOf(mapping.value) !== -1) {
                return true
            }

            if (mapping.type === 'lessThan' && parseInt(mapping.value) > parseInt(attrValue)) {
                return true
            }
            return false
        })

        if (matchedIcons.length > 0) {
            return (
                <div className={'product-overview-item product-overview-item-icon'}>
                    {matchedIcons.map((matchedIcon) => (
                        <>
                            <Image
                                key={matchedAttr.label}
                                media={matchedIcon.icon}
                                alt={matchedAttr.label}
                                className={'product-overview-item-image'}
                                height={35}
                                width={35}
                                disableImageCropping
                                fixedWidth={35}
                                triggerImageDimensionUpdate={false}
                            />
                        </>
                    ))}
                </div>
            )
        }

        return renderText(matchedAttr, matchedAttrValue)
    }

    const renderAttribute = (matchedAttr) => {
        if (selectedVariant.attributes[matchedAttr.attribute] === undefined) {
            return null
        }

        const attrValue = selectedVariant.attributes[matchedAttr.attribute]
        switch (matchedAttr.displayType) {
            case 'text':
                return renderText(matchedAttr, attrValue)
            case 'icon':
                return renderIcon(matchedAttr, attrValue)
            case 'file':
                return renderFile(matchedAttr, attrValue)
            default:
                return renderText(matchedAttr, attrValue)
        }
    }

    return (
        ((matchedAttrs && matchedAttrs.length > 0) && (
            <div className={'product-detail-overview'}>
                <h3>
                    <FormattedMessage id={'detail.overview'} />
                </h3>
                <div className={'product-overview'}>
                    {((hasFile && hasFile.length > 0) || (hasIcon && hasIcon.length > 0)) && (
                        <div className={'product-overview-list'}>
                            <div className={'product-overview-icon'}>
                                {hasIcon.map((item) => renderAttribute(item))}
                            </div>
                            <div className={'product-overview-file'}>
                                {hasFile.map((item) => renderAttribute(item))}
                            </div>
                        </div>
                    )}

                    {hasText && hasText.length > 0 && (
                        <div className={'product-overview-table'}>
                            {hasText.map((item) => renderAttribute(item))}
                        </div>
                    )}
                </div>
            </div>
        ))
    )
}

ProductOverview.propTypes = {
    selectedVariant: PropTypes.object.isRequired,
    setHaveDetailOverview: PropTypes.func,
}

export default injectIntl(ProductOverview)

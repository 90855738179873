import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Button from '../../../atoms/button/Button'

const MenuItem = ({
    onClick, children = () => {
    }, selected = false,
}) => (
    <div className={'mx-4 md:mx-6'}>
        <Button
            className={classnames('btn btn-text icon--left account--panel--menu w-full flex justify-between focus:outline-none cursor-pointer', {
                'account--panel--menu-selected font-bold ': selected,
            })}
            type={'button'}
            onClick={onClick}
        >
            {children}
        </Button>
    </div>
)

MenuItem.propTypes = {
    onClick: PropTypes.func.isRequired,
    children: PropTypes.any.isRequired,
    selected: PropTypes.bool.isRequired,
}

export default MenuItem

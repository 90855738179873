import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useForm } from 'react-hook-form'
import { FormattedMessage, injectIntl } from 'react-intl'
import ErrorMessage from '../../../atoms/errorMessage'

const CreateAccountForm = ({
    intl, isCreateAccount, onChange,
}) => {
    const {
        register,
        getValues,
        watch,

        formState: {
            errors,
        },
    } = useForm({
        mode: 'onChange',
    })
    const requiredField = intl.formatMessage({ id: 'validation.required' })
    const password = useRef({})
    password.current = watch('password', '')

    const handleChange = () => {
        if (onChange) {
            onChange(getValues())
        }
    }

    return (
        <form onChange={handleChange}>
            <div className={'lg:grid-cols-2 lg:gap-6 lg:grid'}>
                <div className={classnames('input--wrapper mb-8 lg:mb-0', {
                    'is--invalid': errors?.password,
                })}
                >
                    <input
                        id={'password'}
                        {...register('password', {
                            required: requiredField,
                            minLength: {
                                value: 8,
                                message: intl.formatMessage({ id: 'validation.minLength' }),
                            },
                            pattern: {
                                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,}$/,
                                message: intl.formatMessage({ id: 'validation.passwordPattern' }),
                            },
                        })}
                        type={'password'}
                        placeholder={intl.formatMessage({ id: 'checkout.form.firstName' })}
                        disabled={!isCreateAccount} />
                    <label htmlFor={'password'}>
                        <FormattedMessage id={'account.password.password'} />
                    </label>
                    <span className={'input--border'} />
                    {errors?.password
                        && (
                            <span className={'input--hint'}>
                                <ErrorMessage errors={errors} name={'password'} />
                            </span>
                        )}
                </div>
                <div className={classnames('input--wrapper mb-8 lg:mb-0', {
                    'is--invalid': errors?.confirmPassword,
                })}
                >
                    <input
                        id={'confirmPassword'}
                        {...register('confirmPassword', {
                            validate: (value) => value === password.current || intl.formatMessage({ id: 'validation.passwordMatch' }),
                        })}
                        type={'password'}
                        placeholder={intl.formatMessage({ id: 'checkout.form.lastName' })}
                        disabled={!isCreateAccount} />
                    <label htmlFor={'confirmPassword'}>
                        <FormattedMessage id={'account.password.passwordConfirm'} />
                    </label>
                    <span className={'input--border'} />
                    {errors?.confirmPassword
                        && (
                            <span className={'input--hint'}>
                                <ErrorMessage errors={errors} name={'confirmPassword'} />
                            </span>
                        )}
                </div>
            </div>
        </form>
    )
}

CreateAccountForm.propTypes = {
    intl: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    isCreateAccount: PropTypes.bool.isRequired,
}

export default injectIntl(CreateAccountForm)

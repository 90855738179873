import React from 'react'
import PropTypes from 'prop-types'

import SlideBottom from '../Panels/SlideBottom'
import PlainModal from '../Modals/PlainModal'

import useMdBreakpoint from './Breakpoints/useMdBreakpoint'

const PanelModalResponsive = ({
    title, children, openPanel, closePanel, openModal, closeModal, subTitle,
}) => {
    const { showPanel, showModal } = useMdBreakpoint()

    return (
        <div>
            <SlideBottom
                overlayVariant={'md:hidden'}
                isOpen={openPanel && showPanel}
                title={title}
                onClose={closePanel && (closePanel)}
            >
                {children && (
                    <>
                        {children}
                    </>
                )}
            </SlideBottom>

            {openModal && showModal && (
                <PlainModal
                    variant={'hidden md:flex'}
                    onClose={closeModal}
                    title={title}
                    subTitle={subTitle}
                >
                    {children && (
                        <>
                            {children}
                        </>
                    )}
                </PlainModal>
            )}
        </div>
    )
}

PanelModalResponsive.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    children: PropTypes.node,
    openPanel: PropTypes.bool,
    closePanel: PropTypes.func,
    openModal: PropTypes.bool,
    closeModal: PropTypes.func,
}

export default PanelModalResponsive

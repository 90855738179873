import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import app from '@frontastic/catwalk/src/js/app/app'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import UrlHandler from '@frontastic/catwalk/src/js/app/urlHandler'
import facetConnector from '@frontastic/catwalk/src/js/app/connector/facet'
import categoryConnector from '@frontastic/catwalk/src/js/app/connector/category'
import urlHandlerConnector from '@frontastic/catwalk/src/js/app/connector/urlHandler'
import Message from '@frontastic/catwalk/src/js/app/message'
import ProductListing from '../../patterns/organisms/ProductListing'
import HorizontalFilter from '../../patterns/organisms/ProductListing/Filters/HorizontalFilter'
import VerticalFilter from '../../patterns/organisms/ProductListing/Filters/VerticalFilter'
import ChosenFacet from '../../patterns/organisms/ProductListing/Facets/ChosenFacet'
import CategoryLayer from '../../patterns/organisms/ProductListing/CategoryLayer'
import CategoryService from '../../services/category'
import LoadingFullScreen from '../../patterns/atoms/loadingFullScreen'
import GtmService from '../../services/gtm'
import tagManager from '../../domain/TagManager'
import OldGtmService from '../../services/oldGtm'
import OldTagManager from '../../domain/OldTagManager'
import fetch from '@frontastic/bridge/js/fetch'

function ProductListingPageTastic({
    node, data, route, tastic, urlHandler,
}) {
    if (!data) {
        return null
    }

    const limitProduct = 48
    const [subCategory, setSubCategory] = useState([])
    const [facetList, setFacetList] = useState([])
    const [verticalFacetList, setVerticalFacetList] = useState([])
    const [total, setTotal] = useState(0)
    const [tmpTotal, setTmpTotal] = useState(0)
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(0)
    const [items, setItems] = useState([...data.stream.items])
    const [isLoading, setIsLoading] = useState(false)
    const [isFullLoading, setIsFullLoading] = useState(false)
    const [isOpenVerticalFilter, setIsOpenVerticalFilter] = useState(false)
    const [blogTiles, setBlogTiles] = useState([])
    const [isActiveScrollLoading, setIsActiveScrollLoading] = useState(false)
    const { category, categoryGenders } = data.stream.projectSpecificData
    const nameCategory = category ? category.name : node.name
    let { categoryHeadline } = category ? category.projectSpecificData : nameCategory
    if (!categoryHeadline) {
        categoryHeadline = nameCategory
    }

    useEffect(() => {
        const controller = new AbortController()
        if (category) {
            CategoryService.getSubCategory(category.categoryId).then((res) => {
                setSubCategory(res)
            })
        }
        return () => controller.abort()
    }, [category])

    useEffect(() => {
        if (!offset) {
            setOffset(data.stream.offset)
        }
        setFacetList([...data.stream.facets])
        setTotal(data.stream.total)
        setLimit(+data.stream.query.limit)
        const gtmService = new GtmService(data.stream.items)
        const productListGTM = gtmService.createProductListGTM('Product List')
        tagManager.productImpressions(productListGTM)

        // for universal analytics ( will be removed )
        const oldGtmService = new OldGtmService(data.stream.items)
        const oldProductListGTM = oldGtmService.createProductListGTM('Product List')
        OldTagManager.productImpressions(oldProductListGTM)
    }, [data, data.stream.items, data.stream.total, data.stream.offset, data.stream.facets])

    useEffect(() => {
        setFacetList(reOrderFacet([...data.stream.facets]))
    }, [])

    useEffect(() => {
        if (total - offset < limitProduct) {
            setIsActiveScrollLoading(false)
        }
    }, [total, offset])

    useEffect(() => {
        const controller = new AbortController()
        const { categoryKey } = category ? category.projectSpecificData : ''

        if (categoryKey) {
            fetch(`/api/graphcms/productlist/tile/${categoryKey}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json()
                    }
                }).then((data) => {
                    if (data && data.data && data.data.marketingTiles) {
                        setBlogTiles(data.data.marketingTiles)
                    }
                })
                .catch((error) => {
                    app.getLoader('context').notifyUser(<Message message={error} />, 'error')
                })
        }

        return () => controller.abort()
    }, [])

    const reOrderFacet = (facetList = []) => {
        facetList.forEach((facet) => {
            if (facet.type === 'range') return
            facet.terms = _.orderBy(facet.terms, ['name'], ['asc'])
        })
        return facetList
    }

    const apiGetProductsAndFacets = async (url) => {
        try {
            const res = await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            return await res.json()
        } catch (e) {
            throw e
        }
    }

    const handleUpdateData = (url, isMergeProducts) => {
        if (isLoading) {
            return
        }
        window.history.replaceState(null, '', `${url.pathname}${url.search}`)
        const searchUrlApi = url ? `${url.pathname}${url.search}` : window.location.href
        apiGetProductsAndFacets(searchUrlApi).then((data) => {
            const response = data.data.stream[tastic.configuration.stream]
            const {
                items, offset, total, facets,
            } = response
            setFacetList(reOrderFacet(facets))
            setItems((prev) => {
                if (isMergeProducts) {
                    return [...prev, ...items]
                }
                return [...items]
            })
            setOffset(offset)
            setLimit(+response.query?.limit)
            setTotal(total)
        })
    }

    const handleUpdateFilter = (parameters, isMergeProducts) => {
        const newParams = {
            ...route.parameters,
            ...parameters,
        }
        const url = app.getRouter().location(route.route, newParams)
        handleUpdateData(url, isMergeProducts)
    }

    const createParameter = (facets, isNextPage) => {
        const parameters = urlHandler.deriveParameters((urlState) => {
            const stream = urlState.getStream(tastic.configuration.stream.dataSourceId)
            stream.setOffset(0)

            if (isNextPage) {
                if (offset + limit > total) {
                    stream.setOffset(total)
                } else {
                    stream.setOffset(offset + limit)
                }
            }

            stream.setLimit(limit)

            facets.forEach((facet) => {
                if (facet.selected) {
                    if (facet.type === 'range') {
                        stream.setFilter(facet.handle, {
                            min: facet.value.min,
                            max: facet.value.max,
                        })
                    }

                    if (facet.type === 'term') {
                        const newTerms = facet.terms
                            .filter((facet) => facet.selected === true)
                            .map((facet) => facet.value)

                        if (newTerms) {
                            stream.setFilter(facet.handle, {
                                terms: newTerms,
                            })
                        }
                    }
                } else {
                    stream.removeFilter(facet.handle)
                }
            })
        })
        return parameters
    }

    const hrefAttrLoadNext = () => {
        const currentOffset = offset + limit
        const attr = `${window.location.pathname}?limit=${data.stream.query.limit}&offset=${currentOffset}`

        return attr
    }

    const hrefAttrTotal = () => {
        const attr = `${window.location.pathname}?limit=${data.stream.query.limit}&offset=${data.stream.total}`

        return attr
    }

    const handleLoadNextPage = (isActiveScroll) => {
        if (total - offset < limitProduct) {
            return
        }
        if (!isActiveScrollLoading && isActiveScroll) {
            setIsActiveScrollLoading(isActiveScroll)
        }
        setIsLoading(true)
        const parameters = createParameter(facetList, true)
        handleUpdateFilter(parameters, true)
    }

    const handleFacetsChanged = (facets) => {
        setIsFullLoading(true)
        const parameters = createParameter(facets)
        handleUpdateFilter(parameters)
    }

    const handleFilter = (facets) => {
        handleFacetsChanged(facets)
    }

    const handleCloseAllFilter = () => {
        setIsOpenVerticalFilter(false)
    }

    const handleUpdateVertitalFilter = (facets) => {
        const parameters = createParameter(facets)
        const newParams = {
            ...route.parameters,
            ...parameters,
        }
        const url = app.getRouter().location(route.route, newParams)
        setIsLoading(true)
        apiGetProductsAndFacets(`${url.pathname}${url.search}`).then((data) => {
            const response = data.data.stream[tastic.configuration.stream]
            setVerticalFacetList(reOrderFacet(response.facets))
            setTmpTotal(response.total)
            setIsLoading(false)
        })
    }

    const handleChangeVerticalFilter = (facets) => {
        handleUpdateVertitalFilter(facets)
    }

    const handleApplyFilter = () => {
        setIsOpenVerticalFilter(false)
        handleFacetsChanged(verticalFacetList)
    }

    const handleResetFilter = (facets) => {
        setIsOpenVerticalFilter(false)
        handleFacetsChanged(facets)
    }

    const handleOpenVerticalFilter = () => {
        setIsLoading(true)
        apiGetProductsAndFacets(window.location.href).then((data) => {
            setIsLoading(false)
            const response = data.data.stream[tastic.configuration.stream]
            setVerticalFacetList(reOrderFacet(response.facets))
            setTmpTotal(response.total)
            setIsOpenVerticalFilter(true)
        })
    }

    const handleStopLoading = () => {
        setIsLoading(false)
        setIsFullLoading(false)
    }

    return (
        <div className={'product-list--wrapper'}>
            {isFullLoading && (
                <LoadingFullScreen />
            )}
            <div className={'product-list--component'}>
                <CategoryLayer
                    subCategory={subCategory}
                    name={categoryHeadline}
                    headline={data.headline}
                />
                <HorizontalFilter
                    facets={facetList}
                    isLoading={isLoading}
                    onChange={(facets) => handleFacetsChanged(facets)}
                    onOpen={handleOpenVerticalFilter}
                    categoryGenders={categoryGenders}
                />
                <VerticalFilter
                    isLoading={isLoading}
                    isOpen={isOpenVerticalFilter}
                    facets={verticalFacetList}
                    onChange={(facets) => handleChangeVerticalFilter(facets)}
                    onReset={(facets) => handleResetFilter(facets)}
                    onApply={handleApplyFilter}
                    total={tmpTotal}
                    onClose={handleCloseAllFilter}
                />
                <ChosenFacet
                    facets={facetList}
                    onRemove={(facets) => handleFilter(facets)}
                    className={'hidden lg:flex'}
                />
                <ProductListing
                    items={items}
                    blogTiles={blogTiles}
                    total={total}
                    offset={offset}
                    isLoading={isLoading}
                    onLoadNextPage={(isActive) => handleLoadNextPage(isActive)}
                    hrefAttrLoadNext={hrefAttrLoadNext}
                    hrefAttrTotal={hrefAttrTotal}
                    onFacetsChanged={handleFacetsChanged}
                    nameCategory={nameCategory}
                    onError={handleStopLoading}
                    onSuccess={handleStopLoading}
                    isActiveScrollLoading={isActiveScrollLoading}
                    onLoading={() => {
                        setIsLoading(true)
                    }}
                />
            </div>
        </div>
    )
}

ProductListingPageTastic.propTypes = {
    node: PropTypes.objectOf(PropTypes.any).isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    tastic: PropTypes.objectOf(PropTypes.any).isRequired,
    route: PropTypes.objectOf(PropTypes.any).isRequired,
    urlHandler: PropTypes.instanceOf(UrlHandler).isRequired,
}

export default tastify({
    translate: true,
    connect: {
        node: true, tastic: true, route: true, urlHandler: true,
    },
})(
    compose(
        connect(facetConnector),
        connect(categoryConnector),
        connect(urlHandlerConnector),
        connect((globalState) => {
            const streamParameters = globalState.app.route?.parameters?.s || {}

            return {
                route: globalState.app.route,
                streamParameters,
            }
        }),
    )(ProductListingPageTastic),
)

import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import parse from 'html-react-parser'
import _ from 'lodash'
import { ReactComponent as InfoIcon } from '../../../../../icons/sport2000-icons/info.svg'
import Image from '../../../atoms/image'
import Button from '../../../atoms/button/Button'
import fetch from '@frontastic/bridge/js/fetch'

const ProductAttribute = ({ intl, productColor, selectedVariant }) => {
    const context = useSelector((state) => state.app.context || {})
    const { attributeVisualization } = context.projectConfiguration

    const matchedAttrs = attributeVisualization
        .filter((attribute) => attribute.productType === selectedVariant.attributes.att_prod_family && attribute.enable)
        .filter((item) => _.has(selectedVariant.attributes, item.attribute))

    if ((!attributeVisualization || !attributeVisualization.length) || (!matchedAttrs || !matchedAttrs.length)) {
        return null
    }

    const [attrCmsInfo, setAttrCmsInfo] = useState({})

    useEffect(() => {
        if (attrCmsInfo.length) {
            return
        }

        const graphCmsKeys = []
        matchedAttrs.map((attr) => {
            if (attr.graphCMSKey) {
                graphCmsKeys.push(attr.graphCMSKey)
            }
        })

        const payload = {
            keys: graphCmsKeys,
        }

        fetch('/api/graphcms/product/attribute-info/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then((response) => response.json())
            .then((body) => {
                setAttrCmsInfo(body)
            })
    }, matchedAttrs)

    const getGraphCMSInfo = (matchedAttr) => {
        if (!matchedAttr.graphCMSKey) {
            return null
        }
        const description = attrCmsInfo[matchedAttr.graphCMSKey]

        if (description === undefined) {
            return null
        }

        return description
    }

    const [contentModal, setContentModal] = useState('')

    const isHaveGraphCMSInfo = (matchedAttr) => {
        if (!matchedAttr.graphCMSKey) {
            return false
        }
        const description = attrCmsInfo[matchedAttr.graphCMSKey]
        if (description === undefined) {
            return false
        }

        return true
    }

    const isMobile = useDeviceType() === 'mobile'
    const [openPanel, setOpenPanel] = useState(false)

    const renderHeadline = (matchedAttr) => (
        <div className={'product-detail-attributes-headline'}>
            {matchedAttr.label ? matchedAttr.label : matchedAttr.attribute}

            {isHaveGraphCMSInfo(matchedAttr) && (
                <Button
                    type={'button'}
                    tabIndex={'0'}
                    className={'relative tooltip-content ml-2'}
                    onMouseEnter={() => {
                        setContentModal(getGraphCMSInfo(matchedAttr))
                    }}
                >
                    <InfoIcon width={20} height={20} className={'info-icon'} />
                    <div className={classnames('tooltip-message tooltip-top', {
                        'tooltip-message-mobile': isMobile && !openPanel,
                    })}
                    >
                        <div className={'tooltip-text'}>
                            {parse(contentModal)}
                        </div>
                    </div>
                </Button>
            )}
        </div>
    )

    const renderText = (matchedAttr, attrValue) => {
        let value = attrValue?.display_metric_value ?? attrValue
        if (value === true) {
            value = 'Ja'
        }
        if (value === false) {
            value = 'Nein'
        }
        const symbol = attrValue?.display_metric_symbol ?? ''
        return (
            <div className={'product-detail-attributes-item product-detail-attributes-text'}>
                {renderHeadline(matchedAttr)}
                <div className={'product-detail-attributes-text-content'}>
                    {value}
                    {symbol && (
                        <span>
                            {symbol}
                        </span>
                    )}
                </div>
            </div>
        )
    }

    const renderIcon = (matchedAttr, matchedAttrValue) => {
        if (!matchedAttr.mapping) {
            return null
        }
        const attrValue = matchedAttrValue?.display_metric_value ?? matchedAttrValue
        const matchedIcons = matchedAttr.mapping.filter((mapping) => {
            if (!mapping.type) {
                mapping.type = 'equal'
            }

            if (mapping.type === 'equal' && (mapping.value === attrValue || parseInt(mapping.value) == parseInt(attrValue))) {
                return true
            }

            if (mapping.type === 'equal' && Array.isArray(attrValue) && attrValue.indexOf(mapping.value) !== -1) {
                return true
            }

            if (mapping.type === 'lessThan' && parseInt(mapping.value) > parseInt(attrValue)) {
                return true
            }
            return false
        })

        if (matchedIcons.length > 0) {
            return (
                <div className={'product-detail-attributes-item product-detail-attributes-icon'}>
                    {renderHeadline(matchedAttr)}
                    {matchedIcons.map((matchedIcon) => (
                        <>
                            <Image
                                key={matchedAttr.label}
                                media={matchedIcon.icon}
                                alt={matchedAttr.label}
                                className={'product-detail-attributes-icon-image'}
                                height={40}
                                width={40}
                                disableImageCropping
                                fixedWidth={40}
                                triggerImageDimensionUpdate={false}
                            />
                            <div
                                className={'product-detail-attributes-bar-item-line-text font-bold'}
                            >
                                {matchedIcon.label ? matchedIcon.label : matchedIcon.value}
                            </div>
                        </>
                    ))}
                </div>
            )
        }

        return renderText(matchedAttr, matchedAttrValue)
    }

    const renderBar = (matchedAttr, matchedAttrValue) => {
        if (!matchedAttr.mapping) {
            return null
        }

        let hasActive = false
        const attrValue = matchedAttrValue?.display_metric_value ?? matchedAttrValue
        return (
            <div className={'product-detail-attributes-item product-detail-attributes-bar'}>
                {renderHeadline(matchedAttr)}
                <div className={'product-detail-attributes-bar-wrapper'}>
                    {matchedAttr.mapping.map((mapping) => {
                        let isActive = false
                        if (!mapping.type) {
                            mapping.type = 'equal'
                        }

                        if (mapping.type === 'equal' && (mapping.value === attrValue || parseInt(mapping.value) == parseInt(attrValue))) {
                            isActive = true
                        }
                        if (mapping.type === 'equal' && Array.isArray(attrValue) && attrValue.indexOf(mapping.value) !== -1) {
                            isActive = true
                        }
                        if (hasActive === false && mapping.type === 'lessThan' && parseInt(mapping.value) > parseInt(attrValue)) {
                            isActive = true
                            hasActive = true
                        }

                        return (
                            <div
                                className={'product-detail-attributes-bar-item'}
                                key={mapping.value}
                            >
                                <div className={classnames('product-detail-attributes-bar-item-box', {
                                    'is--icon': mapping.icon && mapping.icon?.media,
                                })}
                                >
                                    {(!mapping.icon || !mapping.icon?.media) && (
                                        <div className={classnames('product-detail-attributes-bar-item-line', {
                                            'is--active': isActive,
                                        })}
                                        />
                                    )}
                                    {mapping.icon && mapping.icon?.media && (
                                        <Image
                                            width={40}
                                            height={40}
                                            fixedWidth={40}
                                            media={mapping.icon}
                                            alt={mapping.label}
                                            className={classnames('product-detail-attributes-bar-item-image', {
                                                'is--active': isActive,
                                            })}
                                            disableImageCropping
                                        />
                                    )}
                                </div>

                                <div
                                    className={classnames('product-detail-attributes-bar-item-line-text', {
                                        'is--active': isActive,
                                    })}
                                >
                                    {mapping.label ? mapping.label : mapping.value}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    const renderAttribute = (matchedAttr) => {
        if (selectedVariant.attributes[matchedAttr.attribute] === undefined) {
            return null
        }

        const attrValue = selectedVariant.attributes[matchedAttr.attribute]
        switch (matchedAttr.displayType) {
            case 'text':
                return renderText(matchedAttr, attrValue)
            case 'icon':
                return renderIcon(matchedAttr, attrValue)
            case 'bar':
                return renderBar(matchedAttr, attrValue)
            default:
                return renderText(matchedAttr, attrValue)
        }
    }

    return (
        ((matchedAttrs && matchedAttrs.length) && (
            <div className={'product-detail-attributes'}>
                <div className={'product-detail-attributes-left'}>
                    {matchedAttrs.filter((matchedAtt) => matchedAtt.displayType === 'bar')
                        .map((item) => renderAttribute(item))}
                </div>
                <div className={'product-detail-attributes-right'}>
                    {matchedAttrs.filter((matchedAtt) => matchedAtt.displayType === 'icon' || matchedAtt.displayType === 'text')
                        .map((item) => renderAttribute(item))}
                </div>
            </div>
        ))
    )
}

ProductAttribute.propTypes = {
    intl: PropTypes.object.isRequired,
    productColor: PropTypes.object.isRequired,
    selectedVariant: PropTypes.object.isRequired,
}

export default injectIntl(ProductAttribute)

import React from 'react'
import PropTypes from 'prop-types'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import parse from 'html-react-parser'

export const BrandSizeTableTastic = ({ data }) => {
    const sizeTables = data.stream
    if (!sizeTables || !sizeTables.length) {
        return null
    }

    return (
        sizeTables.map((brandSizeTable) => (
            <div className={'brand-size-table brand-size-table-tastic pt-4 mb-4'} key={brandSizeTable.id}>
                <div>
                    {parse(brandSizeTable.content_size_table.html)}
                </div>
            </div>
        ))
    )
}

BrandSizeTableTastic.propTypes = {
    data: PropTypes.object.isRequired,
}

export default tastify({ translate: true })(BrandSizeTableTastic)

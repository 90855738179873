import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import FacetService from '../../../../services/facet'
import { CloseIcon } from '../../../atoms/icon'

const ChosenFacet = ({
    facets, onRemove, className = '',
}) => {
    const initFacets = [...facets]
    const handleRemovePrice = () => {
        initFacets.forEach((facet) => {
            if (facet.type === 'range') {
                facet.selected = false
                facet.value.min = 0
                facet.value.max = 0
            }
        })
        if (onRemove) {
            onRemove(initFacets)
        }
    }

    const handleClearAll = (e) => {
        e.preventDefault()
        const clearedFacet = FacetService.clearFacets(initFacets)
        if (onRemove) {
            onRemove(clearedFacet)
        }
    }

    const handleRemoveTerm = (e, termIndex, facetIndex) => {
        e.preventDefault()
        initFacets[facetIndex].terms[termIndex].selected = !initFacets[facetIndex].terms[termIndex].selected
        initFacets[facetIndex].selected = initFacets[facetIndex].terms.some((term) => term.selected === true)

        if (onRemove) {
            onRemove(initFacets)
        }
    }

    const renderChosenFacet = () => facets.map((facet, facetIndex) => {
        if (facet.type === 'range' && facet.selected) {
            return (
                <div className={'chosen-filter-item'} onClick={handleRemovePrice} key={facet.key}>
                    <span className={'is--price'}>{(facet.value.min / 100).toFixed(2)}</span>
                    <FormattedMessage
                        id={'filters.unit'}
                    />
                    {' - '}
                    <span className={'is--price'}>{(facet.value.max / 100).toFixed(2)}</span>
                    <FormattedMessage
                        id={'filters.unit'}
                    />
                    <CloseIcon width={10} height={10} className={'chosen-filter-item--btn-remove'} />
                </div>
            )
        }
        if (facet.type === 'term') {
            return facet.terms.map((term, index) => {
                if (!term.selected) {
                    return null
                }
                // const isSize = FacetService.isSizeFacet(facet)
                // const termName = isSize ? term.name.replace('EU', '').trim() : term.name
                const termName = term.name
                return (
                    <div
                        key={term.name}
                        className={'chosen-filter-item'}
                        onClick={(e) => handleRemoveTerm(e, index, facetIndex)}
                    >
                        {termName}
                        <CloseIcon width={10} height={10} className={'chosen-filter-item--btn-remove'} />
                    </div>
                )
            })
        }

        return null
    })

    return (
        <>
            <div className={`product-list-chosen-filter ${className}`}>
                {FacetService.numberOfSelectedFacets(facets) > 1
                && (
                    <div
                        className={'chosen-filter-item clear-all'}
                        onClick={(e) => handleClearAll(e)}
                    >
                        <FormattedMessage
                            id={'filters.clearAll'}
                        />
                        <CloseIcon width={10} height={10} className={'chosen-filter-item--btn-remove'} />
                    </div>
                )}
                {renderChosenFacet()}
            </div>
        </>
    )
}

ChosenFacet.propTypes = {
    facets: PropTypes.arrayOf(PropTypes.object).isRequired,
    onRemove: PropTypes.func.isRequired,
    className: PropTypes.string,
}

export default injectIntl(ChosenFacet)

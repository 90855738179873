import React, { useEffect, useRef, useState } from 'react'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import debounce from '@frontastic/common/src/js/helper/debounce'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import classNames from 'classnames'
import Image from '@frontastic/catwalk/src/js/image'
import NodeLink from '@frontastic/catwalk/src/js/app/nodeLink'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import BranchMapImage from 'src/js/tastic/branchMap/branch-map.png'
import Button from '../../patterns/atoms/button/Button'
import FullPageWidthWrapper from '../../patterns/molecules/Layout/FullPageWidthWrapper'
import isElementInViewport from '../../config/isElementInViewport'
import BranchMapEuropeImage from './branch-map-europe.png'
import { ReactComponent as IconLocation } from '../../../icons/sport2000-icons/location-filled.svg'
import { Media } from '../../types/frontastic'
import PageFolderLink from '@frontastic/catwalk/js/app/pageFolderLink'

export type Props = {
    data: {
        headline: string
        textReadMoreButton: string,
        textCalculateRouteLink: string,
        backgroundImageMobile: Media,
        backgroundImageDesktop: Media,
        singleBranch: [
            {
                branchName: string
                branchStreet: string
                branchZip: number
                branchCity: string
                branchTel?: string
                branchUrlWebsite?: Node
                branchUrlLocation?: string
                branchPinLocationX: number
                branchPinLocationY: number
                mainBackgroundImageMobile: Media
                mainBackgroundImageDesktop: Media
            },
        ]
    }
}

const BranchMapEurope: React.FC<Props> = ({
    data,
}: Props) => {
    if (!data || data.singleBranch.length <= 0) {
        return null
    }

    const isMobile = useDeviceType() === 'mobile'
    const [currentBranch, setCurrentBranch] = useState(data.singleBranch[0])
    const [hasBeenShown, setHasBeenShown] = useState(false)
    const [hasBeenAnimated, setHasBeenAnimated] = useState(false)
    const branchMapRef = useRef(null)
    const {
        textCalculateRouteLink, textReadMoreButton, headline, backgroundImageMobile, backgroundImageDesktop,
    } = data
    const watchViewport = () => {
        if (hasBeenAnimated || !branchMapRef.current) {
            return
        }

        if (isElementInViewport(branchMapRef.current)) {
            setHasBeenShown(true)

            setTimeout(() => {
                setHasBeenAnimated(true)
            }, 2000)
        }
    }

    useEffect(() => {
        watchViewport()

        document.addEventListener('scroll', debounce(() => watchViewport(), 25), { passive: true })

        return document.removeEventListener('scroll', debounce(() => watchViewport(), 25))
    }, [])

    const renderBackgroundImage = () => {
        if (!currentBranch && !backgroundImageMobile && !backgroundImageDesktop) {
            return null
        }
        const mediaMobile = currentBranch.mainBackgroundImageMobile?.media || backgroundImageMobile.media
        const mediaDesktop = currentBranch.mainBackgroundImageDesktop?.media || backgroundImageDesktop.media
        const media = isMobile ? mediaMobile : mediaDesktop

        return (
            <div>
                <TransitionGroup>
                    <CSSTransition
                        timeout={500}
                        classNames={'fade'}
                        key={media.mediaId}
                    >
                        <Image
                            className={'branch-map--background absolute'}
                            media={media}
                            loading={'lazy'}
                            decoding={'async'}
                            alt={headline}
                        />
                    </CSSTransition>
                </TransitionGroup>
            </div>
        )
    }

    const renderBranchInformationBlock = () => (
        <div
            className={'branch-map--information w-full md:w-1/3 order-2 md:order-1 flex flex-col items-center md:items-start justify-center text-center md:text-left mt-10 md:mt-0'}
        >
            <span className={'text-subh3'}>{headline}</span>

            {currentBranch.branchName
            && (
                <h3
                    className={'mt-1'}
                    aria-label={currentBranch.branchName}
                >
                    <div>
                        {currentBranch.branchName.split('').map((character, index) => {
                            const key = `${JSON.stringify(currentBranch.branchName?.length)} + ${character} + ${index}`

                            return (
                                <span
                                    className={'character'}
                                    aria-hidden={'true'}
                                    key={key}
                                    style={{
                                        animationDelay: `calc(var(--transition-duration) * (${index} * .25))`,
                                    }}
                                >
                                    {character}
                                </span>
                            )
                        })}
                    </div>
                </h3>
            )}

            <address className={'mt-1'}>
                {currentBranch.branchStreet
                && (
                    <>
                        {currentBranch.branchStreet}
                        <br />
                    </>
                )}
                {currentBranch.branchZip && currentBranch.branchCity
                && (
                    <>
                        {currentBranch.branchZip}
                        {' '}
                        {currentBranch.branchCity}
                        <br />
                    </>
                )}
                {currentBranch.branchTel
                && (
                    <a href={`tel:${currentBranch.branchTel}`}>
                        {currentBranch.branchTel}
                        <br />
                    </a>
                )}
            </address>

            {(currentBranch.branchUrlWebsite || currentBranch.branchUrlLocation?.length > 4)
            && (
                <div>
                    {currentBranch.branchUrlWebsite
                    && (
                        <PageFolderLink
                          pageFolder={currentBranch.branchUrlWebsite}
                        >
                            <Button
                                type={'button'}
                                ariaLabel={textReadMoreButton}
                                className={'btn btn-default w-full md:w-auto md:mr-4 mt-5'}
                                darkTheme
                            >
                                {textReadMoreButton}
                            </Button>
                        </PageFolderLink>
                    )}

                    {currentBranch.branchUrlLocation?.length > 4
                    && (
                        <a
                            href={currentBranch.branchUrlLocation}
                            target={'_blank'}
                            rel={'noreferrer noopener nofollow'}
                        >
                            <Button
                                type={'button'}
                                ariaLabel={textReadMoreButton}
                                className={'btn btn-secondary w-full md:w-auto mt-5'}
                                darkTheme
                            >
                                {textCalculateRouteLink}
                            </Button>
                        </a>
                    )}
                </div>
            )}
        </div>
    )

    const renderBranchMapBlock = () => (
        <div
            className={'branch-map--map w-full md:w-2/3 flex justify-center md:justify-end order-1 md:order-2'}
            ref={branchMapRef}
        >
            <div className={'flex-shrink-0 branch-map--map-wrapper relative'}>
                <img
                    className={'branch-map--map-europe'}
                    src={BranchMapEuropeImage}
                    alt={''}
                    loading={'lazy'}
                    decoding={'async'}
                    style={{ opacity: 0.7 }}
                />

                {data.singleBranch.map((branch, index) => {
                    const isCurrentBranch = branch.branchName === currentBranch.branchName

                    return (
                        <Button
                            ariaLabel={branch.branchName}
                            type={'button'}
                            className={classNames('branch-map--map-pin px-0 absolute overflow-visible', {
                                'is--current': isCurrentBranch,
                            })}
                            style={{
                                top: `${branch.branchPinLocationY}px`,
                                left: `${branch.branchPinLocationX}px`,
                                animationDelay: `calc(var(--transition-duration) * ${index})`,
                            }}
                            key={JSON.stringify(branch.branchName)}
                            onClick={() => setCurrentBranch(data.singleBranch[index])}
                        >
                            <IconLocation className={'icon w-10 h-10'} />
                        </Button>
                    )
                })}
            </div>
        </div>
    )

    return (
        <FullPageWidthWrapper>
            <section
                className={classNames('branch-map relative my-comp', {
                    'has--shown': hasBeenShown,
                    'has--animated': hasBeenAnimated,
                })}
            >
                {renderBackgroundImage()}
                <div
                    className={'branch-map--wrapper flex flex-col items-center md:flex-row xl:w-3/4 xl:mx-auto py-10 relative'}
                >
                    {renderBranchInformationBlock()}
                    {renderBranchMapBlock()}
                </div>
            </section>
        </FullPageWidthWrapper>
    )
}

export default tastify({ translate: true })(BranchMapEurope)

import React from 'react'
import PropTypes from 'prop-types'
import BannerTiles from '../../patterns/organisms/BannerTile'

const BannerTileTastic = ({ data: { bannerLists, btnLinkText, btnVariant, bgColor, textColor, btnColorDefault, btnColorHover } }) => {
    return (
        <BannerTiles
            bannerLists={bannerLists}
            btnLinkText={btnLinkText}
            btnVariant={btnVariant}
            bgColor={bgColor}
            textColor={textColor}
            btnColorDefault={btnColorDefault}
            btnColorHover={btnColorHover}
        />
    )
}

BannerTileTastic.propTypes = {
    data: PropTypes.object.isRequired,
}
export default BannerTileTastic

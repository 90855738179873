import React from 'react'
import classnames from 'classnames'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'
import BaseDotted from '../../atoms/dotted/BaseDotted'
import { DottedFadebarVariantA5mm, DottedFadebarVariantB5mm } from '../../atoms/dotted'
import ErrorMessage from '../../atoms/errorMessage'
import Button from '../../atoms/button/Button'

const NewsletterBlock = ({
    headline, desc, placeholder, btnSubmitText, btnVariant, bgcolor, isDotted, intl,
}) => {
    const renderDottedBar = () => {
        if (!isDotted) {
            return null
        }
        const dottedColor = bgcolor
        return (
            <div className={'teaser--dotted-bar'}>
                <BaseDotted
                    dottedSVG={<DottedFadebarVariantA5mm fill={dottedColor} />}
                    height={14}
                    isTop
                />
                <BaseDotted
                    dottedSVG={<DottedFadebarVariantA5mm fill={dottedColor} />}
                    height={14}
                    isBottom
                    isRotate
                    from={'right'}
                />
                <BaseDotted
                    dottedSVG={<DottedFadebarVariantB5mm fill={dottedColor} />}
                    height={14}
                    isBottom
                    isRotate
                />
                <BaseDotted
                    dottedSVG={<DottedFadebarVariantB5mm fill={dottedColor} />}
                    height={14}
                    isTop
                    from={'right'}
                />
            </div>
        )
    }

    const style = {
        backgroundColor: bgcolor,
    }

    const {
        register,
        handleSubmit,

        formState: {
            errors,
        },
    } = useForm()

    const onSubmit = () => {
        // @TODO handle logic after submit form
    }

    return (
        <div
            className={classnames('relative', {
                'py-comp newsletter': bgcolor,
                'my-comp': !bgcolor,
                'my-14px': isDotted,
            })}
            style={style}
        >
            {renderDottedBar()}
            <div className={'wrapper'}>
                <div className={'md:flex'}>
                    <div className={'md:flex-1 md:pr-3'}>
                        {headline && (
                            <h2 className={'headline'}>
                                {headline}
                            </h2>
                        )}
                        {desc && (
                            <p className={'desc md:mb-0'}>
                                {desc}
                            </p>
                        )}
                    </div>
                    <div className={'md:flex-1 self-center'}>
                        <form className={'form-input-newsletter md:flex'} onSubmit={handleSubmit(onSubmit)}>
                            <div className={'md:flex-auto'}>
                                <div className={classnames('input--wrapper', {
                                    'is--invalid': errors?.email,
                                })}
                                >
                                    <input
                                        id={'newsletter-email'}
                                        {...register('email', {
                                            required: intl.formatMessage({ id: 'validation.required' }),
                                            pattern: {
                                                value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message: intl.formatMessage({ id: 'validation.email' }),
                                            },
                                        })}
                                        type={'email'}
                                        className={'form-input'}
                                        placeholder={placeholder} />
                                    <label htmlFor={'newsletter-email'}>
                                        {placeholder}
                                    </label>
                                    <span className={'input--border'} />
                                    {errors?.email
                                        && (
                                            <span className={'input--hint'}>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={'email'}
                                                />
                                            </span>
                                        )}
                                </div>
                            </div>
                            <div className={'btn-search-wrap'}>
                                {btnSubmitText
                                    && (
                                        <Button
                                            ariaLabel={btnSubmitText}
                                            name={btnSubmitText}
                                            type={'submit'}
                                            className={`btn btn-${btnVariant}`}
                                            darkTheme
                                        >
                                            {btnSubmitText}
                                        </Button>
                                    )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
NewsletterBlock.propTypes = {
    intl: PropTypes.object.isRequired,
    headline: PropTypes.string,
    desc: PropTypes.string,
    placeholder: PropTypes.string,
    btnSubmitText: PropTypes.string.isRequired,
    btnVariant: PropTypes.string,
    bgcolor: PropTypes.string,
    isDotted: PropTypes.bool,
}

export default injectIntl(NewsletterBlock)

import { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

export type VendorConsent = Record<string, boolean>
export const CONSENT_VENDOR_GOOGLE_MAP_ID = 's1104'
export const CONSENT_VENDOR_GOOGLE_TAG_MANAGER_ID = 's905'

const useConsentManagement = (): VendorConsent => {
  const [cmpData, setCmpData] = useState<VendorConsent>(null)
  const optionalVendorConsents = [CONSENT_VENDOR_GOOGLE_MAP_ID, CONSENT_VENDOR_GOOGLE_TAG_MANAGER_ID]

  useEffect(() => {
    const POLLING_INTERVAL = 100
    const MAX_POLLING_ATTEMPTS = 50
    let pollingAttempts = 0
    let timeoutId: NodeJS.Timeout

    const fetchCmpData = () => {
      if (typeof (window as any).__cmp === 'function') {
        const cmpDataFetched = (window as any).__cmp('getCMPData')
        if (isEmpty(cmpDataFetched?.vendorConsents)) {
          pollingAttempts += 1
          if (pollingAttempts < MAX_POLLING_ATTEMPTS) {
            timeoutId = setTimeout(fetchCmpData, POLLING_INTERVAL)
          }
          return
        }

        const vendorConsents = optionalVendorConsents.reduce((acc, vendorId) => {
          acc[vendorId] = cmpDataFetched.vendorConsents[vendorId] || false
          return acc
        }, {} as VendorConsent)

        setCmpData(vendorConsents)
      } else {
        pollingAttempts += 1
        if (pollingAttempts < MAX_POLLING_ATTEMPTS) {
          timeoutId = setTimeout(fetchCmpData, POLLING_INTERVAL)
        }
      }
    }

    fetchCmpData();
    if (typeof (window as any).__cmp === 'function') {
      (window as any).__cmp('addEventListener', ['consent', fetchCmpData, false], null)
    }

    return () => {
      clearTimeout(timeoutId);
      if (typeof (window as any).__cmp === 'function') {
        (window as any).__cmp('removeEventListener', ['consent', fetchCmpData, false], null)
      }
    }
  }, [])

  return cmpData
}

export default useConsentManagement

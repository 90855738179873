import React, { useState } from 'react'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import FullPageWidthWrapper from '../../molecules/Layout/FullPageWidthWrapper'
import IconButton from '../../atoms/button/IconButton'
import { SearchIcon } from '../../atoms/icon'
import Button from '../../atoms/button/Button'

const BrandList = ({ brands, intl, isDarkMode }) => {
    const isDesktop = useDeviceType() === 'desktop'
    const brandList = Object.keys(brands)
    const [filterResult, setFilterResult] = useState(brands)
    const [searchInput, setSearchInput] = useState('')

    const scrollPositionDelta = 152
    const paddingElement = 24

    const searchBrands = (e) => {
        const searchValue = e.target.value.toString().trim().toLowerCase()
        setSearchInput(searchValue)

        if (searchValue === '') {
            setFilterResult(brands)

            return
        }

        const search = {}
        const re = new RegExp(searchValue, 'g')

        brandList.map((brandKey) => {
            const result = _.filter(brands[brandKey], (item) => !_.isEmpty(item.name.toString().toLowerCase().match(re)))
            if (!_.isEmpty(result)) {
                search[brandKey] = result
            }
        })

        setFilterResult(search)
    }

    const boldText = (text) => {
        if (searchInput.toString().trim() === '') {
            return text
        }

        const strRegLowCase = new RegExp(searchInput.toLowerCase(), 'g')

        const newText = text.toLowerCase().replace(strRegLowCase, `<b>${searchInput.toLowerCase()}</b>`)

        return (
            <div dangerouslySetInnerHTML={{ __html: newText }} />
        )
    }

    const handleScrollClick = (target) => {
        const scrollTarget = document.getElementById(target)

        const rect = scrollTarget.getBoundingClientRect()
        const scrollTop = document.documentElement.scrollTop
            ? document.documentElement.scrollTop : document.body.scrollTop
        const elementTop = rect.top + scrollTop - scrollPositionDelta + paddingElement

        if (scrollTarget) {
            if (isDesktop) {
                setTimeout(() => {
                    window.scrollTo({
                        behavior: scrollTarget ? 'smooth' : 'start',
                        top: elementTop,
                    })
                }, 100)

                return
            }

            scrollTarget.scrollIntoView()
        }
    }

    const getBrandList = () => (
        <div className={'brand-category'}>
            <div className={'brand-category-list'}>
                {brandList.map((brand, index) => (
                    <Button
                        className={classnames('brand-category-list-item', {
                            'brand-category-list-item-disabled': !brands[brand].length,
                        })}
                        onClick={() => {
                            handleScrollClick(brandList[index])
                        }}
                        type={'button'}
                    >
                        <span>{brandList[index]}</span>
                    </Button>

                ))}
            </div>
        </div>
    )

    const getBrandListDetail = () => (
        <>
            {brandList.map((brand, index) => (
                <>
                    {filterResult[brand] && filterResult[brand].length > 0 && (
                        <div className={'brand-list-wrap'} id={brandList[index]} key={brandList[index] + index}>
                            <div className={'brand-list-wrap-headline'}>
                                <h3 className={'mb-0'}>{brandList[index]}</h3>
                            </div>
                            <div className={'brand-list-wrap-content'}>
                                {filterResult[brand].map((item, i) => (
                                    <div
                                        className={classnames({ isDesktop: 'brand-list-wrap-content-desktop' })}
                                        key={item.name + i}
                                    >
                                        <a
                                            href={`${item.url}`}
                                            className={'brand-list-wrap-content-item'}
                                        >
                                            {boldText(item.name)}
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </>
            ))}
        </>
    )

    return (
        <FullPageWidthWrapper customClasses={classnames('brands-list-component', {
            'bg-blacksport-100 text-white': isDarkMode,
        })}
        >
            <div className={'o-wrapper'}>
                <div className={'o-grid o-region'}>
                    <div className={'o-cell o-cell--12'}>
                        <div className={'brand-list-component'}>
                            <div className={'brand-list-component-search'}>
                                <div className={'input--wrapper'}>
                                    <IconButton
                                        className={'btn search-box--submit-icon'}
                                        type={'submit'}
                                        ariaLabel={`${intl.formatMessage({ id: 'search.searchBox.iconButtonSubmit' })}`}
                                        icon={<SearchIcon width={16} height={16} />}
                                    />
                                    <input
                                        id={'search-brand-box'}
                                        className={'search-brand--input'}
                                        type={'search'}
                                        placeholder={`${intl.formatMessage({ id: 'search.searchBox.brandPlaceholder' })}`}
                                        onChange={(e) => searchBrands(e)}
                                    />
                                    <label htmlFor={'search-brand-box'}>
                                        <FormattedMessage id={'search.searchBox.brandPlaceholder'} />
                                    </label>
                                    <span className={'input--border'} />
                                </div>
                            </div>

                            <div className={'brand-list-component-category'}>
                                {getBrandList()}
                            </div>

                            <div className={'brand-list-component-group'}>
                                {getBrandListDetail()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FullPageWidthWrapper>
    )
}

BrandList.propTypes = {
    intl: PropTypes.object.isRequired,
    brands: PropTypes.object.isRequired,
    isDarkMode: PropTypes.bool,
}

export default injectIntl(BrandList)

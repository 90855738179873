import fetch from '@frontastic/bridge/js/fetch'

class EmailService {
    async checkEmailDuplicated(email) {
        try {
            const res = await fetch(`/api/account/email-validate/${email}/`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            return await res.json()
        } catch (e) {
            throw e
        }
    }
}

export default new EmailService()

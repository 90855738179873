import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'

import app from '@frontastic/catwalk/src/js/app/app'
import MenuItem from './MenuItem'
import MENU_ITEMS from './MENU_ITEMS'

import useSlidePanel from '../../Layout/Breakpoints/useSlidePanel'
import {
    CartIcon, LocationIcon, LogOutIcon, UserIcon,
} from '../../../atoms/icon'

const AccountMenu = ({
    selectedMenuItem, welcome, children, handleLogout, openPanel, isOpenPanel,
}) => {
    const { usePanel } = useSlidePanel()

    return (
        <>
            <h2 className={'account--headline hidden md:block'}>
                <FormattedMessage id={'account.title'} />
            </h2>
            <div className={'account--panel mt-4 md:mt-8 md:flex'}>
                <div className={`${!isOpenPanel ? 'hidden' : 'account--panel-desktop'}`}>
                    <div className={'account--panel--welcome md:hidden'}>{welcome}</div>

                    <div className={'flex-auto account--panel-desktop-left'}>
                        <MenuItem
                            onClick={() => {
                                selectedMenuItem !== MENU_ITEMS.ORDERS
                                && app.getRouter().push('Frontastic.Frontend.Master.Account.orders')
                                usePanel && openPanel()
                            }}
                            selected={selectedMenuItem === MENU_ITEMS.ORDERS}
                        >
                            <div className={'flex py-6 items-end w-full md:font-bold tracking-normal'}>
                                <CartIcon
                                    width={20}
                                    height={20}
                                    className={classnames('mx-4 account--panel--icon', {
                                        'text-black': selectedMenuItem === MENU_ITEMS.ORDERS,
                                    })}
                                />
                                <FormattedMessage id={'account.placedOrders'} />
                            </div>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                selectedMenuItem !== MENU_ITEMS.ADDRESSES
                                && app.getRouter().push('Frontastic.Frontend.Master.Account.addresses')
                                usePanel && openPanel()
                            }}
                            selected={selectedMenuItem === MENU_ITEMS.ADDRESSES}
                        >
                            <div className={'flex py-6 items-end w-full md:font-bold tracking-normal'}>
                                <LocationIcon
                                    width={20}
                                    height={20}
                                    className={classnames('mx-4 account--panel--icon', {
                                        'text-black': selectedMenuItem === MENU_ITEMS.ADDRESSES,
                                    })}
                                />
                                <FormattedMessage id={'account.addresses'} />
                            </div>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                selectedMenuItem !== MENU_ITEMS.ACCOUNT_DETAILS
                                && app.getRouter().push('Frontastic.Frontend.Master.Account.profile')
                                usePanel && openPanel()
                            }}
                            selected={selectedMenuItem === MENU_ITEMS.ACCOUNT_DETAILS}
                        >
                            <div className={'flex py-6 items-end w-full md:font-bold tracking-normal'}>
                                <UserIcon
                                    width={20}
                                    height={20}
                                    className={classnames('mx-4 account--panel--icon', {
                                        'text-black': selectedMenuItem === MENU_ITEMS.ACCOUNT_DETAILS,
                                    })}
                                />
                                <FormattedMessage id={'account.accountProfile'} />
                            </div>
                        </MenuItem>
                        <MenuItem
                            onClick={handleLogout}
                            selected={false}
                        >
                            <div className={'flex py-6 items-end w-full md:font-bold tracking-normal'}>
                                <LogOutIcon
                                    width={20}
                                    height={20}
                                    className={'mx-4 account--panel--icon'}
                                />
                                <FormattedMessage id={'account.logout'} />
                            </div>
                        </MenuItem>
                    </div>

                    {selectedMenuItem === MENU_ITEMS.NONE && (
                        <div className={'hidden md:flex row-start-1 col-start-2'}>{welcome}</div>
                    )}
                </div>

                {children}
            </div>
        </>
    )
}

AccountMenu.propTypes = {
    selectedMenuItem: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
    welcome: PropTypes.any.isRequired,
    handleLogout: PropTypes.func.isRequired,
    openPanel: PropTypes.func.isRequired,
}

export default AccountMenu

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import app from '@frontastic/catwalk/src/js/app/app'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import FullPageWidthWrapper from '../../../patterns/molecules/Layout/FullPageWidthWrapper'

const AccountConfirmTastic = ({ data }) => {
    const confirmationToken = useSelector((state) => state.app.route.parameters.confirmationToken)
    const [isSuccess, setIsSuccess] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const { successMsg, errorMsg } = data

    useEffect(() => {
        if (!confirmationToken) {
            return
        }

        app.api.request(
            'POST',
            'Frontastic.AccountApi.Api.confirm',
            { confirmationToken, ownErrorHandler: true },
            {},
            () => {
                app.getLoader('context').refresh()
                setIsLoading(false)
            },
            () => {
                setIsSuccess(false)
                setIsLoading(false)
            },
        )
    }, [])

    const renderMessage = () => {
        if (isLoading) {
            return null
        }

        if (!isSuccess) {
            return (
                <h3 className={'h-full flex justify-center p-16'}>
                    <span>{errorMsg}</span>
                </h3>
            )
        }

        return (
            <h3 className={'h-full flex justify-center p-16'}>
                <span>{successMsg}</span>
            </h3>
        )
    }

    return (
        <FullPageWidthWrapper withWrapper>
            {renderMessage()}
        </FullPageWidthWrapper>
    )
}

AccountConfirmTastic.propTypes = {
    data: PropTypes.object.isRequired,
}

export default tastify({ translate: true, connect: { context: true } })(injectIntl(AccountConfirmTastic))

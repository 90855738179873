import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import app from '@frontastic/catwalk/src/js/app/app'
import Entity from '@frontastic/catwalk/src/js/app/entity'

import { FormattedMessage, injectIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import DefaultLoader from '../../patterns/molecules/Loaders/DefaultLoader/index'
import CheckoutPanels from '../../patterns/molecules/Layout/CheckoutPanels'
import IconButton from '../../patterns/atoms/button/IconButton'
import { CartIcon } from '../../patterns/atoms/icon'
import ErrorMessageProduct from '../../patterns/organisms/ErrorMessageProduct'
import PaymentService from '../../services/payment'

const CheckoutTastic = ({
    intl, cart, context, data,
}) => {
    const [countries, setCountries] = useState(app.getApi().router.context.projectConfiguration.countries)
    const [paymentInfo, setPaymentInfo] = useState(null)
    const loggedIn = useSelector((state) => state.app.context.session.loggedIn)
    const [paymentMethods, setPaymentMethods] = useState(null)
    /* eslint-disable */
    const HelmetHtml = () => (
        <Helmet>
            <script src="https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/4.8.0/adyen.js"
                    integrity="sha384-JM4Li2UogXxSpxmM8Xoj1CeRTyzkcoEaU7cTJlK+34aOZ3k8W+eTVrrvW8kpbphp"
                    crossOrigin="anonymous"></script>
            <link rel="stylesheet"
                  href="https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/4.8.0/adyen.css"
                  integrity="sha384-abv8co6wyDCexfM8Iv1sH5xYfTh9wAO5gzwVj0aLZkIOw7dQFEV5ZDwSrywJ7CYf"
                  crossOrigin="anonymous" />
        </Helmet>
    )
    /* eslint-enable */

    useEffect(() => {
        PaymentService.getAdyenPaymentMethods()
            .then((body) => {
                const adyenPaymentMethods = body
                PaymentService.getPayeverPaymentMethods()
                    .then((payeverBody) => {
                        adyenPaymentMethods.paymentMethods.push(...payeverBody.paymentMethods)
                        setPaymentMethods(adyenPaymentMethods)
                    })
                    .catch((error) => {
                        console.error('Get getPayeverPaymentMethods error', error)
                        setPaymentMethods(adyenPaymentMethods)
                    })
            })
    }, [context.locale])

    if (!cart || countries.length === 0 || paymentMethods === null) {
        return (
            <>
                <HelmetHtml />

                <DefaultLoader />
            </>
        )
    }

    if (cart && cart.loaded && cart.data && cart.data.lineItems.length === 0) {
        const title = intl.formatMessage({ id: 'cart.emptyCart.text' })
        const actionLabel = intl.formatMessage({ id: 'checkout.backToShop' })

        return (
            <>
                <h2 className={'checkout-panels-title'}>
                    {loggedIn ? (
                        <FormattedMessage id={'checkout.checkoutTitleLoggedIn'} />
                    ) : (
                        <FormattedMessage id={'checkout.checkoutTitleNotLogIn'} />
                    )}
                </h2>
                <ErrorMessageProduct removedItems={cart.data.projectSpecificData.removedLineItems ?? []} />
                <div className={'checkout-no-product bg-white'}>
                    <IconButton
                        ariaLabel={intl.formatMessage({ id: 'checkout.noProduct' })}
                        className={'btn btn-text icon--left btn-no-item'}
                        icon={<CartIcon width={30} height={30} fill={'var(--color-black-100)'} />}
                        type={'button'}
                        title={title}
                        onClick={(e) => {
                            e.preventDefault()
                            app.getRouter().history.replace('/')
                        }}
                        actionLabel={actionLabel}
                    >
                        <FormattedMessage id={'checkout.noProduct'} />
                    </IconButton>
                </div>
            </>

        )
    }

    if (
        context.session.loggedIn
        && cart
        && cart.loaded
        && cart.data
        && (!cart.data.shippingAddress || !cart.data.shippingAddress.firstName)
    ) {
        cart.data.email = context.session.account.email

        context.session.account.addresses?.forEach((address) => {
            if (address.isDefaultBillingAddress) {
                cart.data.billingAddress = address
            }

            if (address.isDefaultShippingAddress) {
                cart.data.shippingAddress = address
            }
        })

        if (cart.data.billingAddress && !cart.data.shippingAddress) {
            cart.data.shippingAddress = cart.data.billingAddress
        } else if (cart.data.shippingAddress && !cart.data.billingAddress) {
            cart.data.billingAddress = cart.data.shippingAddress
        }
    }

    if (cart.loading) {
        if (!cart.data || !(countries.length > 0) || !paymentMethods) {
            return (
                <>
                    <HelmetHtml />

                    <DefaultLoader />
                </>
            )
        }
        return (
            <>
                <HelmetHtml />

                <CheckoutPanels
                    isLoading
                    app={app}
                    cart={cart}
                    data={cart.data}
                    countries={countries}
                    policy={data.policy}
                    setPaymentInfo={setPaymentInfo}
                    paymentInfo={paymentInfo}
                    contextSession={context}
                    paymentMethods={paymentMethods}
                />
            </>
        )
    }

    return (
        <>
            <HelmetHtml />

            <CheckoutPanels
                app={app}
                cart={cart}
                data={cart.data}
                countries={countries}
                policy={data.policy}
                setPaymentInfo={setPaymentInfo}
                paymentInfo={paymentInfo}
                contextSession={context}
                paymentMethods={paymentMethods}
            />
        </>
    )
}

CheckoutTastic.propTypes = {
    data: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    cart: PropTypes.instanceOf(Entity),
}

export default tastify({ translate: true, connect: { cart: true, context: true } })(injectIntl(CheckoutTastic))

import React from 'react'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import PropTypes from 'prop-types'
import Tile from '../../patterns/organisms/Tile'

const TileComponent = ({ data }) => {
    const {
        media,
        topic,
        title,
        verAlign,
        horAlign,
        buttonLabel,
        reference,
        btnVariant,
        btnColorDefault,
        btnColorHover,
        colorText,
    } = data

    return (
        <Tile
            media={media}
            topic={topic}
            title={title}
            verAlign={verAlign}
            horAlign={horAlign}
            reference={reference}
            buttonLabel={buttonLabel}
            btnVariant={btnVariant}
            btnColorDefault={btnColorDefault}
            btnColorHover={btnColorHover}
            colorText={colorText}
        />
    )
}

TileComponent.propTypes = {
    data: PropTypes.object.isRequired,
    media: PropTypes.object,
    topic: PropTypes.string,
    title: PropTypes.string,
    verAlign: PropTypes.string,
    horAlign: PropTypes.string,
    buttonLabel: PropTypes.string,
    reference: PropTypes.object,
    btnVariant: PropTypes.string,
    btnColorDefault: PropTypes.string,
    btnColorHover: PropTypes.string,
    colorText: PropTypes.string,
}

export default tastify({ translate: true })(TileComponent)

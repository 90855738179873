import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import Price from '../../../atoms/price'

const MiniSummary = ({
    sum, onClick, count,
}) => (
    <div className={'cart-page--title mb-3 md:mb-6'}>
        <div className={'cart-page--title-wrapper'}>
            <h1><FormattedMessage id={'cart.myCart'} /></h1>
            <span className={'is--count-article hidden md:flex'}>
                {count}
                <FormattedMessage id={'cart.article'} />
            </span>
            <Price value={sum} className={'font-bold md:hidden'} />
        </div>

        <button type={'button'} className={'btn btn-default cart-page--btn-submit md:hidden'} onClick={onClick}>
            <FormattedMessage
                id={'cart.checkout'}
            />
        </button>
    </div>
)

MiniSummary.propTypes = {
    sum: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    count: PropTypes.number.isRequired,
}

export default injectIntl(MiniSummary)

import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'
import SelectVariant from '../../../atoms/select/SelectVariant'

const ColorSelector = ({
    variants,
    onChange,
    setVariantsData,
    setIsChooseSize,
    setIsClicked,
    isChooseColor,
    setIsChooseColor,
    setColorProducts,
    onUpdateSkuToUrl,
    context,
}) => {
    let sizeOfColor = []

    const updateUrlAfterSelectedColor = (index) => {
        if (_.isEmpty(variants[index])) {
            return
        }

        if (onUpdateSkuToUrl) {
            onUpdateSkuToUrl(variants[index].sku)
        }
    }

    const updateSizeColor = (i) => {
        sizeOfColor = []

        variants.map((item, index) => {
            item.isActive = false

            if (index === i) {
                item.isActive = true
                sizeOfColor = item.product.variants
            }
        })

        setVariantsData(sizeOfColor)
        updateUrlAfterSelectedColor(i)
        setColorProducts(variants)
    }

    const countTotal = () => variants.length

    return (
        <div className={'select-variants-component'}>
            <div className={'select-attribute label-name color-label leading-4 underline'}>
                <FormattedMessage id={'product.color'} />
                :
            </div>

            <div className={'select-variant-wrapper'}>
                <div className={'select-variant-total font-bold'}>
                    {countTotal()}
                    {' '}
                    <FormattedMessage id={'product.colorTotal'} />
                </div>

                <SelectVariant
                    variants={variants}
                    formatLabel={(option) => (option.variants[0].attributes.att_prod_color_name_sport2000)}
                    isVariantColor
                    isChooseColor={isChooseColor}
                    context={context}
                    onSelect={(i) => {
                        setIsChooseSize(false)
                        setIsClicked(false)
                        updateSizeColor(i)
                        setIsChooseColor(true)

                        return onChange(i)
                    }}
                />
            </div>
        </div>
    )
}

ColorSelector.propTypes = {
    variants: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    setVariantsData: PropTypes.func.isRequired,
    setIsChooseSize: PropTypes.func.isRequired,
    setIsClicked: PropTypes.func.isRequired,
    setIsChooseColor: PropTypes.func.isRequired,
    isChooseColor: PropTypes.bool.isRequired,
    setColorProducts: PropTypes.func.isRequired,
    onUpdateSkuToUrl: PropTypes.any,
    context: PropTypes.any,
}

export default ColorSelector

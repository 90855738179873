import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'

const Teams = ({ teams }) => {
    const teamArray = teams.filter((team) => !!team)
    if (!teamArray || !teamArray.length) {
        return null
    }

    return (
        <div className={'store-detail-teams'}>
            <h2 className={'store-detail-teams-headline mb-7 lg:mb-8'}>
                <FormattedMessage id={'storeDetail.teamHeadline'} />
            </h2>
            <div className={'store-detail-teams-wrap'}>
                {teamArray.map((member) => (
                    <div className={'store-detail-team'} key={member}>
                        <img
                            title={member}
                            src={member}
                            alt={member}
                            className={'store-detail-team-img'}
                            loading={'lazy'}
                            decoding={'async'}
                            draggable={false}
                        />
                    </div>
                ))}
            </div>
        </div>

    )
}

Teams.propTypes = {
    teams: PropTypes.array.isRequired,
}

export default injectIntl(Teams)

import React from 'react'
import PropTypes from 'prop-types'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import StoreHeader from '../../patterns/organisms/StoreHeader'

const StoreDetailHeader = ({
    data: {
        subHeadline,
        headline,
        map,
        titleStoreContact,
        address,
        phoneNumber,
        email,
        website,
        buttonLabelShowRoute,
        referenceButtonShowRoute,
        btnVariantShowRoute,
        titleStoreOpening,
        StoreOpeningHoursList,
        buttonLabelMakeAnAppointment,
        referenceButtonMakeAnAppointment,
        btnVariantMakeAnAppointment,
        isDarkMode,
    },
}) => (
    <StoreHeader
        subHeadline={subHeadline}
        headline={headline}
        map={map}
        titleStoreContact={titleStoreContact}
        address={address}
        phoneNumber={phoneNumber}
        email={email}
        website={website}
        buttonLabelShowRoute={buttonLabelShowRoute}
        referenceButtonShowRoute={referenceButtonShowRoute}
        btnVariantShowRoute={btnVariantShowRoute}
        titleStoreOpening={titleStoreOpening}
        StoreOpeningHoursList={StoreOpeningHoursList}
        buttonLabelMakeAnAppointment={buttonLabelMakeAnAppointment}
        referenceButtonMakeAnAppointment={referenceButtonMakeAnAppointment}
        btnVariantMakeAnAppointment={btnVariantMakeAnAppointment}
        isDarkMode={isDarkMode}
    />
)

StoreDetailHeader.propTypes = {
    data: PropTypes.object.isRequired,
}

export default tastify()(StoreDetailHeader)

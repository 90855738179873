import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormattedMessage, injectIntl } from 'react-intl'
import { ArrowDownIcon } from '../../../atoms/icon'
import useComponentVisible from '../../../../utils/hooks/UseComponentVisible'
import RangeSlider from '../../../atoms/rangeSlider'
import IconButton from '../../../atoms/button/IconButton'

const RangeFacet = ({
    intl, facet, onChange, isPosition,
}) => {
    const initFacet = { ...facet }
    const min = Math.floor(initFacet.min / 100)
    const max = Math.ceil(initFacet.max / 100)
    const [tmpMin, setTmpMin] = useState(initFacet.value.min ? Math.floor(parseFloat(initFacet.value.min) / 100) : min)
    const [tmpMax, setTmpMax] = useState(initFacet.value.max ? Math.ceil(parseFloat(initFacet.value.max) / 100) : max)
    const [value, setValue] = useState([tmpMin, tmpMax])
    const [isErrorMin, setIsErrorMin] = useState(false)
    const [isErrorMax, setIsErrorMax] = useState(false)
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
    useEffect(() => {
        setIsErrorMin(false)
        setIsErrorMax(false)
        setTmpMin(initFacet.value.min ? Math.floor((parseFloat(initFacet.value.min) / 100)) : min)
        setTmpMax(initFacet.value.max ? Math.ceil((parseFloat(initFacet.value.max) / 100)) : max)
        setValue([initFacet.value.min ? (Math.floor(parseFloat(initFacet.value.min) / 100)) : min, initFacet.value.max ? Math.ceil((parseFloat(initFacet.value.max) / 100)) : max])
    }, [facet, min, max, initFacet.value.min, initFacet.value.max])

    const handleClick = (e) => {
        e.stopPropagation()
        e.preventDefault()
        setIsComponentVisible((prev) => !prev)
    }

    const updateRangePrice = (v) => {
        setValue(v)
        setTmpMin(v[0])
        setTmpMax(v[1])
        initFacet.value.min = v[0] * 100
        initFacet.value.max = v[1] * 100
    }

    const handleRangeChange = (v) => {
        updateRangePrice(v)
        if (onChange) {
            onChange(initFacet)
        }
    }

    const handleAfterChange = () => {
        initFacet.selected = true

        if (onChange) {
            onChange(initFacet)
        }
    }

    const handleChange = (v) => {
        updateRangePrice(v)
    }

    const validateNumber = (val) => {
        const pattern = new RegExp('^[0-9]\\d{0,2}(\\.?\\d{3})*(,\\d+)?$')
        return pattern.test(val)
    }

    const formatNumber = (val = '') => parseFloat(val.toString().replace(',', '.'))

    const handleBlurMin = () => {
        if (validateNumber(tmpMin)) {
            setIsErrorMin(false)
            const formatTmpMin = formatNumber(tmpMin)
            if (formatTmpMin < min) {
                handleRangeChange([parseFloat(min), value[1]])
            } else {
                handleRangeChange([Math.floor(formatTmpMin), value[1]])
            }
        } else {
            setIsErrorMin(true)
        }
    }

    const handleBlurMax = () => {
        if (validateNumber(tmpMax)) {
            setIsErrorMax(false)
            const formatTmpMax = formatNumber(tmpMax)
            if (formatTmpMax > max) {
                handleRangeChange([value[0], parseFloat(max)])
            } else {
                handleRangeChange([value[0], Math.ceil(formatTmpMax)])
            }
        } else {
            setIsErrorMax(true)
        }
    }

    return (
        <div
            className={classnames('product-filter-item', {
                'static-position': isPosition,
                opened: isComponentVisible,
            })}
            ref={ref}
        >
            <IconButton
                className={'btn btn-text icon--right justify-between font-bold'}
                type={'button'}
                onClick={handleClick}
                ariaLabel={intl.formatMessage({ id: `filters.${initFacet.handle}` })}
                icon={<ArrowDownIcon width={16} height={16} />}
            >
                <FormattedMessage id={`filters.${initFacet.handle}`} />
            </IconButton>
            <div className={'product-filter-item--content right-0 left-auto product-filter-price'}>
                <div className={'product-filter-price--input'}>
                    <div className={classnames('product-filter-price--input-wrapper', {
                        error: isErrorMin,
                    })}
                    >
                        <input
                            name={'min'}
                            type={'input'}
                            value={tmpMin}
                            onChange={(e) => {
                                setTmpMin(e.target.value)
                            }}
                            onBlur={handleBlurMin}
                        />
                        <FormattedMessage
                            id={'filters.unit'}
                        />
                    </div>
                    <span />
                    <div className={classnames('product-filter-price--input-wrapper', {
                        error: isErrorMax,
                    })}
                    >
                        <input
                            name={'max'}
                            type={'input'}
                            value={tmpMax}
                            onChange={(e) => {
                                setTmpMax(e.target.value)
                            }}
                            onBlur={handleBlurMax}
                        />
                        <FormattedMessage
                            id={'filters.unit'}
                        />
                    </div>
                </div>
                <div className={'product-filter-price--range'}>
                    <RangeSlider
                        value={value}
                        min={min}
                        max={max}
                        onChange={(v) => handleChange(v)}
                        onAfterChange={() => handleAfterChange()}
                    />
                </div>
            </div>
        </div>
    )
}

RangeFacet.propTypes = {
    intl: PropTypes.object.isRequired,
    facet: PropTypes.objectOf(PropTypes.any).isRequired,
    onChange: PropTypes.func.isRequired,
    isPosition: PropTypes.bool,
}

RangeFacet.defaultProps = {
    isPosition: false,
}

export default injectIntl(RangeFacet)

import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Swiper } from 'swiper/react'
import SwiperCore, { Autoplay, Navigation, Scrollbar } from 'swiper'
import { injectIntl } from 'react-intl'
import classnames from 'classnames'
import { ReactComponent as ArrowThickLeftIcon } from '../../../../icons/sport2000-icons/arrow-thick-left.svg'
import { ReactComponent as ArrowThickRightIcon } from '../../../../icons/sport2000-icons/arrow-thick-right.svg'
import Button from '../button/Button'

// SwiperCore.use([Navigation, Autoplay])

const Slider = ({
    swiperColor, loop, children, intl, activeSlide,hasScrollbar, ...rest
}) => {
    const [swiperInstance, setSwiperInstance] = useState(null)
    const [swiperState, setSwiperState] = useState({
        isBeginning: true,
        isEnd: true
    })

    const syncSwiperState = useCallback(
        (swiper) => setTimeout(() => {
            setSwiperState({
                isBeginning: swiper.isBeginning,
                isEnd: swiper.isEnd,
            })
        }, 100),
        [setSwiperState]
    )

    useEffect(() => {
        if (activeSlide) {
            swiperInstance?.slideTo(activeSlide.index, 500, false)
        }
    }, [activeSlide])

    const swiperModules = () => {
        const defaultModules = [Navigation,Autoplay]
        if (hasScrollbar) {
          return defaultModules.push(Scrollbar)
        }
        return defaultModules
      }

    return (
        <div className={'slider relative'}>
            <Swiper
                {...rest}
                speed={500}
                modules={swiperModules}
                loop={loop}
                onSwiper={setSwiperInstance}
                onSlideChange={syncSwiperState}
                onAfterInit={syncSwiperState}
                onTransitionEnd={syncSwiperState}
                onActiveIndexChange={syncSwiperState}
                onResize={syncSwiperState}
                onSlidesGridLengthChange={syncSwiperState}
            >
                {children}
            </Swiper>
            {!swiperState.isBeginning && <Button
                className={classnames('swiper-button-prev', {
                    [`${swiperColor}`]: swiperColor,
                })}
                type={'button'}
                ariaLabel={intl.formatMessage({ id: 'slider.previous' })}
                onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    swiperInstance?.slidePrev()
                }}
            >
                <ArrowThickLeftIcon
                    width={24}
                    height={24}
                />
            </Button>}
            {!swiperState.isEnd && <Button
                className={classnames('swiper-button-next', {
                    [`${swiperColor}`]: swiperColor,
                })}
                type={'button'}
                ariaLabel={intl.formatMessage({ id: 'slider.next' })}
                onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    swiperInstance?.slideNext()
                }}
            >
                <ArrowThickRightIcon
                    width={24}
                    height={24}
                />
            </Button>}
        </div>
    )
}

Slider.propTypes = {
    loop: PropTypes.bool,
    children: PropTypes.node.isRequired,
    intl: PropTypes.object.isRequired,
    swiperColor: PropTypes.string,
    activeSlide: PropTypes.object,
}

export default injectIntl(Slider)

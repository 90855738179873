import React from 'react'
import PropTypes from 'prop-types'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import BlogFullDescription from '../../../patterns/organisms/GraphCMS/BlogFullDescription'

export const BrandContentFullTastic = ({ data }) => {
    const { content } = data
    if (!content) {
        return null
    }

    return (
        <div>
            <BlogFullDescription content={content} />
        </div>
    )
}
BrandContentFullTastic.propTypes = {
    data: PropTypes.object.isRequired,
}

export default tastify({ translate: true })(BrandContentFullTastic)

import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Button from '../../../atoms/button/Button'
import { ReactComponent as WishlistIcon } from '../../../../../icons/sport2000-icons/heart.svg'

const EmptyWishlist = ({ returnToHomePage }) => (
    <div className={'empty-wishlist'}>
        <div className={'empty-wishlist-state text-center'}>
            <WishlistIcon width={50} height={48} className={'empty-wishlist-icon'} />
            <p className={'mt-2 font-semibold lg:text-xl'}>
                <FormattedMessage id={'wishlist.emptyWishlist'} />
            </p>
            <Button onClick={returnToHomePage} className={'btn btn-default font-openSans'}>
                <FormattedMessage id={'wishlist.keepBrowsing'} />
            </Button>
        </div>
    </div>
)

EmptyWishlist.propTypes = {
    returnToHomePage: PropTypes.func.isRequired,
}

export default EmptyWishlist

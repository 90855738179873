import React from 'react'
import PropTypes from 'prop-types'
import FullPageWidthWrapper from '../../molecules/Layout/FullPageWidthWrapper'

const ProductCategoryTiles = ({ categories, title }) => {
    if (!categories || categories.length == 0) {
        return null
    }

    return (
        <FullPageWidthWrapper
            customClasses={'product-categories-tiles'}
            withWrapper
        >
            {title && (
                <h2 className={'product-categories-tiles-headline'}>{title}</h2>
            )}

            <div className={'product-categories-tiles-list'}>
                {categories && categories.map((category) => (
                    <div key={category.key} className={'product-categories-tiles-list-item'}>
                        <a
                            className={'product-categories-tiles-list-item-link'}
                            href={category.url}
                        >
                            {category.name}
                        </a>
                    </div>
                ))}
            </div>
        </FullPageWidthWrapper>
    )
}

ProductCategoryTiles.propTypes = {
    categories: PropTypes.array.isRequired,
    title: PropTypes.string,
}
export default ProductCategoryTiles

import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import Store from '../Store/Store'
import { ReactComponent as FacebookLogo } from '../../../../../icons/sport2000-icons/facebook.svg'
import { ReactComponent as InstagramLogo } from '../../../../../icons/sport2000-icons/instagram.svg'
import { ReactComponent as YoutubeLogo } from '../../../../../icons/sport2000-icons/youtube.svg'

function formatWebsiteUrl(websiteUrl) {
    // Check if the websiteUrl starts with http:// or https://
    if (!websiteUrl.startsWith('http://') && !websiteUrl.startsWith('https://')) {
        // If it doesn't start with a protocol, assume it is an http:// URL
        websiteUrl = 'http://' + websiteUrl;
    }

    // Return the formatted URL
    return websiteUrl;
}

const Information = ({ data, tooltip, context }) => {
    const {
        name,
        street,
        street_number: streetNumber,
        post_code: postCode,
        city,
        telephone,
        website,
        social_links: socialLinks,
        email,
    } = data

    const getIcon = (url) => {
        if (url.trim().toString().includes('instagram.com')) {
            return (
                <a
                    href={url}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                >
                    <div className={'store-detail-information-social-link-item'}>
                        <InstagramLogo width={20} height={20} />
                    </div>
                </a>
            )
        }
        if (url.trim().toString().includes('facebook.com')) {
            return (
                <a
                    href={url}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                >
                    <div className={'store-detail-information-social-link-item'}>
                        <FacebookLogo width={20} height={20} />
                    </div>
                </a>
            )
        }
        if (url.trim().toString().includes('youtube.com')) {
            return (
                <a
                    href={url}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                >
                    <div className={'store-detail-information-social-link-item'}>
                        <YoutubeLogo width={20} height={20} />
                    </div>
                </a>
            )
        }

        return null
    }

    return (
        <div className={'store-detail-information'}>
            <div className={'store-detail-information-address'}>
                {name && (
                    <b>
                        {name}
                        <br />
                    </b>
                )}
                {street && (
                    <span>
                        {street}
                        {' '}
                    </span>
                )}
                {streetNumber && (
                    <span>
                        {streetNumber}
                        <br />
                    </span>
                )}
                {postCode && (
                    <span>
                        {postCode}
                        {' '}
                    </span>
                )}
                {city && <span>{city}</span>}
            </div>

            <div className={'hidden lg:block mt-4 store-detail-information-contact'}>
                {telephone && (
                    <a href={`tel: ${telephone}`}>
                        <FormattedMessage id={'storeDetail.telephone'} />
                        {' '}
                        {telephone}
                        <br />
                    </a>
                )}
                {email && (
                    <a className={'underline'} href={`mailto: ${email}`}>
                        {email}
                        <br />
                    </a>
                )}
                {website && (
                    <a className={'underline'} target="_blank" href={formatWebsiteUrl(website)}>
                        {website}
                    </a>
                )}
            </div>

            <div className={'mt-3'}>
                <Store store={data} tooltip={tooltip} context={context} />
            </div>

            {socialLinks && socialLinks.length > 0 && (
                <div className={'store-detail-information-social-link hidden lg:flex mt-4'}>
                    {socialLinks.map((link) => (
                        <div key={link}>
                            {getIcon(link)}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

Information.propTypes = {
    data: PropTypes.object.isRequired,
    tooltip: PropTypes.string,
    context: PropTypes.any,
}

export default injectIntl(Information)

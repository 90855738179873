import React from 'react'
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl'

import app from '@frontastic/catwalk/src/js/app/app'
import IconButton from '../../../atoms/button/IconButton'
import { CartIcon } from '../../../atoms/icon'

const NoOrders = ({ intl }) => (
    <div className={'account--no-order p-4 md:p-0 text-center'}>
        <IconButton
            type={'button'}
            className={'btn btn-text account--no-order-button flex-col'}
            ariaLabel={intl.formatMessage({ id: 'order.noOrders' })}
            onClick={() => app.getRouter().history.replace('/')}
            icon={<CartIcon />}
        >
            <FormattedMessage id={'order.noOrders'} />
        </IconButton>
    </div>
)

NoOrders.propTypes = {
    intl: PropTypes.object.isRequired,
}

export default injectIntl(NoOrders)

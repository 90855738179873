import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import classnames from 'classnames'
import SelectVariant from '../../../atoms/select/SelectVariant'
import Button from '../../../atoms/button/Button'

const SizeSelector = ({
    variants,
    onChange,
    setIsChooseSize,
    isChooseSize,
    isClicked,
    setIsClicked,
    isChooseColor,
    setIsChooseColor,
    setOpenPanel,
    brandSizeTable,
    selectedSkuFromCart,
    refererStore,
    context,
}) => {
    const [changeLabelSize, setChangeLabelSize] = useState(false)

    return (
        <div className={'select-variants-component'}>
            <div className={'flex justify-between'}>
                <div>
                    {brandSizeTable.brandSizeTables && brandSizeTable.brandSizeTables.length > 0 && (
                        <Button
                            type={'button'}
                            className={'button-table-size label-name leading-4 underline mr-3 p-0 h-auto font-normal capitalize tracking-normal'}
                            onClick={() => {
                                setOpenPanel(true)
                            }}
                        >
                            <FormattedMessage id={'product.sizeProduct'} />
                        </Button>
                    )}
                    {variants[0].attributes.att_prod_size_mf && variants.length > 0 && (
                        <Button
                            type={'button'}
                            className={'button-table-size label-name leading-4 underline mr-3 p-0 h-auto font-normal normal-case tracking-normal'}
                            onClick={() => {
                                setChangeLabelSize(!changeLabelSize)
                            }}
                        >
                            <FormattedMessage id={changeLabelSize ? 'product.sizeManufacture' : 'product.sizeNormal'} />
                        </Button>
                    )}
                </div>
                <div className={classnames('label-name no-size-label leading-4 text-redsport-60', {
                    'block no-size-label-block': isClicked && !isChooseSize,
                    'hidden no-size-label-hidden': isChooseSize || !isClicked,
                })}
                >
                    <FormattedMessage id={'noSizeChoose'} />
                </div>
            </div>

            <div className={'select-variant-wrapper'}>
                <SelectVariant
                    variants={variants}
                    isChooseColor={isChooseColor}
                    setIsChooseColor={setIsChooseColor}
                    formatLabel={(option) => (changeLabelSize ? (option.attributes.att_prod_size_mf).trim() : (option.attributes.attr_size).trim())}
                    isVariantSize
                    selectedSkuFromCart={selectedSkuFromCart}
                    refererStore={refererStore}
                    context={context}
                    onSelect={(i) => {
                        setIsChooseSize(true)
                        setIsClicked(false)
                        setIsChooseColor(false)

                        return onChange(i)
                    }}
                />
            </div>
        </div>
    )
}

SizeSelector.propTypes = {
    variants: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    setIsChooseSize: PropTypes.func.isRequired,
    setIsClicked: PropTypes.func.isRequired,
    isClicked: PropTypes.bool.isRequired,
    isChooseSize: PropTypes.bool.isRequired,
    isChooseColor: PropTypes.bool.isRequired,
    setIsChooseColor: PropTypes.func.isRequired,
    setOpenPanel: PropTypes.func.isRequired,
    brandSizeTable: PropTypes.object.isRequired,
    selectedSkuFromCart: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    refererStore: PropTypes.any,
    context: PropTypes.any,
}

export default SizeSelector

import React, { useEffect, useRef, useState } from 'react'
import Proptypes from 'prop-types'
import classnames from 'classnames'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import useComponentVisible from '../../../../../utils/hooks/UseComponentVisible'
import useGooglePlaces from '../../../../../services/hook/useGooglePlaces'
import Button from '../../../../atoms/button/Button'
import IconButton from '../../../../atoms/button/IconButton'
import { ReactComponent as FilterIcon } from '../../../../../../icons/sport2000-icons/filter.svg'
import { ReactComponent as LocationIcon } from '../../../../../../icons/sport2000-icons/location.svg'
import { ReactComponent as LocationFilledIcon } from '../../../../../../icons/sport2000-icons/location-filled.svg'
import { ReactComponent as CloseIcon } from '../../../../../../icons/sport2000-icons/close.svg'
import { ReactComponent as LoadingIcon } from '../../../../../../icons/sport2000-icons/loading.svg'
import Filter from '../Filter'
import ClientFactory from '../../../../../services/algolia/ClientFactory'
import useDebounce from '../../../../../services/hook/useDebounce'
import CurrentRefinementList from '../CurrentRefinementList'

const SearchBox = ({
    intl,
    setSelectedLocation,
    setSelectedStore,
    refine,
    searchResults,
    searching,
    setIsActiveFilter,
    isActiveFilter,
    selectedLocation,
    context,
    onSelect,
    onChange,
    value,
    attributeToRender,
    facets,
    currentRefinement,
    onFocus,
    initFacetFilter,
    isInSpecialPage,
}) => {
    if (!window.google) {
        return null
    }

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
    const [isFocus, setIsFocus] = useState(false)
    const [currentPosition, setCurrentPosition] = useState(null)
    const [suggestionStoreList, setSuggestionStoreList] = useState([])
    const [isAllow, setIsAllow] = useState(false)
    const isDesktop = useDeviceType() === 'desktop'
    const inputRef = useRef(null)
    const debounceSearch = useDebounce(value, 500)
    const isCurrentPosition = selectedLocation
        && currentPosition
        && selectedLocation.lat === currentPosition.lat
        && selectedLocation.lng === currentPosition.lng

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
    } = useGooglePlaces({})

    const handleQueryStore = (val) => {
        const options = {
            facetFilters: initFacetFilter,
        }
        ClientFactory.queryStore(context, val, options).then((res) => {
            setSuggestionStoreList(res.results[0]?.hits)
        })
    }
    const handleSelectPrediction = (place) => {
        if (place && place.name && onChange && onSelect) {
            setIsFocus(false)
            onSelect(place)
            onChange(place.name)
            return
        }

        if (onChange) {
            onChange(place.description)
        }

        placesService?.getDetails(
            {
                placeId: place.place_id,
            },
            (placeDetails) => {
                if (onSelect) {
                    onSelect({
                        _geoloc: {
                            lat: placeDetails.geometry.location.lat(),
                            lng: placeDetails.geometry.location.lng(),
                        },
                    })
                }
                setIsFocus(false)
            },
        )
    }

    const renderPlacePredictions = () => {
        if ((!placePredictions.length && !suggestionStoreList.length) || !isFocus || !isComponentVisible) {
            return null
        }

        const places = [...suggestionStoreList, ...placePredictions]

        return places.map((place) => (
            <div
                className={'place-prediction-item'}
                key={place.place_id || place.account_id || place.objectID}
                onClick={() => handleSelectPrediction(place)}
            >
                {place.name || place.description}
            </div>
        ))
    }

    const handleGetCurrentLocation = async () => {
        if (currentPosition) {
            setSelectedLocation({
                lat: currentPosition.latitude,
                lng: currentPosition.longitude,
            })
            return
        }
        await navigator.geolocation.getCurrentPosition(
            (position) => {
                setSelectedLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                })
                setCurrentPosition({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                })
                setSelectedStore(null)
            },
            () => {
                setIsFocus(false)
                setIsAllow(true)
            },
        )
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsAllow(false)
        }, 3000)

        return () => {
            clearTimeout(timer)
        }
    }, [isAllow])

    useEffect(() => {
        if (debounceSearch) {
            handleQueryStore(value)
        }

        return () => {
            clearTimeout(debounceSearch)
        }
    }, [debounceSearch])

    useEffect(() => {
        getPlacePredictions({
            input: value,
            componentRestrictions: { country: 'de' },
            types: ['street_address', 'city_hall', 'postal_code'],
        })
    }, [value])

    return (
        <div className={'store-finder--search-form-container'}>
            <div className={'store-finder--search-form-wrapper'}>
                <h3>
                    <FormattedMessage id={'storeFinder.searchTitle'} />
                </h3>

                <div className={'store-finder--search-form'}>
                    <div className={'search-finder--input'} ref={ref}>
                        <input
                            id={'search-store'}
                            ref={inputRef}
                            type={'text'}
                            value={value}
                            placeholder={intl.formatMessage({ id: 'storeFinder.placeHolderInput' })}
                            onChange={(e) => onChange(e.target.value)}
                            onFocus={() => {
                                setIsFocus(true)
                                setIsComponentVisible(true)

                                if (onFocus) {
                                    onFocus()
                                }
                            }}
                            onBlur={() => {
                                if (!value) {
                                    setSelectedLocation(null)
                                }
                            }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSelectPrediction(suggestionStoreList?.length ? suggestionStoreList[0] : null)
                                    if (inputRef) {
                                        inputRef.current.blur()
                                    }
                                }
                            }}
                        />
                        {isAllow && (
                            <div className={'mt-2 text-red-600 text-xs'}>
                                <FormattedMessage id={'storeFinder.permissionLocation'} />
                            </div>
                        )}
                        <div className={'search-finder--prediction-list'}>
                            {renderPlacePredictions()}
                        </div>
                        <IconButton onClick={() => handleGetCurrentLocation()}>
                            {isCurrentPosition ? <LocationFilledIcon /> : <LocationIcon />}
                        </IconButton>
                    </div>

                    {isDesktop && (
                        <>
                            <Button
                                type={'button'}
                                ariaLabel={intl.formatMessage({ id: 'storeFinder.searchTitle' })}
                                className={'btn btn-secondary btn-filter-store'}
                                disabled={isInSpecialPage}
                                onClick={() => {
                                    setIsActiveFilter(!isActiveFilter)
                                }}
                            >
                                <FormattedMessage id={'storeFinder.filterBtn'} />
                                {isActiveFilter ? <CloseIcon width={20} height={20} />
                                    : <FilterIcon width={20} height={20} />}
                            </Button>
                            <Button
                                type={'button'}
                                ariaLabel={intl.formatMessage({ id: 'storeFinder.searchBtn' })}
                                className={'btn btn-default btn-search-store'}
                                onClick={() => {
                                    if (!isFocus) {
                                        return
                                    }
                                    handleSelectPrediction(suggestionStoreList?.length ? suggestionStoreList[0] : null)
                                }}
                            >
                                <FormattedMessage id={'storeFinder.searchBtn'} />
                            </Button>
                        </>
                    )}
                </div>

                {!isDesktop && (
                    <button
                        type={'button'}
                        aria-label={intl.formatMessage({ id: 'filters.filterStores' })}
                        className={'btn store-finder--btn-filter-mobile'}
                        onClick={() => setIsActiveFilter(!isActiveFilter)}
                        disabled={isInSpecialPage}
                    >
                        <FormattedMessage id={'filters.filterStores'} />
                        {searching ? (
                            <LoadingIcon width={20} height={20} />
                        ) : (
                            <FilterIcon width={20} height={20} />
                        )}
                    </button>
                )}

                <div className={classnames('store-finder--filter', {
                    active: isActiveFilter,
                })}
                >
                    {!isDesktop && (
                        <div className={'store-finder--filter-header-mobile'}>
                            <h4>
                                <FormattedMessage id={'filters.filterStores'} />
                            </h4>

                            <CloseIcon
                                width={20}
                                height={20}
                                onClick={() => setIsActiveFilter(false)}
                            />
                        </div>
                    )}

                    <CurrentRefinementList
                        refine={refine}
                        items={currentRefinement}
                        isInSpecialPage={isInSpecialPage}
                    />

                    <div className={'store-finder--filter-properties'}>
                        <Filter
                            setIsActiveFilter={setIsActiveFilter}
                            isActiveFilter={isActiveFilter}
                            facets={facets}
                            attributeToRender={attributeToRender}
                            refine={refine}
                            currentRefinement={currentRefinement}
                        />
                    </div>

                    <div className={'store-finder--filter-actions'}>
                        {searchResults && searchResults.nbHits > 0 && (
                            <p className={'text-center'}>
                                <FormattedMessage
                                    id={'filters.storeCount'}
                                    values={{ num: `${searchResults.nbHits}` }}
                                />
                            </p>
                        )}
                        <Button
                            type={'button'}
                            className={'btn btn-secondary'}
                            ariaLabel={intl.formatMessage({ id: 'filters.resetAll' })}
                            onClick={() => refine()}
                        >
                            <FormattedMessage id={'filters.resetAll'} />
                        </Button>
                        <Button
                            type={'button'}
                            className={'btn btn-default'}
                            ariaLabel={intl.formatMessage({ id: 'filters.filters' })}
                            onClick={() => setIsActiveFilter(false)}
                        >
                            <FormattedMessage id={'filters.filters'} />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

SearchBox.propTypes = {
    intl: Proptypes.any,
    setSelectedLocation: Proptypes.any,
    setSelectedStore: Proptypes.any,
    searchResults: Proptypes.any,
    searching: Proptypes.any,
    setIsActiveFilter: Proptypes.any,
    isActiveFilter: Proptypes.any,
    selectedLocation: Proptypes.any,
    context: Proptypes.any,
    onSelect: Proptypes.any,
    onChange: Proptypes.any,
    value: Proptypes.any,
    facets: Proptypes.any,
    attributeToRender: Proptypes.any,
    refine: Proptypes.any,
    currentRefinement: Proptypes.any,
    onFocus: Proptypes.any,
    initFacetFilter: Proptypes.any,
    isInSpecialPage: Proptypes.any,
}

export default injectIntl(SearchBox)

import React from 'react'
import PropTypes from 'prop-types'
import Advantage from '../../patterns/organisms/Advantages'

function AdvantageTastic({
    data: {
        advantageItem, mode, bgColor, textColor, itemColor,
    },
}) {
    return (
        <Advantage
            advantageItem={advantageItem}
            mode={mode}
            bgColor={bgColor}
            textColor={textColor}
            itemColor={itemColor}
        />
    )
}

AdvantageTastic.propTypes = {
    data: PropTypes.object.isRequired,
}

export default AdvantageTastic

import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { CheckCircleIcon } from '../../../atoms/icon'
import Button from '../../../atoms/button/Button'

const ConfirmationRegister = ({ onClick, intl }) => (
    <div className={'confirmation-register'}>
        <div className={'confirmation-register--wrapper'}>
            <CheckCircleIcon width={32} height={32} className={'confirmation-register--icon'} />
            <h3 className={'confirmation-register--title'}>
                <FormattedMessage
                    id={'account.register.ConfirmationTitle'}
                    values={{ linebreak: <br /> }}
                />
            </h3>
            <p
                className={'confirmation-register--desc'}
                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'account.register.ConfirmationDesc' }) }}
            />
            <Button
                type={'button'}
                className={'btn btn-secondary confirmation-register--btn'}
                ariaLabel={<FormattedMessage id={'account.register.ConfirmationButton'} />}
                onClick={onClick}
            >
                <FormattedMessage id={'account.register.ConfirmationButton'} />
            </Button>
        </div>
    </div>
)

ConfirmationRegister.propTypes = {
    intl: PropTypes.object,
    onClick: PropTypes.func.isRequired,
}

export default injectIntl(ConfirmationRegister)

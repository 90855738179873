import React from 'react'
import PropTypes from 'prop-types'
import SmallTileSlider from '../../../patterns/organisms/sliders/SmallTileSlider'

const SmallTileSliderTastic = ({ data: { title, tileList, bgColor, swiperColor } }) => {
    return (
        <SmallTileSlider
            title={title}
            tileList={tileList}
            bgColor={bgColor}
            swiperColor={swiperColor}
        />
    )
}

SmallTileSliderTastic.propTypes = {
    data: PropTypes.object.isRequired,
}

export default SmallTileSliderTastic

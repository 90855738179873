import React, { useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import IconButton from '../../../atoms/button/IconButton'
import { ArrowDownIcon, ArrowUpIcon } from '../../../atoms/icon'

const Services = ({ services }) => {
    if (!services || !services.length) {
        return null
    }

    const MAX_ITEMS = 6
    const [isOpen, setIsOpen] = useState(false)
    const isDesktop = useDeviceType() === 'desktop'

    const Toggle = () => {
        setIsOpen(!isOpen)
    }

    const renderTemplate = () => {
        if (isOpen) {
            return services
        }

        return services.slice(0, !isDesktop ? MAX_ITEMS : services.length)
    }

    return (
        <div className={'store-detail-services'}>
            <h2 className={'store-detail-services-headline mb-7 lg:mb-8'}>
                <FormattedMessage id={'storeDetail.serviceHeadline'} />
            </h2>
            <div className={'store-detail-services-wrap'}>
                {renderTemplate().map((service) => (
                    <div className={'store-detail-service'} key={service.id}>
                        {service.symbolUrl && (
                            <img
                                src={service.symbolUrl}
                                alt={service.name}
                                className={'store-detail-service-img mb-2'}
                            />
                        )}
                        {(service.name || service.beschreibung) && (
                            <div className={'store-detail-service-info'}>
                                <b>{service.name}</b>
                                <br />
                                {service.beschreibung}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <div className={'store-detail-services-button text-center lg:hidden'}>
                {!isOpen ? (
                    <IconButton
                        className={'btn btn-link'}
                        onClick={Toggle}
                        icon={<ArrowDownIcon width={16} height={16} className={'service-button-icon'} />}
                    >
                        <FormattedMessage id={'storeDetail.serviceSeeMore'} />
                    </IconButton>
                ) : (
                    <IconButton
                        className={'btn btn-link'}
                        onClick={Toggle}
                        icon={<ArrowUpIcon width={16} height={16} />}
                    >
                        <FormattedMessage id={'storeDetail.serviceSeeLess'} />
                    </IconButton>
                )}
            </div>
        </div>
    )
}

Services.propTypes = {
    services: PropTypes.array.isRequired,
}

export default injectIntl(Services)

import React from 'react'
import PropTypes from 'prop-types'
import LightGallery from 'lightgallery/react'
import classNames from 'classnames'
import Video from '../../atoms/video'
import 'lightgallery/css/lightgallery.css'
import Image from '../../atoms/image'

const GalleryGrid = (props) => {
    const {
        imageList, numOfCol, hasGap, openOverlay, url, videoTitle, previewPic, headline,
    } = props

    if (!imageList || !imageList.length) {
        return null
    }

    return (
        <>
            {headline && (
                <h3>{headline}</h3>
            )}

            {url && (
                <div className={'gallery-video'}>
                    <Video
                        url={url}
                        title={videoTitle}
                        previewPic={previewPic}
                    />
                </div>
            )}
            <LightGallery
                mode={'lg-fade'}
                elementClassNames={classNames('gallery-grid-component', {
                    grid: true,
                    [`grid-cols-${numOfCol}`]: true,
                    'gap-3': hasGap,
                    'disable-overlay': !openOverlay,
                })}
            >
                {imageList.map((item) => {
                    if (!item || !item.image.media) {
                        return null
                    }

                    const title = item.title && item.title
                    const desc = item.desc && item.desc

                    return (
                        <a
                            href={item.image.media.file}
                            className={'gallery-item'}
                            data-sub-html={`<h4>${title}</h4><p>${desc}</p>`}
                            key={item.image.media.mediaId}
                        >
                            <Image
                                alt={title}
                                title={title}
                                media={item.image.media.file}
                                height={item.image.media.height}
                                width={item.image.media.width}
                                triggerImageDimensionUpdate={false}
                            />
                        </a>
                    )
                })}
            </LightGallery>
        </>
    )
}

GalleryGrid.propTypes = {
    imageList: PropTypes.array.isRequired,
    numOfCol: PropTypes.number,
    hasGap: PropTypes.bool,
    openOverlay: PropTypes.bool,
    url: PropTypes.string,
    videoTitle: PropTypes.string,
    previewPic: PropTypes.object,
    headline: PropTypes.string,
}

export default GalleryGrid

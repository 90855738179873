import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { ReactComponent as ZiniaIcon } from '../../../../../icons/sport2000-icons/payments/zinia-santander.svg'
import { ReactComponent as VisaIcon } from '../../../../../icons/sport2000-icons/payments/visa.svg'
import { ReactComponent as MastercardIcon } from '../../../../../icons/sport2000-icons/payments/mastercard.svg'

const PaymentMethod = ({ paymentMethods, onSubmit }) => {
    const { register, getValues, setValue } = useForm({
        mode: 'onChange',
    })

    const context = useSelector((state) => state.app.context || {})
    const { paymentInfo } = context.projectConfiguration
    const { sum } = useSelector((state) => state.cart?.cart?.data || {})

    const onChange = () => {
        onSubmit(getValues())
    }

    const isTestMode = window.location.search.indexOf('testPayment=true') >= 0

    const renderPaymentDescription = (paymentType) => {
        const paymentDetail = paymentInfo.filter((payment) => payment.key === paymentType)

        if (!paymentDetail.length || !paymentDetail[0]?.description?.trim()) {
            return null
        }

        return (
            <div
                className={`paymentDescription-${paymentType}`}
            >
                {paymentDetail[0].description}
            </div>
        )
    }

    const isValid = (paymentType) => {
        if (!window.ApplePaySession && paymentType.type === 'applepay') {
            return false
        }

        const matchedPayments = paymentInfo.filter((payment) => payment.key === paymentType.type)
        const paymentDetail = matchedPayments[0] ?? null
        if (!paymentDetail) {
            return true
        }

        if (paymentDetail.isTest && !isTestMode) {
            return false
        }

        if (!paymentDetail.minAmount && !paymentDetail.maxAmount) {
            return true
        }

        if (paymentDetail.minAmount && paymentDetail.minAmount > 0 && sum < paymentDetail.minAmount) {
            return false
        }

        if (paymentDetail.maxAmount && paymentDetail.maxAmount > 0 && sum > paymentDetail.maxAmount) {
            return false
        }

        return true
    }

    const getLogoUrl = (paymentMethod) => {
        if (paymentMethod.provider === 'payever') {
            return paymentMethod.logo
        }

        const url = paymentMethod.type !== 'scheme' ? `https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/${paymentMethod.type}.svg`
            : 'https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/mc.svg'

        return url
    }

    const getPaymentName = (paymentMethod) => {
        const paymentMethodFromProjectSetting = paymentInfo.find((payment) => payment.key === paymentMethod.type)
        return paymentMethodFromProjectSetting?.title ? `${paymentMethodFromProjectSetting?.title}` : paymentMethod.name
    }


    const renderPaymentLogo = (paymentMethod) => {
        if (paymentMethod.type === 'scheme') {
            return (
                <div className={'payment-content-logo flex gap-1'}>
                    <VisaIcon />
                    <MastercardIcon />
                </div>
            )
        }

        if (paymentMethod.type === 'zinia_bnpl_de') {
            return <div className={'payment-content-logo'}><ZiniaIcon /></div>
        }
        const url = getLogoUrl(paymentMethod)

        return (
            <div className={'payment-content-logo'}>
                <img src={url} alt={paymentMethod.type} />
            </div>)
    }

    return (
        <form className={'payment-content'} onChange={onChange}>
            {paymentMethods?.paymentMethods?.map((paymentMethod) => {
                if (!isValid(paymentMethod)) {
                    return null
                }

                return (
                    <div
                        key={paymentMethod.type}
                        className={'payment-type checkbox-radio--wrapper cursor-pointer'}
                        onClick={() => {
                            setValue('type', paymentMethod.type)
                            setValue('name', paymentMethod.name)
                            setValue('provider', paymentMethod.provider)
                            onChange()
                        }}
                    >
                        <input
                            type={'radio'}
                            aria-label={'Payment method'}
                            {...register('type')}
                            value={paymentMethod.type}
                            id={`payment-method-${paymentMethod.type}`}
                            className={'mr-3'} />
                        <input
                            type={'hidden'}
                            aria-label={'Payment method name'}
                            {...register('name')}
                            value={paymentMethod.name}
                            className={'mr-3'} />
                        <input
                            type={'hidden'}
                            aria-label={'Payment method provider'}
                            {...register('provider')}
                            value={paymentMethod.provider}
                            className={'mr-3'} />
                        <div className={'payment-content-detail w-full flex justify-between flex-row'}>
                            <div className={'payment-content-desc'}>
                                <label className={'font-bold'} htmlFor={`payment-method-${paymentMethod.type}`}>
                                    {getPaymentName(paymentMethod)}
                                </label>
                                {renderPaymentDescription(paymentMethod.type)}
                            </div>
                            {renderPaymentLogo(paymentMethod)}
                        </div>
                    </div>
                )
            })}
        </form >
    )
}

PaymentMethod.propTypes = {
    paymentMethods: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

export default PaymentMethod

import React from 'react'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as EmailIcon } from '../../../../../icons/sport2000-icons/envelope.svg'
import { ReactComponent as PhoneIcon } from '../../../../../icons/sport2000-icons/phone.svg'
import { ReactComponent as RouteIcon } from '../../../../../icons/sport2000-icons/route.svg'
import SpecialLogo from './SpecialLogo'

const RouteAndLogo = ({
    logos, telephone, route, email,
}) => {
    const isDesktop = useDeviceType() === 'desktop'

    const routeLinkFormat = `https://www.google.com/maps/dir//${route.lat},${route.lng}/@${route.lat},${route.lng},17z`

    return (
        <div className={'store-detail-route-and-logo'}>
            <>
                {isDesktop ? (
                    <a
                        href={routeLinkFormat}
                        className={'btn btn-default w-full'}
                        target={'_blank'}
                        rel={'noreferrer'}
                    >
                        <FormattedMessage id={'storeDetail.routeDesktop'} />
                    </a>
                ) : (
                    <div className={'store-detail-route'}>
                        <div className={'store-detail-route-link store-detail-route-button'}>
                            <RouteIcon
                                width={20}
                                height={20}
                                className={'store-detail-route-button-icon'}
                            />
                            <a
                                href={routeLinkFormat}
                                target={'_blank'}
                                rel={'noreferrer'}
                            >
                                <FormattedMessage id={'storeDetail.routeMobile'} />
                            </a>
                        </div>
                        {telephone && (
                            <div className={'store-detail-route-phone store-detail-route-button'}>
                                <PhoneIcon
                                    width={20}
                                    height={20}
                                    className={'store-detail-route-button-icon'}
                                />
                                <a href={`tel: ${telephone}`}>
                                    <FormattedMessage id={'storeDetail.telephoneMobile'} />
                                </a>
                            </div>
                        )}

                        {email && (
                            <div
                                className={'store-detail-route-email store-detail-route-button'}
                            >
                                <EmailIcon
                                    width={20}
                                    height={20}
                                    className={'store-detail-route-button-icon'}
                                />
                                <a href={`mailto: ${email}`}>
                                    <FormattedMessage id={'storeDetail.email'} />
                                </a>
                            </div>
                        )}
                    </div>
                )}
            </>

            <div className={'mt-6'}>
                <SpecialLogo logos={logos} />
            </div>
        </div>
    )
}

RouteAndLogo.propTypes = {
    route: PropTypes.object.isRequired,
    logos: PropTypes.array.isRequired,
    telephone: PropTypes.string,
    email: PropTypes.string,
}

export default RouteAndLogo
